<div mat-dialog-close class="close-button" (click)="close()">
  <mat-icon>close</mat-icon>
</div>
<div mat-dialog-header>
  <h1 class="mb-0"> Launch to Annuity Wizard </h1>
  <p>
    Drag and drop annuities to specify if they will be used in a
    replacement/1035 exchange (one or more), or subseqent deposit (limit to
    one).
  </p>
</div>

<div mat-dialog-content class="dialog-content">
  <div class="list-container">
    <h4>Selected Annuities</h4>

    <div
      cdkDropList
      #selectedList="cdkDropList"
      [cdkDropListData]="selected"
      [cdkDropListConnectedTo]="[exchangeList, depositList]"
      class="list"
      (cdkDropListDropped)="drop($event)"
    >
      @for (item of selected; track item.ID) {
        <div class="list-item-box" cdkDrag>
          <span class="list-item-name">
            <mat-icon class="mr-1">drag_handle</mat-icon>
            {{ item.Name }}
          </span>
          <button
            mat-icon-button
            color="warn"
            (click)="removeAnnuity(item, 'selected')"
            matTooltip="Remove Annuity"
          >
            <mat-icon>close</mat-icon>
          </button>
        </div>
      }
    </div>
  </div>

  <div class="list-container">
    <h4>Replacement/1035 Exchange</h4>

    <div
      cdkDropList
      #exchangeList="cdkDropList"
      [cdkDropListData]="exchange"
      [cdkDropListConnectedTo]="[selectedList, depositList]"
      class="list"
      (cdkDropListDropped)="drop($event)"
    >
      @for (item of exchange; track item.ID) {
        <div class="list-item-box" cdkDrag>
          <span class="list-item-name">
            <mat-icon class="mr-1">drag_handle</mat-icon>
            {{ item.Name }}
          </span>
          <button
            mat-icon-button
            color="warn"
            (click)="removeAnnuity(item, 'exchange')"
            matTooltip="Remove Annuity"
          >
            <mat-icon>close</mat-icon>
          </button>
        </div>
      }
    </div>
  </div>

  <div class="list-container">
    <h4>Subsequent Deposit</h4>

    <div
      cdkDropList
      #depositList="cdkDropList"
      [cdkDropListData]="deposit"
      [cdkDropListConnectedTo]="[selectedList, exchangeList]"
      class="list deposit-list"
      (cdkDropListDropped)="drop($event)"
    >
      @for (item of deposit; track item.ID) {
        <div class="list-item-box" cdkDrag>
          <span class="list-item-name">
            <mat-icon class="mr-1">drag_handle</mat-icon>
            {{ item.Name }}
          </span>
          <button
            mat-icon-button
            color="warn"
            (click)="removeAnnuity(item, 'deposit')"
            matTooltip="Remove Annuity"
          >
            <mat-icon>close</mat-icon>
          </button>
        </div>
      }
    </div>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-raised-button class="mr-2" (click)="close()">Cancel</button>
  <button
    mat-raised-button
    color="accent"
    (click)="saveData()"
    [disabled]="selected && selected.length > 0"
  >
    Save
  </button>
</div>

<ngx-loading
  [show]="loading"
  [config]="{ fullScreenBackdrop: false }"
></ngx-loading>
