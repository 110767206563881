export const checkPercentVar = function (varName, questions) {
  let returnVal = false;
  const percVar = questions.find(x => {
    return (
      x.varName === varName.replace('_', '.') &&
      x.controlType.toLowerCase().includes('percentage')
    );
  });

  returnVal = percVar ? true : returnVal;

  return returnVal;
};
