import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-postback-loading',
  templateUrl: './postback-loading.component.html',
  styleUrls: ['./postback-loading.component.scss']
})
export class PostbackLoadingComponent {
  @Input() show;
}
