import { effect, Injectable, signal } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable, Subject, throwError } from 'rxjs';
import { SessionStorageService } from '../services/session-storage.service';
import { RightBridgeApiService } from '../services/right-bridge-api.service';
import { EnvironmentService } from '../services/environment.service';
import { WarnDialogComponent } from '../warn-dialog/warn-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ModuleStatusService } from '../services/module-status.service';
import { FormService } from '../conditional-form/form-service.service';
@Injectable({
  providedIn: 'root',
})
export class UnifiedFlowService {
  isNaN: Function = Number.isNaN;
  private environment;
  private org: string;
  private notesValid = {
    pp: true,
    aw: true,
    li: true,
    iw: true,
    rb: true,
    summary: true,
  };
  private steps = [];
  private saving = false;
  loading = false;
  private triggerSave = new Subject<string>();
  triggerSaveExternal = this.triggerSave.asObservable();
  private triggerValidate = new Subject<string>();
  triggerValidateExternal = this.triggerValidate.asObservable();
  private newProfile = new Subject<string>();
  newProfileExternal = this.newProfile.asObservable();
  private stepsList = new Subject<object[]>();
  stepsListExternal = this.stepsList.asObservable();
  private checkStatuses = new Subject<object[]>();
  checkStatusesExternal = this.checkStatuses.asObservable();
  private rights: string[] = [];
  formValid = signal(true);
  loadingSignal = signal(false);

  constructor(
    private http: HttpClient,
    private ss: SessionStorageService,
    private rbs: RightBridgeApiService,
    private envSvc: EnvironmentService,
    private dialog: MatDialog,
    private modStatusSvc: ModuleStatusService,
    private frmSvc: FormService
  ) {
    this.environment = this.envSvc.get();
    this.org = this.environment.org;
    this.rights = this.ss.get('rights');
  }

  getSession() {
    return this.rbs.getSession();
  }

  getApps(profile) {
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', this.getSession())
      .set('X-RB-I', 'rb')
      .set('X-RB-O', this.org);
    return this.rbs.getRequest(
      `${this.rbs.baseUrl}/GroupProfile/${profile}?groupName=ModuleStatus&saveProfile=false`,
      reqHeaders
    );
  }

  getAllApps() {
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', this.getSession())
      .set('X-RB-I', 'rb')
      .set('X-RB-O', this.org);
    return this.rbs.postRequest(
      `${this.rbs.baseUrl}/GroupProfile?groupName=ModuleStatus&saveProfile=false`,
      { profileId: '' },
      reqHeaders
    );
  }

  getNextApp(profile) {
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', this.getSession())
      .set('X-RB-I', 'rb')
      .set('X-RB-O', this.org);
    return this.rbs.getRequest(
      `${this.rbs.baseUrl}/GroupProfile/${profile}?groupName=NextModule&saveProfile=false`,
      reqHeaders
    );
  }

  getPPResults(profile) {
    this.rbs.setApp('pp');
    return this.rbs.getRequest(
      `${this.rbs.baseUrl}/GroupProfile/${profile}?groupName=ProcessProducts`
    );
  }

  savePPSelected(profile, data) {
    return this.rbs.patchRequest(
      `${this.rbs.baseUrl}/GroupProfile?groupName=ProcessProducts`,
      data
    );
  }

  summaryData(profile) {
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', this.getSession())
      .set('X-RB-I', 'rb')
      .set('X-RB-O', this.org);
    return this.rbs.getRequest(
      `${this.rbs.baseUrl}/GroupProfile/${profile}?groupName=SummaryPage&saveProfile=false`,
      reqHeaders
    );
  }

  saveNote(profile, note, bridge, app): Observable<any> {
    let appCode = 2311;

    switch (app.toLowerCase()) {
      case 'aw':
        appCode = 2341;
        break;
      case 'pp':
        appCode = 2311;
        break;
      case 'li':
        appCode = 2331;
        break;
      case 'iw':
        appCode = 2321;
        break;
    }

    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', this.getSession())
      .set('X-RB-I', app)
      .set('X-RB-O', this.org);
    const url = `${this.rbs.baseUrl}/Widget/SaveBridgeActions`;
    const data = {
      records: [
        {
          profileID: profile,
          bridgeID: bridge,
          actionID: appCode,
          note: note,
        },
      ],
    };

    return this.http.post(url, data, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        console.error(error);
        return throwError(error.error || 'Server error');
      })
    );
  }

  validateNote(bridgeVal, note, id?) {
    const prePrompt =
      this.ss.get('globals').GlobalsGroup.UnitAPI.NoteValidationConfig;
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', this.getSession())
      .set('X-RB-I', 'rb')
      .set('X-RB-O', this.org);
    let url = `${this.rbs.baseUrl}/Widget/Chat?prePrompt=${prePrompt}`;
    if (id) {
      const profileObj = { profileid: id };
      url = url + `&configuration=${JSON.stringify(profileObj)}`;
    }
    const data = {
      ReasonText: bridgeVal,
      SubmittedNote: note,
    };

    return this.http.post(url, data, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        console.error(error);
        return throwError(error.error || 'Server error');
      })
    );
  }

  setNoteValidity(data) {
    if (!this.notesValid) {
      this.notesValid = {
        pp: true,
        aw: true,
        li: true,
        iw: true,
        rb: true,
        summary: true,
      };
    }
    this.notesValid[data.app] = data.valid;
  }

  getNoteValidity(app) {
    if (this.notesValid) {
      return this.notesValid[app];
    }
    return true;
  }

  setLoading(data) {
    this.loading = data;
    this.loadingSignal.set(data);
  }

  getloading() {
    return this.loading;
  }

  setSteps(data) {
    this.steps = [...data];
    this.stepsList.next([...data]);
  }

  getSteps() {
    return this.steps;
  }

  setSaving(val) {
    this.saving = val;
  }

  getSaving() {
    return this.saving;
  }

  setTriggerSave(triggerSave) {
    this.triggerSave.next(triggerSave);
  }

  setTriggerValidate(triggerValidate) {
    this.triggerValidate.next(triggerValidate);
  }

  setNewProfile(profile) {
    this.newProfile.next(profile);
  }

  setStatusCheck() {
    this.checkStatuses.next();
  }

  allowNavigation(
    stepNum = true,
    externalClick?,
    next = false,
    forceTrue = false,
    params?
  ): boolean {
    const notesActive =
      this.rights.includes('BridgeNotes') &&
      this.isNaN(+params.currentStep) &&
      (params.currentStep == 'validate' ||
        params.currentStep == 'results' ||
        params.currentStep == 'products');
    const saveActive = this.getSaving();

    if (forceTrue) {
      return true;
    } else if (params.currentApp == 'rb' &&
    !this.frmSvc.formValid() &&
    // params.currentStep !== '0' &&
    !this.modStatusSvc.clientInfoNotRequired()) {
      this.triggerQuestionnaireError();
    } else if (!this.frmSvc.formValid() && (+params.currentStep + 1 === params.steps.length || params.requestedStep && this.isNaN(+params.requestedStep)) && (params.currentApp == 'rb' && !this.modStatusSvc.clientInfoNotRequired() || params.currentApp !== 'rb')) {
      this.triggerQuestionnaireError();
    } else if (
      !params.prodsSelectedPP &&
      params.currentApp == 'pp' &&
      params.currentStep == 'results' &&
      next
    ) {
      this.triggerQuestionnaireError('Please Select a Product to Continue');
    } else if (
      !params.progSelectedIW &&
      params.currentApp == 'iw' &&
      params.currentStep == 'program-selection' &&
      (next || !stepNum)
    ) {
      this.triggerQuestionnaireError('Please Select a Program to Continue');
    } else {
      if (!saveActive || this.getloading()) {
        if (notesActive) {
          if (this.notesValid[params.currentApp] || externalClick) {
            return true;
          } else {
            this.handleNotesError();
            return false;
          }
        } else {
          return true;
        }
      }
    }
    return false;
  }

  triggerQuestionnaireError(
    error = 'Please Fix Questionnaire Errors to Continue'
  ) {
    let navDialog = this.dialog.open(WarnDialogComponent, {
      minWidth: '35vw',
      panelClass: 'warn-dialog',
      data: {
        headline: 'Warning',
        content: error,
        confirm: 'OK',
        hideCancel: true,
      },
    });
    navDialog.afterClosed().subscribe(() => {
      this.setLoading(false);
      navDialog = null;
    });
  }

  handleNotesError() {
    let dialogRef = this.dialog.open(WarnDialogComponent, {
      minWidth: '60vw',
      panelClass: 'warn-dialog',
      data: {
        headline: 'Warning',
        content:
          '<div class="warn-text">Please enter rationale on all required items (as indicated by the "Notes Required for This Item" flag).<br/>Each required note needs to be filled out and <u>saved</u> in order to continue.</div>',
        confirm: 'OK',
        hideCancel: true,
      },
    });
    dialogRef.afterClosed().subscribe(() => {
      dialogRef = null;
    });
  }
}
