<mat-form-field class="mr-3">
  <mat-label>Application</mat-label>
  <mat-select [(ngModel)]="reason.app">
    <mat-option
      *ngFor="let app of applications"
      [value]="app.value"
    >
      {{ app.name }}
    </mat-option>
  </mat-select>
</mat-form-field>
<mat-form-field class="" class="mr-3">
  <input
    type="text"
    matInput
    placeholder="Search For a Unit"
    [(ngModel)]="reason.unit"
    [matAutocomplete]="reasonAuto"
    name="reasonUnitsControl"
    [formControl]="reasonUnitsControl"
  />
  <mat-autocomplete #reasonAuto="matAutocomplete">
    <mat-option
      *ngFor="let unit of filteredUnits | async"
      [value]="unit.id"
    >
      {{ unit.name }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
<button
  mat-raised-button
  color="primary"
  class="mx-auto"
  (click)="getReasonText()"
  [disabled]="!reason.unit || !reason.app"
>
  Download
</button>
<ngx-loading [show]="loading"></ngx-loading>
