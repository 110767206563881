import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { WarnDialogComponent } from '../warn-dialog/warn-dialog.component';
import { FormControl, Validators } from '@angular/forms';
import { UniversalSearchService } from '../universal-search/services/universal-search.service';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { SessionStorageService } from '../services/session-storage.service';
import { ManageFiltersService } from '../services/manage-filters.service';
import { FilterItem } from '../models/filter-models';
import { Subject } from 'rxjs';
import { DateTime } from 'luxon';
import { CustomFiltersService } from '../services/custom-filters.service';
import { isEmpty } from 'lodash';
import { states } from '../lib/states';
import { MaskService } from '../services/mask.service';

@Component({
  selector: 'app-manage-filters',
  templateUrl: './manage-filters.component.html',
  styleUrl: './manage-filters.component.scss',
})
export class ManageFiltersComponent implements OnInit, OnDestroy {
  @Input() currentFilters: any;
  @Input() additionalFilters: any;
  @Input() currentForm: any;
  @Input() currentFormFields: any;
  @Input() grid: string;
  @Output() selectedTemplate = new EventEmitter();
  @ViewChild('manageFiltersDialog') manageFiltersDialog: TemplateRef<any>;
  @ViewChild('unitInput') unitInput: any;

  UNAVAILABLE_GRIDS = ['income', 'product', 'death'];

  canSaveEditUnitFilters = false;
  changeDetected: boolean;
  currentSelectedFields: string[];
  currentSelectedFieldsValues: any[];
  currentSelectedFilters = { logic: 'and', filters: [] };
  currentSelectedSavedFilter: any;
  currNumFilters: any;
  filterID: any;
  filterOpKey = {};
  globals: any;
  listOfVarNames = [];
  loading = false;
  managedUnits = [];
  modalAction: string;
  modalDataUpToDate = false;
  newFilterName = '';
  newFilterNameControl = new FormControl('', [Validators.maxLength(50)]);
  orgUnit: string;
  orgUnitFilterSelected = false;
  overwriteSaveControl = new FormControl([Validators.required]);
  rights: any;
  rootUnit: string;
  savedFilters: any;
  savedFiltersOrg = {};
  savedGlobalFilters: any[];
  savedManagerFilters: any[];
  savedUserFilters: any[];
  saveMethod: string;
  scope = 0;
  selectedFilters: any;
  templateActive = false;
  unitList = [];
  unitScope = 'user';
  unitSearchControl = new FormControl();
  unitTypeControl = new FormControl([Validators.required]);
  userID: any;
  unsubscribe = new Subject();
  theStates = states;
  filtersFormatted: any;

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<ManageFiltersComponent, null>,
    private manageFilter: ManageFiltersService,
    private ss: SessionStorageService,
    private usServ: UniversalSearchService,
    public cstmFltrSrvc: CustomFiltersService,
    private mask: MaskService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.getSavedFilters();
    this.unitSearchControl.valueChanges
      .pipe(
        takeUntil(this.unsubscribe),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(value => {
        this.getUnitList(value);
      });

    this.rights = this.ss.get('rights');
    if (
      this.rights &&
      this.rights.includes('EditTradeFilters') &&
      !this.UNAVAILABLE_GRIDS.includes(this.grid)
    ) {
      this.canSaveEditUnitFilters = true;
    }
    this.globals = this.ss.get('globals');
    this.userID = this.globals ? this.globals.user.id : null;
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  buildFilterItemDisplay() {
    if (
      !(
        this.filterID &&
        ((this.orgUnitFilterSelected && this.canSaveEditUnitFilters) ||
          (!this.orgUnitFilterSelected && !this.canSaveEditUnitFilters))
      )
    ) {
      this.saveMethod = 'save';
    }

    //This next line looks dumb, but it's important.
    //resetting this.currentSelectedFilters.filters also wipes out this.currentFilters.
    //resetting this.currentFilters to a parsed version of itself separates the two variables.
    this.currentFilters = JSON.parse(JSON.stringify(this.currentFilters));

    this.currentSelectedFilters.filters = [];
    this.currentSelectedFields = [];
    this.currentSelectedFields = Object.keys(
      this.currentForm.value.primaryForm
    ).filter(filterItem => {
      return (
        typeof this.currentForm.value.primaryForm[filterItem] === 'number' ||
        (this.currentForm.value.primaryForm[filterItem] &&
          (this.currentForm.value.primaryForm[filterItem].length > 0 ||
            !isEmpty(this.currentForm.value.primaryForm[filterItem])))
      );
    });
    if (this.currentFormFields['secondaryForm']) {
      this.currentSelectedFields = [
        ...this.currentSelectedFields,
        ...Object.keys(this.currentForm.value.secondaryForm).filter(
          filterItem => {
            return (
              typeof this.currentForm.value.secondaryForm[filterItem] ===
                'number' ||
              (this.currentForm.value.secondaryForm[filterItem] &&
                (this.currentForm.value.secondaryForm[filterItem].length > 0 ||
                  !isEmpty(this.currentForm.value.secondaryForm[filterItem])))
            );
          }
        ),
      ];
    }
    this.currentSelectedFieldsValues = [];
    this.currentSelectedFieldsValues = Object.values(
      this.currentForm.value.primaryForm
    );
    if (this.currentFormFields['secondaryForm']) {
      this.currentSelectedFieldsValues = [
        ...this.currentSelectedFieldsValues,
        ...Object.values(this.currentForm.value.secondaryForm),
      ];
    }

    this.getAndFormatFilters('primaryForm');
    if (this.currentFormFields['secondaryForm']) {
      this.getAndFormatFilters('secondaryForm');
    }
    if (this.additionalFilters) {
      this.getAndFormatFilters('additionalFilters');
    }
  }

  getAndFormatFilters(filterFormGroup) {
    let formFieldGroup;
    let currentFormFieldCheck;
    if (filterFormGroup === 'additionalFilters') {
      formFieldGroup = this.additionalFilters;
      currentFormFieldCheck = 'primaryForm';
    } else {
      formFieldGroup = this.currentFormFields[filterFormGroup];
      currentFormFieldCheck = filterFormGroup;
    }
    formFieldGroup.forEach(selectedItem => {
      if (this.currentSelectedFields.includes(selectedItem['varName'])) {
        const optionValueToAddList = [];
        const optionDisplayToAddList = [];
        let optionValueToAddItem: string;
        let optionDisplayToAddItem: string;
        if (
          selectedItem.controlType === 'repCode' ||
          selectedItem.controlType === 'unit' ||
          selectedItem.controlType === 'text' ||
          selectedItem.controlType === 'integer' ||
          selectedItem.controlType === 'currency'
        ) {
          optionValueToAddItem =
            this.currentForm.value[currentFormFieldCheck][
              selectedItem['varName']
            ];
          optionDisplayToAddItem =
            this.currentForm.value[currentFormFieldCheck][
              selectedItem['varName']
            ];
        } else if (selectedItem.controlType === 'presetRange') {
          optionValueToAddItem =
            this.currentForm.value[currentFormFieldCheck][
              selectedItem['varName']
            ];
          optionDisplayToAddItem =
            this.currentForm.value[currentFormFieldCheck][
              selectedItem['varName']
            ];
        } else if (selectedItem.controlType === 'currencyRange') {
          optionValueToAddItem =
            this.currentForm.value[currentFormFieldCheck][
              selectedItem['varName']
            ];
          optionDisplayToAddItem = `$${
            this.currentForm.value[currentFormFieldCheck][
              selectedItem['varName']
            ].min
          } -> $${
            this.currentForm.value[currentFormFieldCheck][
              selectedItem['varName']
            ].max
          }`;
        } else {
          if (selectedItem['controlType'] === 'states') {
            this.theStates.map(stateItem => {
              if (
                this.currentForm.value[currentFormFieldCheck][
                  selectedItem['varName']
                ].includes(stateItem.value)
              ) {
                optionValueToAddList.push(stateItem.value);
                optionDisplayToAddList.push(
                  stateItem.label ? stateItem.label : stateItem.value
                );
              }
            });
          }
          selectedItem.options.filter(option => {
            if (
              (typeof this.currentForm.value[currentFormFieldCheck][
                selectedItem['varName']
              ] !== 'number' &&
                this.currentForm.value[currentFormFieldCheck][
                  selectedItem['varName']
                ].includes(option.value)) ||
              (typeof this.currentForm.value[currentFormFieldCheck][
                selectedItem['varName']
              ] === 'number' &&
                this.currentForm.value[currentFormFieldCheck][
                  selectedItem['varName']
                ] === option.value)
            ) {
              if (selectedItem.controlType === 'multiselect') {
                optionValueToAddList.push(option.value);
                optionDisplayToAddList.push(
                  option.display ? option.display : option.value
                );
              } else {
                optionValueToAddItem = option.value;
                optionDisplayToAddItem = option.display
                  ? option.display
                  : option.value;
              }
            }
          });
        }

        const selectedFilterDetails = {
          label: selectedItem['label'],
          field: selectedItem['varName'],
          operator: this.filterOpKey[selectedItem['varName']],
          value:
            optionValueToAddList.length > 0
              ? optionValueToAddList
              : optionValueToAddItem,
          display:
            optionDisplayToAddList.length > 0
              ? optionDisplayToAddList
              : optionDisplayToAddItem,
        };

        if (
          selectedItem.controlType === 'presetRange' &&
          this.currentForm.value[currentFormFieldCheck][
            selectedItem['varName']
          ] === 'custom'
        ) {
          const start = this.getPresetDateValue(
            selectedItem['varName'],
            'start'
          );
          const end = this.getPresetDateValue(selectedItem['varName'], 'end');
          selectedFilterDetails['display'] = `Custom (${DateTime.fromISO(
            start,
            { zone: 'utc' }
          ).toFormat('M/d/yyyy')} - ${DateTime.fromISO(end, {
            zone: 'utc',
          }).toFormat('M/d/yyyy')})`;

          selectedFilterDetails['customRange'] = {
            start,
            end,
          };
        } else if (selectedItem.filterType === 'between') {
          const betweenSiblingFilter = {
            label: selectedItem['label'],
            field: selectedItem['between2'],
            operator: this.filterOpKey[selectedItem['between2']],
            value:
              optionValueToAddList.length > 0
                ? optionValueToAddList
                : optionValueToAddItem,
            display:
              optionDisplayToAddList.length > 0
                ? optionDisplayToAddList
                : optionDisplayToAddItem,
          };
          this.currentSelectedFilters.filters.push(betweenSiblingFilter);
        }

        this.currentSelectedFilters.filters.push(selectedFilterDetails);

        this.filtersFormatted = JSON.parse(
          JSON.stringify(
            this.currentSelectedFilters.filters.reduce(
              (container, filterItem) => {
                if (
                  (filterItem.operator === 'lte' ||
                    filterItem.operator === 'gte') &&
                  !container.find(
                    item => item.operator === 'lte' || item.operator === 'gte'
                  )
                ) {
                  return [...container, filterItem];
                } else if (
                  filterItem.operator !== 'lte' &&
                  filterItem.operator !== 'gte'
                ) {
                  return [...container, filterItem];
                }
                return container;
              },
              []
            )
          )
        );
        this.currentSelectedFilters.filters.forEach(filterItem => {
          filterItem.value = filterItem.value.includes('$')
            ? this.mask.removeMoneyMask(filterItem.value)
            : filterItem.value;
          filterItem.display = filterItem.display.includes('$')
            ? this.mask.removeMoneyMask(filterItem.display)
            : filterItem.display;
        });
      }
    });
  }

  getPresetDateValue(varName, position) {
    let returnValue;

    if (
      this.currentFilters.filters.find(
        filter => filter['field'] === varName && filter['operator'] === position
      )
    ) {
      returnValue = this.currentFilters.filters.find(
        filter => filter['field'] === varName && filter['operator'] === position
      ).value;
    }
    return returnValue;
  }

  changeSelection(ev) {
    this.orgUnit = ev.option.value;
  }

  close() {
    this.dialogRef.close();
    this.dialogRef.afterClosed().subscribe(() => {
      this.dialogRef = null;
    });
  }

  deleteFilter(ev, filter, localSection, parentSection, filterIndex) {
    ev.stopPropagation();
    const warnDialogRef = this.dialog.open(WarnDialogComponent, {
      panelClass: 'warn-dialog',
      data: {
        headline: 'Warning',
        content: `Are you sure you would like to delete the filter: ${filter.Name}? Filter deletion cannot be undone.`,
        confirm: 'Delete',
      },
    });
    warnDialogRef.afterClosed().subscribe(result => {
      if (result && result === 'continue') {
        if (filter.Scope === 0) {
          this.savedUserFilters = this.savedUserFilters.splice(
            this.savedUserFilters.indexOf(
              this.savedUserFilters.find(filter => filter.FilterID)
            ),
            1
          );
        } else {
          if (parentSection) {
            this.savedManagerFilters.forEach(filter => {
              if (filter.unit === parentSection.unit) {
                filter.filters.splice(filterIndex, 1);
              }
            });
          } else {
            this.savedGlobalFilters = this.savedGlobalFilters.splice(
              this.savedGlobalFilters.indexOf(
                this.savedGlobalFilters.find(filter => filter.FilterID)
              ),
              1
            );
          }
        }

        if (filter.FilterID === +this.filterID) {
          this.newFilterName = '';
          this.filterID = null;
          this.selectedTemplate.emit({ logic: 'and', filters: [] });
        }

        this.manageFilter
          .deleteFilterTemplate(filter.FilterID)
          .subscribe(() => {
            this.getSavedFilters();
          });
      }
    });
  }

  getSavedFilters() {
    this.loading = true;
    let respManagerUnits = [];
    this.manageFilter.getSavedFilterTemplates().subscribe(res => {
      if (res && res['User']) {
        this.savedUserFilters = res['User'].filter(
          filter => filter.Grid === this.grid
        );
      }
      if (res && res['Manager']) {
        this.managedUnits = [];
        this.savedManagerFilters = [];
        respManagerUnits = res['Manager'].filter(filter => {
          if (filter.Grid === this.grid) {
            if (!this.managedUnits.includes(filter.RootUnitID)) {
              this.managedUnits.push(filter.RootUnitID);
            }
          }
          return filter;
        });
        this.managedUnits.forEach(unit => {
          this.savedManagerFilters.push({
            unit: unit,
            filters: respManagerUnits.filter(filter => {
              return filter.RootUnitID === unit;
            }),
          });
        });
      }
      if (res && res['Global']) {
        this.savedGlobalFilters = res['Global'].filter(
          filter => filter.Grid === this.grid
        );
      }
      this.savedFilters = [
        ...this.savedUserFilters,
        ...respManagerUnits,
        ...this.savedGlobalFilters,
      ];
      if (this.filterID) {
        this.savedFilters.forEach(filter => {
          if (filter.FilterID === +this.filterID) {
            this.currentSelectedSavedFilter = filter;
          }
        });
      }
      this.loading = false;
    });
  }

  getUnitList(searchString: string) {
    this.usServ.unitSearch(searchString).subscribe(resp => {
      const newUnitsList = resp.names.map(rep => ({
        value: rep.id,
        display: rep.name,
      }));
      this.unitList = newUnitsList;
    });
  }

  isDisabled() {
    if (!this.newFilterName || this.newFilterName.length > 50) {
      return true;
    }
    if (this.unitScope !== 'user' && !this.orgUnit) {
      return true;
    }
    if (!this.saveMethod) {
      return true;
    }
    if (this.currentSelectedFilters.filters.length === 0) {
      return true;
    }
    return false;
  }

  launchFilterModal(operation: string) {
    if (operation === 'save') {
      this.currentFilters.filters.forEach(f => {
        this.filterOpKey[f.field] = f.operator;
      });
      this.buildFilterItemDisplay();
    }

    this.modalAction = operation;
    this.dialogRef = this.dialog.open(this.manageFiltersDialog, {
      minWidth: '60vw',
      panelClass: 'template-dialog',
      disableClose: true,
    });
    this.dialogRef.afterClosed().subscribe(() => {
      this.currentSelectedFields = null;
      this.currentSelectedFilters.filters = [];
    });
  }

  submitData(submitAction: string) {
    if (this.unitScope === 'user') {
      this.scope = 0;
    } else if (this.unitScope === 'manager') {
      this.scope = 1;
    } else if (this.unitScope === 'global') {
      this.scope = 2;
    }

    const presetFilterToSave: FilterItem = {
      filterID: this.saveMethod === 'overwrite' ? this.filterID : null,
      grid: this.grid,
      name: this.newFilterName,
      scope: this.scope,
      rootUnitID: this.orgUnit,
      JSONTemplate: {
        logic: 'and',
        filters: this.currentSelectedFilters.filters,
      },
    };
    if (submitAction === 'save') {
      this.loading = true;
      this.manageFilter
        .saveFilterTemplate(presetFilterToSave)
        .subscribe(res => {
          this.loading = false;
          if (res) {
            this.filterID = res['FilterID'];
          }
          this.saveMethod = null;
          this.getSavedFilters();
          this.close();
        });
    }
  }

  templateSelected(ev: any) {
    this.filterID = ev.value.FilterID;
    this.newFilterName = ev.value.Name;
    this.currentSelectedFilters = ev.value.FilterData;
    this.currNumFilters = this.currentSelectedFilters.filters.length;
    this.orgUnitFilterSelected = false;
    this.scope = ev.value.Scope;
    if (this.scope === 0) {
      this.unitScope = 'user';
      this.orgUnitFilterSelected = false;
      this.orgUnit = ev.value.RootUnitID;
    }
    if (this.scope === 1) {
      this.unitScope = 'manager';
      this.orgUnitFilterSelected = true;
      this.orgUnit = ev.value.RootUnitID;
      this.unitSearchControl.setValue(this.orgUnit);
    }
    if (this.scope === 2) {
      this.unitScope = 'global';
      this.orgUnitFilterSelected = true;
      this.orgUnit = ev.value.RootUnitID;
      this.unitSearchControl.setValue(this.orgUnit);
    }
    if (!this.canSaveEditUnitFilters) {
      this.unitScope = 'user';
    }
    this.selectedTemplate.emit(ev.value.FilterData);
  }
}
