<div class="w-100 file-information" [ngClass]="{ 'modal-view': modalView }">
  @if (modalView) {
    <div class="general-build-info">
      <div class="app-files-container">
        <div class="app-name">UI</div>
        <div class="app-files"> <strong>Version:</strong> {{ uiVersion }} </div>
      </div>
      <div class="app-files-container">
        <div class="app-name">Engine</div>
        <div class="app-files"> <strong>Version:</strong> {{ engine }} </div>
      </div>
    </div>
  }
  <ngx-loading
    [show]="loadingAppFiles && modalView"
    [config]="{
      fullScreenBackdrop: false,
      backdropBackgroundColour: 'rgba(0,0,0,0)'
    }"
  ></ngx-loading>
  @if (!loadingAppFiles) {
    @for (app of appFiles; track app.appName) {
      <div class="app-files-container">
        <div class="app-name">
          <span>{{ app.appName.toUpperCase() }}</span>
        </div>
        @for (appFile of app.files; track appFile.label) {
          <div class="app-files">
            <strong>{{ appFile.label }}:</strong> {{ appFile.fileStatus }}
          </div>
        }
      </div>
    }
  }
</div>
