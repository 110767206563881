import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-custom-report-modal',
  templateUrl: './custom-report-modal.component.html',
  styleUrls: ['./custom-report-modal.component.scss'],
})
export class CustomReportModalComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CustomReportModalComponent>
  ) {}

  closeModal(ev) {
    this.dialogRef.close(ev);
  }
}
