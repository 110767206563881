import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  distinctUntilChanged,
  map,
  switchMap,
  startWith,
} from 'rxjs/operators';
import { AdminService } from '../../../admin/admin.service';
import { GridComponent } from '@progress/kendo-angular-grid';
import { ReportingService } from '../../reporting.service';

@Component({
  selector: 'app-bridge-density',
  templateUrl: './bridge-density.component.html',
  styleUrls: ['./bridge-density.component.scss'],
})
export class BridgeDensityComponent implements OnInit {
  densityUnitsControl = new FormControl();
  density = { unit: null, score: null };
  filteredUnits;
  selectedUnit;
  bridgeDensityData = { data: null, headers: null };

  constructor(
    private admnSvc: AdminService,
    private rptSvc: ReportingService
  ) {}

  ngOnInit(): void {
    this.getDensityUnits();
  }

  getBridgeDensity() {
    this.rptSvc
      .getBridgeDensity(this.density.unit, this.density.score)
      .subscribe({
        next: data => {
          this.bridgeDensityData = { ...data.results };
        },
        error: error => {
          console.error(error);
        },
      });
  }

  getDensityUnits() {
    this.filteredUnits = this.densityUnitsControl.valueChanges.pipe(
      startWith('%'),
      distinctUntilChanged(),
      switchMap(value => {
        if ((value && value.length > 2) || value == '%') {
          return this._filterUnits(value);
        }
        return [];
      })
    );
  }

  private _filterUnits(value: string) {
    if (!value) {
      value = '%25';
    }
    const list = this.admnSvc.getUnitList(value).pipe(map(data => data.names));
    return list;
  }

  exportToExcel(grid: GridComponent): void {
    grid.saveAsExcel();
  }
}
