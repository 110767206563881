<div mat-dialog-header>
  <h1>
    Trade Approval History <span> - {{ data.tradeDetail.TradeID }}</span>
  </h1>
</div>
<mat-dialog-content class="dialog-container row">
  <div class="annuity-exchange-container info-card mb-4">
    <table>
      <thead>
        <tr>
          @for (header of tradeApprovalKey; track header.label) {
            <th>{{ header.label }}</th>
          }
        </tr>
      </thead>
      <tbody>
        @for (
          tradeApproval of data.tradeDetail.TradeApprovalHistory;
          track tradeApproval
        ) {
          <tr>
            @for (header of tradeApprovalKey; track header.label) {
              @if (header.key) {
                <td>{{ header.key[tradeApproval[header.varName]] }}</td>
              } @else if (header.valueMask === 'date') {
                <td>{{
                  tradeApproval[header.varName] | localTime: 'shortDateTime'
                }}</td>
              } @else {
                <td>{{ tradeApproval[header.varName] }}</td>
              }
            }
          </tr>
        }
      </tbody>
    </table>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="buttons">
  <button mat-raised-button (click)="cancel()">Close</button>
</mat-dialog-actions>
