import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SessionStorageService } from '../../services/session-storage.service';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { RightBridgeApiService } from '../../services/right-bridge-api.service';
import { EnvironmentService } from '../../services/environment.service';

@Injectable({
  providedIn: 'root',
})
export class AnnuityWizardService {
  private environment;
  private baseUrl: string;
  private firm: string;
  private app = 'aw';
  private org: string;

  constructor(
    private http: HttpClient,
    private ss: SessionStorageService,
    private rbs: RightBridgeApiService,
    private envSvc: EnvironmentService
  ) {
    this.environment = this.envSvc.get();
    this.baseUrl = this.environment.apiBase;
    this.org = this.environment.org;
    this.firm = this.environment.firm || '';
  }

  vaFundFees(ccode, cusip): Observable<any> {
    const sess = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', 'aw')
      .set('X-RB-O', this.org);
    const url = `${this.baseUrl}/Annuities/VAFunds?contractCode=${ccode}&cusip=${cusip}`;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return throwError(error.error || 'Server error');
      })
    );
  }

  faFundFees(bridge): Observable<any> {
    const sess = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', 'aw')
      .set('X-RB-O', this.org);
    const formattedBridge = bridge;
    if (bridge.includes('FA')) {
      formattedBridge.replace('FA', '');
    }
    const url = `${this.baseUrl}/Annuities/FAFunds?bridgeId=${formattedBridge}`;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return throwError(error.error || 'Server error');
      })
    );
  }

  getCarrierGroups(includeAll = false): Observable<any> {
    const sess = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', 'aw')
      .set('X-RB-O', this.org);
    const url = `${this.baseUrl}/Annuities/UnitCarriers?includeAll=${includeAll}`;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return throwError(error.error || 'Server error');
      })
    );
  }

  getFaCarrierGroups(): Observable<any> {
    const sess = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', 'aw')
      .set('X-RB-O', this.org);
    const url = `${this.baseUrl}/Annuities/FAProductCarriers`;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return throwError(error.error || 'Server error');
      })
    );
  }

  getCarrierAnnuities(code): Observable<any> {
    const sess = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', 'aw')
      .set('X-RB-O', this.org);
    const url =
      this.baseUrl + '/Annuities/CarrierAnnuities?carrierCode=' + code;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return throwError(error.error || 'Server error');
      })
    );
  }

  getGroupAnnuities(code): Observable<any> {
    const sess = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', 'aw')
      .set('X-RB-O', this.org);
    const url =
      this.baseUrl + '/Annuities/UnitProductsByCarrier?carrierGroupId=' + code;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return throwError(error.error || 'Server error');
      })
    );
  }

  getFaAnnuities(code): Observable<any> {
    const sess = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', 'aw')
      .set('X-RB-O', this.org);
    const url =
      this.baseUrl +
      '/Annuities/UnitFAProductsByCarrier?carrierGroupId=' +
      code;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return throwError(error.error || 'Server error');
      })
    );
  }

  getAnnuityFactSheet(contract, cusip, date): Observable<Blob> {
    const id = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', id)
      .set('X-RB-I', 'aw')
      .set('X-RB-O', this.org)
      .set('Accept', 'application/pdf');
    const url =
      this.baseUrl + '/Reports/AWFacts/' + contract + '/' + cusip + '/' + date;

    return this.http
      .post(url, null, {
        headers: reqHeaders,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        map(res => {
          return res.body;
        }),
        catchError(error => {
          return throwError(error.error || 'Server error');
        })
      );
  }

  getFaFactSheet(faProductId): Observable<Blob> {
    const id = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', id)
      .set('X-RB-I', 'aw')
      .set('X-RB-O', this.org)
      .set('Accept', 'application/pdf');
    const url = `${this.baseUrl}/Reports/AWFAFacts/${faProductId}`;

    return this.http
      .post(url, null, {
        headers: reqHeaders,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        map(res => {
          return res.body;
        }),
        catchError(error => {
          return throwError(error.error || 'Server error');
        })
      );
  }

  processAnnuities(profile): Observable<any> {
    const id = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', id)
      .set('X-RB-I', 'aw')
      .set('X-RB-O', this.org);

    return this.rbs.getRequest(
      `${this.rbs.baseUrl}/GroupProfile/${profile}?groupName=AWResults`,
      reqHeaders
    );
  }

  processAnnuitiesLocalProfile(profileData): Observable<any> {
    const id = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', id)
      .set('X-RB-I', 'aw')
      .set('X-RB-O', this.org);
    const url = `${this.baseUrl}/Process/Annuities`;

    return this.http.post(url, profileData, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return throwError(error.error || 'Server error');
      })
    );
  }

  getReport(
    profile,
    details?,
    silent = false,
    vaDetails = false,
    validate = false,
    excludeAlternativeReasons = true
  ): Observable<Blob> {
    const id = this.ss.get('session');
    this.firm =
      this.firm && this.firm.length ? this.firm : this.rbs.setFirmId();
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', id)
      .set('X-RB-I', 'aw')
      .set('X-RB-O', this.org)
      .set('Accept', 'application/pdf');

    let url = `${this.baseUrl}/reports/aw/${profile}?selectedBridges=${details}&excludeAlternatives=${excludeAlternativeReasons}`;

    if (vaDetails) {
      url += '&includeVAInvestmentOptions=true';
    }

    if (silent) {
      url += '&returnReport=false';
    }

    if (validate) {
      url += '&isValidation=true';
    }

    return this.http
      .post(url, null, {
        headers: reqHeaders,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        map(res => {
          return res.body;
        }),
        catchError(error => {
          return throwError(error.error || 'Server error');
        })
      );
  }

  getNoSaveReport(
    profileData,
    details?,
    vaDetails = false,
    validate = false,
    excludeAlternativeReasons = true
  ): Observable<Blob> {
    const id = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', id)
      .set('X-RB-I', 'aw')
      .set('X-RB-O', this.org)
      .set('Accept', 'application/pdf');
    let url = `${this.baseUrl}/reports/aw?selectedBridges=${details}&excludeAlternatives=${excludeAlternativeReasons}`;

    if (vaDetails) {
      url += '&includeVAInvestmentOptions=true';
    }

    if (validate) {
      url += '&isValidation=true';
    }

    return this.http
      .post(url, profileData, {
        headers: reqHeaders,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        map(res => {
          return res.body;
        }),
        catchError(error => {
          return throwError(error.error || 'Server error');
        })
      );
  }

  getViaRates() {
    return this.rbs.getRequest(`${this.rbs.baseUrl}/GridData/VIA`, null, 'aw');
  }

  getProductGrid() {
    return this.rbs.getRequest(
      `${this.rbs.baseUrl}/Annuities/ProductGrid`,
      null,
      'aw'
    );
  }

  getIncomeRiderGrid(data?) {
    return this.rbs.postRequest(
      `${this.rbs.baseUrl}/Annuities/RiderGrid`,
      data,
      null,
      null,
      'aw'
    );
  }

  getDeathRiderGrid() {
    return this.rbs.getRequest(
      `${this.rbs.baseUrl}/Annuities/DeathRiderGrid`,
      null,
      'aw'
    );
  }

  getGroupProfile(group) {
    return this.rbs.postRequest(
      `${this.rbs.baseUrl}/GroupProfile?groupName=${group}`,
      {},
      null,
      null,
      'aw'
    );
  }

  processAnnuitiesGroupProfile(profileData) {
    const id = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', id)
      .set('X-RB-I', 'aw')
      .set('X-RB-O', this.org);
    const url = `${this.baseUrl}/GroupProfile?groupName=AWResults`;

    return this.http.post(url, profileData, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return throwError(error.error || 'Server error');
      })
    );
  }

  getIncomeIterations(profileId) {
    return this.rbs.getRequest(
      `${this.rbs.baseUrl}/Annuities/IncomeIterations/${profileId}`,
      null,
      'aw'
    );
  }
}
