import { Pipe, PipeTransform } from '@angular/core';
import { cloneDeep } from 'lodash';

@Pipe({
  name: 'visible',
  pure: false
})
export class Visible implements PipeTransform{

  transform(value) {
    value = value?.filter(item => {
        return !item.hide;
    });
    const newArray = value ? [...value] : []; // or else can do cloning.
    return newArray
  }

}
