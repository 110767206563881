<div class="page-container search-container container unified">
  <!-- <breadcrumb [crumb]="'search'"></breadcrumb> -->
  <div class="row">
    <app-left-nav
      [searchPage]="true"
      *ngIf="!rights?.includes('HideHomeButtonInMenu')"
    ></app-left-nav>
    <div class="content">
      <h1 class="unified-header col-6">Case Search</h1>
      <app-profile-search
        (caseSelected)="onSelect($event)"
        class="search"
      ></app-profile-search>
    </div>
  </div>
</div>
