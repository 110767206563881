import {
  Component,
  Input,
  Output,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { takeUntil, tap } from 'rxjs/operators';
import { InvestmentWizardService } from '../../../investment-wizard/services/investment-wizard-service.service';
import { FormService } from '../../form-service.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-five-two-nine-picker',
  templateUrl: './five-two-nine-picker.component.html',
  styleUrl: './five-two-nine-picker.component.scss',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class FiveTwoNinePickerComponent
  implements OnInit, OnChanges, OnDestroy
{
  @ViewChild('fiveTwoNineField') public fiveTwoNineField;

  @Input({ required: true }) form;
  @Input({ required: true }) program;
  @Input() existingPlan;
  @Input() clear;
  @Output() selectedPlan = new EventEmitter();
  @Output() cleared = new EventEmitter();

  programId: string;
  internalForm = {};
  plans529 = [];
  unsubscribe = new Subject();
  selected = null;

  constructor(
    private iws: InvestmentWizardService,
    private changeDetector: ChangeDetectorRef,
    private formSvc: FormService
  ) {}

  ngOnInit(): void {
    this.programId = this.program;
    // this.get529(true);

    this.formSvc.clear529PickerData$.pipe(tap()).subscribe(x => {
      this.changeDetector.markForCheck();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.form) {
      this.internalForm = { ...changes.form.currentValue };
    }

    if (changes.existingPlan) {
      this.selected = changes.existingPlan.currentValue;
    }

    if (changes.program) {
      this.plans529 = [];
      this.programId = changes.program.currentValue;
      this.get529();
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  get529(firstTime = false) {
    this.iws
      .get529(true, this.programId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(x => {
        this.plans529 = [...x.data];
        if (this.existingPlan && firstTime) {
          this.selected = this.existingPlan;
        }
        this.changeDetector.detectChanges();
      });
  }

  handle529(ev) {
    const plan = this.plans529.find(x => x.PlanId == this.selected);
    this.selectedPlan.emit(plan);
  }
}
