import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SessionStorageService } from './session-storage.service';
import { RightBridgeApiService } from './right-bridge-api.service';
import { EnvironmentService } from '../services/environment.service';
import { ErrorReportingService } from '../services/error-reporting.service';

@Injectable({
  providedIn: 'root',
})
export class LifeWizardService {
  private environment;
  private baseUrl: string;
  private app = 'li';
  private org: string;

  constructor(
    private http: HttpClient,
    private ss: SessionStorageService,
    private rbs: RightBridgeApiService,
    private envSvc: EnvironmentService,
    private errSvc: ErrorReportingService
  ) {
    this.environment = this.envSvc.get();
    this.baseUrl = this.environment.apiBase;
    this.org = this.environment.org;
    this.app = this.ss.get('currentApp') || this.environment.defaultApp || 'pp';
    this.org = this.environment.org || 'basev2';
  }

  getLifeNeeds(profile): Observable<any> {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'li')
      .set('X-RB-O', this.org)
      .set('Content-Type', 'application/json');

    const url = `${this.baseUrl}/Process/LifeNeeds/${profile}`;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return this.errSvc.handleError(error);
      })
    );
  }

  getLifeScenarios(profile): Observable<any> {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'li')
      .set('X-RB-O', this.org)
      .set('Content-Type', 'application/json');

    const url = `${this.baseUrl}/Process/LifeScenarios/${profile}?includeAll=true`;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return this.errSvc.handleError(error);
      })
    );
  }

  getLifeProducts(profile): Observable<any> {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'li')
      .set('X-RB-O', this.org)
      .set('Content-Type', 'application/json');
    const url = `${this.baseUrl}/Process/LifeProducts/${profile}?includeAll=false`;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return this.errSvc.handleError(error);
      })
    );
  }

  getValidateCarriers(): Observable<any> {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'li')
      .set('X-RB-O', this.org)
      .set('Content-Type', 'application/json');
    const url = `${this.baseUrl}/LifeProducts/UnitCarriers`;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return this.errSvc.handleError(error);
      })
    );
  }

  getValidateProducts(group, state): Observable<any> {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'li')
      .set('X-RB-O', this.org)
      .set('Content-Type', 'application/json');
    const url = `${this.baseUrl}/LifeProducts/UnitProductsByCarrier?carrierGroupID=${group}&stateOfIssue=${state}`;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return this.errSvc.handleError(error);
      })
    );
  }

  getReport(profile, prods, silent = false): Observable<Blob> {
    const id = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', id)
      .set('X-RB-I', 'li')
      .set('X-RB-O', this.org)
      .set('Accept', 'application/pdf');
    let url = `${this.baseUrl}/reports/li/${profile}`;

    if (silent) {
      url += '?returnReport=false';
    }

    return this.http
      .post(url, prods, {
        headers: reqHeaders,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        map(res => {
          return res.body;
        }),
        catchError((error: any) => {
          return this.errSvc.handleError(error);
        })
      );
  }
}
