import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DashboardService } from '../../dashboard.service';
import { SessionStorageService } from '../../../services/session-storage.service';
import { DateTime } from 'luxon';
import { GridComponent } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { EnvironmentService } from '../../../services/environment.service';

@Component({
  selector: 'app-case-stats-activity',
  templateUrl: './caseStatsActivity.component.html',
  styleUrls: ['./caseStatsActivity.component.scss'],
})
export class CaseStatsActivityComponent implements OnInit {
  @ViewChild('resultsGrid', { static: false }) resultsGrid;
  @ViewChild('historyGrid', { static: false }) historyGrid;
  @ViewChild('historyAdditionsGrid', { static: false }) historyAdditionsGrid;
  @ViewChild('historyChangesGrid', { static: false }) historyChangesGrid;
  @ViewChild('historyDeletionsGrid', { static: false }) historyDeletionsGrid;

  private environment;
  private baseUrl: string;

  loading = false;
  unitsManaged = this.ss.get('globals')?.user.managerUnits;
  unit =
    this.unitsManaged && this.unitsManaged.length > 0
      ? this.unitsManaged[0].id
      : null;
  app = this.ss.get('currentApp');
  startDate = new FormControl(DateTime.now().minus({ days: 30 }).toISO());
  endDate = new FormControl(DateTime.now().toISO());
  gridData = { Headers: [], Data: null };
  solutions = [];
  solution;
  dataView = 'top5';
  level = 'unit';
  history = [];
  casesRan = [];
  classesChanged = [];
  hidden = [];
  grouping = [];
  profileHistory = { History: [], ProfileID: null };
  profileHistoryDetail = {
    additions: [],
    changes: [],
    deletions: [],
    date: null,
    solution: null,
    userId: null,
  };
  gridTitle: string;
  sort: SortDescriptor[];
  flatOrGrouped = 'FLAT';
  extendedData = false;

  sortingPresets = [
    {
      value: [
        {
          field: 'ScoreClassChanges',
          dir: 'asc',
        },
      ],
      label: 'Score Class Changes - Low To High',
    },
    {
      value: [
        {
          field: 'ScoreClassChanges',
          dir: 'desc',
        },
      ],
      label: 'Score Class Changes - High To Low',
    },
    {
      value: [
        {
          field: 'ScoreClass',
          dir: 'asc',
        },
      ],
      label: 'Score Class - Low To High',
    },
    {
      value: [
        {
          field: 'ScoreClass',
          dir: 'desc',
        },
      ],
      label: 'Score Class - High To Low',
    },
    {
      value: [
        {
          field: 'ProductChanges',
          dir: 'asc',
        },
      ],
      label: 'Product Changes - Low To High',
    },
    {
      value: [
        {
          field: 'ProductChanges',
          dir: 'desc',
        },
      ],
      label: 'Product Changes - High To Low',
    },
  ];

  constructor(
    private dbs: DashboardService,
    private ss: SessionStorageService,
    private envSvc: EnvironmentService
  ) {}

  ngOnInit() {
    this.environment = this.envSvc.get();
    this.baseUrl = this.environment.apiBase;

    this.ss.get('globals')?.Modules.forEach(el => {
      switch (el.toLowerCase()) {
        case 'aw':
          this.solutions.push({
            label: 'Annuity Wizard',
            value: el.toLowerCase(),
          });
          break;
        case 'iw':
          this.solutions.push({
            label: 'Investment Wizard',
            value: el.toLowerCase(),
          });
          break;
        case 'li':
          this.solutions.push({
            label: 'Life Insurance Wizard',
            value: el.toLowerCase(),
          });
          break;
        case 'pp':
          this.solutions.push({
            label: 'Product Profiler',
            value: el.toLowerCase(),
          });
          break;
        case 'rb':
          this.solutions.push({
            label: 'Control KB (RB)',
            value: el.toLowerCase(),
          });
          break;
        default:
          break;
      }
    });

    this.solution = this.solutions[0]?.value;
    this.gridTitle = `Stats for ${this.unit}`;

    this.getCaseStatsSummary();
    this.getTop5();
  }

  setColumnVisiblity() {
    let subCount = 0;
    this.gridData.Headers.forEach((x, i) => {
      if (x.subgroup) {
        if (subCount < 1) {
          this.gridData['subHead'] = [];
        }

        this.gridData['subHead'].push(x);
        this.gridData.Headers = this.gridData.Headers.filter(z => z != x);

        subCount++;
      }
      switch (x.RespVis) {
        case 'xs':
          x.RespVis = '';
          break;
        case 'sm':
          x.RespVis = '(min-width: 700px)';
          break;
        case 'md':
          x.RespVis = '(min-width: 1100px)';
          break;
        case 'lg':
          x.RespVis = '(min-width: 1200px)';
          this.hideColumn(x.DataField);
          break;
        case 'xl':
          x.RespVis = '(min-width: 1500px)';
          this.hideColumn(x.DataField);
          break;
      }

      if (x.Hidden) {
        this.hidden.push(x.DataField);
      }
    });

    this.hidden.forEach(x => {
      this.hideColumn(x);
    });

    this.resultsGrid.autoFitColumns();
  }

  isHidden(columnName: string): boolean {
    return this.hidden.indexOf(columnName) > -1;
  }

  hideColumn(columnName: string): void {
    if (!this.isHidden(columnName)) {
      this.hidden.push(columnName);
    }
  }

  dateChange() {
    this.getCaseStatsSummary(true);
    this.getTop5();
  }

  getTop5() {
    this.loading = true;
    const start = this.startDate.value.toISOString();
    const end = this.endDate.value.toISOString();

    this.dbs
      .getCaseStatsTop(this.unit, this.solution, start, end)
      .subscribe(x => {
        this.casesRan = x.results.MostCases;
        this.classesChanged = x.results.ScoreClassChanges;
        this.loading = false;
      });
  }

  getCaseStatsSummary(clear = false) {
    this.loading = true;
    const start = this.startDate.value.toISOString();
    const end = this.endDate.value.toISOString();

    this.dbs
      .getCaseSummaryStats(
        this.unit,
        this.solution,
        start,
        end,
        null,
        this.flatOrGrouped,
        this.extendedData
      )
      .subscribe(x => {
        this.gridData = { ...x.results };
        this.setColumnVisiblity();
        if (clear) {
          this.history = [];
        }
        this.loading = false;
      });
  }

  getUserCaseSummary(data) {
    this.loading = true;
    const start = this.startDate.value.toISOString();
    const end = this.endDate.value.toISOString();

    this.dbs
      .getCaseSummaryStats(
        this.unit,
        this.solution,
        start,
        end,
        data.UserID,
        this.flatOrGrouped
      )
      .subscribe(x => {
        this.gridData = { ...x.results };
        this.gridData.Headers.find(z => z.DataField == 'User').Hidden = true;
        this.setColumnVisiblity();
        this.loading = false;
      });
  }

  handleRowClick(ev) {
    if (ev.column.field == 'ClientName') {
      this.viewHistory(null, ev.dataItem);
      const row = ev.dataItem;
      row['level'] = 'client';
      this.history.push(row);
    } else if (ev.column.field == 'User') {
      this.getUserCaseSummary(ev.dataItem);
      const row = ev.dataItem;
      row['level'] = 'user';
      this.history.push(row);
    }
    this.updateBreadcrumb();
  }

  updateBreadcrumb() {
    this.history.forEach((crumb, i) => {
      this.gridTitle = 'Stats For';
      switch (crumb.level) {
        case 'top5':
          this.gridTitle += ` ${crumb.UserName}`;
          break;
        case 'user':
          this.gridTitle += ` ${crumb.UserName}`;
          break;
        case 'client':
          if (i > 0) {
            this.gridTitle += `, Case: ${crumb.ClientName}`;
          } else {
            this.gridTitle += ` ${crumb.ClientName}`;
          }
          break;
        case 'overview':
          if (crumb.ClientName) {
            this.gridTitle += ` ${crumb.ClientName}`;
          } else {
            this.gridTitle += ` ${this.history[i - 1].ClientName}`;
          }
          break;
        case 'history':
          this.gridTitle = `${this.history[i - 1].ClientName} Change History`;
          break;
        case 'historyDetail':
          this.gridTitle = `${
            this.history[i - 1].ClientName
          } Change History Detail`;
          break;
        default:
          break;
      }
    });
  }

  historyRowClick(ev, profile) {
    this.getProfileHistoryDetail(profile, ev.dataItem.changeID);
    this.history.push(ev.dataItem);
  }

  dataRowClicked(row, type) {
    switch (type) {
      case 'user':
        this.dataView = 'data';
        this.getUserCaseSummary(row.UserID);
        row['level'] = 'top5';
        this.history.push(row);
        this.updateBreadcrumb();
        break;
      case 'client':
        this.viewCase(null, row.ProfileID);
        row['level'] = 'top5';
        this.history.push(row);
        this.updateBreadcrumb();
        break;
      case 'count':
        this.viewHistory(null, row.ProfileID);
        this.dataView = 'data';
        row['level'] = 'top5';
        this.history.push(row);
        this.updateBreadcrumb();
        break;
    }
  }

  goBack() {
    const idx = this.history.length - 1;
    switch (this.history[idx].level) {
      case 'user':
        this.getCaseStatsSummary();
        this.history.pop();
        break;

      case 'client':
        if (this.history.length == 1) {
          this.getCaseStatsSummary();
        } else {
          this.getProfileHistory(this.history[idx].ProfileID);
        }
        this.history.pop();
        break;
      case 'overview':
        if (this.history.length == 1) {
          this.getCaseStatsSummary();
        } else {
          this.getUserCaseSummary(this.history[idx]);
        }
        this.history.pop();
        this.profileHistory = { History: [], ProfileID: null };
        this.profileHistoryDetail = {
          additions: [],
          changes: [],
          deletions: [],
          date: null,
          solution: null,
          userId: null,
        };
        break;
      case 'history':
        this.getProfile(this.history[idx]);
        this.history.pop();
        this.profileHistory = { History: [], ProfileID: null };
        this.profileHistoryDetail = {
          additions: [],
          changes: [],
          deletions: [],
          date: null,
          solution: null,
          userId: null,
        };
        break;
      case 'historyDetail':
        this.getProfileHistory(this.history[idx].ProfileID);
        this.history.pop();
        break;
      default:
        break;
    }
    this.updateBreadcrumb();
  }

  viewCase(ev, id) {
    if (ev) {
      ev.preventDefault();
      ev.stopPropagation();
    }
    window.open(`/${this.solution}/questionnaire/${id}`, '_blank');
  }

  viewHistory(ev, data) {
    if (ev) {
      ev.preventDefault();
      ev.stopPropagation();
    }

    this.flatOrGrouped = 'GROUP';
    this.getProfile(data);
  }

  getProfile(data) {
    this.loading = true;
    const start = this.startDate.value.toISOString();
    const end = this.endDate.value.toISOString();

    this.dbs
      .getCaseSummaryStatsHistory(
        this.unit,
        this.solution,
        start,
        end,
        null,
        data.ProfileID,
        this.flatOrGrouped,
        this.extendedData
      )
      .subscribe(x => {
        this.gridData.Headers = x.results.Headers;
        this.gridData.Data = x.results.Data;
        setTimeout(z => {
          this.setColumnVisiblity();
        }, 1000);

        this.loading = false;
        const row = data;
        row.level = 'overview';
        this.history.push(row);
        this.updateBreadcrumb();
      });
  }

  getProfileHistory(id) {
    this.dbs.getProfileHistory(this.solution, id).subscribe(x => {
      this.profileHistory = x.profileChangeHistory;
      this.setColumnVisiblity();
      this.history.push({ level: 'history' });
      this.updateBreadcrumb();
    });
  }

  getProfileHistoryDetail(profile, change) {
    this.dbs
      .getProfileHistoryDetail(this.solution, profile, change)
      .subscribe(x => {
        this.profileHistoryDetail = x.profilechange;
        this.history.push({ level: 'historyDetail' });
        this.updateBreadcrumb();
        this.setColumnVisiblity();
      });
  }

  reset() {
    this.getCaseStatsSummary();
    this.solutions = [];
    this.dataView = 'top5';
    this.level = 'unit';
    this.grouping = [];
    this.profileHistory = { History: [], ProfileID: null };
    this.profileHistoryDetail = {
      additions: [],
      changes: [],
      deletions: [],
      date: null,
      solution: null,
      userId: null,
    };
  }

  findValue(val, list) {
    return list.find(x => x.value == val).label;
  }

  updateSorting() {
    this.gridData.Data = orderBy(this.gridData.Data, this.sort);
  }

  exportExcel(grids: GridComponent[]): void {
    grids.forEach(gr => {
      gr.saveAsExcel();
    });
  }

  gridExportChooser() {
    const gridList = [];

    if (this.profileHistory.History.length < 1) {
      gridList.push(this.resultsGrid);
    }

    if (
      this.profileHistory &&
      this.profileHistory.History.length > 0 &&
      this.profileHistoryDetail.additions.length == 0 &&
      this.profileHistoryDetail.changes.length == 0 &&
      this.profileHistoryDetail.deletions.length == 0
    ) {
      gridList.push(this.historyGrid);
    }

    if (
      this.profileHistoryDetail.additions &&
      this.profileHistoryDetail.additions.length > 0
    ) {
      gridList.push(this.historyAdditionsGrid);
    }

    if (
      this.profileHistoryDetail.changes &&
      this.profileHistoryDetail.changes.length > 0
    ) {
      gridList.push(this.historyChangesGrid);
    }

    if (
      this.profileHistoryDetail.deletions &&
      this.profileHistoryDetail.deletions.length > 0
    ) {
      gridList.push(this.historyDeletionsGrid);
    }

    return gridList;
  }

  updateFlatGrouped() {
    if (this.history.length < 1) {
      this.getCaseStatsSummary();
    } else {
      const idx = this.history.length - 1;
      const level = this.history[idx].level;
      switch (level) {
        case 'user':
          this.getUserCaseSummary(this.history[idx]);
          break;

        case 'client':
          if (this.history.length == 1) {
            this.getCaseStatsSummary();
          } else {
            this.getProfileHistory(this.history[idx].ProfileID);
          }
          break;

        case 'overview':
          if (this.history.length == 1) {
            this.getCaseStatsSummary();
          } else {
            this.getUserCaseSummary(this.history[idx]);
          }
          break;

        case 'history':
          this.getProfileHistory(this.history[idx]);
          break;

        case 'historyDetail':
          this.getProfileHistory(this.history[idx].ProfileID);
          break;

        default:
          break;
      }
    }
  }
}
