import { Component, ViewContainerRef, HostBinding } from '@angular/core';
import { UntypedFormGroup, FormGroup } from '@angular/forms';


import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'form-toggle',
  styleUrls: ['form-toggle.component.scss'],
  template: `
    <label class="section" class="w-100" *ngIf="props.label" [innerHTML]="props.label"></label>
    <mat-icon *ngIf="props.help" class="help-icon" matTooltip="{{to.help}}" [matTooltipPosition]="'above'" [matTooltipClass]="'rb-tooltip'">help</mat-icon>
    <mat-slide-toggle [formControl]="formControl" [formlyAttributes]="field" class="w-100" [required]="props.required" ></mat-slide-toggle>
    <mat-error *ngIf="formControl.hasError('required') && !formControl.hasError('regRequired')">This field is required</mat-error>
      <mat-error *ngIf="formControl.hasError('regRequired')">This field is needed for suitability review. You can opt out below if the applicant refuses to provide the information.</mat-error>
  `
})
export class FormlyToggle extends FieldType  {}

