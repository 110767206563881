<mat-card appearance="outlined" class="col-12 stretch">
  <mat-card-header>
    Case Activity Statistics
    <button mat-button color="white" class="download" (click)="exportCSV(viewingUser ? data.Cases[0].AdvisorID : null)">
      <mat-icon>cloud_download</mat-icon>Download CSV File
    </button>
  </mat-card-header>
  <mat-card-content class="row">
    <div class="col-12">
      <h4 *ngIf="viewingUser"><mat-icon (click)="getData()" class="cursor">arrow_back</mat-icon>Stats for {{data.Cases[0].AdvisorID}}</h4>
      <mat-form-field class="pull-right" *ngIf="!limit30">
        <input matInput [matDatepicker]="endPicker" placeholder="Choose an end date" [formControl]="endDate" (dateChange)="getData(viewingUser ? data.Cases[0].AdvisorID : null)">
        <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
        <mat-datepicker #endPicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="pull-right mr-2" *ngIf="!limit30">
        <input matInput [matDatepicker]="startPicker" placeholder="Choose a start date" [formControl]="startDate" (dateChange)="getData(viewingUser ? data.Cases[0].AdvisorID : null)">
        <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
        <mat-datepicker #startPicker></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-12 data-container">
      <table class="content col-12 products" *ngIf="data">
        <thead class="mb-2">
          <th *ngIf="!viewingUser">User</th>
          <th>Client Name</th>
          <th>Product</th>
          <th class="text-center">Score Class</th>
          <th class="text-center">Case Run Count</th>
          <th class="text-center">Score Class Changes</th>
          <th class="text-center">Product Change Count</th>
        </thead>
        <tbody>
          <tr *ngFor="let case of data.Cases">
            <td *ngIf="!viewingUser" (click)="getData(case.AdvisorID)" class="cursor">{{case.AdvisorID}}</td>
            <td>{{case.FullName}}</td>
            <td>{{case.Products[0].ProductName}}</td>
            <td class="text-center">
              <mat-icon class="positive" *ngIf="case.Products[0].ScoreClass == 'positive'" matTooltip="Positive">check_circle</mat-icon>
              <mat-icon class="neutral" *ngIf="case.Products[0].ScoreClass == 'neutral'" matTooltip="Neutral">error</mat-icon>
              <mat-icon class="negative" *ngIf="case.Products[0].ScoreClass == 'negative'" matTooltip="Negative">remove_circle</mat-icon>
              <!-- {{case.Products[0].ScoreClass}} -->
            </td>
            <td class="text-center">{{case.RunCount}}</td>
            <td class="text-center">{{case.ScoreClassChangeCount}}</td>
            <td class="text-center">{{case.ProductChangeCount}}</td>
          </tr>
          <!-- <tr>
            <td>Totals</td>
            <td class="text-center"><span>{{data.overall.total}}</span> <span>100%</span></td>
            <td class="text-center"><span>{{data.overall.positive}}</span> <span>{{data.overall.positive > 0 ? (data.overall.positive / data.overall.total).toFixed(2) * 100 : 0}}%</span></td>
            <td class="text-center"><span>{{data.overall.neutral}}</span> <span>{{data.overall.neutral > 0 ? (data.overall.neutral / data.overall.total).toFixed(2) * 100 : 0}}%</span></td>
            <td class="text-center"><span>{{data.overall.negative}}</span> <span>{{data.overall.negative > 0 ? (data.overall.negative / data.overall.total).toFixed(2) * 100 : 0}}%</span></td>
          </tr> -->
        </tbody>
      </table>
    </div>
  </mat-card-content>
    <ngx-loading [show]="loading" [config]="{fullScreenBackdrop: false}"></ngx-loading>
</mat-card>
