import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DashboardService } from '../../dashboard.service';
import { Router } from '@angular/router';
import { SessionStorageService } from '../../../services/session-storage.service';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-unit-product-stats',
  templateUrl: './unitProductStats.component.html',
  styleUrls: ['./unitProductStats.component.scss'],
})
export class UnitProductStatsComponent implements OnInit {
  loading = false;
  unitsManaged = this.ss.get('globals')?.user.managerUnits;
  unit =
    this.unitsManaged && this.unitsManaged.length > 0
      ? this.unitsManaged[0].id
      : null;
  app = this.ss.get('currentApp');
  startDate = new FormControl(
    DateTime.now().minus({ days: 7 }).toUTC().startOf('day').toISO()
  );
  endDate = new FormControl(DateTime.now().toUTC().endOf('day').toISO());
  data;
  solutions = [];
  solution;
  limit30 = true;

  constructor(
    private dbs: DashboardService,
    private ss: SessionStorageService,
    private router: Router
  ) {}

  ngOnInit() {
    this.ss.get('globals')?.Modules.forEach(el => {
      switch (el.toLowerCase()) {
        case 'aw':
          this.solutions.push({
            label: 'Annuity Wizard',
            value: el.toLowerCase(),
          });
          break;
        case 'iw':
          this.solutions.push({
            label: 'Investment Wizard',
            value: el.toLowerCase(),
          });
          break;
        case 'li':
          this.solutions.push({
            label: 'Life Insurance Wizard',
            value: el.toLowerCase(),
          });
          break;
        case 'pp':
          this.solutions.push({
            label: 'Product Profiler',
            value: el.toLowerCase(),
          });
          break;
        default:
          break;
      }
    });

    const rights = this.ss.get('rights');
    this.limit30 = rights?.includes('limit30Days');

    this.solution = this.solutions[0]?.value;

    this.updateData();
  }

  updateData() {
    this.loading = true;

    const back30 = new Date();
    back30.setDate(back30.getDate() - 30);

    const start = this.limit30
      ? back30.toISOString()
      : DateTime.fromJSDate(new Date(this.startDate.value)).toISO();
    const end = this.limit30
      ? new Date().toISOString()
      : DateTime.fromJSDate(new Date(this.endDate.value)).toISO();

    this.dbs
      .getUnitProductCaseStats(this.unit, start, end, this.solution)
      .subscribe(x => {
        this.data = x;
        this.loading = false;
      });
  }

  goToProfile(ev, id) {
    this.router.navigate([`/li/questionnaire/${id}`]);
  }
}
