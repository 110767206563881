import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Global } from '../models/global.model';
import { SessionStorageService }  from '../services/session-storage.service'
import { throwError as observableThrowError } from 'rxjs';
import { catchError } from "rxjs/operators";
// import { environment } from '../../environments/environment';
import { EnvironmentService } from '../services/environment.service';

@Injectable({ providedIn: 'root' })
export class GlobalsService {
  private environment;
  public baseUrl: string;
  private firm: string;
  private app: string;
  private org: string;

  constructor(
    private http: HttpClient,
    private ss: SessionStorageService,
    private envSvc: EnvironmentService
  ) {
    this.environment = this.envSvc.get();
    this.baseUrl = this.environment.apiBase;
    this.org = this.environment.org;
    this.firm = this.environment.firm || '';
    this.app = this.ss.get('currentApp') || this.environment.defaultApp || 'pp';
    this.org = this.environment.org || 'basev2';
  }

  // get() {
  //   let id = this.ss.get('session');
  //   const reqHeaders = new HttpHeaders().set('X-RB-S', id).set('X-RB-I', this.app).set('X-RB-O', this.org);

  //   let url = this.baseUrl + '/Globals';

  //   return this.http.get(url, { headers: reqHeaders }).subscribe(data =>
  //     this.globalsStore.update(data['results'])
  //   ),
  //   catchError((error: any) => {
  //     // this.logout();
  //     return observableThrowError(error.error || 'Server error');
  //   });
  // }

  // update(global: Partial<Global>) {
  //   this.globalsStore.update(global);
  // }

  // remove() {
  //   this.globalsStore.destroy();
  // }

  // reset() {
  //   resetStores();
  // }
}
