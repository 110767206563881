import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { ConditionalFormModule } from '../conditional-form/conditional-form.module';
import { CaseManagementModalComponent } from './case-management-modal/case-management-modal.component';
import { AdHocModalComponent } from './ad-hoc-modal/ad-hoc-modal.component';
import { MatSelectModule } from '@angular/material/select';
import { CaseManagementComponent } from './case-management-shell/case-management.component';

//Kendo Imports
import { GridModule } from '@progress/kendo-angular-grid';

//Services
import { AuthService } from '../services/auth-service.service';
import { RightBridgeApiService } from '../services/right-bridge-api.service';
import { CaseManagementService } from './case-management.service';

import { CaseManagementRouting } from './case-management-routing.module';

@NgModule({
  imports: [
    MatSelectModule,
    CommonModule,
    RouterModule,
    CaseManagementRouting,
    FormsModule,
    ReactiveFormsModule,
    ConditionalFormModule,
    NgxDatatableModule,
    GridModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.circle,
      backdropBackgroundColour: 'rgba(0, 0, 0, 0.3)',
      fullScreenBackdrop: true,
      backdropBorderRadius: '0',
      primaryColour: 'rgb(38, 91, 151)',
      secondaryColour: 'rgba(38, 91, 151, 0.2)',
      tertiaryColour: '#265b97',
    }),
    SharedComponentsModule,
  ],
  declarations: [
    CaseManagementComponent,
    CaseManagementModalComponent,
    AdHocModalComponent,
  ],
  providers: [RightBridgeApiService, CaseManagementService, AuthService],
  schemas: [NO_ERRORS_SCHEMA],
})
export class CaseManagementModule {}
