<mat-dialog-header>
  <h1>{{headline}}</h1>
</mat-dialog-header>
<mat-dialog-content #scroll class="dialog-container">
  <form>
    <mat-form-field class="col-6" [ngClass]="{'col-12': data.app == 'cp'}">
      <input matInput [(ngModel)]="reportOptions.filename" name="text" [placeholder]="placeholder" />
    </mat-form-field>
    <mat-form-field class="col-6" *ngIf="data.app != 'cp'">
      <input matInput [(ngModel)]="reportOptions.threshold" name="number" placeholder="Minimum Bridge Score" />
    </mat-form-field>
    <mat-checkbox [(ngModel)]="reportOptions.annReview" name="annReview" class="col-6">Include Annual Review</mat-checkbox>
    <mat-checkbox [(ngModel)]="reportOptions.needSummary" name="needSummary" class="col-6">Include Needs Summary</mat-checkbox>
    <mat-checkbox [(ngModel)]="reportOptions.needDetail" name="needDetail" class="col-6">Include Needs Detail</mat-checkbox>
    <mat-checkbox *ngIf="data.app === 'cp'" [(ngModel)]="reportOptions.prodSummary" name="prodSummary" class="col-6">Include Product Summary</mat-checkbox>
    <mat-checkbox *ngIf="data.app === 'cp'" [(ngModel)]="reportOptions.prodDetail" name="prodDetail" class="col-6">Include Product Details</mat-checkbox>
    <mat-checkbox [(ngModel)]="specific" name="specific" class="col-6">Include Specific Suggestions</mat-checkbox>
    <h4 *ngIf="data.needs && data.needs.length && specific">Needs to Include</h4>
    <ng-container *ngIf="specific">
      <!-- {{data.needs | json}} -->
      <mat-checkbox *ngFor="let nd of data.needs" [(ngModel)]="selectedBridges[nd.name]" name="{{nd.name}}" class="col-6">{{nd.ShortName}}</mat-checkbox>
    </ng-container>
    <!-- <h4 *ngIf="data.products.length">Products to Include</h4>
    <mat-checkbox *ngFor="let prd of data.products" [(ngModel)]="selectedBridges[prd.name]" name="{{prd.name}}"
      class="col-6">{{prd.ShortName}}</mat-checkbox> -->
  </form>
</mat-dialog-content>
<mat-dialog-actions class="row buttons">
  <button mat-raised-button color="accent" (click)="save()">{{confirm}}</button>
  <button mat-raised-button (click)="cancel()">Cancel</button>
</mat-dialog-actions>
