import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, FormGroup, FormControl } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from '@angular/material/dialog';
import { RightBridgeApiService } from '../../services/right-bridge-api.service';
import { InvestmentWizardService } from '../services/investment-wizard-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionStorageService } from '../../services/session-storage.service';
import { WarnDialogComponent } from '../../warn-dialog/warn-dialog.component';
import { saveAs } from '@progress/kendo-file-saver';
import { Platform } from '@angular/cdk/platform';
import { EnvironmentService } from '../../services/environment.service';

@Component({
  selector: 'validation-dialog',
  templateUrl: './validation-dialog.component.html',
  styleUrls: ['./validation-dialog.component.scss'],
})
export class ValidationDialogComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ValidationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private rbs: RightBridgeApiService,
    private params: ActivatedRoute,
    private ss: SessionStorageService,
    private route: Router,
    private iws: InvestmentWizardService,
    private pltfrm: Platform,
    private envSvc: EnvironmentService
  ) {}
  private environment;
  private baseUrl: string;

  form: FormGroup;
  hideDetails: boolean =
    this.data && this.data.hideDetails ? this.data.hideDetails : false;
  portInfo;
  chartImage: string;
  loading = false;
  id: string;
  clientName: string = this.ss.get('clientName');
  externalLink;
  externalAudit;
  crossApp;
  path: string;
  explanationPlaceholder = 'Notes (appears on report)';

  ngOnInit() {
    this.environment = this.envSvc.get();
    this.baseUrl = this.environment.apiBase;
    this.path = this.environment.assets ? this.environment.assets : '';

    const globals = this.ss.get('globals') ? this.ss.get('globals') : {};

    this.explanationPlaceholder = this.data.placeholder
      ? this.data.placeholder
      : this.explanationPlaceholder;

    this.externalLink = globals.standardPostBack || null;
    this.externalAudit = globals.dynamicPostBack || null;

    const formGroup = new UntypedFormGroup({
      filename: new FormControl(''),
    });
    this.form = formGroup;

    if (!this.data.hideDetails) {
      if (!this.data.noSelection) {
        this.form.addControl('details', new FormControl(''));
        this.form.controls?.details.setValue(this.data.defaults?.details);

        this.form.addControl(
          'includeFundAlternativesChart',
          new FormControl('')
        );
        this.form.controls?.includeFundAlternativesChart.setValue(
          this.data.defaults?.includeFundAlternativesChart
        );
      }

      this.form.addControl('alternative', new FormControl(''));
      this.form.controls?.alternative.setValue(this.data.defaults?.alternative);
    }

    this.form.addControl('explanation', new FormControl(''));

    this.id = this.data.id;
  }

  cancel() {
    this.dialogRef.close('cancel');
    this.dialogRef.afterClosed().subscribe(() => {
      this.dialogRef = null;
    });
  }

  report() {
    this.loading = true;
    const iosSafari =
      this.pltfrm.IOS ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

    const formData = this.form.value;
    const data = {};

    if (formData.explanation) {
      data['InvestmentWizard.ValidationNote'] = formData.explanation;
    }

    this.rbs.saveProfile(this.id, data, false).subscribe(res => {
      this.iws
        .getReport(
          this.id,
          formData.details,
          formData.alternative,
          formData.includeFundAlternativesChart
        )
        .subscribe(data => {
          const date = new Date();
          const filename =
            formData.filename && formData.filename.length > 0
              ? formData.filename + '.pdf'
              : 'InvestmentWizardReport-' +
                date.getMonth() +
                date.getDay() +
                date.getFullYear() +
                '.pdf';

          let fileData;
          const reader = new FileReader();
          reader.readAsDataURL(data);
          reader.onloadend = () => {
            fileData = reader.result;
            saveAs(fileData, filename, {
              proxyURL: `${this.baseUrl}/util/proxy`,
              proxyTarget: '_self',
              forceProxy: iosSafari,
            });
            this.loading = false;
            this.dialogRef.close();
          };
        });
    });
  }

  gotoPP() {
    const pb = this.crossApp.includePostBack || null;
    const silentPrint = this.crossApp.silentPrint || null;

    if (silentPrint && pb) {
      this.loading = true;
      this.rbs.getReport(this.id, false, silentPrint).subscribe(z => {
        this.rbs.postback(this.id).subscribe(x => {
          this.loading = false;
          this.route.navigate(['/pp/questionnaire/' + this.id]);
        });
      });
    } else if (pb && !silentPrint) {
      this.loading = true;
      this.rbs.postback(this.id).subscribe(x => {
        this.loading = false;
        this.route.navigate(['/pp/questionnaire/' + this.id]);
      });
    } else if (silentPrint && !pb) {
      this.loading = true;
      this.rbs.getReport(this.id, false, silentPrint).subscribe(z => {
        this.loading = false;
        this.route.navigate(['/pp/questionnaire/' + this.id]);
      });
    } else {
      this.route.navigate(['/pp/questionnaire/' + this.id]);
    }

    this.cancel();
  }

  externalAction() {
    let silentPrint = this.externalLink.silentPrint,
      link = this.externalLink.link || null,
      pb = this.externalLink.includePostBack || null,
      includePID = this.externalLink.includePID,
      newTab = this.externalLink.RedirectSameTab ? '_self' : '_blank';

    if (includePID) {
      link += this.id;
    }

    if (silentPrint && pb) {
      this.loading = true;
      this.rbs.getReport(this.id, true, silentPrint).subscribe(z => {
        this.rbs.postback(this.id).subscribe(x => {
          this.loading = false;
          window.open(link, newTab);
        });
      });
    } else if (pb) {
      this.loading = true;
      this.rbs.postback(this.id).subscribe(x => {
        this.loading = false;
        window.open(link, newTab);
      });
    } else if (silentPrint) {
      this.loading = true;
      this.rbs.getReport(this.id, true, silentPrint).subscribe(z => {
        this.loading = false;
        window.open(link, newTab);
      });
    } else {
      window.open(link, newTab);
    }
  }

  externalAuditAction() {
    const silentPrint = this.externalAudit.silentPrint,
      newTab = this.externalAudit.RedirectSameTab ? '_self' : '_blank',
      silentPrintPp = this.externalAudit.silentPrintPP;

    this.loading = true;

    if (silentPrintPp) {
      this.rbs.silentPrintPpReport(this.id).subscribe(z => {});
    }

    this.loading = true;
    this.rbs.getReport(this.id, true, silentPrint).subscribe(z => {
      this.rbs.extPostback(this.id).subscribe(x => {
        if (x.results != null) {
          const link = x.results;
          this.loading = false;
          window.open(link, newTab);
        } else {
          let dialogRef = this.dialog.open(WarnDialogComponent, {
            panelClass: 'warn-dialog',
            data: {
              headline: 'Warning',
              content:
                'The service you are trying to connect to is not currently available. Please report this to your system administrator and try again later.',
              confirm: 'OK',
              hideCancel: true,
            },
          });
          dialogRef.afterClosed().subscribe(result => {
            dialogRef = null;
          });
        }
      });
    });
  }
}
