import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, FormGroup, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { InvestmentWizardService } from '../investment-wizard/services/investment-wizard-service.service';

@Component({
  selector: 'app-family-dialog',
  templateUrl: './family-dialog.component.html',
  styleUrls: ['./family-dialog.component.scss'],
})
export class FamilyDialogComponent implements OnInit {
  form: FormGroup;
  headline: string;
  content: string;
  confirm: string;
  fundFamilies;

  constructor(
    public dialogRef: MatDialogRef<FamilyDialogComponent>,
    private iws: InvestmentWizardService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    const formGroup = {};
    formGroup['family'] = new FormControl();
    this.form = new UntypedFormGroup(formGroup);
    if (data) {
      this.headline = data.headline || 'Please Select a Fund Family';
      this.content = data.content || '';
      this.confirm = data.confirm || 'Save';
    }
  }

  ngOnInit() {
    this.iws.fundFamilyList(this.data.program).subscribe(({ results }) => {
      this.fundFamilies = results;
    });
  }

  cancel() {
    this.dialogRef.close({ data: 'cancel' });
  }

  save() {
    if (this.form.valid) {
      this.dialogRef.close({ data: this.form.get('family').value });
      this.dialogRef.afterClosed().subscribe(() => {
        this.dialogRef = null;
      });
    }
  }
}
