<div class="page-container asset-container container se-container">
  <breadcrumb [crumb]="'se-details'"></breadcrumb>
  <h1 class="header col-12" *ngIf="need"
    >{{ need.ShortName }} Need Details
    <div class="buttons">
      <button
        class="no-thanks bridge-actions"
        mat-icon-button
        matTooltip="No Thank You"
        matTooltipPosition="above"
        (click)="bridgeAction('noThanks', id, idx); $event.stopPropagation()"
        *ngIf="currentApp === 'se'"
        color="primary"
      >
        <mat-icon>thumb_down</mat-icon>
      </button>
      <button
        class="follow-up bridge-actions"
        mat-icon-button
        matTooltip="Follow Up"
        matTooltipPosition="above"
        (click)="bridgeAction('followUp', id, idx)"
        *ngIf="currentApp === 'se'"
        color="primary"
      >
        <mat-icon>update</mat-icon>
      </button>
      <button
        class="like bridge-actions"
        mat-icon-button
        matTooltip="I Like It"
        matTooltipPosition="above"
        (click)="bridgeAction('like', id, idx); $event.stopPropagation()"
        *ngIf="currentApp === 'se'"
        color="primary"
      >
        <mat-icon>thumb_up</mat-icon>
      </button>
    </div>
  </h1>

  <div class="w-100 toolbar clearfix" *ngIf="need">
    <button mat-button class="back" (click)="back()" color="primary">
      <mat-icon class="mat-icon material-icons">arrow_back</mat-icon>Back to
      Results
    </button>
    <!-- <button mat-raised-button class="report" (click)="report()" *ngIf="!hidePrint">
      <mat-icon class="mat-icon material-icons">insert_drive_file</mat-icon>Printable report
    </button> -->
    <button
      mat-button
      class="header-button"
      (click)="report()"
      *ngIf="!hidePrint"
    >
      <mat-icon>insert_drive_file</mat-icon>
      <span>Download Report</span>
    </button>
  </div>
  <!-- {{realData.ShortName}} -->
  <div class="needs-container" *ngIf="need">
    <div class="col-12 row pr-0">
      <div class="col-6 content pr-5">
        <p class="description" [innerHTML]="need.ShortDescription"></p>
        <ul class="details">
          <ngTemplate *ngFor="let r of need.reasons">
            <div class="col-12 reason {{ r.reasonType.toLowerCase() }}">
              <strong class="header"
                ><img
                  src="{{
                    './assets/' + path + r.reasonType.toLowerCase() + '.png'
                  }}"
                /><span [innerHTML]="r.headline"></span>
                <span *hasPermission="'!RelevanceHideReasonScores'">
                  | {{ r.score / 10 }}</span
                ></strong
              >
              <p
                class="explanation"
                *ngIf="r.explanation"
                [innerHTML]="r.explanation"
              ></p>
            </div>
          </ngTemplate>
        </ul>
      </div>
      <div class="col-6 chart-container">
        <div [chart]="need.formattedChart" *ngIf="need.formattedChart"></div>
        <div
          *ngIf="need.formattedChart && need.ChartLegend"
          class="chart-legend"
        >
          <ng-container *ngIf="need.ChartLegend.header; else noHeader">
            <table>
              <thead>
                <tr>
                  <th *ngFor="let th of need.ChartLegend.header">{{ th }}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let tr of need.ChartLegend.data">
                  <td *ngFor="let td of tr">{{ td }}</td>
                </tr>
              </tbody>
            </table>
          </ng-container>
          <ng-template #noHeader>
            <div *ngFor="let c of need.ChartLegend">
              <strong>{{ c.label }}</strong> {{ c.value }}
            </div>
          </ng-template>
        </div>
        <div class="info-container">
          <div
            *ngIf="!need.formattedChart"
            class="infographic w-100 {{ need.ScoreClass }}"
          >
            <div class="inner">
              <div
                class="detail"
                [ngClass]="{ smaller: need.Detail.length > 6 }"
                >{{ need.Detail }}</div
              >
              <div class="detailName">{{ need.detailname }}</div>
            </div>
          </div>
        </div>
        <h5 *ngIf="currentApp !== 'se'"
          >Solutions that may help address this need:</h5
        >
        <div *ngIf="currentApp !== 'se'" class="products clearfix">
          <ul>
            <ng-container
              *ngFor="let p of need.products ? need.products.slice(0, 3) : []"
            >
              <li class="product {{ p.scoreClass }}">
                <h4
                  >{{ p.name }}
                  <!-- <span *hasPermission="'!RelevanceHideReasonScores'"> | {{p.score / 10}}%</span> -->
                </h4>
                <div class="sub">{{ p.subhead }}</div>
                <div
                  class="rating-line {{ need.ScoreClass }}"
                  [style.width.%]="p.score / 10"
                ></div>
              </li>
            </ng-container>
          </ul>
          <!-- <button mat-button class="moreProducts" color="primary" (click)="details(idx);"> -->
          <button
            mat-button
            class="moreProducts"
            color="primary"
            (click)="productDetails()"
          >
            <span class="collapsed-content">
              See All Products
              <mat-icon>arrow_forward</mat-icon>
            </span>
          </button>
        </div>
      </div>
    </div>
    <!-- <div class="details col-11">
            <ngTemplate *ngFor="let r of need.reasons" >
              <div class="col-12">
                <strong><img src="{{'./assets/' + r.reasonType.toLowerCase() + '.png'}}" /><span [innerHTML]="r.headline"></span> <span *hasPermission="'!RelevanceHideReasonScores'"> | {{r.score / 10}}%</span></strong>
                <p class="explanation" [innerHTML]="r.explanation"></p>
              </div>
            </ngTemplate>
          </div>
          <div class="rating-line {{need.ScoreClass}}" [style.width.%]="need.score/10"></div> -->
  </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>
