import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-tool-link',
  templateUrl: './tool-link.component.html',
  styleUrls: ['./tool-link.component.scss'],
})
export class ToolLinkComponent {
  @Input() tooltip = '';
  @Input() externalLink = '';
  @Input() toolLabel = '';
  @Output() defaultClick = new EventEmitter();
  @Output() secondaryClick = new EventEmitter();
}
