import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UniversalSearchComponent } from './universal-search/universal-search.component';
import { ProfileSearchComponent } from './profile-search/profile-search.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';

import { SearchModalComponent } from './search-modal/search-modal.component';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';

//Kendo Imports
import {
  GridModule,
  ExcelModule,
  PDFModule,
} from '@progress/kendo-angular-grid';

import { UniversalSearchService } from './services/universal-search.service';

// import { SeRouting } from "./universal-search-routing.module";
import { HierarchyModalComponent } from './hierarchy-modal/hierarchy-modal.component';
import { NameSearchComponent } from './name-search/name-search.component';
import { MatMenuModule } from '@angular/material/menu';
import { BreadcrumbComponent } from '../breadcrumb/breadcrumb.component';
// import { UtilityToolbarComponent } from '../shared-components/utility-toolbar/utility-toolbar.component';

// import { SharedComponentsModule } from '../shared-components/shared-components.module';

@NgModule({
  declarations: [
    UniversalSearchComponent,
    HierarchyModalComponent,
    ProfileSearchComponent,
    SearchModalComponent,
    NameSearchComponent,
  ],
  providers: [UniversalSearchService],
  exports: [
    UniversalSearchComponent,
    HierarchyModalComponent,
    ProfileSearchComponent,
  ],
  imports: [
    CommonModule,
    // SeRouting,
    GridModule,
    ExcelModule,
    PDFModule,
    MatIconModule,
    MatCheckboxModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatButtonToggleModule,
    MatDatepickerModule,
    MatTooltipModule,
    MatCardModule,
    MatRadioModule,
    FormsModule,
    ReactiveFormsModule,
    NgxLoadingModule,
    MatMenuModule,
    BreadcrumbComponent,
  ],
})
export class UniversalSearchModule {}
