<div class="page-container aw-comparison-container">
  <button
    mat-flat-button
    class="reset-columns-button"
    (click)="resetColumns()"
  >
    <mat-icon>restore</mat-icon> Restore Columns
  </button>
  <div class="comparison-link-container mb-2">
    See Annuity Product Comparisons on <a href="https://ejprod.sharepoint.com/sites/JL-US-BR-Annuities/SitePages/web044635.aspx" target="_blank">JonesLink</a> for a full comparison of annuity products and features 
  </div>
  <div class="annuity-comparison-container mb-4" *ngFor="let label of annuityLabels">
    <ng-container *ngIf="grids[label.annuityType]">
      <div class="annuity-comparison-header">
        <h3>
          {{ label.label }}
        </h3>
        <button
          mat-button
          class="grid-column-button ml-3 pull-right"
          [matMenuTriggerFor]="menu"
        >
          <mat-icon>view_column</mat-icon>Column Options
        </button>
        <mat-menu #menu="matMenu" class="column-menu">
          <button
            (click)="toggleVisible($event, label.annuityType, 'all')"
            mat-menu-item
            [ngClass]="{'selected': allSelected(label.annuityType)}"
          >
            <mat-icon class="empty">check_box_outline_blank</mat-icon>
            <mat-icon class="checked">check_box</mat-icon>
            Select All
          </button>
          <ng-container *ngFor="let col of grids[label.annuityType].headers">
            <button
              *ngIf="!hiddenMenuItems.includes(col.DataField)"
              (click)="toggleVisible($event, label.annuityType, col.DataField)"
              mat-menu-item
              [value]="col.DataField"
              [ngClass]="{'selected': !col.Hidden}"
            >
              <mat-icon class="empty">check_box_outline_blank</mat-icon>
              <mat-icon class="checked">check_box</mat-icon>
              {{ stripTags(col.Label) }}
            </button>
          </ng-container>
        </mat-menu>
      </div>
  
      <div class="comparison-grid-container">
        <kendo-grid
          #comparisonGrid="kendoGrid"
          class="col-12"
          [kendoGridBinding]="grids[label.annuityType].data"
          (dataStateChange)="onDataStateChange($event)"
          [resizable]="true"
          [sortable]="{ allowUnsort: true, mode: 'multiple' }"
          [state]="state"
        >
          <ng-container *ngFor="let column of grids[label.annuityType].headers">
            <kendo-grid-column-group
              [hidden]="column.Hidden"
              [width]="column.DataField === 'RiderFee' ? 50 : 75"
              *ngIf="column.Type !== 'parent'"
              [headerClass]="'non-parent'"
            >
              <kendo-grid-column
                [field]="column.DataField"
                [title]="column.Label"
                [sortable]="true"
              >
                <ng-template kendoGridHeaderTemplate>
                  <span [innerHTML]="column.Label"></span>
                  <sup *ngIf="column.Label === 'Payment Option' || column.Label === 'Payment Frequency'">
                    *
                  </sup>
                  <sup *ngIf="column.Label === 'Payment Frequency'">
                    *
                  </sup>
                </ng-template>
                <ng-template
                  kendoGridCellTemplate
                  let-dataItem
                  *ngIf="column.Type === 'perc'"
                >
                  <span>
                    {{ dataItem[column.DataField] | percent: '1.2-2' }}
                  </span>
                </ng-template>
                <ng-template
                  kendoGridCellTemplate
                  let-dataItem
                  *ngIf="column.Type == 'curr'"
                >
                  <span>
                    {{ dataItem[column.DataField] | currency : 'USD' : 'symbol' : '1.0-0' }}
                  </span>
                </ng-template>
                <ng-template
                  kendoGridCellTemplate
                  let-dataItem
                  *ngIf="column.Type == 'link'"
                >
                  <span *ngIf="dataItem[column.DataField]">
                    <a [href]="dataItem[column.DataField]" target="_blank">
                      POS Aid
                    </a>
                  </span>
                </ng-template>
                <ng-template
                  kendoGridCellTemplate
                  let-dataItem
                  *ngIf="column.DataField === 'InitialPremium'"
                >
                  <span>
                    {{ initialPremium }}
                  </span>
                </ng-template>
                <ng-template
                  kendoGridCellTemplate 
                  let-dataItem
                  *ngIf="column.Label === 'Annuity'"
                >
                  <span>
                    {{ dataItem.Carrier }} - {{ dataItem[column.DataField] }}
                  </span>
                </ng-template>
                <ng-template
                  kendoGridCellTemplate
                  let-dataItem
                  *ngIf="
                    column.Type !== 'perc' &&
                    column.Type !== 'curr' &&
                    column.Type !== 'link' &&
                    column.DataField !== 'InitialPremium' &&
                    column.Label !== 'Annuity'
                  "
                >
                  <span>
                    {{ dataItem[column.DataField] }}
                  </span>
                </ng-template>
              </kendo-grid-column>
            </kendo-grid-column-group>
            <kendo-grid-column-group
              *ngIf="column.Type === 'parent' && column.Children.length"
              [title]="column.Label"
              [hidden]="column.Hidden"
              [headerClass]="'parent'"
            >
              <ng-container *ngFor="let childCol of column.Children; let i = index">
                <kendo-grid-column
                  [field]="childCol.DataField"
                  [title]="childCol.Label"
                  [headerClass]="childCol.Label === 'Age ' + deferralAge ? 'child deferral' : 'child'"
                  [width]="75"
                  [sortable]="true"
                >
                  <ng-template
                    kendoGridCellTemplate
                    let-dataItem
                    *ngIf="childCol.Type === 'curr'"
                  >
                    <span
                      *ngIf="dataItem[childCol.DataField]"
                      [class]="childCol.Label === 'Age ' + deferralAge ? 'deferral' : ''"
                    >
                      {{ dataItem[childCol.DataField] | currency : 'USD' : 'symbol' : '1.0-0' }}
                    </span>
                    <span
                      *ngIf="!dataItem[childCol.DataField]"
                      [class]="childCol.Label === 'Age ' + deferralAge ? 'deferral blank' : 'blank'"
                    >
                      --
                    </span>
                  </ng-template>
                  <ng-template
                    kendoGridCellTemplate
                    let-dataItem
                    *ngIf="childCol.Type === 'perc'"
                  >
                    <span
                      *ngIf="dataItem[childCol.DataField]"
                      [class]="childCol.Label === 'Age ' + deferralAge ? 'deferral' : ''"
                    >
                      {{ dataItem[childCol.DataField] | percent: '1.2' }}
                    </span>
                    <span
                      *ngIf="!dataItem[childCol.DataField]"
                      [class]="childCol.Label === 'Age ' + deferralAge ? 'deferral blank' : 'blank'"
                    >
                      --
                    </span>
                  </ng-template>
                  <ng-template
                    kendoGridCellTemplate
                    let-dataItem
                    *ngIf="childCol.Type !== 'perc' && childCol.Type !== 'curr'"
                  >
                    <span *ngIf="dataItem[childCol.DataField]">
                      {{ dataItem[childCol.DataField] }}
                    </span>
                    <span *ngIf="!dataItem[childCol.DataField]">
                      --
                    </span>
                  </ng-template>
                </kendo-grid-column>
              </ng-container>
            </kendo-grid-column-group>
            <kendo-grid-column
              [field]="initialPremium"
              [title]="'Initial Premium'"
              [hidden]="true"
              [sortable]="true"
              *ngIf="initialPremium"
            ></kendo-grid-column>
          </ng-container>
        </kendo-grid>
      </div>
      <div *ngIf="label.annuityType === 'SPIA' || label.annuityType === 'DIA'">
        <div>
          <p class="disclaimer mt-2">
            <sup>*</sup>Immediate income annuities generally begin payments within 13 months of purchase. Deferred income annuities generally begin payments 24 months to 10 years after purchase.
          </p>
          <p class="disclaimer">
            <sup>**</sup>Immediate and Deferred Income annuity contracts are based off the annuitant(s) birthdate(s) which may impact the withdrawal amount.
          </p>
          <p class="disclaimer">
            <sup>***</sup>Additional payment options and frequencies are available for election
          </p>
        </div>
      </div>
    </ng-container>
  </div>
  <ngx-loading [show]="loading"></ngx-loading>
</div>
<div class="bottom-disclosure">
  <p class="mb-0 mt-4">
    <strong>Disclosure:</strong> Guaranteed withdrawal amounts are not the only consideration when recommending an annuity to clients. You should consider the guaranteed withdrawal amount in addition to the other features, provisions and guarantees of the annuity contract.
  </p>
  <p class="mb-0 mt-3 warn">
    <strong>
      INTERNAL USE ONLY - DO NOT SHOW or DISTRIBUTE TO THE PUBLIC.
    </strong>
  </p>
  <p class="mb-0 mt-0 warn">
    <strong>
      This material DOES NOT meet the requirements for use in printed or media form.
    </strong>
  </p>
</div>