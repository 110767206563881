import { Component, OnInit } from '@angular/core';
import { RightBridgeApiService } from '../services/right-bridge-api.service';
import { Location } from '@angular/common';
import { SessionStorageService } from '../services/session-storage.service';

@Component({
  selector: 'app-tools-page',
  templateUrl: './tools-page.component.html',
  styleUrls: ['./tools-page.component.scss'],
})
export class ToolsPageComponent implements OnInit {
  toolUnits = [];

  constructor(
    private ss: SessionStorageService,
    private rbs: RightBridgeApiService,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.getToolsGroup();
  }

  getToolsGroup() {
    this.rbs.getToolsPageGroup().subscribe(x => {
      const toolHeaders = x.UnitToolsHeaders;
      this.toolUnits = Object.keys(x.UnitToolsHeaders).filter(unitHeader => {
        if (x.UnitToolsHeaders[unitHeader] && unitHeader.includes('Title')) {
          return unitHeader;
        }
      });
      this.toolUnits.map((unit, i) => {
        const unitHeader = unit.replace('Title', '');
        this.toolUnits[i] = {
          label: toolHeaders[unit],
          description: toolHeaders[`${unitHeader}HeaderDescription`],
          unitTools: x[`${unitHeader}UnitTools`],
        };
      });
    });
  }

  onLinkClick(link, name) {
    if (name.toLowerCase().includes('wizard')) {
      this.goToQuestionnaire(link);
    } else {
      window.open(`${this.location.prepareExternalUrl('/')}${link}`, '_blank');
    }
  }

  goToQuestionnaire(app) {
    this.ss.set('currentApp', app);
    this.rbs.setApp(app);
    this.rbs.newProfile({ 'Profile.Description': '' }).subscribe(x => {
      window.open(
        `${this.location.prepareExternalUrl('/case/')}${app}/${x.results}/0`,
        '_blank'
      );
    });
  }
}
