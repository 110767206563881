import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  AfterContentChecked,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionStorageService } from '../../services/session-storage.service';
import { AdminService } from '../admin.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProductCompareModalComponent } from '../product-compare-modal/product-compare-modal.component';
import { DashboardService } from '../../dashboard/dashboard.service';
import { saveAs } from '@progress/kendo-file-saver';
import { Platform } from '@angular/cdk/platform';
import { EnvironmentService } from '../../services/environment.service';
import { interval } from 'rxjs';
import { mergeMap, takeWhile, map } from 'rxjs/operators';
import { uniqBy } from 'lodash';
import { DateTime } from 'luxon';
import { LoadingDialogComponent } from '../../loading-dialog/loading-dialog.component';

@Component({
  selector: 'app-profile-history',
  templateUrl: './profile-history.component.html',
  styleUrls: ['./profile-history.component.scss'],
})
export class ProfileHistoryComponent implements OnInit, AfterContentChecked {
  private environment;
  unitsManaged = this.ss.get('globals')
    ? this.ss.get('globals').user.managerUnits
    : {};
  unit =
    this.unitsManaged && this.unitsManaged.length > 0
      ? this.unitsManaged[0].id
      : null;
  profile;
  changeSummary;
  loading = true;
  solution;
  rights = [];
  caseSummary;
  solutionName = '';
  caseNumber = '';
  profileId = '';
  clientName = '';
  userName = '';
  repCode = '';
  runs = 0;
  scoreClassChanges = 0;
  productsSelected = 0;
  productTypes;
  dataColumns = [];

  private baseUrl: string;

  @ViewChild('profileHistoryGrid', { static: false })
  profileHistoryGrid: ElementRef;
  dialogRef: MatDialogRef<LoadingDialogComponent>;

  constructor(
    private admnSrvc: AdminService,
    private route: ActivatedRoute,
    private ss: SessionStorageService,
    private dialog: MatDialog,
    private dbs: DashboardService,
    private pltfrm: Platform,
    private envSvc: EnvironmentService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.profile = params['params']['profileId'];
      this.solution = params['params']['solution']?.toLowerCase();
    });
    this.getProfileHistory();
    this.rights = this.ss.get('rights') ? this.ss.get('rights') : [];
    this.environment = this.envSvc.get();
    this.baseUrl = this.environment.apiBase;
  }

  ngAfterContentChecked() {
    if (this.productTypes && this.profileHistoryGrid) {
      const gridWidth = this.profileHistoryGrid.nativeElement.offsetWidth;
      if (gridWidth < window.innerWidth - 80) {
        document.getElementById('grid-container').style.display = 'flex';
        document.getElementById('grid-container').style.justifyContent =
          'center';
        document.getElementById('grid-container').style.overflowX = 'auto';
      }
    }
  }

  getProfileHistory() {
    this.loading = true;
    this.admnSrvc.getBridgeHistory(this.profile, this.solution).subscribe(x => {
      this.caseSummary = x;
      this.solutionName = this.getHistoryValue(x, 'SolutionID', true);
      this.caseNumber = this.getHistoryValue(x, 'caseNumber');
      this.profileId = this.getHistoryValue(x, 'profileID');
      this.clientName =
        this.getHistoryValue(x, 'FirstName') +
        ' ' +
        this.getHistoryValue(x, 'LastName');
      this.userName = this.getHistoryValue(x, 'Users');
      this.repCode = this.getHistoryValue(x, 'RepCodes');
      this.runs = this.getHistoryValue(x, 'Runs');
      this.scoreClassChanges = this.getHistoryValue(x, 'ScoreClassChanges');
      this.productsSelected = x?.ProductType
        ? this.getProductsSelectedSum(x.ProductType)
        : 0;
      this.productTypes = x?.ProductType;
      this.loading = false;
    });
  }

  getHistoryValue(dataset, value, labelFlag = false) {
    if (labelFlag) {
      return dataset?.historySummary.find(x => x.id === value)?.label;
    }
    return dataset?.historySummary.find(x => x.id === value)?.Value;
  }

  getProductsSelectedSum(dataset) {
    let productCount = 0;
    dataset.map(product => {
      product.Bridges.map(bridge => {
        bridge.changes.map(({ amount, date, profileChangeID }) => {
          if (amount * 1 > 0) {
            productCount++;
          }
          this.dataColumns.push({
            date,
            profileChangeID,
          });
        });
      });
    });
    this.dataColumns.sort((a, b) => Date.parse(a.date) - Date.parse(b.date));
    this.dataColumns = uniqBy(this.dataColumns, 'profileChangeID');
    return productCount;
  }

  bridgeHasChanges(bridge, changeCount) {
    return bridge.changes.find(x => x.profileChangeID === changeCount);
  }

  getChangeClass(bridge, changecount) {
    const bridgeChange = bridge.changes.find(
      x => x.profileChangeID === changecount
    );
    if (bridgeChange && bridgeChange.amount * 1 > 0) {
      return bridge.changes.find(x => x.profileChangeID === changecount)
        .scoreClass;
    }
    return (
      bridge.changes.find(x => x.profileChangeID === changecount).scoreClass +
      ' no-amount'
    );
  }

  getChangeAmount(bridge, changeCount) {
    const bridgeChange = bridge.changes.find(
      x => x.profileChangeID === changeCount
    );
    if (bridgeChange && bridgeChange.amount * 1 > 0) {
      const returnAmount = bridge.changes.find(
        x => x.profileChangeID === changeCount
      ).amount;
      return '$' + returnAmount.substring(0, returnAmount.length - 2);
    }
    return '--';
  }

  openChangeDetail(bridge, changeCount) {
    const { productCode } = bridge;
    const clickedChange = bridge.changes.find(
      x => x.profileChangeID === changeCount
    );
    const prevChange =
      bridge.changes[
        bridge.changes.findIndex(x => x.profileChangeID === changeCount) - 1
      ];
    const changeIDs = [clickedChange.profileChangeID];
    const changeCounts = [clickedChange.profileChangeID];
    const runCounts = [this.dataColumns.findIndex(x => x === changeCount) + 1];

    if (prevChange) {
      changeIDs.unshift(prevChange?.profileChangeID);
      changeCounts.unshift(prevChange.profileChangeID);
      runCounts.unshift(
        this.dataColumns.findIndex(x => x === prevChange.profileChangeID) + 1
      );
    }

    this.dialog.open(ProductCompareModalComponent, {
      panelClass: 'app-product-compare-modal',
      data: {
        profileId: this.profileId,
        changeIDs,
        changeCounts,
        runCounts,
        productCode,
        prevRunCount: prevChange
          ? this.dataColumns.findIndex(x => x === prevChange.profileChangeID) +
            1
          : 0,
        solution: this.solution,
      },
    });
  }

  dlXlsx() {
    this.loading = true;
    this.dialogRef = this.dialog.open(LoadingDialogComponent, {
      panelClass: 'app-loading-dialog',
      data: {
        headline: 'Please wait',
        content: 'Your report is generating. This may take a moment...',
      },
      disableClose: true,
    });
    const unit = this.unit;
    const solution = this.solution;
    const start = DateTime.fromJSDate(
      new Date(this.dataColumns[this.dataColumns.length - 1].date),
      {
        zone: 'utc',
      }
    )
      .minus({ days: 1 })
      .toISO();
    const end = DateTime.fromJSDate(
      new Date(this.dataColumns[this.dataColumns.length - 1].date),
      {
        zone: 'utc',
      }
    )
      .plus({ days: 1 })
      .toISO();
    this.dbs
      .getExplorerExcel(
        unit,
        solution,
        start,
        end,
        false,
        false,
        'all',
        this.profileId
      )
      .subscribe(data => {
        if (data.result && data.result.length > 5) {
          const status = interval(15000).pipe(
            mergeMap(() =>
              this.dbs.getFileStatus(data.result).pipe(map(z => z))
            ),
            takeWhile(x => x.result.toLowerCase() == 'false')
          );

          status.subscribe(
            val => val,
            err => console.error(err),
            () => this.dlXlsxFile(data.result)
          );
        }
      });
  }

  dlXlsxFile(name) {
    const date = new Date();
    const filename =
      'CaseExplorer-' +
      date.getMonth() +
      date.getDay() +
      date.getFullYear() +
      '.xlsx';
    const iosSafari =
      this.pltfrm.IOS ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

    this.dbs.getXlsxFile(name).subscribe(data => {
      let fileData;
      const reader = new FileReader();
      reader.readAsDataURL(data);
      reader.onloadend = () => {
        fileData = reader.result;
        saveAs(fileData, filename, {
          proxyURL: `${this.baseUrl}/util/proxy`,
          proxyTarget: '_self',
          forceProxy: iosSafari,
        });
        if (this.dialogRef) {
          this.dialogRef.componentInstance.headline = 'Complete!';
          this.dialogRef.componentInstance.content =
            'Your file is ready! Thank you for your patience.';
          this.dialogRef.componentInstance.endModal();
        }
        this.loading = false;
      };
    });
  }

  legacyView() {
    this.router.navigate([
      `/legacy-profile-history/${this.solution}/${this.profile}`,
    ]);
  }
}
