import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  switchMap,
  distinctUntilChanged,
  map,
  startWith,
} from 'rxjs/operators';
import { Form, FormControl, UntypedFormGroup, FormGroup } from '@angular/forms';
import { InvestmentWizardService } from '../../../investment-wizard/services/investment-wizard-service.service';
import { SessionStorageService } from '../../../services/session-storage.service';
import { ActivatedRoute } from '@angular/router';
import { saveAs } from '@progress/kendo-file-saver';
// import { environment } from '../../../../environments/environment';
import { Platform } from '@angular/cdk/platform';
import { EnvironmentService } from '../../../services/environment.service';

@Component({
  selector: 'app-fund-comparison',
  templateUrl: './fund-comparison.component.html',
  styleUrls: ['./fund-comparison.component.scss'],
})
export class FundComparisonComponent implements OnInit {
  private environment;
  private baseUrl: string;

  loading = false;
  debug = false;
  programControl = new FormControl();
  familyControl = new FormControl();
  fundControl = new FormControl({ value: null, disabled: true });
  numberControl = new FormControl(3);
  classControl = new FormControl('A');
  selected = { fund: null, fundName: null, program: null };
  filteredPrograms;
  filteredFunds;
  programList;
  familyList;
  fundsDisabled = true;
  fundNum;
  fundClass;

  constructor(
    private ss: SessionStorageService,
    private route: ActivatedRoute,
    private iws: InvestmentWizardService,
    private pltfrm: Platform,
    private envSvc: EnvironmentService
  ) {}

  ngOnInit() {
    this.environment = this.envSvc.get();
    this.baseUrl = this.environment.apiBase;

    this.iws.programSearch().subscribe(x => {
      this.programList = x.results;
      // this.searchPrograms();
      // this.searchFund();
    });

    this.iws.fundFamilyList().subscribe(x => {
      this.familyList = x.results;
    });
  }

  searchPrograms() {
    this.filteredPrograms = this.programControl.valueChanges.pipe(
      startWith(''),
      map(program => {
        if (typeof program === 'string') {
          return this._filterPrograms(program);
        }
      })
    );
  }

  searchFund() {
    this.filteredFunds = this.fundControl.valueChanges.pipe(
      distinctUntilChanged(),
      switchMap(fund => {
        if (fund && fund.length > 2) {
          return this._filterFunds(fund);
        }
        return [];
      })
    );
  }

  getPdf() {
    this.loading = true;
    const iosSafari =
      this.pltfrm.IOS ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

    this.iws
      .getDashFundCompareReport(
        this.fundControl.value.FundID,
        this.programControl.value.ProgramID,
        +this.numberControl.value
      )
      .subscribe(data => {
        const filename = `${this.fundControl.value.FundName}-${this.fundControl.value.FundID}-FundComparison.pdf`;

        let fileData;
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onloadend = () => {
          fileData = reader.result;
          saveAs(fileData, filename, {
            proxyURL: `${this.baseUrl}/util/proxy`,
            proxyTarget: '_self',
            forceProxy: iosSafari,
          });
          this.loading = false;
        };
      });
  }

  displayProg(program): string | undefined {
    return program ? program.DisplayName : undefined;
  }

  displayFund(fund): string | undefined {
    return fund ? `${fund.FundID} - ${fund.FundName}` : undefined;
  }

  private _filterFunds(value: string) {
    const prog = this.programControl.value.ProgramID;
    const share = this.classControl.value;
    const families = this.familyControl.value
      ? this.familyControl.value.map(x => x.ID.toString())
      : null;
    const family = [...new Set(families)];
    const list = this.iws.fundSearch(value, prog, family, share).pipe(
      map(x => {
        return x.results;
      })
    );
    return list;
  }

  private _filterPrograms(value): any[] {
    if (value.length < 1) {
      return this.programList;
    }
    return this.programList.filter(x => {
      return x.DisplayName.toLowerCase().includes(value.toLowerCase());
    });
  }

  updateFunds(ev, clearAction?) {
    if (clearAction && clearAction == 'all') {
      this.clearAllData();
    } else if (clearAction && clearAction == 'product') {
      this.clearProductData();
    }

    if (
      this.programControl.value &&
      this.programControl.value.ProgramID &&
      this.classControl.value
    ) {
      this.fundControl.enable();
      this.searchFund();
    }
  }

  clearProductData() {
    this.fundControl.setValue(null);
    this.fundControl.disable();
  }

  clearAllData() {
    this.classControl.setValue(null);
    this.fundControl.setValue(null);
    this.fundControl.disable();
  }

  deDupObjectArray(data) {
    const uniqueArray = data.filter(
      (object, index) =>
        index ===
        data.findIndex(obj => JSON.stringify(obj) === JSON.stringify(object))
    );
    return uniqueArray;
  }

  alphabetizeGroups(a, b) {
    const groupA = a.display.toUpperCase();
    const groupB = b.display.toUpperCase();

    let comparison = 0;
    if (groupA > groupB) {
      comparison = 1;
    } else if (groupA < groupB) {
      comparison = -1;
    }
    return comparison;
  }
}
