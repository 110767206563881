<div mat-dialog-header>
  <h1>{{ dialogTitle }} - Change Comparison</h1>
</div>
<div mat-dialog-content class="dialog-content">
  <table>
    <thead>
      <th></th>
      <th *ngFor="let date of changeDates; let i = index">
        {{ date }}<br/>
        Run Count: {{ runCounts[i] }}
      </th>
    </thead>
    <tbody>
      <tr *ngFor="let row of gridRows">
        <td>
          <strong>{{ row.fieldLabel }}</strong>
        </td>
        <td
          *ngFor="let value of row.Values"
          [ngClass]="{
            'highlight': row.Values[1] &&
            row.Values[0] !== row.Values[1] &&
            !highlightExclusions.includes(row.fieldName)
          }"
        >
          {{ value }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div class="buttons">
  <button mat-stroked-button (click)="close()">Close</button>
</div>