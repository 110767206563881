import { Injectable, EventEmitter } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { Observable, fromEvent, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class SessionStorageService {
  private _emitter = new EventEmitter();
  private storageChange = new Subject<any>();
  storageChangeExternal = this.storageChange.asObservable();

  constructor() {}

  set(key, value) {
    sessionStorage.setItem(key, JSON.stringify(value));
    this.storageChange.next({
      key,
      value
    })
    return true;
  }

  get(key) {
    return JSON.parse(sessionStorage.getItem(key));
  }

  remove(key) {
    let prevValue = JSON.parse(sessionStorage.getItem(key));
    sessionStorage.removeItem(key);
    this.storageChange.next({ key })
    return prevValue;
  }
  
  clear() {
    sessionStorage.clear();
    this.storageChange.next({ key: '' })
    return true;
  }
}
