import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { RightBridgeApiService } from './right-bridge-api.service';
import { SessionStorageService } from './session-storage.service';
import { Observable } from 'rxjs/Rx';

@Injectable()
export class OutsideAuthService {
  constructor(
    private rbs: RightBridgeApiService,
    private ss: SessionStorageService,
    public router: Router,
    private titleService: Title
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    const session: string = route.params.session || null,
      org: string = route.params.org || null,
      cusip: string = route.params.cusip || null,
      auth: any = false,
      path: string = route.routeConfig.path;

    if (session && org) {
      this.rbs.querySession(session, org, 'aw').subscribe(data => {
        const auth = Number(data.results) > 0;
        if (auth) {
          if (path == 'fia/:session/:org') {
            this.rbs.setRogue('aw', org, session);
            this.titleService.setTitle('Annuity Wizard');
            this.router.navigate(['/aw/fia']);
          } else if (path == 'fa/:session/:org') {
            this.rbs.setRogue('aw', org, session);
            this.titleService.setTitle('Annuity Wizard');
            this.router.navigate(['/aw/fa']);
          } else if (path == 'rila/:session/:org') {
            this.rbs.setRogue('aw', org, session);
            this.titleService.setTitle('Annuity Wizard');
            this.router.navigate(['/aw/rila']);
          } else if (path == 'fia/:session/:org/:cusip') {
            this.titleService.setTitle('Annuity Wizard');
            this.router.navigate(['/aw/fia/' + cusip]);
          } else if (path == 'fa/:session/:org/:cusip') {
            this.titleService.setTitle('Annuity Wizard');
            this.router.navigate(['/aw/fa/' + cusip]);
          } else if (path == 'rila/:session/:org/:cusip') {
            this.titleService.setTitle('Annuity Wizard');
            this.router.navigate(['/aw/rila/' + cusip]);
          }
        }
      });
    }
    return auth;
  }
}
