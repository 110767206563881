import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../dashboard.service';
import { Router } from '@angular/router';
import { SessionStorageService } from '../../../services/session-storage.service';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-system-usage-summary',
  templateUrl: './systemUsageSummary.component.html',
  styleUrls: ['./systemUsageSummary.component.scss'],
})
export class SystemUsageSummaryComponent implements OnInit {
  Highcharts: typeof Highcharts = Highcharts;
  loading = false;
  unitsManaged = this.ss.get('globals')?.user.managerUnits;
  unit =
    this.unitsManaged && this.unitsManaged.length > 0
      ? this.unitsManaged[0].id
      : null;
  app = this.ss.get('currentApp');
  data;
  selectedUnit;
  chart = { data: null, legend: null };
  months = 6;

  constructor(
    private dbs: DashboardService,
    private ss: SessionStorageService,
    private router: Router
  ) {}

  ngOnInit() {
    const firm = this.ss.get('firm');
    this.selectedUnit = firm;
    this.getChart();
  }

  getChart() {
    this.loading = true;
    this.dbs.getUsageChart(this.selectedUnit, +this.months).subscribe(x => {
      this.chart.data = x.charts[0].Data;
      this.chart.data.chart.width = null;
      this.chart.data.title.text = null;
      this.chart.data.xAxis.title.text = null;
      this.chart.legend = x.charts[0].Legend;
      this.loading = false;
    });
  }
}
