<div class="hierarchy-criteria row">
  <div class="col-12 col-md-6 hierarchy"></div>
  <div class="col-2"></div>
  <div class="col-12 col-md-4 search-form unified">
    <form
      class="search-form w-100"
      *ngIf="!managerSearch"
      (submit)="search(searchValue)"
    >
      <mat-form-field class="w-75">
        <mat-label>Search for a case</mat-label>
        <input
          matInput
          type="text"
          placeholder="Search for a case"
          name="homeSearch"
          [(ngModel)]="searchValue"
        />
        <button
          *ngIf="searchValue"
          type="button"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          class="clearButton"
          (click)="clearSearch()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <button
        mat-button
        class="w-25 search-button"
        color="primary"
        type="submit"
      >
        <mat-icon>search</mat-icon>Search
      </button>
    </form>
    <form
      class="search-form w-100"
      *ngIf="managerSearch"
      (submit)="search(searchValue)"
    >
      <mat-form-field class="w-75">
        <mat-label>Search for a case</mat-label>
        <input
          matInput
          type="text"
          placeholder="Search for a case"
          #managerSearchBox
          name="managerSearch"
          [(ngModel)]="searchValue"
        />
        <button
          *ngIf="searchValue"
          type="button"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          class="clearButton"
          (click)="clearSearch()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <button
        mat-button
        class="w-25 search-button"
        color="primary"
        type="submit"
      >
        <mat-icon>search</mat-icon>Search
      </button>
    </form>
  </div>
  <div class="info col-12 row">
    <div class="col-12 col-md-8">
      <ng-container *ngIf="initialSearch">
        <mat-icon>info</mat-icon>Showing 200 Most Recent Cases
      </ng-container>
    </div>
    <div class="col-12 col-md-4 hierarchy unified">
      <button
        mat-button
        (click)="clearHierarchy()"
        *ngIf="selectedUnit || selectedUser || selectedRepCode"
        class="clear"
      >
        <mat-icon>close</mat-icon>
      </button>
      <p *ngIf="selectedUnit || selectedUser || selectedRepCode">
        <strong>Searching as: </strong>
        <ng-container
          *ngIf="selectedUnit && !selectedUser && !selectedRepCode"
          class="search-as"
        >
          Unit: {{ selectedUnit.name }}
        </ng-container>
        <ng-container
          *ngIf="selectedUser && !selectedRepCode"
          class="search-as"
        >
          User: {{ selectedUser.name }}
        </ng-container>
        <ng-container *ngIf="selectedRepCode" class="search-as">
          Rep Code: {{ selectedRepCode.name }}
        </ng-container>
      </p>
      <button mat-button (click)="setHierarchy()" *ngIf="chooseHierarchy">
        <mat-icon>search</mat-icon>Change Search Hierarchy
      </button>
    </div>
  </div>
</div>
<span class="search-grid-container unified">
  <kendo-grid
    #resultsGrid="kendoGrid"
    class="col-12 search-grid"
    [scrollable]="'none'"
    [kendoGridBinding]="rows"
    [pageSize]="pageSize"
    [pageable]="true"
    [sortable]="{ allowUnsort: true, mode: 'multiple' }"
    [filterable]="'menu'"
    [columnMenu]="true"
    [filter]="filter"
    [selectable]="{ mode: 'single' }"
  >
    <ng-template kendoGridNoRecordsTemplate>{{ noResults }}</ng-template>
    <kendo-grid-column
      class="color-key-container"
      [width]="270"
      title="Application"
    >
      <ng-template kendoGridHeaderTemplate>
        Launch Case in an Application <mat-icon>info</mat-icon>
      </ng-template>
      <ng-template kendoGridColumnMenuTemplate>
        <div class="px-3 py-1">
          <div class="color-key-item d-flex align-items-center">
            <span class="color-key mr-1"></span> = Complete
          </div>
          <div class="color-key-item d-flex align-items-center">
            <span class="color-key mr-1"></span> = In Progress
          </div>
          <div class="color-key-item d-flex align-items-center">
            <span class="color-key mr-1"></span> = Not Started
          </div>
        </div>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <button
          mat-icon-button
          (click)="
            launchApp($event, dataItem.id, 'rb'); $event.stopPropagation()
          "
          matTooltip="Launch Case to Client Information"
          [matTooltipShowDelay]="1000"
          [ngClass]="{
            complete:
              dataItem.ciStatus === 'C' ||
              ((dataItem.ciStatus === '' || !dataItem.ciStatus) &&
                dataItem.ppStatus === 'C'),
            opened:
              dataItem.ciStatus === 'O' ||
              ((dataItem.ciStatus === '' || !dataItem.ciStatus) &&
                dataItem.ppStatus === 'O')
          }"
          class="app-button"
          *ngIf="rights?.includes('PP')"
          [disabled]="rights?.includes('DisableCaseLaunchButtons')"
        >
          <mat-icon class="app-button-icon">perm_contact_calendar</mat-icon>
        </button>
        <button
          mat-icon-button
          (click)="
            launchApp($event, dataItem.id, 'pp'); $event.stopPropagation()
          "
          matTooltip="Launch Case in Product Profiler"
          [matTooltipShowDelay]="1000"
          [ngClass]="{
            complete: dataItem.ppStatus === 'C',
            opened: dataItem.ppStatus === 'O'
          }"
          class="app-button"
          *ngIf="rights?.includes('PP')"
          [disabled]="
            (dataItem.ppStatus !== 'C' && dataItem.ppStatus !== 'O') ||
            rights?.includes('DisableCaseLaunchButtons')
          "
        >
          <mat-icon class="app-button-icon" svgIcon="pp_icon"></mat-icon>
        </button>
        <button
          mat-icon-button
          (click)="
            launchApp($event, dataItem.id, 'aw'); $event.stopPropagation()
          "
          matTooltip="Launch Case in Annuity Wizard"
          [matTooltipShowDelay]="1000"
          [ngClass]="{
            complete: dataItem.awStatus === 'C',
            opened: dataItem.awStatus === 'O'
          }"
          class="app-button"
          *ngIf="rights?.includes('AW')"
          [disabled]="
            (dataItem.awStatus !== 'C' && dataItem.awStatus !== 'O') ||
            rights?.includes('DisableCaseLaunchButtons')
          "
        >
          <mat-icon class="app-button-icon" svgIcon="aw_icon"></mat-icon>
        </button>
        <button
          mat-icon-button
          (click)="
            launchApp($event, dataItem.id, 'iw'); $event.stopPropagation()
          "
          matTooltip="Launch Case in Investment Wizard"
          [matTooltipShowDelay]="1000"
          [ngClass]="{
            complete: dataItem.iwStatus === 'C',
            opened: dataItem.iwStatus === 'O'
          }"
          class="app-button"
          *ngIf="rights?.includes('IW')"
          [disabled]="
            (dataItem.iwStatus !== 'C' && dataItem.iwStatus !== 'O') ||
            rights?.includes('DisableCaseLaunchButtons')
          "
        >
          <mat-icon class="app-button-icon" svgIcon="iw_icon"></mat-icon>
        </button>
        <button
          mat-icon-button
          (click)="
            launchApp($event, dataItem.id, 'li'); $event.stopPropagation()
          "
          matTooltip="Launch Case in Life Insurance Wizard"
          [matTooltipShowDelay]="1000"
          [ngClass]="{
            complete: dataItem.lwStatus === 'C',
            opened: dataItem.lwStatus === 'O',
            disabled: dataItem.lwStatus !== 'O' && dataItem.lwStatus !== 'C'
          }"
          class="app-button"
          *ngIf="rights?.includes('LI')"
          [disabled]="
            (dataItem.lwStatus !== 'C' && dataItem.lwStatus !== 'O') ||
            rights?.includes('DisableCaseLaunchButtons')
          "
        >
          <mat-icon class="app-button-icon" svgIcon="lw_icon"></mat-icon>
        </button>
        <!-- <button
          mat-icon-button
          (click)="
            launchApp($event, dataItem.CRID, 'cm'); $event.stopPropagation()
          "
          matTooltip="Launch Case in Case Management"
          [matTooltipShowDelay]="1000"
          [ngClass]="{
            complete: dataItem.CRID && dataItem.CRID !== '',
            disabled: dataItem.CRID === ''
          }"
          class="app-button"
          *ngIf="rights?.includes('CM')"
          [disabled]="
            !dataItem.CRID ||
            dataItem.CRID === '' ||
            rights?.includes('DisableCaseLaunchButtons')
          "
        >
          <mat-icon class="app-button-icon" svgIcon="cm_icon"></mat-icon>
        </button> -->
        <button
          *ngIf="showSummarylink"
          mat-icon-button
          (click)="
            launchApp($event, dataItem.id, 'summary'); $event.stopPropagation()
          "
          matTooltip="Launch to Case Summary"
          [matTooltipShowDelay]="1000"
          [ngClass]="{
            complete:
              dataItem.lwStatus === 'C' ||
              dataItem.iwStatus === 'C' ||
              dataItem.awStatus === 'C' ||
              dataItem.ppStatus === 'C'
          }"
          [disabled]="
            dataItem.lwStatus !== 'C' &&
            dataItem.iwStatus !== 'C' &&
            dataItem.awStatus !== 'C' &&
            dataItem.ppStatus !== 'C'
          "
          class="app-button"
        >
          <mat-icon class="app-button-icon">list</mat-icon>
        </button>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="caseNumber"
      title="Case Number"
      media="md"
      [width]="230"
    ></kendo-grid-column>
    <kendo-grid-column
      field="FullName"
      title="Name"
      [width]="350"
    ></kendo-grid-column>
    <kendo-grid-column
      field="description"
      title="Description"
      media="md"
      [width]="250"
    ></kendo-grid-column>
    <kendo-grid-column
      field="LastInteractionDate"
      title="Last Interaction Date"
      media="md"
      [width]="250"
      filter="date"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.LastInteractionDate | date: 'medium' }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      *ngIf="canPrint || canCopy || canSwap || canDelete"
      [width]="30"
      [columnMenu]="false"
      title="Actions"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button
            mat-menu-item
            *ngIf="canPrint"
            (click)="printReport($event, dataItem)"
            matTooltip="Download Report"
            matTooltipPosition="above"
            [matTooltipShowDelay]="1000"
            [disabled]="dataItem.ppStatus !== 'C'"
          >
            <mat-icon>insert_drive_file</mat-icon>
            <span>Download Report</span>
          </button>
          <button
            mat-menu-item
            *ngIf="canCopy"
            (click)="copy(dataItem)"
            matTooltip="Copy Profile"
            matTooltipPosition="above"
            [matTooltipShowDelay]="1000"
          >
            <mat-icon>content_copy</mat-icon>
            <span>Copy Profile</span>
          </button>
          <button
            mat-menu-item
            *ngIf="canSwap"
            (click)="swap(dataItem)"
            matTooltip="Copy Profile and Reverse Spouses"
            matTooltipPosition="above"
            [matTooltipShowDelay]="1000"
          >
            <mat-icon>swap_calls</mat-icon>
            <span>Copy Profile and Reverse Spouses</span>
          </button>
          <button
            mat-menu-item
            *ngIf="canDelete"
            (click)="delete(dataItem.id)"
            matTooltip="Delete Profile"
            matTooltipPosition="above"
            [matTooltipShowDelay]="1000"
          >
            <mat-icon>delete</mat-icon>
            <span>Delete Profile</span>
          </button>
        </mat-menu>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</span>
<ngx-loading [show]="loading"></ngx-loading>
