import { Component, HostBinding } from '@angular/core';
import { UntypedFormGroup, FormGroup } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'form-radioValidation',
  styleUrls: ['form-radioValidation.component.scss'],
  template: `
      <label class="section" class="w-100" [class.mat-error]="formControl.errors" *ngIf="props.label" [innerHTML]="props.label"><sup *ngIf="props.required">*</sup></label>
      <mat-icon *ngIf="props.help" class="help-icon" matTooltip="{{to.help}}" [matTooltipPosition]="'above'" [matTooltipClass]="'rb-tooltip'">help</mat-icon>
      <p *ngIf="props.prompt" class="explanation" [class.mat-error]="formControl.errors" [innerHTML]="props.prompt"></p>
      <p *ngIf="props.placeholder" class="italic" [class.mat-error]="formControl.errors" [innerHTML]="props.placeholder"></p>

      <mat-radio-group class="radio-group radio-group-validate col-12" [formControl]="formControl" [formlyAttributes]="field" [required]="props.required" (change)="props.change ? to.change(field, formControl):''" >
        <mat-radio-button class="radio-button" *ngFor="let opt of to.options" [value]="opt.value">
          <mat-icon *ngIf="opt.value == 0">highlight_off</mat-icon>
          <mat-icon *ngIf="opt.value == 1">stars</mat-icon>
          <mat-icon *ngIf="opt.value == 2">find_in_page</mat-icon>
          <mat-icon *ngIf="opt.value == 'RET'">beach_access</mat-icon>
          <mat-icon *ngIf="opt.value == 'CF'">school</mat-icon>
          <mat-icon *ngIf="opt.value == 'OTH'">multiline_chart</mat-icon>
          <mat-icon *ngIf="opt.value == 'LI'">security</mat-icon>
          {{opt.label}}
        </mat-radio-button>
        <mat-error *ngIf="formControl.hasError('required') && !formControl.hasError('regRequired')">This field is required</mat-error>
        <mat-error *ngIf="formControl.hasError('regRequired')">This field is needed for suitability review. You can opt out below if the applicant refuses to provide the information.</mat-error>
      </mat-radio-group>
  `
})
export class FormlyRadioValidation extends FieldType { }
