<div class="page-container rep-review-detail-container container pt-2">
  <h1 class="header col-12 mb-1"> Rep Details - {{ repId }} </h1>
  @if (!loading) {
    <div class="actions-container mb-3">
      <button mat-button (click)="nav('rep-review')" color="primary">
        <mat-icon>arrow_back_ios</mat-icon> Back to Financial Professional
        Monitor
      </button>
    </div>

    <div class="trade-info-container info-card mb-4 w-100 p-0">
      <div class="card-header">
        <span class="card-title-container">
          <mat-icon
            [matTooltip]="'Rep Score: ' + repDetail.ScoreClass"
            class="scoreclass-icon mr-2"
            [class]="repDetail.ScoreClass"
          >
            {{ scoreClassKey[repDetail.ScoreClass] }}
          </mat-icon>
          RepCode:
          <span class="card-title mb-0 ml-2">
            {{ repDetail.RepCode }}
          </span>
        </span>

        <span>
          <button
            class="mr-2"
            mat-raised-button
            [matMenuTriggerFor]="tradeActionsMenu"
          >
            <mat-icon>settings</mat-icon> Actions
          </button>

          <button
            class="mr-2"
            mat-raised-button
            [matMenuTriggerFor]="additionalRepActionsMenu"
          >
            <mat-icon>more_vert</mat-icon> more
          </button>

          <mat-menu
            #tradeActionsMenu="matMenu"
            direction="down"
            yPosition="below"
            class="unified-menu"
          >
            @if (repDetail.ReviewStatus !== 'INP') {
              <button mat-menu-item (click)="toggleReviewed('INP')">
                <mat-icon>construction</mat-icon> Mark In Process
              </button>
            }
            @if (repDetail.ReviewStatus !== 'REV') {
              <button mat-menu-item (click)="toggleReviewed('REV')">
                <mat-icon>check_circle_outline</mat-icon> Mark as Reviewed
              </button>
            } @else {
              <button mat-menu-item (click)="toggleReviewed('REO')">
                <mat-icon>meeting_room</mat-icon> Reopen
              </button>
            }
          </mat-menu>

          <mat-menu
            #additionalRepActionsMenu="matMenu"
            direction="down"
            yPosition="below"
            class="unified-menu"
          >
            @if (repDetail.RepHistory && !isEmpty(repDetail.RepHistory)) {
              <button mat-menu-item (click)="viewRepHistory()">
                <mat-icon class="mr-0">alarm_on</mat-icon> View Rep History
              </button>
            }

            <button mat-menu-item (click)="seeDocuments()">
              <mat-icon class="mr-0">file_present</mat-icon> View Notes &
              Attachments
            </button>

            <button mat-menu-item (click)="initiateCase()">
              <mat-icon>medical_services</mat-icon> Initiate Case
            </button>
          </mat-menu>
        </span>
      </div>
      <div class="card-info row">
        @for (repInfo of repInfoKey; track repInfo.label) {
          <div class="col-sm-3 col-12">
            <div class="card-info-label">
              {{ repInfo.label }}
            </div>
            <div class="card-info-value mb-2">
              @if (repInfo.key) {
                {{ repInfo.key[repDetail[repInfo.varName]] }}
              } @else if (repInfo?.varSet) {
                {{ repDetail['RepProfile'][repInfo.varSet][repInfo.varName] }}
              } @else {
                {{ repDetail[repInfo.varName] }}
              }
            </div>
          </div>
        }
      </div>
    </div>

    @if (rights.includes('CM') && caseHistory.length > 0) {
      <div class="mutual-funds-container info-card mb-4">
        <div class="card-header">
          <span class="card-title mb-0"> Case History </span>
        </div>
        <table class="details col-12">
          <thead>
            <tr>
              <th></th>
              @for (header of caseHistoryKey; track header.label) {
                <th>{{ header.label }}</th>
              }
            </tr>
          </thead>
          <tbody>
            @for (case of caseHistory; track case.CRID; let idx = $index) {
              @if (idx < caseHistoryRows) {
                <tr>
                  <td width="75">
                    <div class="action-button-container">
                      <button
                        mat-icon-button
                        class="fs-button"
                        (click)="toggleCaseReview(case)"
                        color="primary"
                        matTooltip="Review Case"
                      >
                        <mat-icon>rate_review</mat-icon>
                      </button>
                    </div>
                  </td>
                  @for (caseKey of caseHistoryKey; track caseKey.label) {
                    <td>
                      @if (
                        caseKey.valueMask && caseKey.valueMask === 'currency'
                      ) {
                        <span>{{ case[caseKey.varName] | currency }}</span>
                      } @else if (
                        caseKey.valueMask && caseKey.valueMask === 'date'
                      ) {
                        <span>{{
                          case[caseKey.varName] | localTime: 'shortDateTime'
                        }}</span>
                      } @else {
                        {{ case[caseKey.varName] }}
                      }
                    </td>
                  }
                </tr>
              }
            }
          </tbody>
        </table>
        @if (caseHistory.length > 5) {
          <div class="toggle-row">
            <button
              class="mr-2 w-100"
              mat-button
              (click)="toggleRows('caseHistoryRows', caseHistory.length)"
              color="primary"
            >
              <mat-icon class="mr-1">
                @if (caseHistoryRows === 5) {
                  add
                } @else {
                  remove
                }
              </mat-icon>
              @if (caseHistoryRows === 5) {
                Show More
              } @else {
                Show Less
              }
            </button>
          </div>
        }

        <div class="case-reviewer" [ngClass]="{ expanded: reviewCase }">
          @if (reviewCase && caseData) {
            <div class="app-case-management-modal">
              <app-case-manager
                [data]="caseData"
                [changesMade]="caseChangesMade"
                (flagChanges)="changeCaseValue($event)"
              ></app-case-manager>
            </div>
          }
        </div>
      </div>
    }

    @if (repDetail?.RepProfile.bridges) {
      <div class="alerts-container info-card mb-4">
        <div class="card-header">
          <span class="card-title mb-0"> Alerts </span>
        </div>
        <div class="card-info row">
          <div class="alerts">
            @for (bridge of repDetail.RepProfile.bridges; track bridge) {
              <app-reason-text
                class="col-6"
                [data]="bridge.trueRules"
              ></app-reason-text>
            }
          </div>
        </div>
      </div>
    }

    @if (repDetail?.RepProfile.RepMetrics) {
      <div class="key-metrics-container info-card mb-4">
        <div class="card-header">
          <span class="card-title mb-0"> Key Metrics </span>
        </div>
        <div class="card-info row">
          @for (table of keyMetricsKey; track table) {
            <div class="col-6">
              <table class="metrics-table">
                <tbody>
                  @for (keyMetric of table; track keyMetric.label) {
                    <tr>
                      <td class="metric-label">
                        <strong>
                          {{ keyMetric.label }}
                        </strong>
                      </td>
                      <td>
                        @if (
                          keyMetric.valueMask &&
                          keyMetric.valueMask === 'currency'
                        ) {
                          {{
                            repDetail.RepProfile.RepMetrics[keyMetric.varName]
                              | currency
                          }}
                        } @else if (
                          keyMetric.valueMask &&
                          keyMetric.valueMask === 'percent'
                        ) {
                          {{
                            repDetail.RepProfile.RepMetrics[keyMetric.varName]
                              | percent
                          }}
                        } @else {
                          {{
                            repDetail.RepProfile.RepMetrics[keyMetric.varName]
                          }}
                        }
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          }
        </div>
      </div>
    }

    @if (
      repDetail?.RepProfile.RelatedAccounts &&
      repDetail?.RepProfile.RelatedAccounts.length > 0
    ) {
      <div class="mutual-funds-container info-card mb-4">
        <div class="card-header">
          <span class="card-title mb-0"> Related Accounts </span>
        </div>
        <table class="details col-12">
          <thead>
            <tr>
              @for (header of relatedAccountsKey; track header.label) {
                <th>{{ header.label }}</th>
              }
            </tr>
          </thead>
          <tbody>
            @for (
              relatedAccount of repDetail.RepProfile.RelatedAccounts;
              track relatedAccount.CRID;
              let idx = $index
            ) {
              @if (idx < relatedAccountRows) {
                <tr>
                  @for (header of relatedAccountsKey; track header.label) {
                    <td>
                      @if (
                        header.key && relatedAccount[header.varName] === '401k'
                      ) {
                        {{ header.key['FOUR01K'] }}
                      } @else if (
                        header.key && relatedAccount[header.varName] === '529'
                      ) {
                        {{ header.key['FIVE29'] }}
                      } @else if (header.varName === 'ScoreClass') {
                        <div class="score-class-container">
                          <mat-icon
                            [matTooltip]="
                              'Account Score: ' + relatedAccount[header.varName]
                            "
                            class="scoreclass-icon mr-2"
                            [class]="relatedAccount[header.varName]"
                          >
                            {{ scoreClassKey[relatedAccount[header.varName]] }}
                          </mat-icon>
                        </div>
                      } @else if (header.key) {
                        {{ header.key[relatedAccount[header.varName]] }}
                      } @else if (
                        header.valueMask && header.valueMask === 'currency'
                      ) {
                        <span>{{
                          relatedAccount[header.varName] | currency
                        }}</span>
                      } @else if (
                        header.valueMask && header.valueMask === 'date'
                      ) {
                        <span>{{
                          relatedAccount[header.varName]
                            | localTime: 'shortDateTime'
                        }}</span>
                      } @else if (
                        header.valueMask && header.valueMask === 'percent'
                      ) {
                        {{ relatedAccount[header.varName] | percent }}
                      } @else {
                        {{ relatedAccount[header.varName] }}
                      }

                      @if (header.varName === 'AccountIDDisplay') {
                        <button
                          class="launch-account-button"
                          mat-icon-button
                          color="primary"
                          (click)="
                            nav('account-review/' + relatedAccount['AccountID'])
                          "
                        >
                          <mat-icon class="mr-2"> launch </mat-icon>
                        </button>
                      }
                    </td>
                  }
                </tr>
              }
            }
          </tbody>
        </table>
        @if (repDetail.RepProfile.RelatedAccounts.length > 5) {
          <div class="toggle-row">
            <button
              class="mr-2 w-100"
              mat-button
              (click)="
                toggleRows(
                  'relatedAccountRows',
                  repDetail?.RepProfile.RelatedAccounts.length
                )
              "
              color="primary"
            >
              <mat-icon class="mr-1">
                @if (relatedAccountRows === 5) {
                  add
                } @else {
                  remove
                }
              </mat-icon>
              @if (relatedAccountRows === 5) {
                Show More
              } @else {
                Show Less
              }
            </button>
          </div>
        }

        <div class="case-reviewer" [ngClass]="{ expanded: reviewCase }">
          @if (reviewCase && caseData) {
            <div class="app-case-management-modal">
              <app-case-manager
                [data]="caseData"
                [changesMade]="caseChangesMade"
                (flagChanges)="changeCaseValue($event)"
              ></app-case-manager>
            </div>
          }
        </div>
      </div>
    }

    @if (
      repDetail.RepProfile.bridges &&
      repDetail.RepProfile.bridges[0] &&
      repDetail.RepProfile.bridges[0].charts &&
      !isEmpty(repDetail.RepProfile.bridges[0].charts)
    ) {
      <div class="breakpoints-container info-card mb-4">
        <div class="card-header">
          <span class="card-title mb-0"> Accounts by Registration </span>
        </div>
        <div class="card-info row">
          @for (chrt of repDetail.RepProfile.bridges[0].charts; track chrt) {
            @if (chrt) {
              <div
                class="chart-container clearfix mb-5"
                [ngClass]="{
                  'col-6': chrt.ChartContainer === 'half',
                  'col-12': chrt.ChartContainer === 'full'
                }"
              >
                @if (!isEmpty(chrt.Data)) {
                  <div
                    class="chart pull-left px-4"
                    [ngClass]="{
                      'col-12': chrt.LegendPosition === 'bottom',
                      'col-6': chrt.LegendPosition === 'right'
                    }"
                  >
                    <highcharts-chart
                      [Highcharts]="Highcharts"
                      [options]="chrt.Data"
                      class="mx-auto"
                    ></highcharts-chart>
                  </div>
                }

                @if (chrt.Legend) {
                  <div
                    class="chart-legend"
                    [ngClass]="{
                      'col-12': chrt.LegendPosition === 'bottom',
                      'col-6 ml-auto': chrt.LegendPosition === 'right'
                    }"
                  >
                    @if (chrt.Legend.header) {
                      <table>
                        <thead>
                          <tr>
                            @for (th of chrt.Legend.header; track th) {
                              @if (th.toLowerCase() !== 'color') {
                                <th>
                                  {{ th }}
                                </th>
                              }
                            }
                          </tr>
                        </thead>
                        <tbody>
                          @for (tr of chrt.Legend.data; track tr) {
                            <tr>
                              <ng-container
                                *ngFor="
                                  let td of tr | keyvalue: returnZero;
                                  let i = index
                                "
                              >
                                @if (
                                  chrt.Legend.header[i].toLowerCase() !==
                                  'color'
                                ) {
                                  <td>
                                    @if (
                                      chrt.Legend.header[i].toLowerCase() !==
                                        'color' &&
                                      chrt.Legend.header[0].toLowerCase() ===
                                        'color' &&
                                      i === 1
                                    ) {
                                      <span
                                        [style.background]="tr['Color']"
                                        class="indicator"
                                      ></span>
                                    }
                                    {{ td.value }}
                                  </td>
                                }
                              </ng-container>
                            </tr>
                          }
                        </tbody>
                      </table>
                    } @else {
                      @for (c of chrt.Legend; track c.value) {
                        <div>
                          <strong>{{ c.label }}</strong> {{ c.value }}
                        </div>
                      }
                    }
                  </div>
                }
              </div>
            }
          }
        </div>
      </div>
    }

    @if (repDetail.RepProfile.RecentAccountActivity) {
      <div class="mutual-funds-container info-card mb-4">
        <div class="card-header">
          <span class="card-title mb-0"> Recent Rep Activity </span>
        </div>
        <table class="details col-12">
          <thead>
            <tr>
              @for (header of recentActivityKey; track header.label) {
                <th>{{ header.label }}</th>
              }
            </tr>
          </thead>
          <tbody>
            @for (
              account of repDetail.RepProfile.RecentAccountActivity;
              track account.SecID;
              let idx = $index
            ) {
              @if (idx < recentActivityRows) {
                <tr>
                  @for (holding of recentActivityKey; track holding.label) {
                    <td>
                      @if (
                        holding.valueMask && holding.valueMask === 'currency'
                      ) {
                        <span>{{ account[holding.varName] | currency }}</span>
                      } @else if (
                        holding.valueMask && holding.valueMask === 'date'
                      ) {
                        <span>{{
                          account[holding.varName] | localTime: 'shortDate'
                        }}</span>
                      } @else if (holding.varName === 'ScoreClass') {
                        <div class="score-class-container">
                          <mat-icon
                            [matTooltip]="'Score: ' + account[holding.varName]"
                            class="scoreclass-icon mr-2"
                            [class]="account[holding.varName]"
                          >
                            {{ scoreClassKey[account[holding.varName]] }}
                          </mat-icon>
                        </div>
                      } @else {
                        {{ account[holding.varName] }}
                      }

                      @if (holding.varName === 'TradeID') {
                        <button
                          class="launch-account-button"
                          mat-icon-button
                          color="primary"
                          (click)="nav('trade-review/' + account['TradeID'])"
                        >
                          <mat-icon class="mr-2"> launch </mat-icon>
                        </button>
                      }
                    </td>
                  }
                </tr>
              }
            }
          </tbody>
        </table>
        @if (repDetail.RepProfile.RecentAccountActivity.length > 5) {
          <div class="toggle-row">
            <button
              class="mr-2 w-100"
              mat-button
              (click)="
                toggleRows(
                  'recentActivityRows',
                  repDetail.RepProfile.RecentAccountActivity.length
                )
              "
              color="primary"
            >
              <mat-icon class="mr-1">
                @if (recentActivityRows === 5) {
                  add
                } @else {
                  remove
                }
              </mat-icon>
              @if (recentActivityRows === 5) {
                Show More
              } @else {
                Show Less
              }
            </button>
          </div>
        }
      </div>
    }
  }

  <ngx-loading [show]="loading"></ngx-loading>
</div>
