import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-warn-dialog',
  templateUrl: './warn-dialog.component.html',
  styleUrls: ['./warn-dialog.component.scss'],
})
export class WarnDialogComponent {
  headline: string;
  content: string;
  confirm: string;
  hideCancel = false;

  constructor(
    public dialogRef: MatDialogRef<WarnDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.headline = data.headline || 'Warning';
    this.content = data.content;
    this.confirm = data.confirm || 'Save';
    this.hideCancel = data.hideCancel || false;
  }
}
