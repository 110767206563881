<mat-card appearance="outlined" class="col-12 stretch">
  <mat-card-header>
    Fixed Annuities
    <button mat-button (click)='load()' class="header-button download-link">
      <mat-icon>cloud_download</mat-icon>
    </button>
  </mat-card-header>
  <mat-card-content>
    <ngx-loading [show]="state == 'loading'" [config]="{fullScreenBackdrop: false}"></ngx-loading>
    <span *ngIf="state=='loaded' || state=='none'">
      <div mat-dialog-content class="dialog-container">
        <div class='row'>
          <mat-form-field class='col-2'>
            <mat-label>Rows</mat-label>
            <select matNativeControl (change)='updateRowCount($event.target.value)'>
              <option value='10'>10</option>
              <option value='25'>25</option>
              <option value='50'>50</option>
              <option value='75'>75</option>
              <option value='100'>100</option>
            </select>
          </mat-form-field>
          <mat-form-field class='col-3'>
            <input matInput [(ngModel)]="startDate" placeholder="Start Date MM-DD-YYYY" />
          </mat-form-field>
          <mat-form-field class='col-3'>
            <input matInput [(ngModel)]="endDate" placeholder="End Date MM-DD-YYYY" />
          </mat-form-field>
          <mat-form-field class='col-3'>
            <input matInput [(ngModel)]="numDays" placeholder="Num Days" />
          </mat-form-field>
          <div class='col-1'>
            <button mat-button *ngIf="state == 'loaded' || state=='none'" (click)='timespanDownload()'
              class="header-button download-link">
              <mat-icon>save</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </span>
    <button mat-button *ngIf="state == 'loaded' || state=='none'" (click)='download()'
      class="header-button download-link">
      <mat-icon>save</mat-icon>
    </button>
    <div class="no-results" *ngIf="state == 'none'">
      <h4>No Annuities Available</h4>
    </div>
    <strong *ngIf="state == 'error'">Error Loading Rates</strong>
    <strong *ngIf="expiring">Expiring Fixed Annuity Rates</strong>
    <table *ngIf="expiring">
      <thead>
        <th>Carrier</th>
        <th>Carrier Code</th>
        <th>ID</th>
        <th>Cusip</th>
        <th>Product</th>
        <th>Version</th>
        <th>Term</th>
        <th>BaseRate</th>
        <th>Guarantee Length</th>
        <th>Bonus</th>
        <th>PreMin</th>
        <th>PremMax</th>
        <th>ROP</th>
        <th>Effective Date</th>
        <th>Expiration</th>
      </thead>
      <tbody>
        <tr *ngFor="let a of expiring;" [className]="GetClass(a['Rate Effective Date'], a['Rate Deprecate Date'])">
          <td>{{a['Carrier Name']}}</td>
          <td>{{a['Carrier Code']}}</td>
          <td>{{a['id']}}</td>
          <td>{{a['Cusip']}}</td>
          <td>{{a['Product']}}</td>
          <td>{{a['Version']}}</td>
          <td>{{a['Term']}}</td>
          <td>{{a['Base Rate']}}</td>
          <td>{{a['Guarantee Length']}}</td>
          <td>{{a['Bonus Rate']}}</td>
          <td>{{a['Premium Range Min']}}</td>
          <td>{{a['Premium Range Max']}}</td>
          <td>{{a['ROP']}}</td>
          <td>{{a['Rate Effective Date']}}</td>
          <td>{{a['Rate Deprecate Date']}}</td>
        </tr>
      </tbody>
    </table>
    <strong *ngIf="updating">Fixed Annuity Rates Updating</strong>
    <table *ngIf="updating">
      <thead>
        <th>Carrier</th>
        <th>Carrier Code</th>
        <th>ID</th>
        <th>Cusip</th>
        <th>Product</th>
        <th>Version</th>
        <th>Term</th>
        <th>BaseRate</th>
        <th>Guarantee Length</th>
        <th>Bonus</th>
        <th>PreMin</th>
        <th>PremMax</th>
        <th>ROP</th>
        <th>Effective Date</th>
        <th>Expiration</th>
      </thead>
      <tbody>
        <tr *ngFor="let a of updating;" [className]="GetClass(a['Rate Effective Date'], a['Rate Deprecate Date'])">
          <td>{{a['Carrier Name']}}</td>
          <td>{{a['Carrier Code']}}</td>
          <td>{{a['id']}}</td>
          <td>{{a['Cusip']}}</td>
          <td>{{a['Product']}}</td>
          <td>{{a['Version']}}</td>
          <td>{{a['Term']}}</td>
          <td>{{a['Base Rate']}}</td>
          <td>{{a['Guarantee Length']}}</td>
          <td>{{a['Bonus Rate']}}</td>
          <td>{{a['Premium Range Min']}}</td>
          <td>{{a['Premium Range Max']}}</td>
          <td>{{a['ROP']}}</td>
          <td>{{a['Rate Effective Date']}}</td>
          <td>{{a['Rate Deprecate Date']}}</td>
        </tr>
      </tbody>
    </table>
  </mat-card-content>
</mat-card>