import { Component, OnInit, Inject, ViewEncapsulation, ViewChild } from '@angular/core';
import { FormBuilder, UntypedFormGroup, FormGroup, FormControl, Validators, NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MaskService } from '../../services/mask.service';


@Component({
  selector: 'custom-model-dialog',
  templateUrl: './custom-model-dialog.component.html',
  styleUrls: ['./custom-model-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomModelDialogComponent implements OnInit {
  modelForm: NgForm;
  @ViewChild('modelForm', { static: true }) private form: FormGroup;

  classes = [];
  model = {};
  percentMask = this.mask.percentMaskSpecs();
  percentAllocated: number = 100;

  constructor(public dialogRef: MatDialogRef<CustomModelDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private mask: MaskService) {}

  ngOnInit() {
    this.formatModel();
  }

  formatModel() {
    let model = this.data.model ? this.data.model : {allocations: []};

    this.classes = model.allocations;
    model.allocations.forEach(el => {
      this.model['cm-' + el.AssetClassID] = el.Percent * 100;
    });
  }

  cancel() {
    this.dialogRef.close({ data: 'cancel' });
  }

  save() {
    let data = {};
    this.validateTotal();

    Object.keys(this.model).forEach(e => {
      data[e.replace('cm-', '')] = this.removePercentMask(this.model[e]);
    });
    if(this.form.valid) {
      this.dialogRef.close({ data: data });
      this.dialogRef.afterClosed().subscribe(result => {
        this.dialogRef = null;
      });
    }
  }

  addEntries() {
    let values = [];

    Object.keys(this.model).forEach(e => {
      values.push(this.removePercentMask(this.model[e]));
    });


    this.percentAllocated = values.reduce(function (a, b) {
      return Number(a) + Number(b);
    }, 0);

    return this.percentAllocated;
  }

  validateTotal() {
    let total = this.addEntries();
    let valid = false;

    valid = 100 == total;

    if(valid) {
      Object.keys(this.form.controls).forEach(x => {
        this.form.controls[x].setErrors(null);
        this.form.controls[x].markAsTouched();
      });

      return { 'totalValid': valid };
    } else {
      Object.keys(this.form.controls).forEach(x => {
        this.form.controls[x].setErrors({ 'notEqual': true });
        this.form.controls[x].markAsTouched();
      });
    }
  }

  removePercentMask(obj) {
    if (typeof obj === 'string' || obj instanceof String) {
      return Number(obj.replace(/\,/gi, '').replace('%', ''));
    }
    return Number(obj);
  }

}
