import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../dashboard.service';
import { saveAs } from '@progress/kendo-file-saver';
import { DateTime } from 'luxon';
import { catchError } from 'rxjs/operators';
import { Platform } from '@angular/cdk/platform';
import { EnvironmentService } from '../../../services/environment.service';

@Component({
  selector: 'app-fixed-annuities-active-rates',
  templateUrl: './fixed-annuities-active-rates.component.html',
  styleUrls: ['./fixed-annuities-active-rates.component.scss'],
})
export class FixedAnnuitiesActiveRatesComponent implements OnInit {
  private environment;
  public expiring: Array<object>;
  public updating: Array<object>;
  state = 'start';
  public startDate: string;
  public endDate: string;
  public numDays: string;
  private baseUrl: string;

  constructor(
    private dbs: DashboardService,
    private pltfrm: Platform,
    private envSvc: EnvironmentService
  ) {}

  GetClass(startDate: string, expireDate: string): string {
    const start = DateTime.fromJSDate(new Date(startDate)).toUTC();
    const expire = DateTime.fromJSDate(new Date(expireDate)).toUTC();
    if (
      expire >= DateTime.now().toUTC().minus({ days: 1 }) &&
      expire <= DateTime.now().toUTC().add({ days: 1 })
    ) {
      return 'ExpiringTomorrow';
    }
    if (
      expire >= DateTime.now().toUTC().minus({ days: 1 }) &&
      expire <= DateTime.now().toUTC().add(7, 'days')
    ) {
      return 'ExpiringSoon';
    }
    if (
      start >= DateTime.now().toUTC() &&
      start <= DateTime.now().toUTC().add({ days: 1 })
    ) {
      return 'EffectiveToday';
    }
    if (
      start >= DateTime.now().toUTC() &&
      start <= DateTime.now().toUTC().add({ days: 7 })
    ) {
      return 'EffectiveSoon';
    }
    return '';
  }

  timespanDownload(): void {
    const iosSafari =
      this.pltfrm.IOS ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

    this.dbs
      .getFixedAnnuitiesNoUpdatesCsv(this.startDate, this.endDate, this.numDays)
      .subscribe(data => {
        const filename = `annuities.csv`;

        let fileData;
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onloadend = () => {
          fileData = reader.result;
          saveAs(fileData, filename, {
            proxyURL: `${this.baseUrl}/util/proxy`,
            proxyTarget: '_self',
            forceProxy: iosSafari,
          });
        };
      });
  }

  download(): void {
    const iosSafari =
      this.pltfrm.IOS ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

    this.dbs
      .getDashboardAnnuitiesCsv('activeannuitiesreport')
      .subscribe(data => {
        const filename = `annuities.csv`;

        let fileData;
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onloadend = () => {
          fileData = reader.result;
          saveAs(fileData, filename, {
            proxyURL: `${this.baseUrl}/util/proxy`,
            proxyTarget: '_self',
            forceProxy: iosSafari,
          });
        };
      });
  }

  ngOnInit(): void {
    this.environment = this.envSvc.get();
    this.baseUrl = this.environment.apiBase;
  }

  load(): void {
    this.state = 'loading';
    this.dbs.getDashboardAnnuities('activeannuities').subscribe(
      x => {
        this.state = 'loaded';
        if (x['expiring']['data'].length) {
          this.expiring = x['expiring']['data'].slice(0, 10);
        }
        if (x['updating']['data'].length) {
          this.updating = x['updating']['data'].slice(0, 10);
        }
        if (this.expiring == null && this.updating == null) {
          this.state = 'none';
        }
      },
      e => {
        this.state = 'none';
        catchError(e);
      }
    );
  }
}
