<div class="page-container asset-container container unified">
  <div class="products-container" *ngIf="!loading">
    <ng-container *ngFor="let grp of bridgeTypes">
      <h2 *ngIf="grp === 'portfolio'">Portfolio</h2>
      <h2 *ngIf="grp === 'program'">Selected Program</h2>
      <h2 *ngIf="grp === 'altProgram'" class="w-100"
        [ngClass]="{ 'group-collapsed': !altsExpanded && grp === 'altProgram' }">
        Alternative Programs
        <button mat-button color="primary" class="pull-right expand-button" (click)="toggleAlternatives('programs')">
          <span *ngIf="!altsExpanded">
            <mat-icon>add</mat-icon>
            View Alternatives
          </span>
          <span *ngIf="altsExpanded">
            <mat-icon>remove</mat-icon>
            Hide Alternatives
          </span>
        </button>
      </h2>
      <h2 *ngIf="grp === 'ALT'">
        {{
        bridgeTypes.includes('ALT') && selectedAltsHeader
        ? selectedAltsHeader
        : 'Selected Alternative Investments '
        }}
      </h2>
      <h2 *ngIf="grp === 'ALTopt'" class="w-100" [ngClass]="{
          'group-collapsed': !altsOptionsExpanded && grp === 'ALTopt'
        }">
        {{
        bridgeTypes.includes('ALT') && otherAltsHeader
        ? otherAltsHeader
        : 'Other Alternative Investments'
        }}
        <button mat-button color="primary" class="pull-right expand-button" (click)="toggleAlternatives('alts')">
          <span *ngIf="!altsOptionsExpanded">
            <mat-icon>add</mat-icon>
            View Alternatives
          </span>
          <span *ngIf="altsOptionsExpanded">
            <mat-icon>remove</mat-icon>
            Hide Alternatives
          </span>
        </button>
      </h2>
      <h2 *ngIf="grp === 'Plans'">Selected Plans</h2>
      <h2 *ngIf="grp === 'altPlan'" class="w-100" [ngClass]="{
          'group-collapsed': !altsPlansExpanded && grp === 'altPlan'
        }">
        Alternative Plans
        <button mat-button color="primary" class="pull-right expand-button" (click)="toggleAlternatives('plans')">
          <span *ngIf="!altsPlansExpanded">
            <mat-icon>add</mat-icon>
            View Alternatives
          </span>
          <span *ngIf="altsPlansExpanded">
            <mat-icon>remove</mat-icon>
            Hide Alternatives
          </span>
        </button>
      </h2>
      <h2 *ngIf="grp === 'funds'">Selected Investments</h2>
      <ul class="products col-12 mb-5" [ngClass]="{
          'group-collapsed':
            (!altsExpanded && grp === 'altProgram') ||
            (!altsPlansExpanded && grp === 'altPlan') ||
            (!altsOptionsExpanded && grp === 'ALTopt')
        }">
        @for (x of bridges; track x) {
        <li *ngIf="
              (x.BridgeType === grp && x.BridgeType !== 'ALT') ||
              (x.BridgeType === grp &&
                x.BridgeType === 'ALT' &&
                x.SelectedBridge.toLowerCase() === 'yes') ||
              (grp === 'ALTopt' &&
                x.BridgeType === 'ALT' &&
                x.SelectedBridge.toLowerCase() === 'no')
            " class="product col-12 {{ x.ScoreClass }} {{ x.expandedClass }}"
          [ngClass]="{ expanded: expandedBridges.includes(x.BridgeNoteType) }">
          @if (
          rights.includes('BridgeNotes') &&
          !moduleNotes.include &&
          x.vars.CurrentBridgeNote &&
          (x.vars.CurrentBridgeNote.Required === 2 ||
          (x.vars.CurrentBridgeNote.Required === 3 && x.negativeReason))
          ) {
          <div class="notes-notification" [ngClass]="{ valid: x.notesValid }">
            <ng-container *ngIf="x.notesValid; else invalid">
              <mat-icon>thumb_up</mat-icon>Required Notes Entered for This
              Item
            </ng-container>
            <ng-template #invalid>
              <mat-icon>error</mat-icon>Notes Required for This Item
            </ng-template>
          </div>
          }

          <div class="w100 unified-content">
            <div class="type-indicator {{ x.indicatorClass }}" *ngIf="x.PlanName">
              {{ x.PlanName }}
            </div>
            <div class="score-icon {{ x.ScoreClass }}">
              <ng-container *hasPermission="'RelevanceBridgeIcon'">
                <mat-icon *ngIf="x.ScoreClass === 'positive'">check_circle</mat-icon>
                <mat-icon *ngIf="x.ScoreClass === 'neutral'">error</mat-icon>
                <mat-icon *ngIf="x.ScoreClass === 'negative'">remove_circle</mat-icon>
              </ng-container>
            </div>
            <div class="actions"></div>
            <div class="content">
              <h4>
                {{ x.outline.sections.ShortName }}
                <span *hasPermission="'!RelevanceHideBridgeScore'">
                  | {{ x.score / 10 }}</span>
              </h4>
              <div class="sub">{{ x.subhead }}</div>
            </div>

            <div class="details-button-container">
              <button mat-icon-button (click)="expand($event)">
                <span class="collapsed-content">
                  <mat-icon>expand_circle_down</mat-icon>
                </span>
                <span class="expanded-content">
                  <mat-icon>expand_circle_down</mat-icon>
                </span>
              </button>
            </div>
          </div>

          <div class="plan-grid-table col-11" *ngIf="x.PlanGrid">
            <table class="plan-grid">
              <thead>
                <tr>
                  <th *ngFor="let h of x.PlanGrid.Header">{{ h }}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let t of x.PlanGrid.Data">
                  <td *ngFor="let z of t">{{ z }}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <app-sub-item-chart *ngIf="x.SubItemChart" [data]="x.SubItemChart" [chartTitle]="'Brokerage Subtypes'"
            [chartStyle]="'minimal'"></app-sub-item-chart>
          <app-sub-item-chart *ngIf="x.BridgeDetailChart" [data]="x.BridgeDetailChart"
            [chartTitle]="x.BridgeDetailChart.Label" [chartStyle]="'minimal'"></app-sub-item-chart>

          <div class="details col-12 mx-auto custom-tabs"
            [ngClass]="{ 'unified-selected': x.SelectedBridge === 'Yes' }">
            <mat-tab-group mat-stretch-tabs [ngClass]="{
                  'left-align':
                    (!x.grids || x.grids.length <= 0) &&
                    (!x.bridgeCharts ||
                      (x.bridgeCharts && !x.bridgeCharts.Bridges))
                }">
              <mat-tab [label]="grp === 'funds' ? 'Analysis' : 'Reasons'">
                <app-sub-item-chart *ngIf="x.ComplianceChart" [data]="x.ComplianceChart"
                  [chartTitle]="'Compliance Information'">
                </app-sub-item-chart>
                <app-reason-text class="col-6" [data]="x.trueRules" *ngIf="x.trueRules"></app-reason-text>
                <div class="mb-4" *ngIf="x.alternatives && x.alternatives.headers.length > 2">
                  <h5>Alternatives</h5>
                  <kendo-grid [kendoGridBinding]="x.alternatives.rows" [pageable]="true" [pageSize]="altGridPageSize"
                    class="iw-compare">
                    <kendo-grid-column [field]="x.alternatives.headers[0].field"
                      [title]="x.alternatives.headers[0].field.title"></kendo-grid-column>
                    <kendo-grid-column-group title="Selected">
                      <kendo-grid-column [field]="x.alternatives.headers[1].field"
                        [title]="x.alternatives.headers[1].title" [class]="'selected'" [headerClass]="'selected'">
                      </kendo-grid-column>
                    </kendo-grid-column-group>
                    <ng-container *ngIf="x.alternatives.headers.length > 2">
                      <kendo-grid-column-group title="Alternatives">
                        <ng-template ngFor [ngForOf]="x.alternatives.headers | slice: 2" let-column let-columnIndex>
                          <kendo-grid-column [field]="column.field" [title]="column.title">
                            <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
                              {{ x.alternatives.headers[columnIndex].title }}
                              <div>
                                <mat-icon class="swap-icon" (click)="
                                      alternativeSwap(
                                        x,
                                        x.alternatives.headers[columnIndex]
                                          .fundID
                                      )
                                    " matTooltip="Switch to this investment">
                                  swap_horizontal_circle
                                </mat-icon>
                              </div>
                            </ng-template>
                          </kendo-grid-column>
                        </ng-template>
                      </kendo-grid-column-group>
                    </ng-container>
                    <ng-template kendoPagerTemplate>
                      <button mat-button color="primary" [showButtons]="false" class="expand-btn w-100" (click)="
                            toggleGrid($event, x.alternatives.rows.length)
                          ">
                        @if (altGridExpand) {
                        See less
                        } @else {
                        See more
                        }
                      </button>
                    </ng-template>
                  </kendo-grid>
                </div>
                @if (rights.includes('BridgeNotes') && !moduleNotes.include) {
                <app-notes-entry class="w-100 mt-2" [bridgeVal]="x.trueRules"
                  (noteEntered)="handleNotesValidation($event, x)" [notesData]="{
                        app: 'iw',
                        profile: id,
                        bridgeId: x.vars.CurrentBridgeNote.NoteBridgeID,
                        note: x.vars.CurrentBridgeNote.NoteText,
                        prompt: x.vars.CurrentBridgeNote.Prompt,
                        required:
                          x.vars.CurrentBridgeNote.Required === 2 ||
                          (x.vars.CurrentBridgeNote.Required === 3 &&
                            x.negativeReason)
                      }"></app-notes-entry>
                }
              </mat-tab>
              <mat-tab label="Description" *ngIf="x.programDescription">
                <strong>{{ x.programDescription.Description }}</strong>
                <ul>
                  <li *ngFor="
                        let bul of x.programDescription.DescriptionBullets
                      ">{{ bul }}</li>
                </ul>
                <span class="program-instructions" [innerHTML]="x.programDescription.Instructions"></span>
              </mat-tab>
              <mat-tab label="Details" *ngIf="grp === 'funds'">
                <div class="grid-table-row w-100 row my-5" *ngFor="let gr of x.fundDetails">
                  <table *ngFor="let tb of gr.tables" [ngClass]="{
                        'col-12': gr.tables.length === 1,
                        'col-6': gr.tables.length === 2,
                        'col-4': gr.tables.length === 3,
                        'col-3': gr.tables.length === 4
                      }">
                    <thead>
                      <ng-container *ngFor="let hd of tb.headers">
                        <th *ngIf="hd !== 'Remove Me'">{{ hd }}</th>
                      </ng-container>
                    </thead>
                    <tbody>
                      <tr *ngFor="let rw of tb.data">
                        <td *ngFor="let cell of rw.row" [innerHTML]="cell"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="row chart-row">
                  <ng-container *ngFor="let chrt of x.charts">
                    <div *ngIf="chrt" class="chart-container clearfix mb-5" [ngClass]="{
                          'col-6': chrt.ChartContainer === 'half',
                          'col-12': chrt.ChartContainer === 'full'
                        }">
                      <div class="chart pull-left px-4" [ngClass]="{
                            'col-12': chrt.LegendPosition === 'bottom',
                            'col-6': chrt.LegendPosition === 'right'
                          }">
                        <highcharts-chart [Highcharts]="Highcharts" [options]="chrt.Data"
                          class="mx-auto"></highcharts-chart>
                      </div>
                      <div *ngIf="chrt.Legend" class="chart-legend" [ngClass]="{
                            'col-12': chrt.LegendPosition === 'bottom',
                            'col-6 ml-auto': chrt.LegendPosition === 'right'
                          }">
                        <ng-container *ngIf="chrt.Legend.header; else noHeader">
                          <table>
                            <thead>
                              <tr>
                                <ng-container *ngFor="let th of chrt.Legend.header">
                                  <th *ngIf="th.toLowerCase() !== 'color'">
                                    {{ th }}
                                  </th>
                                </ng-container>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let tr of chrt.Legend.data">
                                <ng-container *ngFor="
                                      let td of tr | keyvalue: returnZero;
                                      let i = index
                                    ">
                                  <td *ngIf="
                                        chrt.Legend.header[i].toLowerCase() !==
                                        'color'
                                      ">
                                    <span *ngIf="
                                          chrt.Legend.header[
                                            i
                                          ].toLowerCase() !== 'color' &&
                                          chrt.Legend.header[0].toLowerCase() ===
                                            'color' &&
                                          i === 1
                                        " [style.background]="tr['Color']" class="indicator"></span>
                                    {{ td.value }}
                                  </td>
                                </ng-container>
                              </tr>
                            </tbody>
                          </table>
                        </ng-container>
                        <ng-template #noHeader>
                          <div *ngFor="let c of chrt.Legend">
                            <strong>{{ c.label }}</strong> {{ c.value }}
                          </div>
                        </ng-template>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </mat-tab>
              <mat-tab label="Charts" *ngIf="
                    x.charts && !isEmpty(x.charts[0]?.Data) && grp !== 'funds'
                  ">
                <div class="row chart-row">
                  <ng-container *ngFor="let chrt of x.charts">
                    <div *ngIf="chrt" class="chart-container clearfix mb-5" [ngClass]="{
                          'col-6': chrt.ChartContainer === 'half',
                          'col-12': chrt.ChartContainer === 'full'
                        }">
                      <div class="chart px-4" [ngClass]="{
                            'col-10 mx-auto': chrt.LegendPosition === 'bottom',
                            'col-7 pull-left': chrt.LegendPosition === 'right'
                          }" *ngIf="isTableChart(chrt)">
                        <highcharts-chart [Highcharts]="Highcharts" [options]="chrt.Data"
                          class="mx-auto"></highcharts-chart>
                      </div>
                      <div *ngIf="chrt.Legend" class="chart-legend" [ngClass]="{
                            'col-12': chrt.LegendPosition === 'bottom',
                            'col-5 ml-auto': chrt.LegendPosition === 'right'
                          }">
                        <ng-container *ngIf="chrt.Legend.header; else noHeader">
                          <table>
                            <thead>
                              <tr>
                                <ng-container *ngFor="let th of chrt.Legend.header">
                                  <th *ngIf="th.toLowerCase() !== 'color'">
                                    {{ th }}
                                  </th>
                                </ng-container>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let tr of chrt.Legend.data">
                                <ng-container *ngFor="
                                      let td of tr | keyvalue: returnZero;
                                      let i = index
                                    ">
                                  <td *ngIf="
                                        chrt.Legend.header[i].toLowerCase() !==
                                        'color'
                                      ">
                                    <span *ngIf="
                                          chrt.Legend.header[
                                            i
                                          ].toLowerCase() !== 'color' &&
                                          chrt.Legend.header[0].toLowerCase() ===
                                            'color' &&
                                          i === 1
                                        " [style.background]="tr['Color']" class="indicator"></span>
                                    {{ td.value }}
                                  </td>
                                </ng-container>
                              </tr>
                            </tbody>
                          </table>
                        </ng-container>
                        <ng-template #noHeader>
                          <div *ngFor="let c of chrt.Legend">
                            <strong>{{ c.label }}</strong> {{ c.value }}
                          </div>
                        </ng-template>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </mat-tab>
              <mat-tab label="Switch Comparison" *ngIf="x.BridgeNoteType === 'ExchangeSwap'">
                <div class="row chart-row">
                  <div *ngIf="switchCompareHeaders.length > 0" class="col-12 chart-container clearfix mb-5">
                    <div class="exchange-table col-12">
                      <table>
                        <thead class="source-destination">
                          <tr>
                            <th></th>
                            <ng-container *ngFor="let th of x.vars.SwitchGridOut.IsSource">
                              <th [ngClass]="{
                                    'is-source': th,
                                    'is-destination': !th
                                  }"></th>
                            </ng-container>
                          </tr>
                        </thead>
                        <thead>
                          <tr>
                            <th></th>
                            <ng-container *ngFor="
                                  let th of x.vars.SwitchGridOut.DisplayName;
                                  let idx = index
                                ">
                              <th [ngClass]="{
                                    'is-source':
                                      x.vars.SwitchGridOut.IsSource[idx]
                                  }">
                                {{ th }}
                              </th>
                            </ng-container>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let tr of switchCompareHeaders">
                            <td>{{ tr.Description }}</td>
                            <ng-container *ngIf="x.vars.SwitchGridOut[tr.VarName]">
                              <td *ngFor="
                                    let td of x.vars.SwitchGridOut[tr.VarName]
                                  ">
                                {{ td }}
                              </td>
                            </ng-container>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
          <div class="rating-line {{ x.ScoreClass }}" [style.width.%]="x.score / 10"></div>
        </li>
        }
      </ul>
    </ng-container>

    @if (moduleNotes.include && !loading) {
    <app-notes-entry class="w-100 mt-2" [moduleNotes]="true" (noteEntered)="handleNotesValidation($event)" [notesData]="{
          app: 'iw',
          profile: id,
          note: moduleNotes.note,
          prompt: moduleNotes.prompt,
          required: moduleNotes.required
        }" [moduleBridges]="bridges" (updateModuleNote)="updateModuleNote($event)"></app-notes-entry>
    }
  </div>
</div>