import {
  Component,
  OnInit,
  Inject,
  Renderer2,
  ChangeDetectorRef,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import {
  trigger,
  style,
  transition,
  animate,
  state,
} from '@angular/animations';
import { TagsEvaluationService } from '../services/tags-evaluation.service';
import { FormGroup } from '@angular/forms';
import { SessionStorageService } from '../services/session-storage.service';

import * as money from 'js-money';

import { cloneDeep } from 'lodash';
import { EnvironmentService } from '../services/environment.service';
import { MaskService } from '../services/mask.service';
import { uniq } from 'lodash';

@Component({
  selector: 'app-multi-dialog',
  templateUrl: './multi-dialog.component.html',
  styleUrls: ['./multi-dialog.component.scss'],
  animations: [
    trigger('slideCompare', [
      state(
        'showSelect',
        style({
          transform: 'translate3d(105%, 0, 0)',
        })
      ),
      state(
        'showCompare',
        style({
          transform: 'translate3d(0, 0, 0)',
        })
      ),
      transition('showSelect => showCompare', animate('400ms ease-in-out')),
      transition('showCompare => showSelect', animate('400ms ease-in-out')),
    ]),
    trigger('slideSelect', [
      state(
        'showSelect',
        style({
          transform: 'translate3d(0, 0, 0)',
        })
      ),
      state(
        'showCompare',
        style({
          transform: 'translate3d(-50%, 0, 0)',
        })
      ),
      transition('showSelect => showCompare', animate('400ms ease-in-out')),
      transition('showCompare => showSelect', animate('400ms ease-in-out')),
    ]),
  ],
})
export class MultiDialogComponent implements OnInit {
  @ViewChild('scroll') private scrollContainer: ElementRef;
  @ViewChild('fundForm') private form: FormGroup;

  private environment;
  funds;
  amount;
  adjustedAmount;
  distAmounts = {};
  selected = [];
  distEven = true;
  dollarMask = this.mask.dollarMaskSpecs();
  percentMask = this.mask.percentMaskSpecs();
  fundSelected;
  compareList = [];
  compared = {};
  modalState = 'showSelect';
  gridData = [];
  columns = [];
  limit: number;
  model = { selected: {}, amount: {}, percentAmount: {}, compared: {} };
  scoring = {};
  showAll = false;
  showSome = false;
  showButtonLabel = ' Show More';
  showButtonIcon = 'add_box';
  path: string;
  allocMethod = 'dollar';
  globals;

  tableDef = {
    id: 'compare1',
    name: 'compare1',
    label: 'table label',
    type: 'vert',
    columns: [
      {
        var: 'header1',
        label: 'Label',
      },
      {
        var: 'header1',
        label: 'Fund',
      },
    ],
    rows: [
      {
        var: 'name',
        label: 'Name',
      },
      {
        var: 'RBCategory',
        label: 'Category',
      },
      {
        var: 'AssetAllocationCashnet',
        label: 'Asset Allocation in Cash',
      },
      {
        var: 'AssetAllocationNonUSBondNet',
        label: 'Asset Allocation in non-US Bonds',
      },
      {
        var: 'AssetAllocationNonUSStockNet',
        label: 'Asset Allocation in non-US Stocks',
      },
      {
        var: 'AssetAllocationOtherNet',
        label: 'Asset Allocation in Other Investments',
      },
      {
        var: 'AssetAllocationPreferredNet',
        label: 'Asset Allocation in Preferred Stocks',
      },
      {
        var: 'AssetAllocationUSBondNet',
        label: 'Asset Allocation in US Bonds',
      },
      {
        var: 'AssetAllocationUSStockNet',
        label: 'Asset Allocation in US Stocks',
      },
      {
        var: 'TrailingReturnYTD',
        label: 'YTD',
      },
      {
        var: 'TrailingReturnY1',
        label: '1 Yr Trailing Return',
      },
      {
        var: 'TrailingReturnY3',
        label: '3 Yr Trailing Return',
      },
      {
        var: 'TrailingReturnY5',
        label: '5 Yr Trailing Return',
      },
      {
        var: 'TrailingReturnY10',
        label: '10 Yr Trailing Return',
      },
      {
        var: '3yStandardDeviation',
        label: 'Standard Deviation',
      },
      {
        var: 'R_Squared',
        label: 'R^2',
      },
      {
        var: 'InceptionDate',
        label: 'Inception Date',
      },
      {
        var: 'MgrTenure',
        label: 'Average Manager Tenure',
      },
      {
        var: 'NetAssets',
        label: 'Net Assets',
      },
      {
        var: 'TurnoverRatio',
        label: 'Turnover Rate',
      },
      {
        var: 'ExpenseRatio',
        label: 'Expense Ratio',
      },
      {
        var: 'AverageCreditQuality',
        label: 'Average Credit Quality',
      },
      {
        var: 'AverageEffectiveDuration',
        label: 'Average Duration',
      },
      {
        var: 'ThirtyDaySECYield',
        label: '30-Day Yield',
      },
    ],
  };

  constructor(
    public dialogRef: MatDialogRef<MultiDialogComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public render: Renderer2,
    private ref: ChangeDetectorRef,
    private tes: TagsEvaluationService,
    private sanitizer: DomSanitizer,
    private ss: SessionStorageService,
    private envSvc: EnvironmentService,
    private mask: MaskService
  ) {}

  ngOnInit() {
    this.environment = this.envSvc.get();
    this.path = this.environment.assets ? this.environment.assets : '';

    this.globals = this.ss.get('globals');
    this.amount = this.data.amount;
    this.adjustedAmount = this.data.amount;
    this.limit = this.data.limit;
    let count = 0;
    this.funds = this.data.funds?.filter(x => {
      const keepFund = true;
      this.model.selected[x.fundId] = false;
      this.model.amount[x.fundId] = null;
      this.model.percentAmount[x.fundId] = null;
      this.model.compared['compare-' + x.fundId] = false;

      x['hasContributionLimit'] =
        x.currFund.HasContributionLimit == true ||
        x.currFund.HasContributionLimit == 'true';
      x['multiAssetClass'] =
        x.currFund.MultiAssetClass == true ||
        x.currFund.MultiAssetClass == 'true';
      const selected = this.data.selected
        ? this.data.selected.find(z => z['SelectedFunds.FundID'] === x.fundId)
        : null;
      if (count >= this.limit) {
        x['hidden'] = selected ? false : true;
      } else {
        count++;
      }

      return keepFund;
    });

    if (
      this.data.selected &&
      this.data.selected[0]['SelectedFunds.FundID'] != 'TBD'
    ) {
      this.distEven = false;
      const selectedAmounts: number[] = [];
      let sum = 0;
      this.data.selected.forEach(selectedItem => {
        selectedAmounts.push(+selectedItem['SelectedFunds.Amount']);
        sum += +selectedItem['SelectedFunds.Amount'];
      });
      const uniqSelectedAmounts = uniq(selectedAmounts);
      if (uniqSelectedAmounts.length === 1) {
        this.distEven = true;
        if (sum !== this.data.amount) {
          this.distEven = false;
        }
      }
      this.setPreSelected();
    }

    if (this.globals?.tables.length > 0 && this.globals?.tables !== 'none') {
      this.tableDef = this.globals?.tables[0];
      this.tableDef.rows.forEach(x => {
        x.var = x.var.split('^').pop();
      });
    }
  }

  expand(event) {
    const el = this.closestByClass(event.target, 'fund');
    el.classList.toggle('expanded');
  }

  closestByClass(el, clazz) {
    // Traverse the DOM up with a while loop
    while (!el.classList.contains(clazz)) {
      // Increment the loop to the parent node
      el = el.parentNode;
      if (!el) {
        return null;
      }
    }
    // At this point, the while loop has stopped and `el` represents the element that has
    // the class you specified in the second parameter of the function `clazz`

    // Then return the matched element
    return el;
  }

  setPreSelected() {
    setTimeout(() => {
      this.data.selected.forEach(x => {
        const fundInfo = this.funds.find(z => {
          return z.fundId == x['SelectedFunds.FundID'];
        });
        if (fundInfo) {
          this.selectFund(null, {
            name: fundInfo.name,
            fundId: fundInfo.fundId,
            amount: x['SelectedFunds.Amount'],
            symbol: fundInfo.Symbol,
            assetClass: fundInfo.assetClass,
            fundFamilyID: fundInfo.FundFamilyID,
            hasContributionLimit: fundInfo.HasContributionLimit,
            sectorLimit: fundInfo.HasContributionLimit
              ? fundInfo.ContributionLimit
              : null,
          });
        }
      });
    }, 500);
  }

  preUpdate() {
    const amounts = this.selected.map(x => {
      return x.Amount;
    });
    let evenly = 0;

    amounts.forEach(x => {
      evenly = Math.ceil(amounts[0]) % Math.ceil(x);
    });

    this.distEven = evenly >= 0 && evenly <= 1 ? true : false;
    const update = {};
    this.selected.forEach(x => {
      update[x.Symbol] = x.Amount;
    });
  }

  inSelected(fund) {
    return this.selected.filter(x => {
      return x.FundID == fund;
    });
  }

  selectFund(e, fund) {
    const inList = this.selected.filter(x => {
      return x.FundID == fund.fundId;
    });

    if (inList && inList.length) {
      this.selected = this.selected.filter(x => {
        return x.FundID != fund.fundId;
      });
      this.model.selected[fund.fundId] = false;
      this.model.amount[fund.fundId] = null;
      this.model.percentAmount[fund.fundId] = null;

      this.updateValues(null, fund);
    } else {
      this.model.selected[fund.fundId] = true;
      if (!this.distEven) {
        this.model.amount[fund.fundId] = +fund.amount;
      }
      this.selected.push({
        FundID: fund.fundId,
        Symbol: fund.symbol,
        AssetClass: fund.assetClass,
        Name: fund.name,
        Amount: +fund.amount,
        FundFamilyID: fund.fundFamilyID,
        ContributionLimit: fund.hasContributionLimit ? fund.sectorLimit : null,
        IsSpecial: fund.hasContributionLimit,
      });

      this.updateValues(null, fund, this.allocMethod);
    }
  }

  checkReadOnly(fundId) {
    const disabled = this.distEven;

    return disabled;
  }

  compare(e, fund) {
    const inList = this.compareList.filter(x => {
      return x == fund;
    });

    if (inList && inList.length) {
      this.compareList = this.compareList.filter(x => {
        return x != fund;
      });
    } else {
      this.compareList.push(fund);
    }
  }

  viewComparison() {
    this.formatTable();
    this.modalState =
      this.modalState === 'showSelect' ? 'showCompare' : 'showSelect';
    this.scrollContainer.nativeElement.scrollTop = 0;
  }

  formatTable() {
    const rows = this.tableDef.rows,
      rawData = [],
      data = [],
      columnData = [];

    this.compareList.forEach((x, i) => {
      const currFund = this.funds.filter(z => {
        return z.fundId == x;
      })[0];

      rawData[i] = {};

      rows.forEach(y => {
        if (y.var == 'name') {
          rawData[i][y.var] = currFund[y.var];
        } else {
          rawData[i][y.var] = currFund.currFund[y.var];
        }
      });

      rawData[i].id = x;
    });

    rawData.forEach((x, i) => {
      columnData[i] = { id: x.id, name: x.name };
    });

    const dataLength = rawData.length - 1,
      propertiesLength = Object.keys(rawData[0]).length - 1;

    for (let i = 0; i <= propertiesLength; i++) {
      data[i] = {};
      for (let j = 0; j <= dataLength; j++) {
        const currentItem = rawData[j];
        const property = Object.keys(currentItem)[i];
        const label =
          property != 'id'
            ? rows.filter(q => {
                return q.var == property;
              })[0].label
            : null;

        if (j === 0) {
          data[i]['metric'] = label;
        }
        data[i][currentItem.id] = currentItem[property];
      }
    }

    //Sooooooooooo Janky!!!!!!!!!
    data.shift();
    data.pop();

    this.gridData = data;
    this.columns = columnData;
  }

  checkDist(val) {
    if (this.distEven) {
      this.updateValues(null);
    }
  }

  updateValues(ev, fund?, type?) {
    const asset = this.selected,
      length = asset ? asset.length : 0,
      evenly =
        length > 1
          ? this.divideCurrencyEvenly(this.amount, length)
          : this.amount;
    if (length == 1 && this.distEven) {
      this.model.amount[asset[0].FundID] = this.amount.toFixed(2);
      this.model.percentAmount[asset[0].FundID] = 100;
      this.selected.forEach(x => {
        x.Amount = Number(this.amount.toFixed(2));
      });
    } else if (length > 1 && this.distEven) {
      this.selected.forEach((x, i) => {
        x.Amount = evenly[i];
        this.model.amount[x.FundID] = evenly[i];
        const perc = (evenly[i] / this.amount) * 100;
        this.model.percentAmount[x.FundID] = perc;
        if (this.form.controls['iw-amount-' + x]) {
          this.form.controls['iw-amount-' + x].markAsTouched();
        }
        if (this.form.controls['iw-percentAmount-' + x]) {
          this.form.controls['iw-percentAmount-' + x].markAsTouched();
        }
      });
    } else {
      const iterator =
        type == 'dollar'
          ? cloneDeep(this.model.amount)
          : cloneDeep(this.model.percentAmount);
      Object.keys(iterator).forEach(x => {
        if (iterator[x]) {
          if (type == 'dollar') {
            this.model.amount[x] = this.removeMoneyMask(this.model.amount[x]);
            this.model.percentAmount[x] =
              (this.removeMoneyMask(this.model.amount[x]) / this.amount) * 100;
          } else {
            const multiplier = this.model.percentAmount[x].includes('%')
              ? cloneDeep(this.removePercentMask(this.model.percentAmount[x])) /
                100
              : cloneDeep(this.model.percentAmount[x]) / 100;
            const percentCalc = this.model.percentAmount[x]
              ? this.amount * multiplier
              : 0;
            this.model.amount[x] = percentCalc;
          }
        }
      });
    }
  }

  divideEvenly(numerator, minPartSize) {
    if (numerator / minPartSize < 2) {
      return [numerator];
    }
    return [minPartSize].concat(
      this.divideEvenly(numerator - minPartSize, minPartSize)
    );
  }

  divideCurrencyEvenly(num, div) {
    if (num) {
      const amounts = money.fromDecimal(num, money.USD, Math.floor);
      const dist = [...Array(div)].map(() => {
        return 1;
      });
      return amounts.allocate(dist).map(x => {
        return x.amount / 100;
      });
    }
  }

  removeMoneyMask(obj) {
    if (obj && typeof obj == 'string') {
      return Number(obj.replace(/,/gi, '').replace('$', ''));
    } else if (obj && typeof obj == 'number') {
      return obj;
    }
  }

  removePercentMask(obj) {
    if (obj && typeof obj == 'string') {
      return Number(obj.replace(/\,/gi, '').replace('%', ''));
    }
  }

  validateTotal() {
    return { totalValid: true };
  }

  validateSectorLimit() {
    let invalid = false;
    let total = 0;
    const sector = this.selected.filter(z => {
      return z.IsSpecial;
    });

    if (sector && sector.length) {
      const limit = sector[0].ContributionLimit;

      sector.forEach(x => {
        total = total + x.Amount;
      });
      invalid = total > limit;

      if (!invalid) {
        sector.forEach(x => {
          this.form.controls['iw-amount-' + x.FundID].setErrors(null);
          this.form.controls['iw-amount-' + x.FundID].markAsTouched();
          this.form.controls['iw-percentAmount-' + x.FundID].markAsTouched();
        });
      } else {
        sector.forEach(x => {
          this.form.controls['iw-amount-' + x.FundID].setErrors({
            overLimit: true,
          });
          this.form.controls['iw-amount-' + x.FundID].markAsTouched();
          this.form.controls['iw-percentAmount-' + x.FundID].markAsTouched();
        });
      }
    }
  }

  save() {
    if (!this.distEven) {
      Object.keys(this.model.amount).forEach(x => {
        if (this.model.amount[x] > 0) {
          this.selected.find(z => z.FundID == x).Amount = this.model.amount[x];
        }
      });
    }

    this.validateTotal();
    this.validateSectorLimit();
    Object.keys(this.distAmounts).forEach(x => {
      this.selected.find(s => s.symbol == x)
        ? (this.selected.find(s => s.symbol == x).Amount = this.distAmounts[x])
        : null;
    });
    if (this.form.valid) {
      this.dialogRef.close({ data: this.selected });
      this.dialogRef.afterClosed().subscribe(() => {
        this.dialogRef = null;
      });
    }
  }

  showMore() {
    if (!this.showSome) {
      this.showSome = true;
      this.showButtonIcon = 'add_box';
      this.showButtonLabel = ' Show All';
      const count = 30;
      for (let i = 0; i <= count; i++) {
        const element = this.funds[i];
        if (element && element.hidden) {
          element.hidden = false;
        }
      }
    } else if (this.showSome && !this.showAll) {
      this.showAll = true;
      this.showButtonIcon = 'indeterminate_check_box';
      this.showButtonLabel = ' Show Less Funds';
    } else {
      this.showAll = false;
      this.showButtonIcon = 'add_box';
      this.showButtonLabel = ' Show More';
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  isSelected(x) {
    return this.compareList.includes(x);
  }

  evalTags(str) {
    return this.tes.evalTags(str);
  }

  identify(index, item) {
    return item.fundId;
  }
}
