<span class="utility-apps-container">
  @for (utility of utilityApps; track utility.link) {
    @if (rights?.includes(utility.right)) {
      <button
        mat-stroked-button
        class="menu-button utility-app"
        (click)="goToPage(utility.link)"
      >
        {{ utility.label }} <mat-icon>{{ utility.icon }}</mat-icon>
      </button>
    }
  }

  @if (unitLandingPage && unitLandingPage.LearnButtonShow) {
    <button
      mat-stroked-button
      class="menu-button utility-app"
      (click)="goToPage('/training')"
    >
      Training<mat-icon>school</mat-icon>
    </button>
  }

  @if (
    (unitLandingPage && unitLandingPage.LearnButtonShow) ||
    rights?.includes('BookManagement') ||
    rights?.includes('NewDashboardTab') ||
    rights?.includes('CM')
  ) {
    <button
      mat-stroked-button
      [matMenuTriggerFor]="utilityAppMenu"
      class="menu-button utility-app-toggle"
    >
      <mat-icon class="mr-0">widgets</mat-icon>
    </button>
  }

  <mat-menu
    #utilityAppMenu="matMenu"
    direction="down"
    yPosition="below"
    class="unified-menu"
  >
    @for (utility of utilityApps; track utility.link) {
      @if (rights?.includes(utility.right)) {
        <button mat-button class="menu-button" (click)="goToPage(utility.link)">
          {{ utility.label }} <mat-icon>{{ utility.icon }}</mat-icon>
        </button>
      }
    }
    @if (unitLandingPage && unitLandingPage.LearnButtonShow) {
      <button mat-button class="menu-button" (click)="goToPage('/training')">
        Training<mat-icon>school</mat-icon>
      </button>
    }
  </mat-menu>
</span>
<div class="page-container unified container launcher-container">
  @if (!loading && unitLandingPage) {
    <div class="apps w-100 mb-4">
      <div class="buttons-container mb-3">
        <div class="apps-container">
          @for (app of apps; track app) {
            @if (
              setUpLandingPage &&
              setUpLandingPage['Show' + app + 'CreateCaseButton']
            ) {
              <button
                (click)="goToQuestionnaire(app.toLowerCase())"
                class="app-button disabled"
                [ngClass]="{
                  disabled:
                    !unitLandingPage[app + 'StartButtonActive'] ||
                    disableButtons
                }"
              >
                <mat-icon [svgIcon]="app.toLowerCase() + '_icon'"></mat-icon>
                @if (moduleLabels[app]) {
                  <div class="mt-2">
                    {{ moduleLabels[app] }}
                  </div>
                }
                <div class="app-button-text mt-2">
                  {{ unitLandingPage[app + 'StartButtonText'] }}
                </div>
              </button>
            }
          }
        </div>
        <div class="buttons-container-bottom-border"></div>
      </div>

      <div class="actions">
        @if (unitLandingPage && !unitLandingPage.HideExistingCaseButton) {
          <button class="existing-button" (click)="goToPage('/search')">
            <mat-icon>search</mat-icon> Select Existing Case
            <div class="actions-container-bottom-border"></div>
          </button>
        }
      </div>
    </div>
  }

  <div class="resources w-100 row">
    @if (unitToolsCheck()) {
      <div class="tools col-12" [class]="toolsWidthCheck(true)">
        @if (!loading) {
          @for (app of apps; track app) {
            @if (
              unitTools[app + 'UnitTools'] &&
              unitTools[app + 'UnitTools'].length > 0
            ) {
              <div
                class="unit-tools-container col-12"
                [class]="toolsWidthCheck()"
              >
                <h4>{{
                  unitToolsHeaders[app + 'Title'] ||
                    defaultTitle(app) + ' Tools'
                }}</h4>

                @if (unitToolsHeaders[app + 'Description']) {
                  <p>{{ unitToolsHeaders[app + 'Description'] }}</p>
                }

                <div class="unit-tools-list">
                  @for (
                    tool of unitTools[app + 'UnitTools'];
                    track tool.ToolName
                  ) {
                    @if (!tool.ToolExternal) {
                      <app-tool-link
                        [tooltip]="tool.ToolDescription"
                        [toolLabel]="tool.ToolName"
                        (defaultClick)="
                          onInternalLink(tool.ToolLink, tool.ToolName)
                        "
                      ></app-tool-link>
                    } @else {
                      <app-tool-link
                        [tooltip]="tool.ToolDescription"
                        [toolLabel]="tool.ToolName"
                        [externalLink]="tool.ToolLink"
                      ></app-tool-link>
                    }
                  }
                </div>
              </div>
            }
          }
        }
      </div>
    }

    @if (unitLandingPage && unitLandingPage.GeneralDescriptionShow) {
      <div
        class="landing-description-container"
        [ngClass]="unitToolsCheck() ? 'col-md-3' : 'col-12'"
      >
        <div
          class="landing-description"
          [innerHTML]="unitLandingPage.GeneralDescriptionText"
        ></div>
      </div>
    }
  </div>

  <svg
    class="landing-decoration"
    viewBox="0 0 837 1045"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:sketch="http://www.bohemiancoding.com/sketch/ns"
  >
    <g
      id="Page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
      sketch:type="MSPage"
    >
      <path
        d="M353,9 L626.664028,170 L626.664028,487 L353,642 L79.3359724,487 L79.3359724,170 L353,9 Z"
        fill="#fff"
        sketch:type="MSShapeGroup"
      ></path>
      <path
        d="M78.5,529 L147,569.186414 L147,648.311216 L78.5,687 L10,648.311216 L10,569.186414 L78.5,529 Z"
        fill="#7c9cc0"
        sketch:type="MSShapeGroup"
      ></path>
      <path
        d="M773,186 L827,217.538705 L827,279.636651 L773,310 L719,279.636651 L719,217.538705 L773,186 Z"
        fill="#939597"
        sketch:type="MSShapeGroup"
      ></path>
      <path
        d="M639,529 L773,607.846761 L773,763.091627 L639,839 L505,763.091627 L505,607.846761 L639,529 Z"
        fill="#e6e7e8"
        sketch:type="MSShapeGroup"
      ></path>
      <path
        d="M281,801 L383,861.025276 L383,979.21169 L281,1037 L179,979.21169 L179,861.025276 L281,801 Z"
        fill="#939597"
        sketch:type="MSShapeGroup"
      ></path>
    </g>
  </svg>
</div>
