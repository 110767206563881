export const summaryData = {
    "client": {
        "name": "Ed Sullivan"
    },
    "canPrintSummary": true,
    "profileCompleteness": 0.73,
    "applications": {
        "aw": {
            "tracks": [
                {
                    "type": "Accumulation",
                    "status": "completed",
                    "selectedProducts": [
                        {
                            "productType": "VA",
                            "carrier": "Jackson National",
                            "product": "Perspective II",
                            "premium": 1000000
                        }
                    ],
                    "canPrintReport": true
                },
                {
                    "type": "Death Benefit",
                    "status": "notStarted",
                    "selectedProducts": null,
                    "canPrintReport": false
                },
                {
                    "type": "Income",
                    "status": "partial",
                    "selectedProducts": [
                        {
                            "productType": "VA",
                            "carrier": "Jackson National",
                            "product": "Perspective II",
                            "premium": 1000000,
                            "riderName": "Income master 1"
                        },
                        {
                            "productType": "FA",
                            "carrier": "AIG",
                            "product": "Income o matic",
                            "premium": 1000000,
                            "riderName": "GMWB 2"
                        }
                    ],
                    "canPrintReport": false
                }
            ]
        },
        "iw": {
            "status": "completed",
            "investmentModel": "conservative",
            "investmentProgram": "brokerage",
            "investmentAmount": 100000,
            "selectedFunds": [
                {
                    "assetClass": "Cash",
                    "amount": 10000,
                    "postponed": false,
                    "funds": [
                        {
                            "name": "FCDXX - Fidelity® Inv MM Fds Prime Money Mkt III",
                            "amount": 5000   
                        },
                        {
                            "name": "GBCXX - Gabelli US Treasury MMkt C",
                            "amount": 5000
                        }
                    ]
                },
                {
                    "assetClass": "High Yield Bond",
                    "amount": 7500,
                    "postponed": true,
                    "funds": [
                        {
                            "name": "Funds to be Selected at a Later Date",
                            "amount": null
                        }
                    ]
                }
            ],
            "canPrintReport": true
        },
        "li": {
            "status": "completed",
            "totalNeed": 637000,
            "productMix": {
                "temp": 0.34,
                "perm": 0.66
            },
            "selectedProducts": [
                {
                    "type": "Perm",
                    "name": "World Benefit Life - Whole Life",
                    "amount": 420420
                },
                {
                    "type": "Temp",
                    "name": "HH Verity - Term - 10 Year Level",
                    "amount": 216580
                }
            ],
            "canPrintReport": true
        }
    }
}