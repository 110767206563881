import { Component, HostBinding } from '@angular/core';
import { UntypedFormGroup, FormGroup } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';
import { ES6Parser, ES6StaticEval } from 'espression';

@Component({
  selector: 'form-integerExp',
  styleUrls: ['form-integerExp.component.scss'],
  template: `
    <label class="section col-12" *ngIf="props.label" [innerHTML]="props.label"></label>
    <p *ngIf="props.prompt" class="explanation" [innerHTML]="props.prompt"></p>
    <mat-form-field floatPlaceholder="auto" class="w-100"  >
      <mat-label>{{to.placeholder}}</mat-label>
      <input matInput type="number" [placeholder]="props.placeholder" [formControl]="formControl" [formlyAttributes]="field" [required]="props.required"  onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57">
      <mat-icon *ngIf="props.help" class="help-icon" matTooltip="{{to.help}}" [matTooltipPosition]="'above'" [matTooltipClass]="'rb-tooltip'">help</mat-icon>
      <mat-error *ngIf="formControl.hasError('required') && !formControl.hasError('regRequired')">This field is required</mat-error>
      <mat-error *ngIf="formControl.hasError('regRequired')">This field is needed for suitability review. You can opt out below if the applicant refuses to provide the information.</mat-error>
    </mat-form-field>
  `
})
export class FormlyIntegerExp extends FieldType {
  stringEval(str) {
    const parser = new ES6Parser();
    const staticEval = new ES6StaticEval();

    let ast = parser.parse(str.replace('.','_'));
    return staticEval.evaluate(ast);
  }
}
