@if (message && message.length > 0) {
  <div class="message-text">{{ message }}</div>
}
<div class="loadingContainer">
  <div class="ball1"></div>
  <div class="ball2"></div>
  <div class="ball3"></div>
  <div class="ball4"></div>
  <div class="ball5"></div>
</div>
