import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { InvestmentWizardService } from '../../investment-wizard/services/investment-wizard-service.service';
import {
  switchMap,
  distinctUntilChanged,
  map,
  debounceTime,
  tap,
  finalize,
} from 'rxjs/operators';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { SessionStorageService } from '../../services/session-storage.service';

@Component({
  selector: 'app-fund-search',
  templateUrl: './fund-search.component.html',
  styleUrls: ['./fund-search.component.scss'],
})
export class FundSearchComponent implements OnInit, OnChanges {
  @ViewChild('symbolLookup', { static: false })
  symbolComplete: MatAutocompleteTrigger;
  @Input() type: string;
  @Input() options;
  @Input() program;
  @Input() fiveTwoNinePlan;
  @Input() search529;
  @Output() fundSelected = new EventEmitter();

  searchControl = new FormControl();
  searchControl529 = new FormControl('');
  filteredSymbols;
  symbols;
  label;
  funds529;

  shareClass = 'A';
  fundFamily;

  familyList;

  rights = [];
  dropdownLoading = false;
  dropdownNoResults = false;

  constructor(
    private iws: InvestmentWizardService,
    private ss: SessionStorageService,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (!this.search529) {
      this.symbolSearch();
      this.iws.fundFamilyList().subscribe(data => {
        this.familyList = data.results;
      });
    } else if (this.search529 && !this.fiveTwoNinePlan) {
      this.searchControl529.disable();
    } else if (this.search529 && this.fiveTwoNinePlan && !this.symbols) {
      this.symbolSearch529();
    }

    this.rights = this.ss.get('rights');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.hasOwnProperty('fiveTwoNinePlan') &&
      !changes.fiveTwoNinePlan.firstChange
    ) {
      this.searchControl529.disable();
      this.symbols = null;
      this.symbolSearch529();
    }
  }

  selectFundLookup(ev) {
    this.searchControl.setValue('');
    this.searchControl529.setValue('');
    this.fundSelected.emit(ev);
  }

  symbolSearch(preSearch = false) {
    this.searchControl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.dropdownNoResults = false;
          this.dropdownLoading = true;
          this.changeDetector.detectChanges();
        }),
        distinctUntilChanged(),
        switchMap((value: string) => {
          if (value || preSearch) {
            return this._filterSymbols(value).pipe(
              finalize(() => {
                this.dropdownLoading = false;
                this.changeDetector.detectChanges();
              })
            );
          } else if (!value) {
            this.dropdownLoading = false;
            this.filteredSymbols = null;
            this.changeDetector.detectChanges();
          }
          return [];
        })
      )
      .subscribe(results => {
        if (!results) {
          this.dropdownNoResults = true;
        } else {
          this.filteredSymbols = results;
          this.dropdownNoResults = false;
        }
        this.changeDetector.detectChanges();
      });
  }

  symbolSearch529() {
    this.searchControl529.disable();
    const types = this.options['fundtype'].toString().replace(',', '');
    this.iws
      .fundSearch(null, this.program, null, null, types, this.fiveTwoNinePlan)
      .subscribe(x => {
        this.searchControl529.enable();
        this.symbols = x.results;
      });
  }

  private _filterSymbols(value: string, preSearch = false) {
    const types = this.options['fundtype'].toString().replace(',', '');
    return this.iws
      .fundSearch(value, this.program, null, null, types, this.fiveTwoNinePlan)
      .pipe(
        map(x => {
          if (x.results && x.results.length > 0) {
            return x.results;
          }
        })
      );
  }

  displayFn(option?): string | undefined {
    return option ? `${option.FundID} - ${option.FundName}` : undefined;
  }
}
