<div mat-dialog-header>
  <h1>Filter On Program</h1>
</div>
<mat-dialog-content>
  <div>
    <mat-form-field class="col-12">
      <mat-select [(ngModel)]="programName">
        @for (option of filterOptions; track option) {
          <mat-option [value]="option">
            {{ option }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
    <div class="col-12 buttons">
      <button
        mat-raised-button
        (click)="sendProgramOut()"
        class="ml-2"
        color="accent"
        [disabled]="!programName"
      >
        <mat-icon aria-label="Search Using Program Name">search</mat-icon>
        Search Using Program Name
      </button>
      <button
        mat-raised-button
        [disabled]="!data.currentProgram"
        (click)="dialogRef.close('cancel')"
        >Cancel</button
      >
    </div>
  </div>
</mat-dialog-content>
