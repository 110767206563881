import { throwError as observableThrowError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SessionStorageService } from '../services/session-storage.service';
import { EnvironmentService } from '../services/environment.service';

@Injectable({
  providedIn: 'root',
})
export class SuggestionEngineService {
  private environment;
  private baseUrl: string;
  private app: string;
  private org: string;

  constructor(
    private http: HttpClient,
    private ss: SessionStorageService,
    private envSvc: EnvironmentService
  ) {
    this.environment = this.envSvc.get();
    this.baseUrl = this.environment.apiBase;
    this.org = this.environment.org;
    this.app = this.ss.get('currentApp') || this.environment.defaultApp || 'pp';
    this.org = this.environment.org || 'basev2';
  }

  getTop5(): Observable<any> {
    const sess = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', this.app)
      .set('X-RB-O', this.org);
    const url = `${this.baseUrl}/Widget/DailyDeal?numProfiles=5&minScore=-9999&getCached=true`;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return observableThrowError(error.error || 'Server error');
      })
    );
  }

  getTopOpps(): Observable<any> {
    const sess = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', this.app)
      .set('X-RB-O', this.org);
    const url = `${this.baseUrl}/Widget/TopClientOpportunities`;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return observableThrowError(error.error || 'Server error');
      })
    );
  }

  getTopBridges(): Observable<any> {
    const sess = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', this.app)
      .set('X-RB-O', this.org);
    const url = `${this.baseUrl}/Widget/TopBridges`;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return observableThrowError(error.error || 'Server error');
      })
    );
  }

  getTimeSensitive(): Observable<any> {
    const sess = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', this.app)
      .set('X-RB-O', this.org);
    const url = `${this.baseUrl}/Widget/TimeSensitiveOpportunities`;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return observableThrowError(error.error || 'Server error');
      })
    );
  }

  getCallList(): Observable<any> {
    const sess = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', this.app)
      .set('X-RB-O', this.org);
    const url = `${this.baseUrl}/Widget/CallList`;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return observableThrowError(error.error || 'Server error');
      })
    );
  }

  getCallback(): Observable<any> {
    const sess = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', this.app)
      .set('X-RB-O', this.org);
    const url = `${this.baseUrl}/Widget/CallbackDates`;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return observableThrowError(error.error || 'Server error');
      })
    );
  }

  getFollowUp(): Observable<any> {
    const sess = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', this.app)
      .set('X-RB-O', this.org);
    const url = `${this.baseUrl}/Widget/FollowUp`;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return observableThrowError(error.error || 'Server error');
      })
    );
  }

  getMessages(): Observable<any> {
    const sess = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', this.app)
      .set('X-RB-O', this.org);
    const url = `${this.baseUrl}/messages`;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return observableThrowError(error.error || 'Server error');
      })
    );
  }

  getScorecard(): Observable<any> {
    const sess = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', this.app)
      .set('X-RB-O', this.org);
    const url = `${this.baseUrl}/Widget/ScoreCard`;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return observableThrowError(error.error || 'Server error');
      })
    );
  }

  takeDailyAction(profile, action, bridge, date?): Observable<any> {
    const sess = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', this.app)
      .set('X-RB-O', this.org);
    const url = `${this.baseUrl}/Action/Daily?profileId=${profile}&bridgeId=${bridge}&actionId=${action}`;
    return this.http.patch(url, null, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return observableThrowError(error.error || 'Server error');
      })
    );
  }

  takeBridgeAction(profile, action, bridge, date?): Observable<any> {
    const sess = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', this.app)
      .set('X-RB-O', this.org);
    const url = `${this.baseUrl}/Action/Bridge?profileId=${profile}&bridgeId=${bridge}&actionId=${action}`;
    return this.http.patch(url, null, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return observableThrowError(error.error || 'Server error');
      })
    );
  }

  addToCallList(profiles): Observable<any> {
    const sess = this.ss.get('session');
    const payload = JSON.stringify(profiles);
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', this.app)
      .set('X-RB-O', this.org);
    const url = `${this.baseUrl}/Widget/AddToCallList?profileIds={profileIds: ${payload}}`;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return observableThrowError(error.error || 'Server error');
      })
    );
  }

  removeFromCallList(profiles): Observable<any> {
    const sess = this.ss.get('session');
    const payload = JSON.stringify(profiles);
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', this.app)
      .set('X-RB-O', this.org);
    const url = `${this.baseUrl}/Widget/RemoveFromCallList?profileIds={profileIds: ${payload}}`;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return observableThrowError(error.error || 'Server error');
      })
    );
  }

  clearCallList(list?): Observable<any> {
    const sess = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', this.app)
      .set('X-RB-O', this.org);
    const url = `${this.baseUrl}/Widget/ClearCallList`;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return observableThrowError(error.error || 'Server error');
      })
    );
  }

  getReport(profile, accts: true, descs: true, reasons: true): Observable<any> {
    const sess = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', this.app)
      .set('X-RB-O', this.org);
    const url = `${this.baseUrl}/Reports/Client/${profile}?includeAccounts=${accts}&includeDescriptions=${descs}&includeReasons=${reasons}}`;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return observableThrowError(error.error || 'Server error');
      })
    );
  }

  saveBridgeActions(actions): Observable<any> {
    const sess = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', this.app)
      .set('X-RB-O', this.org);
    const url = `${this.baseUrl}/Widget/SaveBridgeActions`;

    return this.http.post(url, actions, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return observableThrowError(error.error || 'Server error');
      })
    );
  }

  saveLifeEvent(profile, event, user, date, note): Observable<any> {
    const sess = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', this.app)
      .set('X-RB-O', this.org);
    const url = `${this.baseUrl}/Widget/SaveLifeEvent?profileId=${profile}&eventId=${event}&userId=${user}&date=${date}&note=${note}`;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return observableThrowError(error.error || 'Server error');
      })
    );
  }

  takeBulkAction(data) {
    const sess = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', this.app)
      .set('X-RB-O', this.org)
      .set('Content-Type', 'application/x-www-form-urlencoded');
    const url = `${this.baseUrl}/Widget/SaveBridgeActions`;
    return this.http
      .post(url, JSON.stringify(data), { headers: reqHeaders })
      .pipe(
        map(res => {
          return res;
        }),
        catchError((error: any) => {
          return observableThrowError(error.error || 'Server error');
        })
      );
  }

  viewedProfile(profiles): Observable<any> {
    const sess = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', this.app)
      .set('X-RB-O', this.org);
    const profilesFmt = {
      profileIds: profiles,
    };
    const url = `${this.baseUrl}/Profile/Viewed`;

    return this.http.post(url, profilesFmt, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return observableThrowError(error.error || 'Server error');
      })
    );
  }
}
