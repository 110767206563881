<div mat-dialog-content class="dialog-container">
  <h1 class="w-100">Plan Lookup</h1>
  <form #rixtremaForm>
    <div class="mb-3">
      <mat-radio-group
        class="rixtrema-radio col-12"
        [(ngModel)]="searchType"
        [ngModelOptions]="{ standalone: true }"
        (change)="clearData()"
      >
        <mat-radio-button class="radio-button" [value]="'name'">
          <mat-icon>topic</mat-icon>
          Plan Name
        </mat-radio-button>
        <mat-radio-button class="radio-button" [value]="'sponsor'">
          <mat-icon>location_city</mat-icon>
          Sponsor
        </mat-radio-button>
        <mat-radio-button class="radio-button" [value]="'ein'">
          <mat-icon>pin</mat-icon>
          EIN
        </mat-radio-button>
        <mat-radio-button class="radio-button" [value]="'zip'">
          <mat-icon>mail</mat-icon>
          ZIP Code
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="row">
      <div class="col-10 search-fields">
        <mat-form-field class="col-12" *ngIf="searchType === 'name'">
          <mat-label
            >Enter Plan Name. End with '*' for wildcard search.</mat-label
          >
          <input
            matInput
            [(ngModel)]="searchTerm"
            [ngModelOptions]="{ standalone: true }"
            id="rixtrema-search"
            placeholder="Enter Plan Name. End with '*' for wildcard search."
            name="searchTerm"
            (keydown.enter)="$event.preventDefault()"
            (keyup.enter)="search(); $event.preventDefault()"
          />
        </mat-form-field>
        <mat-form-field class="col-12" *ngIf="searchType === 'sponsor'">
          <mat-label>Sponsor</mat-label>
          <input
            matInput
            [(ngModel)]="sponsor"
            [ngModelOptions]="{ standalone: true }"
            id="rixtrema-sponsor"
            placeholder="Sponsor"
            label="Sponsor"
            name="sponsor"
            (keydown.enter)="$event.preventDefault()"
            (keyup.enter)="search(); $event.preventDefault()"
          />
        </mat-form-field>
        <mat-form-field class="col-12" *ngIf="searchType === 'ein'">
          <mat-label>EIN</mat-label>
          <input
            matInput
            [(ngModel)]="ein"
            [ngModelOptions]="{ standalone: true }"
            id="ein"
            placeholder="EIN"
            label="EIN"
            name="ein"
            (keydown.enter)="$event.preventDefault()"
            (keyup.enter)="search(); $event.preventDefault()"
          />
        </mat-form-field>
        <ng-container *ngIf="searchType === 'zip'">
          <mat-form-field class="col-6">
            <mat-label>Plan/Sponsor Name</mat-label>
            <input
              matInput
              [(ngModel)]="zipName"
              [ngModelOptions]="{ standalone: true }"
              id="rixtrema-search"
              placeholder="Plan Name"
              label="Plan Name"
              name="zipName"
              (keydown.enter)="$event.preventDefault()"
              (keyup.enter)="search(); $event.preventDefault()"
            />
          </mat-form-field>
          <mat-form-field class="col-3">
            <mat-label>ZIP</mat-label>
            <input
              matInput
              [(ngModel)]="zip"
              [ngModelOptions]="{ standalone: true }"
              id="rixtrema-zip"
              placeholder="ZIP"
              label="ZIP"
              name="zip"
              (keydown.enter)="$event.preventDefault()"
              (keyup.enter)="search(); $event.preventDefault()"
            />
          </mat-form-field>
          <mat-form-field class="col-3">
            <mat-label>Distance (miles)</mat-label>
            <input
              matInput
              [(ngModel)]="distance"
              [ngModelOptions]="{ standalone: true }"
              id="rixtrema-distance"
              placeholder="Distance (miles)"
              label="Distance (miles)"
              name="distance"
              (keydown.enter)="$event.preventDefault()"
              (keyup.enter)="search(); $event.preventDefault()"
            />
          </mat-form-field>
        </ng-container>
      </div>
      <div class="col-2">
        <button
          mat-raised-button
          color="accent"
          (click)="search(); $event.preventDefault()"
          [disabled]="!searchValid()"
        >
          <mat-icon>search</mat-icon>
          Search
        </button>
      </div>
    </div>
    <!-- <ngx-loading [show]="loading" [config]="{fullScreenBackdrop: false, backdropBackgroundColour: 'rgba(0,0,0,0)'}">
            </ngx-loading> -->

    <div class="results-container">
      <ng-container *ngIf="plans && plans.length > 0">
        <h4 class="mt-3">Search Results</h4>
        <div class="table-wrapper">
          <table class="results-table">
            <thead>
              <th>Plan Name</th>
              <th>Sponsor</th>
              <th>EIN</th>
              <th>ZIP</th>
              <th>Admin Fee</th>
              <th>Participants</th>
            </thead>
            <tbody>
              <tr
                *ngFor="let plan of plans"
                (click)="planSelect(plan)"
                [ngClass]="{ selected: plan === selectedPlan }"
              >
                <td>
                  @if (plan === selectedPlan) {
                    <mat-icon>check_circle</mat-icon>
                  }
                  {{ plan.PLAN_NAME }}
                </td>
                <td>{{ plan.SPONSOR_DFE_NAME }}</td>
                <td>{{ plan.SPONSOR_EIN }}</td>
                <td>{{ plan.Zip }}</td>
                <td>{{ plan.AdminExpRate / 100 | percent: '1.3' }}</td>
                <td>{{ plan.TOT_ACTIVE_PARTCP_CNT | number }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- <button mat-button *ngFor="let plan of plans"
                              [ngClass]="{'selected': plan===selectedPlan, 'planItem': true, 'col-12': true}"
                              (click)="planSelect(plan)">
                              <mat-icon>check_circle</mat-icon> {{plan.PLAN_NAME}}
                          </button> -->
      </ng-container>
      <div *ngIf="resultsMessage">
        <h4>{{ resultsMessage }}</h4>
      </div>
    </div>
  </form>
</div>
<div mat-dialog-actions class="actions">
  @if (
    showRlc &&
    (selectedPlan !== null || (subplanID !== '' && subplanID !== null))
  ) {
    <button
      mat-raised-button
      [disabled]="subplanID === '' || subplanID === null"
      (click)="getPdf()"
    >
      <mat-icon>insert_drive_file</mat-icon>Download RLC Fact Sheet
    </button>
  }
  <button
    mat-raised-button
    [disabled]="true"
    (click)="getPdf()"
    *ngIf="!showRlc && rlcAccess"
  >
    <mat-icon>insert_drive_file</mat-icon>RLC Factsheet Unavailable
  </button>
  <button
    mat-raised-button
    [disabled]="true"
    (click)="getPdf()"
    *ngIf="showRlc && subplanID === '' && selectedPlan !== null"
  >
    <mat-icon>insert_drive_file</mat-icon>Select Sub-Plan
  </button>
  <button
    mat-raised-button
    [disabled]="selectedPlan === null"
    (click)="getFactsheet()"
    *ngIf="!rlcAccess"
  >
    <mat-icon>insert_drive_file</mat-icon> Download F5500 Fact Sheet
  </button>
  <form
    #planForm
    *ngIf="showSubplanSelect && rlcAccess && selectedPlan !== null"
  >
    <div>
      <mat-form-field class="col-12">
        <mat-label>Sub-Plan</mat-label>
        <select
          matNativeControl
          [(ngModel)]="subplanID"
          [ngModelOptions]="{ standalone: true }"
          id="rixtrema-subplanID"
          name="PDF"
        >
          <option
            *ngFor="let pdf of selectedPlanData.pdfs"
            [value]="pdf.PathFilename"
          >
            {{ pdf.PlanName }}
          </option>
        </select>
      </mat-form-field>
    </div>
  </form>
  <button
    mat-button
    color="warn"
    (click)="close(); $event.preventDefault()"
    class="mx-2"
  >
    Cancel
  </button>
  <button
    mat-stroked-button
    color="primary"
    (click)="noneFound(); $event.preventDefault()"
    class="mx-2"
  >
    <mat-icon>troubleshoot</mat-icon> I Can't Find It
  </button>
  <button
    mat-raised-button
    color="accent"
    (click)="save(); $event.preventDefault()"
    class="mx-2"
    [disabled]="selectedPlanData === null"
  >
    <mat-icon>check</mat-icon> Use This One
  </button>
</div>
<ngx-loading [show]="loading"></ngx-loading>
