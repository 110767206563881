<div class="page-container downloads-container container">
  <app-utility-toolbar></app-utility-toolbar>
  <h1 class="header col-12"> Downloads </h1>
  <div class="row col-12 mt-2">
    <ul>
      <li>
        <a
          href="{{
            baseUrl
          }}Confidential RightBRIDGE Product Profiler Rollover Fee Summary (Sept 2021) DO NOT DISTIBUTE.pdf"
          download
          ><mat-icon>cloud_download</mat-icon>RightBRIDGE Product Profiler
          Rollover Fee Summary (Sept 2021)</a
        >
      </li>
      <li>
        <a href="{{ baseUrl }}RightBRIDGE Breakpoint Analysis.docx" download
          ><mat-icon>cloud_download</mat-icon>RightBRIDGE Breakpoint Analysis</a
        >
      </li>
      <li>
        <a href="{{ baseUrl }}RightBRIDGE Fee Compare Report.pdf" download
          ><mat-icon>cloud_download</mat-icon>RightBRIDGE Fee Compare Report</a
        >
      </li>
      <li>
        <a
          href="{{ baseUrl }}RightBRIDGE Guaranteed Income Analysis.pdf"
          download
          ><mat-icon>cloud_download</mat-icon>RightBRIDGE Guaranteed Income
          Analysis</a
        >
      </li>
      <li>
        <a
          href="{{ baseUrl }}RightBRIDGE Product Profiler 401k Fee Benchmarking"
          download
          ><mat-icon>cloud_download</mat-icon>RightBRIDGE Product Profiler 401k
          Fee Benchmarking.pdf</a
        >
      </li>
      <li>
        <a
          href="{{ baseUrl }}RIGHTBRIDGE ReasonText Report Explained.docx"
          download
          ><mat-icon>cloud_download</mat-icon>RIGHTBRIDGE ReasonText Report
          Explained</a
        >
      </li>
    </ul>
  </div>
</div>
