import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BeaconService } from '../services/beacon.service';
import { AnnuityLookupComponent } from './annuity-lookup/annuity-lookup.component';
import { AnnuityDialogComponent } from './annuity-dialog/annuity-dialog.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Factsheet } from './factsheet';
import { BeaconServiceV2 } from '../services/beaconv2.service';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { MaskitoDirective } from '@maskito/angular';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatSelectModule,
    MatInputModule,
    MatIconModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    FormsModule,
    MatRadioModule,
    MatTooltipModule,
    SharedComponentsModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.circle,
      backdropBackgroundColour: 'rgba(0, 0, 0, 0.3)',
      fullScreenBackdrop: true,
      backdropBorderRadius: '0',
      primaryColour: 'rgb(38, 91, 151)',
      secondaryColour: 'rgba(38, 91, 151, 0.2)',
      tertiaryColour: '#265b97',
    }),
    MaskitoDirective,
  ],
  declarations: [AnnuityLookupComponent, AnnuityDialogComponent],
  providers: [BeaconService, Factsheet, BeaconServiceV2],
  exports: [AnnuityLookupComponent],
})
export class AnnuityLookupModule {}
