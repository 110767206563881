import { Component, ViewContainerRef, HostBinding } from '@angular/core';
import { UntypedFormGroup, FormGroup } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'form-textarea',
  styleUrls: ['form-textarea.component.scss'],
  template: `
    <mat-form-field floatPlaceholder="auto" class="w-100">
      <mat-label>{{ to.label }}</mat-label>
      <textarea
        matInput
        [placeholder]="props.placeholder || props.label"
        [formControl]="formControl"
        [formlyAttributes]="field"
        [rows]="props.rows ? to.rows : 5"
        [required]="props.required"
        (change)="props.change ? to.change(field, formControl) : ''"
      ></textarea>
      <mat-icon
        class="help-icon"
        *ngIf="props.help"
        matTooltip="{{ to.help }}"
        [matTooltipPosition]="'above'"
        [matTooltipClass]="'rb-tooltip'"
        >help</mat-icon
      >
      <mat-error
        *ngIf="
          formControl.hasError('required') &&
          !formControl.hasError('regRequired')
        "
        >This field is required</mat-error
      >
      <mat-error *ngIf="formControl.hasError('regRequired')"
        >This field is needed for suitability review. You can opt out below if
        the applicant refuses to provide the information.</mat-error
      >
    </mat-form-field>
  `,
})
export class FormlyTextArea extends FieldType {}
