<div class="page-container asset-container container unified">
  <div class="col-12 pt-2 action-button-container">
    <button
      mat-button
      class="compare left"
      color="primary"
      (click)="viewComparison()"
      [disabled]="compareList.length < 2"
    >
      <mat-icon>compare_arrows</mat-icon> Compare Products
    </button>
    @if (hiddenItems) {
      <button
        mat-button
        class="icon-button show left"
        color="primary"
        (click)="showMore()"
      >
        <mat-icon>
          @if (!showAll) {
            add_box
          } @else {
            indeterminate_check_box
          }
        </mat-icon>
        @if (!showAll) {
          Show All
        } @else {
          Show Less Annuities
        }
      </button>
    }

    @if (
      rights?.includes('AwFullReport') &&
      !rights?.includes('DropdownReportMenu') &&
      !hasSummaryModule
    ) {
      <button
        [disabled]="
          (!noSaveProfile && !annuitySelected) ||
          (noSaveProfile && compareList.length < 1)
        "
        mat-raised-button
        color="primary"
        class="icon-button pull-right"
        (click)="report()"
        [ngClass]="{
          disabled: assessmentTypes?.includes('validate')
            ? false
            : noSaveProfile
              ? compareList.length < 1
              : !annuitySelected
        }"
        matTooltip="Select an annuity below in order to generate a report"
        [matTooltipPosition]="'above'"
        [matTooltipClass]="'rb-tooltip'"
        [matTooltipDisabled]="
          assessmentTypes?.includes('validate')
            ? true
            : noSaveProfile
              ? compareList.length > 0
              : annuitySelected
        "
      >
        <mat-icon class="mat-icon material-icons mr-2"
          >insert_drive_file</mat-icon
        >
        Download Full Report
      </button>
    }
    @if (
      isExchange &&
      !rights?.includes('DropdownReportMenu') &&
      !hasSummaryModule &&
      rights.includes('ExchangeReport')
    ) {
      <button
        [disabled]="!annuitySelected"
        mat-raised-button
        color="primary"
        class="pull-right mr-2"
        (click)="exchangeReport()"
        [ngClass]="{
          disabled: noSaveProfile ? compareList.length < 1 : !annuitySelected
        }"
        matTooltip="Select one annuity below in order to generate an exchange report"
        [matTooltipPosition]="'above'"
        [matTooltipClass]="'rb-tooltip'"
        [matTooltipDisabled]="
          noSaveProfile ? compareList.length > 0 : annuitySelected
        "
      >
        <mat-icon class="mat-icon material-icons mr-2">
          insert_drive_file </mat-icon
        >Exchange Report
      </button>
    }
  </div>

  <!-- Broken Down By Assessment Type Results Display -->
  @if (
    ((assessmentTypes?.includes('validate') ||
      assessmentTypes?.includes('exchange')) &&
      annuities &&
      annuities.length > 0) ||
    assessmentTypes?.includes('selectedProduct') ||
    assessmentTypes?.includes('select')
  ) {
    <div class="annuities-container">
      <form #annForm="ngForm">
        @for (grp of assessmentTypes; track grp) {
          @if (
            grp === 'selectedProduct' && !assessmentTypes?.includes('validate')
          ) {
            <div>
              <h2 class="mt-1"> Selected Product(s) </h2>
            </div>
          }
          @if (grp === 'validate') {
            <div>
              <h2 class="mt-1"> Product Validated </h2>
            </div>
          }
          @if (grp === 'exchange') {
            <div>
              <h2 class="mt-1"> Exchange </h2>
            </div>
          }
          @if (grp === 'select') {
            <div>
              <h2 class="mt-1">
                @if (assessmentTypes?.includes('selectedProduct')) {
                  <span> Alternatives </span>
                } @else {
                  <span> Products to Consider </span>
                }
                @if (filteredProductsSubmitted) {
                  <small> <sup>*</sup>Displaying filtered list </small>
                }
              </h2>
              @if (
                assessmentTypes?.includes('selectedProduct') ||
                !assessmentTypes?.includes('selectedProduct')
              ) {
                <div>
                  <form (submit)="searchProducts()">
                    <mat-form-field>
                      <input
                        matInput
                        type="text"
                        [placeholder]="
                          assessmentTypes?.includes('selectedProduct')
                            ? 'Search Alternatives'
                            : 'Search Products'
                        "
                        name="productSearch"
                        [(ngModel)]="productSearch"
                      />
                      <button
                        class="placeholder-button"
                        matSuffix
                        mat-icon-button
                        color="primary"
                        (click)="searchProducts()"
                        [disabled]="!productSearch"
                      >
                        <mat-icon>search</mat-icon>
                      </button>
                      @if (filteredProductsSubmitted) {
                        <button
                          matSuffix
                          mat-icon-button
                          color="warn"
                          (click)="clearProductSearch()"
                        >
                          <mat-icon>close</mat-icon>
                        </button>
                      }
                    </mat-form-field>
                    <button
                      class="ml-2"
                      mat-flat-button
                      color="primary"
                      (click)="searchProducts()"
                      [disabled]="!productSearch"
                    >
                      <mat-icon>search</mat-icon> Search
                    </button>
                  </form>
                </div>
              }
            </div>
          }

          <ul class="annuities col-12">
            @for (x of annuities; track x.name; let i = $index) {
              @if (showProduct(x, grp)) {
                <li class="annuity-container">
                  <div class="type-indicator {{ x.indicatorClass }}">{{
                    x.outline.sections.ShortNameHeader
                  }}</div>
                  @if (
                    rights.includes('BridgeNotes') &&
                    !moduleNotes.include &&
                    (x.SelectedBridge === 'Yes' ||
                      (x.SelectedBridge === 'No' &&
                        x.assessmenttype === 'exchange')) &&
                    x.vars.CurrentBridgeNote &&
                    (x.vars.CurrentBridgeNote.Required === 2 ||
                      x.vars.CurrentBridgeNote.Required === '2' ||
                      ((x.vars.CurrentBridgeNote.Required === 3 ||
                        x.vars.CurrentBridgeNote.Required === '3') &&
                        x.negativeReason))
                  ) {
                    <div
                      class="notes-notification"
                      [ngClass]="{ valid: x.notesValid }"
                    >
                      @if (x.notesValid) {
                        <mat-icon>thumb_up</mat-icon>Required Notes Entered for
                        This Item
                      } @else {
                        <mat-icon>error</mat-icon>Notes Required for This Item
                      }
                    </div>
                  }
                  <div
                    class="annuity col-12 {{ x.ScoreClass }} {{
                      x.expandedClass
                    }}"
                    [class.selected]="x.SelectedBridge === 'Yes'"
                    [class.exchange]="
                      x.name.toLowerCase() === 'annuityproductexchange' ||
                      x.name.toLowerCase() === 'annuityproductexchangeiova'
                    "
                  >
                    <div class="w100 unified-content">
                      @if (
                        (x.name &&
                          x.name.toLowerCase() === 'annuityproductexchange') ||
                        (x.name &&
                          x.name.toLowerCase() === 'annuityproductexchangeiova')
                      ) {
                        <div class="existing-tag"> Current Annuity </div>
                      }
                      <div class="score-icon {{ x.ScoreClass }}">
                        @if (rights.includes('RelevanceBridgeIcon')) {
                          @switch (x.ScoreClass) {
                            @case ('positive') {
                              <mat-icon>check_circle</mat-icon>
                            }
                            @case ('neutral') {
                              <mat-icon>error</mat-icon>
                            }
                            @case ('negative') {
                              <mat-icon>remove_circle</mat-icon>
                            }
                          }
                        }
                      </div>
                      <div class="actions">
                        @if (
                          (x.name &&
                            x.name.toLowerCase() ===
                              'annuityproductexchange') ||
                          (x.name &&
                            x.name.toLowerCase() ===
                              'annuityproductexchangeiova')
                        ) {
                          <div class="existing-tag"> Current Annuity </div>
                        }
                        @if (
                          (grp === 'select' &&
                            !noSaveProfile &&
                            !assessmentTypes?.includes('validate')) ||
                          (grp === 'selectedProduct' &&
                            !noSaveProfile &&
                            !assessmentTypes?.includes('validate'))
                        ) {
                          @if (x.SelectedBridge === 'No' && !noSaveProfile) {
                            <button
                              mat-button
                              class="select-button"
                              (click)="
                                selectAnnuity($event, x.name, false);
                                $event.stopPropagation()
                              "
                            >
                              Select
                            </button>
                          }
                          @if (x.SelectedBridge === 'Yes' && !noSaveProfile) {
                            <button
                              mat-button
                              class="select-button"
                              (click)="
                                deselectAnnuity($event, x.name, false);
                                $event.stopPropagation()
                              "
                            >
                              Deselect
                            </button>
                          }
                        }
                        @if (
                          rights.includes('AnnuityFactsSheet') &&
                          x.hidefactsheet !== 'true'
                        ) {
                          <button
                            mat-button
                            class="fs-button"
                            (click)="
                              dlFactsheet(
                                x.ContractCode,
                                x.Cusip,
                                x.VersionDate,
                                x.outline.sections.ShortName,
                                x.BridgeType,
                                x.name
                              )
                            "
                          >
                            <mat-icon>insert_drive_file</mat-icon
                            >Download<br />Factsheet
                          </button>
                        }
                      </div>

                      <div class="content">
                        <h4>
                          <div>
                            {{ x.outline.sections.ShortName }}
                            @if (!rights.includes('RelevanceHideBridgeScore')) {
                              <span> | {{ x.score / 10 }}%</span>
                            }
                          </div>
                        </h4>
                        @if (grp !== 'validate' && grp !== 'exchange') {
                          <mat-checkbox
                            [(ngModel)]="compareChecked[x.name]"
                            [ngModelOptions]="{ standalone: true }"
                            color="primary"
                            (click)="
                              compare($event, x.name, x.SelectedBridge);
                              $event.stopPropagation()
                            "
                            [disabled]="x.SelectedBridge === 'Yes'"
                          >
                            Compare
                          </mat-checkbox>
                        }
                        <div class="sub">{{
                          x.outline.sections.ShortName2
                        }}</div>
                      </div>

                      <div class="details-button-container">
                        <button mat-icon-button (click)="expand($event, x)">
                          <span class="collapsed-content">
                            <mat-icon>expand_circle_down</mat-icon>
                          </span>
                        </button>
                      </div>
                    </div>

                    <div
                      class="details ml-auto custom-tabs"
                      [ngClass]="{
                        'unified-selected': x.SelectedBridge === 'Yes'
                      }"
                    >
                      @if (x.bulletList) {
                        <ul>
                          @for (bul of x.bulletList; track bul) {
                            <li>{{ bul }}</li>
                          }
                        </ul>
                      }

                      <mat-tab-group mat-stretch-tabs>
                        <mat-tab label="Reasons">
                          @if (x.ComplianceChart) {
                            <app-sub-item-chart
                              [data]="x.ComplianceChart"
                              [chartTitle]="'Compliance Information'"
                            ></app-sub-item-chart>
                          }
                          <app-reason-text
                            class="col-6"
                            [data]="x.trueRules"
                            [selected]="x.SelectedBridge === 'Yes'"
                          ></app-reason-text>
                          @if (
                            rights.includes('BridgeNotes') &&
                            !moduleNotes.include
                          ) {
                            <app-notes-entry
                              class="w-100 mt-2"
                              [bridgeVal]="x.trueRules"
                              (noteEntered)="handleNotesValidation($event, x)"
                              [notesData]="{
                                app: 'aw',
                                profile: this.id,
                                bridgeId: x.vars.CurrentBridgeNote.NoteBridgeID,
                                note: x.vars.CurrentBridgeNote.NoteText,
                                prompt: x.vars.CurrentBridgeNote.Prompt,
                                required:
                                  x.SelectedBridge === 'Yes' &&
                                  (x.vars.CurrentBridgeNote.Required === 2 ||
                                    x.vars.CurrentBridgeNote.Required === '2' ||
                                    ((x.vars.CurrentBridgeNote.Required === 3 ||
                                      x.vars.CurrentBridgeNote.Required ===
                                        '3') &&
                                      x.negativeReason))
                              }"
                            ></app-notes-entry>
                          }
                        </mat-tab>
                        @if (
                          x.grids &&
                          x.grids.length > 0 &&
                          !x.name.includes('AnnuityProductExchange1') &&
                          !x.name.includes('AnnuityProductExchange2') &&
                          !x.name.includes('AnnuityProductExchange3') &&
                          !x.name.includes('AnnuityProductExchange4')
                        ) {
                          <mat-tab label="Product Summary">
                            @for (gr of x.grids; track gr.title) {
                              <div
                                class="grid-table w-100"
                                [ngClass]="{
                                  'unified-selected': x.SelectedBridge === 'Yes'
                                }"
                              >
                                <div class="table-header w-100">{{
                                  gr.title
                                }}</div>
                                @for (tb of gr.rows; track tb) {
                                  <table class="w33">
                                    @for (rw of tb.table; track rw.value) {
                                      <tr>
                                        <td
                                          [innerHTML]="rw.label"
                                          class="table-label"
                                        ></td>
                                        <td [innerHTML]="rw.value"></td>
                                      </tr>
                                    }
                                  </table>
                                }
                              </div>
                            }
                          </mat-tab>
                        }
                        @if (
                          (rights.includes('ShowVAFunds') &&
                            x.BridgeType &&
                            (x.BridgeType.toLowerCase() === 'va' ||
                              x.BridgeType.toLowerCase() === 'io') &&
                            x.name.toLowerCase() !== 'annuityproductexchange' &&
                            x.name.toLowerCase() !==
                              'annuityproductexchange1' &&
                            x.name.toLowerCase() !==
                              'annuityproductexchange2' &&
                            x.name.toLowerCase() !==
                              'annuityproductexchange3' &&
                            x.name.toLowerCase() !==
                              'annuityproductexchange4' &&
                            x.name.toLowerCase() !== 'annuityaddtldeposit') ||
                          (x.vars.CurrentVARate &&
                            x.vars.CurrentVARate.length > 1) ||
                          (x.vars.CurrentVARate &&
                            x.vars.CurrentVARate.length === 1 &&
                            !x.vars.CurrentVARate[0].Hide)
                        ) {
                          <mat-tab label="Investment Options">
                            @if (!x.vaFundFees && !feesLoading) {
                              <div class="no-data">
                                No Investment Options Available
                              </div>
                            }
                            @if (x.vaFundFees) {
                              <kendo-grid
                                class="w-100 vaGrid"
                                #vaGrid="kendoGrid"
                                [kendoGridBinding]="x.vaFundFees.data"
                                [style.maxHeight.px]="400"
                                [sortable]="true"
                              >
                                <ng-template
                                  ngFor
                                  [ngForOf]="x.vaFundFees.headers"
                                  let-column
                                >
                                  <kendo-grid-column
                                    [field]="column.DataField"
                                    [title]="column.Label"
                                  >
                                    @if (
                                      column.DataField === 'Summary$FeePct'
                                    ) {
                                      <ng-template
                                        kendoGridCellTemplate
                                        let-dataItem
                                      >
                                        {{
                                          dataItem[column.DataField] / 100
                                            | percent: '1.2'
                                        }}
                                      </ng-template>
                                    }
                                  </kendo-grid-column>
                                </ng-template>
                              </kendo-grid>
                            }
                            @if (feesLoading && !x.vaFundFees) {
                              <div style="min-height: 100px">
                                <ngx-loading
                                  [show]="feesLoading && !x.vaFundFees"
                                  [config]="{
                                    fullScreenBackdrop: false,
                                    backdropBackgroundColour: 'rbga(0,0,0,0)'
                                  }"
                                ></ngx-loading>
                              </div>
                            }
                          </mat-tab>
                        }
                        @if (
                          rights.includes('ShowFIAFunds') &&
                          x.vars.CurrentFIARate &&
                          x.vars.CurrentFIARate.length
                        ) {
                          <mat-tab label="Rate Summary">
                            <div class="table-container fiaGrid">
                              <table class="details col-12">
                                <thead>
                                  <tr>
                                    <th>Index</th>
                                    <th>Crediting Method</th>
                                    <th>Strategy Term</th>
                                    <th>Calc Period</th>
                                    <th>Cap Rate (ann)</th>
                                    <th>Spread (ann)</th>
                                    <th>Par Rate</th>
                                    <th>Trigger</th>
                                    <th>Fixed Rate</th>
                                    <th>Annuity Term</th>
                                    <th>Fee</th>
                                    <th>Version</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  @for (
                                    t of x.vars.CurrentFIARate;
                                    track t.IndexName
                                  ) {
                                    <tr>
                                      @if (
                                        t.Hide === false || t.Hide === 'false'
                                      ) {
                                        <td [innerHTML]="t.IndexName"></td>
                                        <td
                                          [innerHTML]="t.CreditingMethod"
                                        ></td>
                                        <td [innerHTML]="t.StrategyTerm"></td>
                                        <td
                                          [innerHTML]="t.CalculationPeriod"
                                        ></td>
                                        <td
                                          [innerHTML]="t.DeclaredRateCap"
                                        ></td>
                                        <td [innerHTML]="t.Spread"></td>
                                        <td
                                          [innerHTML]="t.ParticipationRate"
                                        ></td>
                                        <td
                                          [innerHTML]="t.PerformanceTriggerRate"
                                        ></td>
                                        <td [innerHTML]="t.FixedRate"></td>
                                        <td [innerHTML]="t.AnnuityTerm"></td>
                                        <td [innerHTML]="t.FeePct"></td>
                                        <td [innerHTML]="t.RateVersion"></td>
                                      }
                                    </tr>
                                  }
                                </tbody>
                              </table>

                              <span class="disclaimer"
                                >Results filtered by entered premium, for
                                additional options
                                <button
                                  class="click-here"
                                  (click)="onLinkClick($event, 'fia', x.Cusip)"
                                >
                                  click here
                                </button>
                              </span>
                            </div>
                          </mat-tab>
                        }
                        @if (
                          rights.includes('ShowFAFunds') &&
                          x.faFundFees &&
                          x.faFundFees.data.length
                        ) {
                          <mat-tab label="Rate Summary">
                            @if (!x.faFundFees) {
                              <div class="no-data">
                                No Rate Data Available
                              </div>
                            } @else {
                              <kendo-grid
                                class="w-100 faGrid"
                                #faGrid="kendoGrid"
                                [kendoGridBinding]="x.faFundFees.data"
                                [style.maxHeight.px]="400"
                              >
                                <ng-template
                                  ngFor
                                  [ngForOf]="x.faFundFees.headers"
                                  let-column
                                >
                                  <kendo-grid-column
                                    [field]="column.DataField"
                                    [title]="column.Label"
                                  ></kendo-grid-column>
                                </ng-template>
                              </kendo-grid>
                            }
                            <span class="fa-disclaimer"
                              >Results filtered by entered premium, for
                              additional options
                              <button
                                class="click-here"
                                (click)="
                                  onLinkClick(
                                    $event,
                                    'fa',
                                    x.name.replace('FA', '')
                                  )
                                "
                              >
                                click here
                              </button>
                            </span>
                          </mat-tab>
                        }
                        @if (
                          (rights.includes('ShowVIAFunds') &&
                            x.vars.CurrentVARate &&
                            x.vars.CurrentVARate.length > 1) ||
                          (x.vars.CurrentVARate &&
                            x.vars.CurrentVARate.length === 1 &&
                            !x.vars.CurrentVARate[0].Hide)
                        ) {
                          <mat-tab label="Rate Summary">
                            <div class="table-container viaGrid">
                              <table class="details col-12">
                                <thead>
                                  <tr>
                                    <th>Index</th>
                                    <th>Cap Rate</th>
                                    <th>Par Rate</th>
                                    <th>Spread</th>
                                    <th>Step/Trigger</th>
                                    <th>Fixed Rate</th>
                                    <th>Buffer</th>
                                    <th>Floor</th>
                                    <th>Term</th>
                                    <th>Rate Lock Period</th>
                                    <th>Fee</th>
                                    <th>Version</th>
                                    <th>Note</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  @for (
                                    t of x.vars.CurrentVARate;
                                    track t.IndexName
                                  ) {
                                    <tr>
                                      @if (
                                        t.Hide === false || t.Hide === 'false'
                                      ) {
                                        <td [innerHTML]="t.IndexName"></td>
                                        <td [innerHTML]="t.AdjRateCap"></td>
                                        <td
                                          [innerHTML]="t.ParticipationRate"
                                        ></td>
                                        <td [innerHTML]="t.Spread"></td>
                                        <td [innerHTML]="t.StepRate"></td>
                                        <td [innerHTML]="t.Rate"></td>
                                        <td [innerHTML]="t.AdjBuffer"></td>
                                        <td [innerHTML]="t.AdjFloor"></td>
                                        <td [innerHTML]="t.Term"></td>
                                        <td [innerHTML]="t.RateLockPeriod"></td>
                                        <td [innerHTML]="t.Fee"></td>
                                        <td [innerHTML]="t.RateVersion"></td>
                                        <td [innerHTML]="t.Note"></td>
                                      }
                                    </tr>
                                  }
                                </tbody>
                              </table>

                              <span class="disclaimer"
                                >Results filtered by entered premium, for
                                additional options
                                <button
                                  class="click-here"
                                  (click)="onLinkClick($event, 'rila', x.Cusip)"
                                >
                                  click here
                                </button>
                              </span>
                            </div>
                          </mat-tab>
                        }
                        @if (
                          x.vars.CurrentDBOptions &&
                          x.vars.CurrentDBOptions.length > 0
                        ) {
                          <mat-tab label="Death Benefits">
                            <div class="table-container">
                              <h5 class="col-12 pl-0">Death Benefit Summary</h5>
                              <table class="details col-12">
                                <thead>
                                  <tr>
                                    <th>Death Benefit Name</th>
                                    <th>Death Benefit Type</th>
                                    <th>Rider Fee</th>
                                    <th>Maximum Step-up Age</th>
                                    <th>Maximum Roll-up Age</th>
                                    <th>Enhancement Rate</th>
                                    <th>Spousal Continuation</th>
                                    <th>Min Age</th>
                                    <th>Max Age</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  @for (
                                    t of x.vars.CurrentDBOptions;
                                    track t.Name
                                  ) {
                                    <tr>
                                      @if (
                                        t.Hide === false || t.Hide === 'false'
                                      ) {
                                        <td [innerHTML]="t.Name"></td>
                                        <td [innerHTML]="t.Type"></td>
                                        <td>
                                          {{ +t.BasisCost | number: '1.2-2' }}%
                                        </td>
                                        <td [innerHTML]="t.StepUpAgeMax"></td>
                                        <td [innerHTML]="t.DBRUPAgeMax"></td>
                                        <td
                                          [innerHTML]="t.NetEnhancementRate"
                                        ></td>
                                        <td
                                          [innerHTML]="t.SpousalProtection"
                                        ></td>
                                        <td [innerHTML]="t.AgeMin"></td>
                                        <td [innerHTML]="t.AgeMax"></td>
                                      }
                                    </tr>
                                  }
                                </tbody>
                              </table>
                            </div>
                          </mat-tab>
                        }
                        @if (
                          x.outline.sections.DDW_Purposes &&
                          x.outline.sections.DDW_Purposes.length > 1
                        ) {
                          <mat-tab label="Due Diligence Works">
                            <div class="ddw">
                              <div class="ddw-section purposes col-6">
                                <strong>Purposes</strong>
                                <ul>
                                  @for (
                                    purpose of x.outline.sections.DDW_Purposes;
                                    track purpose
                                  ) {
                                    <li>{{ purpose }}</li>
                                  }
                                </ul>
                              </div>
                              <div class="ddw-section upside col-6">
                                <strong>Upside</strong>
                                <ul>
                                  @for (
                                    upside of x.outline.sections.DDW_Upside;
                                    track upside
                                  ) {
                                    <li>{{ upside }}</li>
                                  }
                                </ul>
                              </div>
                              <div class="ddw-section edge col-6">
                                <strong>Edge</strong>
                                <ul>
                                  @for (
                                    edge of x.outline.sections.DDW_Edge;
                                    track edge
                                  ) {
                                    <li>{{ edge }}</li>
                                  }
                                </ul>
                              </div>
                              <div class="ddw-section protection col-6">
                                <strong>Protection</strong>
                                <ul>
                                  @for (
                                    protection of x.outline.sections
                                      .DDW_Protection;
                                    track protection
                                  ) {
                                    <li>{{ protection }}</li>
                                  }
                                </ul>
                              </div>
                            </div>
                          </mat-tab>
                        }
                      </mat-tab-group>
                    </div>
                    <div
                      class="rating-line {{ x.ScoreClass }}"
                      [style.width.%]="x.score / 10"
                    ></div>
                  </div>
                </li>
              }
            }
          </ul>
        }
      </form>
      @if (moduleNotes.include && !loading) {
        <app-notes-entry
          class="w-100 mt-2"
          [moduleNotes]="true"
          (noteEntered)="handleNotesValidation($event)"
          [notesData]="{
            app: 'aw',
            profile: id,
            note: moduleNotes.note,
            prompt: moduleNotes.prompt,
            required: moduleNotes.required
          }"
          [moduleBridges]="annuities"
          (updateModuleNote)="updateModuleNote($event)"
        ></app-notes-entry>
      }
    </div>
  }
  @if (
    (!annuities || !annuities.length) && !unfFlowSvc.getloading() && !loading
  ) {
    <h2 class="my-5 text-center w-100">
      There are no applicable products based on the customer's profile and the
      available product shelf.
    </h2>
  }
  <div class="buttons row w-100">
    @if (
      !noSaveProfile &&
      !hasSummaryModule &&
      rights?.includes('AwCFVReport') &&
      !rights?.includes('DropdownReportMenu')
    ) {
      <button
        mat-button
        color="primary"
        class="report exchange print-button"
        (click)="validationReport()"
        [ngClass]="{
          disabled: assessmentTypes?.includes('validate')
            ? false
            : noSaveProfile
              ? compareList.length < 1
              : !annuitySelected
        }"
        matTooltip="Select an annuity below in order to generate a report"
        [matTooltipPosition]="'above'"
        [matTooltipClass]="'rb-tooltip'"
        [matTooltipDisabled]="
          assessmentTypes?.includes('validate')
            ? true
            : noSaveProfile
              ? compareList.length > 0
              : annuitySelected
        "
      >
        <span class="mat-button-wrapper">
          <mat-icon class="mat-icon material-icons">insert_drive_file</mat-icon
          >Download Client Report
        </span>
      </button>
    }
  </div>
  <ngx-loading [show]="loading"></ngx-loading>
</div>

<form #samlForm ngNoForm method="POST">
  <input type="hidden" name="SAMLResponse" />
</form>
