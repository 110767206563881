<div class="page-container asset-container container unified">
  <div
    class="chart-container col-12 row"
    *ngIf="
      selectionType === 'Allocation' ||
      selectionType === 'ETFOnly' ||
      selectionType === 'Brokerage' ||
      selectionType === 'UITBrokerage' ||
      selectionType === 'UITOnly'
    "
  >
    <div class="chart col-12 col-md-6">
      <h3>Target Allocations</h3>
      <div [chart]="modelChartData"></div>
    </div>
    <div class="chart col-12 col-md-6">
      <h3>Proposed Allocations</h3>
      <div [chart]="selectedChartData"></div>
    </div>
  </div>
  <div class="table-header" *ngIf="selectionType !== 'Advisory'">
    <div class="w-100">
      <h2
        *ngIf="
          (selectionType === 'Allocation' ||
            selectionType === 'ETFOnly' ||
            selectionType === 'Brokerage' ||
            selectionType === 'UITBrokerage' ||
            selectionType === 'UITOnly') &&
          investment
        "
        [ngClass]="{ negative: investment - investmentTotal < 0 }"
      >
        Total Dollars to Allocate:
        {{
          investment - investmentTotal
            | currency: 'USD' : 'symbol-narrow' : '0.2-2'
        }}
      </h2>
      <h2 *ngIf="selectionType === 'SingleFunds' && investment">
        Amount Being Invested:
        {{ investment | currency: 'USD' : 'symbol-narrow' : '0.2-2' }}
      </h2>
    </div>
    <div class="w-100 row mx-0">
      <ng-container
        *ngIf="
          selectionType === 'Allocation' ||
          selectionType === 'ETFOnly' ||
          selectionType === 'Brokerage' ||
          selectionType === 'UITBrokerage' ||
          selectionType === 'UITOnly'
        "
      >
        <mat-form-field class="col-12 col-md-6" *hasPermission="'FundSearch'">
          <input
            placeholder="Search for a fund"
            matInput
            [formControl]="searchControl"
            [matAutocomplete]="symbolLookup"
          />
          <mat-icon matSuffix>search</mat-icon>
          <mat-autocomplete
            #symbolLookup="matAutocomplete"
            autoActiveFirstOption
            [displayWith]="displayFn"
            (optionSelected)="selectFundLookup($event)"
          >
            <mat-option
              *ngFor="let option of filteredSymbols | async"
              [value]="option"
            >
              {{ option.FundID }} - {{ option.FundName }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field
          *ngIf="filtering === 'multi' && selectionType !== 'Advisory'"
          class="col-12 col-md-3"
          [class.no-shares]="!shareClassFilter"
        >
          <mat-select
            placeholder="Filter Fund Families"
            multiple
            [(value)]="filteredFundFams"
            #fundFamilyMulti
            (openedChange)="filterWarn($event)"
            (selectionChange)="fundFamilyChange($event)"
          >
            <mat-option *ngFor="let fam of fundFamilies" [value]="fam.ID">
              {{ fam.Name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field
          *ngIf="filtering === 'single' && selectionType !== 'UITOnly'"
          class="col-12 col-md-3"
          [class.no-shares]="!shareClassFilter"
        >
          <mat-select
            placeholder="Filter Fund Families"
            [(value)]="filteredFundFams"
            #fundFamilySingle
            (openedChange)="filterWarn($event)"
            (selectionChange)="fundFamilyChange($event)"
            [disabled]="disableFundFamilySelect"
          >
            <mat-option *ngFor="let fam of fundFamilies" [value]="fam.ID">
              {{ fam.Name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field
          *ngIf="
            shareClassFilter &&
            selectionType !== 'ETFOnly' &&
            selectionType !== 'UITOnly'
          "
          class="col-12 col-md-3"
        >
          <mat-select
            placeholder="Filter Fund Classes"
            [(value)]="shareClass"
            (selectionChange)="shareClassChange($event, false)"
          >
            <mat-option value="A">A Shares</mat-option>
            <mat-option value="C">C Shares</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field
          class="col-12 col-md-6"
          *hasPermission="'showMultiAssetClassSearch'"
        >
          <input
            placeholder="Select multi-asset class funds"
            matInput
            [formControl]="multiSearchControl"
            [matAutocomplete]="multiLookup"
          />
          <mat-icon matSuffix>library_books</mat-icon>
          <mat-autocomplete
            #multiLookup="matAutocomplete"
            autoActiveFirstOption
            [displayWith]="displayFn"
            (optionSelected)="selectFundLookup($event)"
          >
            <mat-option *ngFor="let option of filteredMulti" [value]="option">
              {{ option.FundID }} - {{ option.FundName }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field class="col-12 col-md-6" *hasPermission="'IWTemplates'">
          <mat-select
            placeholder="Select a template to apply"
            (selectionChange)="applyTemplate($event)"
            [formControl]="templatesControl"
            [disabled]="
              templates &&
              templates.length < 1 &&
              firmTemplates &&
              firmTemplates.length < 1
            "
          >
            <ng-container *ngIf="firmTemplates && firmTemplates.length > 0">
              <mat-optgroup label="Firm Templates">
                <mat-option *ngFor="let tmplt of firmTemplates" [value]="tmplt">
                  {{ tmplt.name }}
                </mat-option>
              </mat-optgroup>
            </ng-container>
            <ng-container *ngIf="templates && templates.length > 0">
              <mat-optgroup label="User Templates">
                <mat-option *ngFor="let tmplt of templates" [value]="tmplt">
                  {{ tmplt.name }}
                </mat-option>
              </mat-optgroup>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </ng-container>
      <ng-container *ngIf="selectionType === 'SingleFunds'">
        <button
          class="single-compare"
          mat-raised-button
          color="primary"
          (click)="viewComparison()"
          [disabled]="compareList.length < 2"
        >
          <mat-icon>compare_arrows</mat-icon> Compare Funds
        </button>
        <button
          mat-raised-button
          color="primary"
          (click)="showAll = !showAll"
          class="single-show"
        >
          <span *ngIf="!showAll"> <mat-icon>add_box</mat-icon> Show All </span>
          <span *ngIf="showAll">
            <mat-icon>indeterminate_check_box</mat-icon> Show Less Funds
          </span>
        </button>

        <mat-form-field *ngIf="shareClassFilter" class="share-filter">
          <mat-select
            placeholder="Filter Fund Classes"
            [(value)]="shareClass"
            (selectionChange)="shareClassChange($event)"
          >
            <mat-option value="A">A Shares</mat-option>
            <mat-option value="C">C Shares</mat-option>
          </mat-select>
        </mat-form-field>

        <button
          mat-raised-button
          color="accent"
          class="print right top pull-right"
          (click)="validatePortfolio()"
          [disabled]="disabledFin()"
        >
          <mat-icon>verified_user</mat-icon> Validate and Print Report
        </button>
      </ng-container>
    </div>
  </div>

  <table
    class="details col-12 mx-auto"
    *ngIf="
      selectionType === 'Allocation' ||
      selectionType === 'ETFOnly' ||
      selectionType === 'Brokerage' ||
      selectionType === 'UITBrokerage' ||
      selectionType === 'UITOnly'
    "
  >
    <thead>
      <tr>
        <th class="text-center">Asset Class</th>
        <th class="text-center">Target Amounts</th>
        <th class="text-center">Proposed Amounts</th>
        <th class="text-center"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let row of rows">
        <tr>
          <td class="text-center">{{ row.className }}</td>
          <td class="text-center">
            {{ row.percentage | percent: '1.0-2' }} |
            {{ row.amount | currency: 'USD' : 'symbol-narrow' : '0.2-2' }}
          </td>
          <td class="text-center">
            {{ row.actualPercentage | percent: '1.0-2' }} |
            {{ row.actualAmount | currency: 'USD' : 'symbol-narrow' : '0.2-2' }}
          </td>
          <td class="text-center">
            <button
              mat-raised-button
              (click)="
                selectFund(row.className, row.classId, row.amount, false, 'MF')
              "
              class="select-button"
              *ngIf="selectionType !== 'ETFOnly' && selectionType !== 'UITOnly'"
            >
              <mat-icon>check_circle</mat-icon> Select Mutual Funds
            </button>
            <button
              mat-raised-button
              (click)="
                selectFund(row.className, row.classId, row.amount, false, 'ETF')
              "
              class="select-button"
              *ngIf="
                selectionType === 'ETFOnly' ||
                selectionType === 'Brokerage' ||
                selectionType === 'UITBrokerage'
              "
            >
              <mat-icon>check_circle</mat-icon> Select ETFs
            </button>
            <button
              mat-raised-button
              (click)="
                selectFund(row.className, row.classId, row.amount, false, 'UIT')
              "
              class="select-button"
              *ngIf="
                selectionType === 'UITOnly' || selectionType === 'UITBrokerage'
              "
            >
              <mat-icon>check_circle</mat-icon> Select UITs
            </button>
          </td>
        </tr>
        <ng-container>
          <tr
            class="selected-funds {{
              sel['SelectedFunds.FundType'].toLowerCase()
            }}"
            *ngFor="let sel of selectedFunds[row.classId]"
          >
            <td colspan="3">
              <span
                class="type-indicator {{
                  sel['SelectedFunds.FundType'].toLowerCase()
                }}"
                >{{ sel['SelectedFunds.FundType'] }}</span
              >
              {{ sel['SelectedFunds.Name'] }}
            </td>
            <td>
              {{
                sel['SelectedFunds.Amount'] | currency: 'USD' : 'symbol-narrow'
              }}
              <button
                mat-icon-button
                class="pull-right"
                matTooltip="Clear Fund"
                (click)="clearIndividualFund(sel['SelectedFunds.FundID'])"
              >
                <mat-icon>cancel</mat-icon>
              </button>
            </td>
          </tr>
        </ng-container>
      </ng-container>
      <tr class="total">
        <td class="text-center">TOTALS</td>
        <td *ngIf="investment" class="text-center">{{
          investment | currency: 'USD' : 'symbol-narrow' : '0.2-2'
        }}</td>
        <td class="text-center">{{
          investmentTotal | currency: 'USD' : 'symbol-narrow' : '0.2-2'
        }}</td>
        <td></td>
      </tr>
    </tbody>
  </table>

  <div
    class="single-funds col-12 row mx-auto"
    *ngIf="selectionType === 'SingleFunds'"
  >
    <mat-button-toggle-group
      #singleFundButtons
      class="override-toggle"
      [value]="selectedSingle"
    >
      <mat-accordion class="funds col-12 fake-class">
        <ng-container *ngFor="let row of rows">
          <mat-expansion-panel
            class="fund"
            *ngIf="
              (!row.hidden && row.type === singleFundClassFilter) ||
              (row.hidden && showAll && row.type === singleFundClassFilter) ||
              singleFundClassFilter === 'ALL'
            "
          >
            <mat-expansion-panel-header
              [collapsedHeight]="'62px'"
              [expandedHeight]="'62px'"
            >
              <div class="col-5 col-lg-3 pl-0 pr-0 button">
                <mat-button-toggle
                  class="select-button single"
                  id="{{ row.symbol }}"
                  (click)="
                    selectSingle(
                      $event,
                      row.name,
                      row.assetClass,
                      row.symbol,
                      row.currFund.FundFamilyID
                    )
                  "
                  [value]="row.symbol"
                >
                  <mat-icon>check_circle</mat-icon> Select
                </mat-button-toggle>
                <mat-checkbox
                  class="single-compare-check"
                  (click)="
                    compare($event, row.fundId); $event.stopPropagation()
                  "
                >
                  Compare
                </mat-checkbox>
              </div>
              <h5 class="col-7 col-lg-9 pl-0">
                {{ row.name }}
              </h5>
            </mat-expansion-panel-header>
            <div class="details-container row">
              <h5 class="col-11 mx-auto pl-0 mt-3">Average Annual Returns</h5>
              <table class="details col-11 mx-auto">
                <thead>
                  <tr>
                    <th>{{ row.name }}</th>
                    <th>1 Mo</th>
                    <th>1 Yr</th>
                    <th>3 Yr</th>
                    <th>5 Yr</th>
                    <th>10 Yr</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Growth of $10,000</td>
                    <td>
                      {{
                        row.currFund['10kGrowthAfter1M'] === '($999)'
                          ? 'N/A'
                          : row.currFund['10kGrowthAfter1M'].toLocaleString()
                      }}
                    </td>
                    <td>
                      {{
                        row.currFund['10kGrowthAfter1Y'] === '($999)'
                          ? 'N/A'
                          : row.currFund['10kGrowthAfter1Y'].toLocaleString()
                      }}
                    </td>
                    <td>
                      {{
                        row.currFund['10kGrowthAfter3Y'] === '($999)'
                          ? 'N/A'
                          : row.currFund['10kGrowthAfter3Y'].toLocaleString()
                      }}
                    </td>
                    <td>
                      {{
                        row.currFund['10kGrowthAfter5Y'] === '($999)'
                          ? 'N/A'
                          : row.currFund['10kGrowthAfter5Y'].toLocaleString()
                      }}
                    </td>
                    <td>
                      {{
                        row.currFund['10kGrowthAfter10Y'] === '($999)'
                          ? 'N/A'
                          : row.currFund['10kGrowthAfter10Y'].toLocaleString()
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Total Return %</td>
                    <td>{{ row.currFund.TrailingReturnM1 }}</td>
                    <td>{{ row.currFund.TrailingReturnY1 }}</td>
                    <td>{{ row.currFund.TrailingReturnY3 }}</td>
                    <td>{{ row.currFund.TrailingReturnY5 }}</td>
                    <td>{{ row.currFund.TrailingReturnY10 }}</td>
                  </tr>
                  <tr>
                    <td>Load Adjusted Total Return %</td>
                    <td>{{ row.currFund.LoadAdjustedTrailingReturnM1 }}</td>
                    <td>{{ row.currFund.LoadAdjustedTrailingReturnY1 }}</td>
                    <td>{{ row.currFund.LoadAdjustedTrailingReturnY3 }}</td>
                    <td>{{ row.currFund.LoadAdjustedTrailingReturnY5 }}</td>
                    <td>{{ row.currFund.LoadAdjustedTrailingReturnY10 }}</td>
                  </tr>
                </tbody>
              </table>
              <div class="col-12 small text-right asofdate"
                >Data as of {{ row.currFund?.DataAsOfDate }}</div
              >
              <h5 class="col-11 mx-auto">Reasons for this Fund</h5>
              <div class="details col-11 mx-auto" *ngFor="let z of row.reasons">
                <strong>
                  <img
                    src="{{
                      './assets/' + path + z.reasonType.toLowerCase() + '.png'
                    }}"
                    alt="fund text"
                  />{{ z.headline }}
                  <span *ngIf="!hideScore"> | {{ z.score / 10 }}%</span>
                </strong>
                <p>{{ z.explanation }}</p>
              </div>
            </div>
          </mat-expansion-panel>
        </ng-container>
      </mat-accordion>
    </mat-button-toggle-group>
  </div>

  <div *ngIf="selectionType === 'Advisory'" class="row">
    <div
      class="col-10 program-instructions"
      [innerHtml]="programInstructions"
    ></div>
  </div>

  <div class="buttons w-100" *ngIf="!loading">
    <span class="program-message" [innerHTML]="programMessage"></span>
  </div>
</div>
