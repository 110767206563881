<div class="page-container post-trade-reports-container container">
  <div class="breadcrumb">
    <button
      mat-button
      (click)="nav('unified')"
      *hasPermission="'!ToolsLandingPage'"
    >
      <mat-icon>home</mat-icon>
      <span class="text-container">Home</span>
    </button>
    <button
      mat-button
      (click)="nav('tools')"
      *hasPermission="'ToolsLandingPage'"
    >
      <mat-icon>home</mat-icon>
      <span class="text-container">Home</span>
    </button>
    /
    <button mat-button (click)="nav('post-trade')">
      <span class="text-container">Post Transaction</span>
    </button>
    /
    <button mat-button [disabled]="true">
      <span class="text-container">Downloadable Reports</span>
    </button>
  </div>

  <h1 class="header col-12 mb-0"> Downloadable Reports </h1>

  <div class="downloadable-reports-container">
    <table>
      <thead>
        <tr>
          <th></th>
          @for (header of headers; track header.fieldName) {
            <th>{{ header.label }}</th>
          }
        </tr>
      </thead>
      <tbody>
        @for (report of reports; track report.ID) {
          <tr>
            <td class="actions-cell">
              <button
                mat-button
                class="fs-button"
                (click)="saveFile(report.ID, report.FileName)"
                color="primary"
              >
                <mat-icon>save</mat-icon>
              </button>
            </td>
            @for (header of headers; track header.fieldName) {
              @if (header.fieldMask === 'date') {
                <td>{{
                  report[header.fieldName] | localTime: 'shortDateTime'
                }}</td>
              } @else {
                <td>{{ report[header.fieldName] }}</td>
              }
            }
          </tr>
        }
      </tbody>
    </table>
  </div>
</div>
