import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { map, catchError, flatMap } from 'rxjs/operators';
import {
  throwError as observableThrowError,
  Observable,
  of,
  Subject,
} from 'rxjs';
import { SessionStorageService } from '../services/session-storage.service';
// import { environment } from '../../environments/environment';
import { RightBridgeApiService } from '../services/right-bridge-api.service';
import { EnvironmentService } from '../services/environment.service';
import { ErrorReportingService } from '../services/error-reporting.service';

@Injectable({
  providedIn: 'root',
})
export class RixtremaService {
  private environment;
  private baseUrl: string;
  private rixtremaBaseUrl = 'https://www.larkspurweb.com/PS2/AJAXPS.aspx';
  private assertion = '';
  private loginTimeout: Date;
  private sessionDurationMinutes = 15;
  private sessionId = '';
  private server = '';
  private app: string;
  private org: string;
  private planSelectedSource = new Subject();

  planSelected$ = this.planSelectedSource.asObservable();

  constructor(
    private http: HttpClient,
    private ss: SessionStorageService,
    private rbs: RightBridgeApiService,
    private envSvc: EnvironmentService,
    private errSvc: ErrorReportingService
  ) {
    this.environment = this.envSvc.get();
    this.baseUrl = this.environment.apiBase;
    this.org = this.environment.org;
    this.app = this.ss.get('currentApp') || this.environment.defaultApp || 'pp';
    this.org = this.environment.org || 'basev2';
  }

  setServer(server: string): void {
    if (server?.toLowerCase() == 'rixtrema') {
      this.server = server;
      this.rixtremaBaseUrl = this.environment.rixtremaBase;
    }
    if (server?.toLowerCase() == 'rightbridge') {
      this.server = server;
      this.rixtremaBaseUrl =
        this.environment.f5500Base + '/products/product2020';
    }
  }

  startSession(assertion: string): Observable<any> {
    const reqHeaders = new HttpHeaders()
      .set('Accept', '*/*')
      .set('Content-Type', 'application/x-www-form-urlencoded');

    const url = this.rixtremaBaseUrl + '?Action=SAML';
    return this.http
      .post(url, 'SAMLResponse=' + encodeURIComponent(assertion), {
        headers: reqHeaders,
        observe: 'response',
      })
      .pipe(
        map(res => {
          this.loginTimeout = new Date(
            Date.now() + this.sessionDurationMinutes * 60000
          );
          if (res.body['PS']['Token'] == undefined) {
            return observableThrowError(
              'Rixtrema did not return a login token'
            );
          }
          this.sessionId = res.body['PS']['Token'];
          return res;
        }),
        catchError((error: any) => {
          return this.errSvc.handleError(error);
        })
      );
  }

  loginRixtrema(): Observable<any> {
    if (this.server?.toLowerCase() == 'rixtrema') {
      if (
        this.assertion.length == 0 ||
        new Date() >= this.loginTimeout ||
        this.sessionId.length == 0
      ) {
        return this.rbs.authenticate().pipe(
          flatMap(assertion => {
            this.assertion = assertion;
            return this.startSession(assertion);
          })
        );
      }
    } else {
      return this.rbs.getToken();
    }
  }

  getHeaders(): HttpHeaders {
    if (this.server?.toLowerCase() == 'rightbridge') {
      return new HttpHeaders()
        .set('Accept', 'application/json')
        .set('Response-Type', 'application/json')
        .set('Authorization', 'Bearer ' + this.rbs.getJwt());
    } else {
      return new HttpHeaders()
        .set('Accept', 'application/json')
        .set('Response-Type', 'application/json');
    }
  }

  IncrementalSearch(
    RowsToReturn: number,
    SearchStr: string,
    PlanType: string,
    rlc?
  ): Observable<any> {
    //return of(<RixtremaData><unknown>IncrementalSearchSample.PS);
    const reqHeaders = this.getHeaders();

    const url =
      this.rixtremaBaseUrl +
      '?Action=GETINCREMENTALSEARCHONPLANSAPI&RowsToReturn=' +
      RowsToReturn +
      '&SearchStr=' +
      encodeURIComponent(SearchStr) +
      '&token=' +
      this.sessionId +
      '&PlanType=' +
      PlanType;
    return this.http
      .post(url, {}, { headers: reqHeaders, observe: 'response' })
      .pipe(
        map(res => {
          return this.ProcessResponseData(res);
        }),
        catchError((error: any) => {
          return this.errSvc.handleError(error);
        })
      );
  }

  AckSearch(AckId: string): Observable<any> {
    const Values = { ACK_ID: { id: 'ACK_ID', value: AckId, type: 'ex' } };
    return this.SearchPlans(Values);
  }

  AdvancedSearch(
    zip: string,
    distance: string,
    sponsor: string,
    plantype: string,
    ein: string,
    page = 0,
    rlc?
  ): Observable<any> {
    const Values = {
      Zip: { id: 'ZIP', value: zip, type: 'ex' },
      Distance: { id: 'Distance', value: distance, type: 'ex' },
      Sponsor: {
        id: 'Sponsor_DFE_NAME',
        value: encodeURIComponent(sponsor),
        type: 'like',
      },
      PlanType: { id: 'PlanType', value: plantype, type: 'or' },
      SponsorEIN: { id: 'SponsorEIN', value: ein, type: 'ex' },
    };

    return this.SearchPlans(Values);
  }

  SearchPlans(Values: Object) {
    //return of(<RixtremaData><unknown>SearchPlansSample.PS);
    const reqHeaders = this.getHeaders();

    const url =
      this.rixtremaBaseUrl +
      '?Action=SEARCHPLANSAPI&pagesize=30&OrderBy=TOT_ASSETS_EOY_AMT desc&GetCount=0&token=' +
      this.sessionId +
      '&Values=' +
      JSON.stringify(Values) +
      '&page=0';
    return this.http
      .post(url, {}, { headers: reqHeaders, observe: 'response' })
      .pipe(
        map(res => {
          return this.ProcessResponseData(res);
        }),
        catchError((error: any) => {
          return this.errSvc.handleError(error);
        })
      );
  }

  GetPlans(Values: string, rlc?): Observable<any> {
    //return of(<RixtremaData><unknown>GetPlansSample.PS);
    const reqHeaders = this.getHeaders();

    let url =
      this.rixtremaBaseUrl +
      '?Action=GETPLANSAPI&ACK_IDS=' +
      Values +
      '&token=' +
      this.sessionId;

    url += rlc ? '&HasRLCData=true' : '';

    return this.http
      .get(url, { headers: reqHeaders, observe: 'response' })
      .pipe(
        map(res => {
          return this.ProcessResponseData(res);
        }),
        catchError((error: any) => {
          return this.errSvc.handleError(error);
        })
      );
  }

  ProcessResponseData(response: HttpResponse<Object>): Object {
    if (response.body['PS']['Plan'] != undefined) {
      if (this.server == 'rixtrema') {
        response.body['PS']['Plan'].forEach(element => {
          element['AverageFundFee'] != undefined
            ? (element['AverageFundFee'] = (
                Number(element['AverageFundFee']) * 100
              ).toFixed(6))
            : element['AvgFundFeeBucket'] != undefined
              ? (element['AverageFundFee'] = (
                  Number(element['AvgFundFeeBucket']) * 100
                ).toFixed(6))
              : null;
          element['AdminExpRate'] != undefined
            ? (element['AdminExpRate'] = (
                Number(element['AdminExpRate']) * 100
              ).toFixed(6))
            : null;
          element['AdminexpRate'] != undefined
            ? (element['AdminexpRate'] = (
                Number(element['AdminexpRate']) * 100
              ).toFixed(6))
            : null;
          element['FundFee'] = Number(element['AverageFundFee']);
        });
      } else {
        response.body['PS']['Plan'].forEach(element => {
          element['ExternalFundFees'] != undefined
            ? (element['FundFee'] = (
                Number(element['ExternalFundFees']) * 100
              ).toFixed(6))
            : null;
          element['AdminExpRate'] != undefined
            ? (element['AdminExpRate'] = (
                Number(element['AdminExpRate']) * 100
              ).toFixed(6))
            : null;
        });
      }
    }
    return response.body['PS'];
  }

  planData(plan) {
    this.planSelectedSource.next(plan);
  }
}
