import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SessionStorageService }  from '../../services/session-storage.service'
import { ConsolidatedReportModalComponent } from '../consolidated-report-modal/consolidated-report-modal.component';
import { CustomReportModalComponent } from '../custom-report-modal/custom-report-modal.component';

@Component({
  selector: 'app-print-dropdown',
  templateUrl: './print-dropdown.component.html',
  styleUrls: ['./print-dropdown.component.scss']
})
export class PrintDropdownComponent implements OnInit {
  @Input() params;
  @Input() annuitySelected = false;
  @Input() isExchange = false;
  @Input() assessments = [];
  @Input() isRollover = false;
  @Output() standardReport = new EventEmitter();

  dialogRef;
  rights;
  app;
  customPrintDefinition;

  constructor(
    private dialog: MatDialog,
    private ss: SessionStorageService
  ) { }

  ngOnInit(): void {
    this.app = this.ss.get('currentApp');
    this.rights = this.ss.get('rights');

    let globals = this.ss.get('globals');
    this.customPrintDefinition = globals?.customReportDefinition ? {name: globals.customReportName, definition: JSON.parse(globals?.customReportDefinition)} : null;
  }

  consolidatedReport() {
    if (!this.dialogRef) {
      this.dialogRef = this.dialog.open(ConsolidatedReportModalComponent, { panelClass: 'consolidated-report', data: { profile: this.params.profile}, minWidth: '80vw', minHeight: '95vh', maxHeight: '95vh' });
      this.dialogRef.afterClosed().subscribe(result => {
        if (result && result != "Cancel") {

        }
        this.dialogRef = null;
      });
    }
  }

  customReport() {
    if (!this.dialogRef) {
      this.dialogRef = this.dialog.open(CustomReportModalComponent, { panelClass: 'custom-report', data: { name: 'custom', profile: this.params.profile, reportDef: this.customPrintDefinition}, minWidth: '80vw', minHeight: '95vh', maxHeight: '95vh' });
      this.dialogRef.afterClosed().subscribe(result => {
        if (result && result != "Cancel") {

        }
        this.dialogRef = null;
      });
    }
  }

  generateStandardReport() {
    this.standardReport.emit('standard');
  }

  generateClientReport() {
    this.standardReport.emit('client');
  }

  generateExchangeReport() {
    if(this.annuitySelected) {
      this.standardReport.emit('exchange');
    }
  }

  generateRolloverReport() {
    this.standardReport.emit('rollover');
  }

}
