import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { repReviewKey } from '../rep-review-detail-keys';
import { Router } from '@angular/router';

@Component({
  selector: 'app-rep-history-dialog',
  templateUrl: './rep-history-dialog.component.html',
  styleUrls: ['./rep-history-dialog.component.scss'],
})
export class RepHistoryDialogComponent {
  repReviewKey = repReviewKey;

  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<RepHistoryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  viewHistoryEvent(repDetail) {
    this.router.navigate([`/rep-review/${repDetail.RepID}`]).then(() => {
      window.location.reload();
    });
  }

  cancel() {
    this.dialogRef.close('cancel');
    this.dialogRef.afterClosed().subscribe(() => {
      this.dialogRef = null;
    });
  }
}
