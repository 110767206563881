import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AdminService } from '../../../admin/admin.service';
import { Unit } from '../../../models/admin-models';
import { ReportingService } from '../../reporting.service';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { BridgeActionDetailModalComponent } from './bridge-action-detail-modal/bridge-action-detail-modal.component';

@Component({
  selector: 'app-bridge-action',
  templateUrl: './bridge-action.component.html',
  styleUrls: ['./bridge-action.component.scss'],
})
export class BridgeActionComponent implements OnInit {
  actionUnitsControl = new FormControl();
  units: Unit[] = [] as Unit[];
  filteredUnits;
  selectedUnit;
  bridgeActionData = { data: null, headers: null };
  bridgeActionDetailData = {};
  action = { unit: null };
  displayData = [];
  hiddenItemNum: number;
  loading: boolean;

  constructor(
    private admnSvc: AdminService,
    private rptSvc: ReportingService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.getActionUnits();
  }

  display(item) {
    return `${Object.keys(item)[0]}: ${item[Object.keys(item)[0]]}`;
  }

  setHiddenCols(item) {
    this.displayData.forEach(dataItem => {
      if (!dataItem[item.DataField] && dataItem[item.DataField] !== 0) {
        item.Hidden = true;
      }
    });
  }

  openDetailModal(ev, dataItem) {
    let detailModal = this.dialog.open(BridgeActionDetailModalComponent, {
      data: { dataItem: dataItem },
      width: '80vw',
    });
    detailModal.afterClosed().subscribe(() => {
      detailModal = null;
    });
  }

  getBridgeAction() {
    this.loading = true;
    this.rptSvc.getBridgeAction(this.action.unit).subscribe({
      next: data => {
        this.bridgeActionData = { ...data.results };
        this.displayData = this.bridgeActionData.data.filter(
          element => element.numActions > 0
        );
        this.bridgeActionData.headers.forEach(header => {
          this.setHiddenCols(header);
        });
        this.hiddenItemNum =
          this.bridgeActionData.data.length - this.displayData.length;
        this.loading = false;
      },
      error: error => {
        this.loading = false;
        console.error(error);
      },
    });
  }

  getActionUnits() {
    this.filteredUnits = this.actionUnitsControl.valueChanges.pipe(
      distinctUntilChanged(),
      switchMap(value => {
        if (value && value.length > 2) {
          return this._filterUnits(value);
        }
        return [];
      })
    );
  }

  private _filterUnits(value: string) {
    if (!value) {
      value = '%25';
    }
    const list = this.admnSvc.getUnitList(value).pipe(map(data => data.names));
    return list;
  }
}
