import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { PostTradeService } from '../../post-trade.service';
import { Router } from '@angular/router';
import * as Highcharts from 'highcharts';
import { accountGroupDetailsKey } from './account-grouping-detail-keys';
import { MatSnackBar } from '@angular/material/snack-bar';
import { isEmpty } from 'lodash';
import { CaseManagementService } from '../../../case-management/case-management.service';
import { SessionStorageService } from '../../../services/session-storage.service';
import { MatDialog } from '@angular/material/dialog';
import { AccountGroupingDialogComponent } from '../../account-grouping/account-grouping-dialog/account-grouping-dialog.component';
import { WarnDialogComponent } from '../../../warn-dialog/warn-dialog.component';

@Component({
  selector: 'app-account-grouping-detail',
  templateUrl: './account-grouping-detail.component.html',
  styleUrls: ['./account-grouping-detail.component.scss'],
})
export class AccountGroupingDetailComponent implements OnInit, OnDestroy {
  @Input() groupId;
  rights = [];
  accountGroup;
  isEmpty = isEmpty;
  accountGroupDetailsKey = accountGroupDetailsKey;

  constructor(
    private dialog: MatDialog,
    public fb: FormBuilder,
    public ptSvc: PostTradeService,
    public cms: CaseManagementService,
    public ss: SessionStorageService,
    private route: Router,
    private snacky: MatSnackBar
  ) {}

  Highcharts: typeof Highcharts = Highcharts;
  unsubscribe: Subject<any> = new Subject();
  loading = false;

  ngOnInit(): void {
    this.loading = true;
    this.rights = this.ss.get('rights');
    this.getAccountGroupDetail();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  nav(location) {
    this.route.navigate([`/${location}`]);
  }

  getAccountGroupDetail() {
    this.ptSvc.getAccountGroupingDetail(this.groupId).subscribe(resp => {
      this.accountGroup = resp;
      this.loading = false;
    });
  }

  linkAccount() {
    let dialogRef = this.dialog.open(AccountGroupingDialogComponent, {
      data: {
        searchView: true,
        groupId: this.groupId,
      },
      width: '80vw',
      maxWidth: '80vw',
    });
    dialogRef.afterClosed().subscribe(() => {
      this.getAccountGroupDetail();
      dialogRef = null;
    });
  }

  togglePrimaryAccount(account) {
    const { GroupID, HashedAccountID } = account;
    let dialogRef = this.dialog.open(WarnDialogComponent, {
      panelClass: 'warn-dialog',
      data: {
        headline: 'Assign primary account?',
        content: 'Please confirm as primary account for this group.',
        confirm: 'Confirm',
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'continue') {
        this.ptSvc.makePrimaryAccount(GroupID, HashedAccountID).subscribe(
          () => {
            this.loading = true;
            this.getAccountGroupDetail();
            this.snacky.open(`Primary account assigned!`, 'Close', {
              duration: 3000,
            });
          },
          err => {
            this.snacky.open(
              `Whoops something went wrong, please try again later!`,
              'Close',
              {
                duration: 3000,
              }
            );
            console.error(err);
          }
        );
      }
      dialogRef = null;
    });
  }

  removeAccount(account) {
    const { GroupID, HashedAccountID, PrimaryHashedAccountID } = account;
    if (HashedAccountID === PrimaryHashedAccountID) {
      this.dialog.open(WarnDialogComponent, {
        panelClass: 'warn-dialog',
        data: {
          headline: 'Whoops! You cannot remove the primary account.',
          content:
            'Please assign a different primary account to remove this account',
          confirm: 'Okay',
          hideCancel: true,
        },
      });
    } else {
      let dialogRef = this.dialog.open(WarnDialogComponent, {
        panelClass: 'warn-dialog',
        data: {
          headline: 'Remove Account from Group?',
          content: 'Please confirm account removal.',
          confirm: 'Remove',
        },
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result === 'continue') {
          this.ptSvc.removeAccount(GroupID, HashedAccountID).subscribe(
            () => {
              this.loading = true;
              this.getAccountGroupDetail();
              this.snacky.open(`Account removed!`, 'Close', {
                duration: 3000,
              });
            },
            err => {
              this.snacky.open(
                `Whoops something went wrong, please try again later!`,
                'Close',
                {
                  duration: 3000,
                }
              );
              console.error(err);
            }
          );
        }
        dialogRef = null;
      });
    }
  }
}
