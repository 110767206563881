import { Component, OnInit } from '@angular/core';
import { SuggestionEngineService } from '../../../suggestion-engine/suggestion-engine.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-topOpps',
  templateUrl: './topOpps.component.html',
  styleUrls: ['./topOpps.component.scss']
})
export class TopOppsComponent implements OnInit {
  opportunities: any[];
  state: string = 'loading';

  constructor(private ses: SuggestionEngineService, private router: Router) { }

  ngOnInit() {
    this.ses.getTopOpps().subscribe(x => {
      if (x.ClientOpportunities.Clients && x.ClientOpportunities.Clients.length) {
        this.opportunities = x.ClientOpportunities.Clients.map (z => {
          return { id: z.id, name: z.Name, suggestions: z.Bridges};
        });
        this.state = 'loaded';
      } else if (!x.ClientOpportunities.Clients) {
        this.state = 'none';
      }
    });
  }

  goToProfile(ev, id) {
    this.router.navigate([`/se/client/${id}`]);
  }

}
