import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { summaryData } from "./summary-data";
import { RightBridgeApiService } from "../../services/right-bridge-api.service";
import { SessionStorageService }  from '../../services/session-storage.service'

@Component({
  selector: 'app-summary-page',
  templateUrl: './summary-page.component.html',
  styleUrls: ['./summary-page.component.scss']
})
export class SummaryPageComponent implements OnInit {
  id: string;
  data;
  loading: boolean = false;

  constructor(private rbs: RightBridgeApiService, private params: ActivatedRoute, private ss: SessionStorageService) { }

  ngOnInit() {
    this.data = summaryData;
    this.params.params.subscribe(params => {
      this.id = params.id;
    });
  }

}
