import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { accountReviewKey } from '../account-review-detail-keys';
import { Router } from '@angular/router';

@Component({
  selector: 'app-account-history-dialog',
  templateUrl: './account-history-dialog.component.html',
  styleUrls: ['./account-history-dialog.component.scss'],
})
export class AccountHistoryDialogComponent {
  accountReviewKey = accountReviewKey;

  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<AccountHistoryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  viewHistoryEvent(accountDetail) {
    this.router
      .navigate([`/account-review/${accountDetail.AccountID}`])
      .then(() => {
        window.location.reload();
      });
  }

  cancel() {
    this.dialogRef.close('cancel');
    this.dialogRef.afterClosed().subscribe(() => {
      this.dialogRef = null;
    });
  }
}
