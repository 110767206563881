import { Component, OnInit } from '@angular/core';
import { ReportingService } from '../../reporting.service';

@Component({
  selector: 'app-load-history',
  templateUrl: './load-history.component.html',
  styleUrls: ['./load-history.component.scss'],
})
export class LoadHistoryComponent implements OnInit {
  loadHistoryData;

  constructor(private rptSvc: ReportingService) {}

  ngOnInit(): void {
    this.getHistory();
  }

  getHistory() {
    this.rptSvc.getLoadHistory().subscribe({
      next: data => {
        this.loadHistoryData = { ...data.results };
      },
      error: error => {
        console.error(error);
      },
    });
  }
}
