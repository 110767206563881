import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { RightBridgeApiService } from '../../services/right-bridge-api.service';
@Component({
  selector: 'app-pershing-print',
  templateUrl: './pershing-print.component.html',
  styleUrls: ['./pershing-print.component.scss']
})
export class PershingPrintComponent implements OnInit {
  @Input() profile;
  @Input() silentPrint;
  @Input() customDefinition;
  @Input() headline;
  @Input() content;
  @Input() accountTypes;
  @Output() closeDialog = new EventEmitter();

  loading = false;
  rights: string[];
  generating = false;

  Client = {BackOfficeRRCode: null, BackOfficeOfficeCode: null, BackOfficeAccountTypeCode: null};

  constructor(
    private rbs: RightBridgeApiService,
  ) { }

  ngOnInit(): void {
  }

  cancel() {
    this.closeDialog.emit('cancel');
  }

  generateReport() {
    this.loading = true;
    let saveData = {};
    Object.keys(this.Client).forEach(x => {
      saveData[`Client.${x}`] = this.Client[x];
    });
    
    this.rbs.saveProfile(this.profile, saveData, false).subscribe(x => {
      this.closeDialog.emit('continue');
      this.loading = false;
    });
  }
}
