<!-- <mat-form-field>
  <mat-label>Change Column Visibility</mat-label>
  <mat-select multiple (selectionChange)="selectionChange($event)" [(ngModel)]="updatedColumns">
    <mat-option *ngFor="let col of columns" [value]="col.DataField">{{col.Label}}</mat-option>
  </mat-select>
</mat-form-field> -->

<button
  mat-button
  class="grid-column-button"
  [matMenuTriggerFor]="menu"
  color="primary"
>
  <mat-icon>view_column</mat-icon>Column Options
</button>
<mat-menu #menu="matMenu" class="column-menu">
  <button
    (click)="selectionChange($event, col.DataField)"
    mat-menu-item
    *ngFor="let col of columns"
    [value]="col.DataField"
    [ngClass]="{ selected: updatedColumns.includes(col.DataField) }"
  >
    <mat-icon class="empty">check_box_outline_blank</mat-icon>
    <mat-icon class="checked">check_box</mat-icon>
    {{ col.Label }}
  </button>
</mat-menu>
