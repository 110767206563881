import { Injectable } from '@angular/core';

@Injectable()
export class TagsEvaluationService {

  constructor() { }

  evalTags(text) {
    var result = "";
    while (text.length > 0) {
      var elem = text.enclosedText('[', ']');
      var endElem = text.enclosedText('[/', ']');
      var contents = text.enclosedText(']', '[/');
      var attrib1 = "";
      var attrib2 = "";
      var attrib3 = "";
      var attribVal1 = "";
      var attribVal2 = "";
      var attribVal3 = "";
      var isStyle = false;
      var isLink = false;
      var isBr = false;
      var isSpace = false;
      if (elem.iEquals("/br") && endElem.iEquals("br")) {
        isBr = true;
      }
      else if (elem.iEquals("/space") && endElem.iEquals("space")) {
        isSpace = true;
      }
      else if (contents || contents.length >= 0) {
        if (elem.iStartsWith('style') && endElem.iStartsWith('style')) {
          attrib1 = "name=";
          isStyle = true;
        }
        else if (elem.iStartsWith('link') && endElem.iStartsWith('link')) {
          attrib1 = "href=";
          attrib2 = "hrefvalue=";
          attrib3 = "display=";
          isLink = true;
        }
        var attribPos = attrib1.length > 0 ? elem.iIndexOf(attrib1) : -1;
        if (attribPos >= 0) {
          attribVal1 = elem.enclosedText("'", "'", attribPos);
          if (attribVal1.length == 0) {
            attribVal1 = elem.enclosedText('"', '"', attribPos);
          }
        }
        attribPos = attrib2.length > 0 ? elem.iIndexOf(attrib2) : -1;
        if (attribPos >= 0) {
          attribVal2 = elem.enclosedText("'", "'", attribPos);
          if (attribVal2.length == 0) {
            attribVal2 = elem.enclosedText('"', '"', attribPos);
          }
        }
        attribPos = attrib3.length > 0 ? elem.iIndexOf(attrib3) : -1;
        if (attribPos >= 0) {
          attribVal3 = elem.enclosedText("'", "'", attribPos);
          if (attribVal3.length == 0) {
            attribVal3 = elem.enclosedText('"', '"', attribPos);
          }
        }
        if (elem.iStartsWith('inferred')) {
          attrib1 = "name=";
          attribVal1 = "inferred";
          isStyle = true;
          contents += " (inferred)";
        }
      }
      if (attribVal1.length > 0 || isBr == true || isSpace == true) {
        result += "<span>" + text.upToEnclosedText("[");
        if (isStyle == true) {
          result += "<span class='" + attribVal1 + "'>" + contents + "</span>";
        }
        else if (isLink == true) {
          var attr1 = attribVal1.split(',');
          var attr2 = attribVal2.split(',');
          var attr3 = attribVal3.split(',');
          if (1 == attr1.length && contents.length > 0) {
            result += "<a href='" + attribVal1 + "' target='_blank'>" + contents + "</a>";
          }
          else if (attribVal2.length != 0 && attr2.length == attr3.length && (attr1.length == 1 || (attr1.length == attr2.length))) {
            for (var ii = 0; ii < attr2.length; ++ii) {
              attr2[ii] = attr2[ii].trim();
              attr3[ii] = attr3[ii].trim();
              var href = ((attr1.length == 1) ? attr1[0] : attr1[ii]).replace("#VALUE#", attr2[ii]);
              result += "<a href='" + href + "' target='_blank'>" + attr3[ii] + "</a>";
              if (ii < (attr2.length - 1)) {
                result += ", ";
              }
            }
          }
        }
        else if (isBr == true) {
          result += "<br />";
        }
        else if (isSpace == true) {
          result += "<span style='padding-right:5px'></span>";
        }
        result += "</span>";
        text = text.afterEnclosedText("[/", "]");
      }
      else if (isLink == true) {
        text = text.afterEnclosedText("[/", "]");
      }
      else {
        result += text;
        text = "";
      }
    }
    return result;
  }

}

declare global {
  interface String {
    iIndexOf(str: string, startPos: number): number;
    iEquals(str: string): Boolean;
    iStartsWith(str: string, startPos: number): Boolean;
    enclosedText(startDelim: string, endDelim: string, startPos: number): string;
    afterEnclosedText(startDelim: string, endDelim: string, startPos: number): string;
    upToEnclosedText(delim: string): string;
  }
}

String.prototype.iIndexOf = function (str, startPos) { startPos = startPos ? startPos : 0; return this.toLowerCase().indexOf(String(str).toLowerCase(), startPos); }
String.prototype.iEquals = function (str) { return (this.length == String(str).length) && (this.length == 0 || this.toLowerCase() == String(str).toLowerCase()); }
String.prototype.iStartsWith = function (str, startPos) { startPos = startPos ? startPos : 0; return this.toLowerCase().indexOf(String(str).toLowerCase(), startPos) == 0; }
String.prototype.enclosedText = function (startDelim, endDelim, startPos) { endDelim = endDelim ? endDelim : startDelim; startPos = startPos ? startPos : 0; var retVal = ""; startPos = this.indexOf(startDelim, startPos); if (startPos >= 0) { var endPos = this.indexOf(endDelim, startPos + startDelim.length); retVal = this.substr(startPos + startDelim.length, endPos - 1 - (startPos - 1 + startDelim.length)).trim(); } return retVal; }
String.prototype.afterEnclosedText = function (startDelim, endDelim, startPos) { endDelim = endDelim ? endDelim : startDelim; startPos = startPos ? startPos : 0; var retVal = ""; startPos = this.indexOf(startDelim, startPos); if (startPos >= 0) { var endPos = this.indexOf(endDelim, startPos + startDelim.length); retVal = this.substr(endPos + endDelim.length); } return retVal; }
String.prototype.upToEnclosedText = function (delim) { var pos = this.indexOf(delim); var retVal = ""; if (pos >= 0) { retVal = this.substr(0, pos); } return retVal; }