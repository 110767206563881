import { Component, HostBinding, ChangeDetectorRef } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-form-radio',
  styleUrls: ['form-radio.component.scss'],
  template: `
    <label
      class="section"
      class="w-100"
      [class.mat-error]="formControl.errors && formControl.touched"
      *ngIf="props.label"
      ><span [innerHTML]="props.label"></span
      ><sup *ngIf="props.required">*</sup>
      <mat-icon
        *ngIf="props.help"
        class="help-icon"
        matTooltip="{{ to.help }}"
        [matTooltipPosition]="'above'"
        [matTooltipClass]="'rb-tooltip'"
        >help</mat-icon
      ></label
    >
    <mat-radio-group
      class="radio-group radio-group-explanation col-12"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [required]="props.required"
    >
      <mat-radio-button
        class="radio-button"
        *ngFor="let opt of to.options"
        [value]="opt.value"
        >{{ opt.label }}</mat-radio-button
      >
      <mat-error *ngIf="formControl.hasError('required') && formControl.touched"
        >This field is required</mat-error
      >
      <mat-error
        *ngIf="formControl.hasError('regRequired') && formControl.touched"
        >This field is needed for suitability review. You can opt out below if
        the applicant refuses to provide the information.</mat-error
      >
    </mat-radio-group>
  `,
})
export class FormlyRadioComponent extends FieldType {
  @HostBinding('class') classes = 'bottom-margin';
}
