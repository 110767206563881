import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../dashboard.service';
import { Platform } from '@angular/cdk/platform';
import { saveAs } from '@progress/kendo-file-saver';
import { EnvironmentService } from '../../../services/environment.service';
// import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-annuities-by-connector',
  templateUrl: './annuities-by-connector.component.html',
  styleUrls: ['./annuities-by-connector.component.scss']
})
export class AnnuitiesByConnectorComponent implements OnInit {
  private environment;
  public name : string;
  private baseUrl;

  constructor(
    private dbs: DashboardService,
    private pltfrm: Platform,
    private envSvc: EnvironmentService
  ) {

  }

  ngOnInit(): void {
    this.environment = this.envSvc.get();
    this.baseUrl = this.environment.apiBase;
  }

  download(): void {
    let iosSafari = this.pltfrm.IOS || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

    this.dbs.getDashboardAnnuitiesCsv("annuitiesbyconnector", null, null, `name=${this.name}`).subscribe(data => {
      let filename = `annuities_by_connector.csv`;

      let fileData;
      const reader = new FileReader();
      reader.readAsDataURL(data);
      reader.onloadend = () => {
        fileData = reader.result;
        saveAs(fileData, filename, {
          proxyURL: `${this.baseUrl}/util/proxy`,
          proxyTarget: '_self',
          forceProxy: iosSafari
        });
        // this.loading = false;
      };
    }, e => {

    });
  }

}
