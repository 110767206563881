<div class="rank-order-container">
  @if (props.label) {
    <label
      class="section"
      class="w-100"
      [class.mat-error]="
        formControl.errors && formControl.touched && this.ordered.length <= 0
      "
      ><span [innerHTML]="props.label"></span>
      @if (props.required) {
        <sup>*</sup>
      }
      @if (props.help) {
        <mat-icon
          class="help-icon"
          matTooltip="{{ to.help }}"
          [matTooltipPosition]="'above'"
          [matTooltipClass]="'rb-tooltip'"
          >help</mat-icon
        >
      }
    </label>
  }
  @if (props.prompt) {
    <p
      class="explanation"
      [class.mat-error]="
        formControl.errors && formControl.touched && this.ordered.length <= 0
      "
    >
      <span [innerHTML]="props.prompt"></span>
      @if (props.help && !to.label && !to.placeholder) {
        <mat-icon
          class="help-icon"
          matTooltip="{{ to.help }}"
          [matTooltipPosition]="'above'"
          [matTooltipClass]="'rb-tooltip'"
        >
          help
        </mat-icon>
      }
    </p>
  }
  @if (props.placeholder) {
    <p
      class="italic"
      [class.mat-error]="
        formControl.errors && formControl.touched && this.ordered.length <= 0
      "
    >
      <span [innerHTML]="props.placeholder"></span>
      @if (props.required && !to.label) {
        <span> *</span>
      }
      @if (props.help && !to.label && !to.prompt) {
        <mat-icon
          class="help-icon"
          matTooltip="{{ to.help }}"
          [matTooltipPosition]="'above'"
          [matTooltipClass]="'rb-tooltip'"
        >
          help
        </mat-icon>
      }
    </p>
  }
  <div class="rank-order-component">
    <div
      class="list-container"
      [class.mat-error]="
        formControl.errors && formControl.touched && this.ordered.length <= 0
      "
    >
      <h5>Unranked</h5>

      <div
        cdkDropList
        #unorderedList="cdkDropList"
        [cdkDropListData]="unordered"
        [cdkDropListConnectedTo]="[orderedList]"
        class="list unranked"
        (cdkDropListDropped)="drop($event)"
      >
        @for (item of unordered; track item.value) {
          <div class="list-item-box" cdkDrag>
            <span class="list-item-name">
              <mat-icon class="mr-2">drag_handle</mat-icon>
              {{ item.label }}
            </span>
            <button
              mat-icon-button
              color="primary"
              (click)="addItem(item)"
              matTooltip="Add to ranked list"
            >
              <mat-icon>add</mat-icon>
            </button>
          </div>
        }
      </div>
    </div>

    <div
      class="list-container"
      [class.mat-error]="
        formControl.errors && formControl.touched && this.ordered.length <= 0
      "
    >
      <h5>Ranked</h5>

      <div
        cdkDropList
        #orderedList="cdkDropList"
        [cdkDropListData]="ordered"
        [cdkDropListConnectedTo]="[unorderedList]"
        class="list"
        (cdkDropListDropped)="drop($event)"
      >
        @if (ordered.length === 0) {
          <p>Drag & order options here.</p>
        }
        @for (item of ordered; track item.value; let idx = $index) {
          <div class="list-item-box" cdkDrag>
            <span class="list-item-name">
              <span class="ranking mr-2"> ({{ idx + 1 }}) </span>
              {{ item.label }}
            </span>
            <button
              mat-icon-button
              color="warn"
              (click)="removeItem(item)"
              matTooltip="Remove Annuity"
            >
              <mat-icon>close</mat-icon>
            </button>
          </div>
        }
      </div>
    </div>
  </div>
</div>
