import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, FormControl } from '@angular/forms';
import { RightBridgeApiService } from '../../services/right-bridge-api.service';
import { SessionStorageService } from '../../services/session-storage.service';
import { ActivatedRoute } from '@angular/router';
import {
  GridComponent,
  DataBindingDirective,
  FilterService,
} from '@progress/kendo-angular-grid';
import {
  SortDescriptor,
  process,
  State,
  GroupDescriptor,
  CompositeFilterDescriptor,
  filterBy,
} from '@progress/kendo-data-query';
import { UserPreferencesService } from '../../services/user-preferences.service';
import { CustomFiltersService } from '../../services/custom-filters.service';
import { getFilterMenuText } from '../../lib/grid-sort-text';

@Component({
  selector: 'app-fa',
  templateUrl: './fa.component.html',
  styleUrls: ['./fa.component.scss'],
  // encapsulation: ViewEncapsulation.None,
})
export class FaComponent implements OnInit {
  @ViewChild(DataBindingDirective) directive;
  @ViewChild(GridComponent) faGrid: GridComponent;

  public getFilterMenuText = getFilterMenuText;
  public gridData: any;
  public ogData: any;
  public gridColumns: any[] = [];
  public pageSize = 25;
  public pageSizeOptions = [25, 50, 75, 100];
  public skip = 0;
  public sort: SortDescriptor[] = [];
  public filter: CompositeFilterDescriptor = { logic: 'and', filters: [] };
  public group: GroupDescriptor[] = [];
  public hidden: string[] = [];
  public state: State = {
    skip: this.skip,
    take: this.pageSize,
  };
  public defaultValue: string = undefined;
  form = new UntypedFormGroup({
    defaultsControl: new FormControl(),
  });
  public defaults = [
    {
      label: 'Highest First Year Rate',
      group: [{ field: 'FA_ProductRate$Year1Rate', dir: 'desc' }],
      sort: [{ field: 'FA_ProductRate$GuaranteeLength', dir: 'asc' }],
    },
    {
      label: '3 Year Product Term - Highest Yield to Surrender',
      sort: [{ field: 'FA_ProductRate$CAGR', dir: 'desc' }],
      filter: [{ field: 'FA_Product$ProductTerm', operator: 'eq', value: 3 }],
    },
    {
      label: '4 Year Product Term - Highest Yield to Surrender',
      sort: [{ field: 'FA_ProductRate$CAGR', dir: 'desc' }],
      filter: [{ field: 'FA_Product$ProductTerm', operator: 'eq', value: 4 }],
    },
    {
      label: '5 Year Product Term - Highest Yield to Surrender',
      sort: [{ field: 'FA_ProductRate$CAGR', dir: 'desc' }],
      filter: [{ field: 'FA_Product$ProductTerm', operator: 'eq', value: 5 }],
    },
    {
      label: '6 Year Product Term - Highest Yield to Surrender',
      sort: [{ field: 'FA_ProductRate$CAGR', dir: 'desc' }],
      filter: [{ field: 'FA_Product$ProductTerm', operator: 'eq', value: 6 }],
    },
    {
      label: '7 Year Product Term - Highest Yield to Surrender',
      sort: [{ field: 'FA_ProductRate$CAGR', dir: 'desc' }],
      filter: [{ field: 'FA_Product$ProductTerm', operator: 'eq', value: 7 }],
    },
    {
      label: '8 Year Product Term - Highest Yield to Surrender',
      sort: [{ field: 'FA_ProductRate$CAGR', dir: 'desc' }],
      filter: [{ field: 'FA_Product$ProductTerm', operator: 'eq', value: 8 }],
    },
    {
      label: '9 Year Product Term - Highest Yield to Surrender',
      sort: [{ field: 'FA_ProductRate$CAGR', dir: 'desc' }],
      filter: [{ field: 'FA_Product$ProductTerm', operator: 'eq', value: 9 }],
    },
    {
      label: '10 Year Product Term - Highest Yield to Surrender',
      sort: [{ field: 'FA_ProductRate$CAGR', dir: 'desc' }],
      filter: [{ field: 'FA_Product$ProductTerm', operator: 'eq', value: 10 }],
    },
  ];

  columnWidths = {
    FA_Product$Carrier: 200,
    FA_Product$Product: 200,
    FA_Product$Version: 200,
  };

  cusip: string;
  cusipTitle;
  loading = true;
  loadedUserPrefs = false;
  visibleColumns: any[] = [];
  filterData = {};
  ogFilterData = {};

  constructor(
    private rbs: RightBridgeApiService,
    private route: ActivatedRoute,
    private prefSrvc: UserPreferencesService,
    private cstmFltrSrvc: CustomFiltersService,
    private ss: SessionStorageService
  ) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params.cusip) {
        this.cusip = params.cusip;
      }
    });

    this.getGridData();
  }

  gridHeight() {
    return window.innerHeight - 100;
  }

  getGridData() {
    this.rbs.FiaGridData('fa').subscribe(res => {
      const gd = res.results;
      gd.headers.forEach(x => {
        switch (x.RespVis) {
          case 'xs':
            x.RespVis = '';
            break;
          case 'sm':
            x.RespVis = '(min-width: 700px)';
            break;
          case 'md':
            x.RespVis = '(min-width: 1100px)';
            break;
          case 'lg':
            x.RespVis = '(min-width: 1200px)';
            break;
          case 'xl':
            x.RespVis = '(min-width: 1500px)';
            break;
        }
        if (Object.keys(this.columnWidths).includes(x.DataField)) {
          x.Width = this.columnWidths[x.DataField];
        } else if (!Object.keys(this.columnWidths).includes(x.DataField)) {
          x.Width = 100;
        }
      });
      this.gridColumns = gd.headers;

      this.ogData = gd.data;
      this.gridData = gd.data;
      this.buildFilterOptions();

      if (this.cusip && this.cusip.length) {
        this.cusipFilter(this.cusip);
        // this.cusipTitle = this.gridData.data[0];
      }

      this.getUserDefinedColumns();
      this.loading = false;
    });
  }

  buildFilterOptions() {
    this.filterData = this.cstmFltrSrvc.buildColumnFilterOptions(
      this.ogData,
      this.gridColumns
    );
    this.ogFilterData = this.cstmFltrSrvc.buildColumnFilterOptions(
      this.ogData,
      this.gridColumns
    );
  }

  setHiddenColumns() {
    setTimeout(() => {
      const columns = [...this.gridColumns];
      if (!this.loadedUserPrefs) {
        columns.forEach(x => {
          if (x.Hidden) {
            this.hidden.push(x.DataField);
          }
        });
        this.gridColumns = [...columns];
      } else {
        columns.forEach(x => {
          if (this.hidden.includes(x.DataField)) {
            x.Hidden = true;
          } else {
            x.Hidden = false;
          }
        });
        this.gridColumns = [...columns];
      }

      this.hidden.forEach(x => {
        this.hideColumn(x);
      });
    });
  }

  public groupChange(groups: GroupDescriptor[]): void {
    this.group = groups;
    this.state = {
      skip: this.skip,
      take: this.pageSize,
      group: this.group,
      sort: this.sort,
      filter: this.filter,
    };

    this.gridData = process(this.ogData, this.state);
  }

  updateHidden(ev) {
    this.hidden = ev.value;
    this.setStorage();
  }

  isHidden(columnName: string): boolean {
    return this.hidden.indexOf(columnName) > -1;
  }

  hideColumn(columnName: string): void {
    if (!this.isHidden(columnName)) {
      this.hidden.push(columnName);
      this.setStorage();
    }
  }

  toggleColumn(columnName: string): void {
    const hiddenColumns = this.hidden;

    if (!this.isHidden(columnName)) {
      hiddenColumns.push(columnName);
    } else {
      hiddenColumns.splice(hiddenColumns.indexOf(columnName), 1);
    }
  }

  showColumn(columnName: string): void {
    const hiddenColumns = this.hidden;

    if (this.isHidden(columnName)) {
      hiddenColumns.push(columnName);
    } else {
      hiddenColumns.splice(hiddenColumns.indexOf(columnName), 1);
    }
  }

  externalColumnsUpdated(ev) {
    // This is a hack to stop the grid from scrolling to the right
    const gridContentScroll = document.querySelector('.k-grid-content');
    const oldScrollPosition = gridContentScroll.scrollLeft;

    const headerWrapContentScroll = document.querySelector(
      '.k-grid-header-wrap'
    );
    const headerWrapPosition = headerWrapContentScroll.scrollLeft;
    setTimeout(() => {
      gridContentScroll.scrollLeft = oldScrollPosition;
      headerWrapContentScroll.scrollLeft = headerWrapPosition;
    });

    const fullList = this.gridColumns.map(x => {
      return x.DataField;
    });
    const hidden = fullList.filter(y => !ev.includes(y));

    this.updateHidden({ value: hidden });
    this.saveUserDefinedColumns();
  }

  sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.state = {
      skip: this.skip,
      take: this.pageSize,
      group: this.group,
      sort: this.sort,
      filter: this.filter,
    };

    this.gridData = process(this.ogData, this.state);
  }

  setStorage() {
    this.ss.set('hiddenFaColumns', this.hidden);
  }

  public cusipFilter(cusip) {
    this.filter.filters = [
      {
        field: 'FA_Product$ID',
        operator: 'eq',
        value: cusip,
      },
    ];
    this.gridData = process(this.ogData, { filter: this.filter });
  }

  useDefault(ev, clearControl = false) {
    this.cusip = null;
    if (ev.value == 'clear') {
      this.group = [];
      if (this.filter) {
        this.filter['filters'] = [];
      }
      this.sort = [];
      this.skip = 0;
      if (clearControl) {
        this.form.controls.defaultsControl.reset();
        this.buildFilterOptions();
      }
      this.directive.skip = 0;

      if (!this.directive.state.filter) {
        this.directive.state.filter = { filters: [] };
      }
      this.directive.state.filter.filters = [];
    } else {
      this.useDefault({ value: 'clear' });
      const preset = this.defaults.find(x => x.label == ev.value);
      if (preset.filter) {
        if (!this.filter) {
          this.filter = { filters: [], logic: 'and' };
        }
        this.filter['filters'] = preset.filter;
        this.directive.state.filter.filters = preset.filter;
      } else {
        this.filter['filters'] = [];
        this.directive.state.filter.filters = [];
      }

      if (preset.sort) {
        this.sort = preset.sort as [];
      } else {
        this.sort = [];
      }

      if (preset.group) {
        this.group = preset.group as [];
      } else {
        this.group = [];
      }
    }

    this.state = {
      skip: this.skip,
      take: this.pageSize,
      group: this.group,
      sort: this.sort,
      filter: this.filter,
    };

    this.gridData = process(this.ogData, this.state);
  }

  exportToExcel(grid: GridComponent): void {
    grid.saveAsExcel();
  }

  public excelData = () => {
    const fullData = this.ogData.map(a => ({ ...a }));
    fullData.forEach(el => {
      Object.keys(el).forEach(x => {
        if (el[x] == -9.99 || el[x] == -999) {
          el[x] = 'N/A';
        }
      });
    });

    return process(fullData, {});
  };

  getUserDefinedColumns() {
    const fullList = this.gridColumns.map(x => {
      return x.DataField;
    });

    this.prefSrvc.getColumns().subscribe(x => {
      if (x && x.fa && x.fa.length > 0) {
        this.loadedUserPrefs = true;
        const hidden = fullList.filter(y => x && x.fa && !x.fa.includes(y));
        this.gridColumns.map(col => {
          if (!x.fa.includes(col.DataField)) {
            col.Hidden = true;
          }
        });

        this.updateHidden({ value: hidden });
      }
      this.setHiddenColumns();
    });
  }

  saveUserDefinedColumns() {
    const fullList = this.gridColumns.map(x => {
      return x.DataField;
    });
    const visible = fullList.filter(x => !this.hidden.includes(x));

    this.prefSrvc.saveColumns({ fa: visible }).subscribe();
  }

  public filterValueChange(
    values: any[],
    field,
    filterService: FilterService
  ): void {
    filterService.filter({
      filters: values.map(value => ({
        field: field,
        operator: 'eq',
        value,
      })),
      logic: 'or',
    });
  }

  public onFilterChange(searchTerm: string, dataSet): void {
    if (!searchTerm) {
      this.filterData[dataSet] = this.ogFilterData[dataSet];
    } else {
      this.filterData[dataSet] = this.filterData[dataSet].filter(dataPoint =>
        dataPoint.value.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    const passedFilterNames = [];
    const passedFilterDatas = {};

    // Extract passed filter data for trickle down column filter option narrowing
    filter.filters.map(filterObj => {
      const filterName = filterObj['filters'][0]['field'];
      passedFilterNames.push(filterName);
      passedFilterDatas[filterName] = this.filterData[filterName];
    });

    this.gridData = filterBy(this.ogData, filter);
    this.filterData = this.cstmFltrSrvc.buildColumnFilterOptions(
      filterBy(this.ogData, filter),
      this.gridColumns
    );

    // Replace filter options with trickled down options
    passedFilterNames.map(filterName => {
      this.filterData[filterName] = passedFilterDatas[filterName];
    });
  }
}
