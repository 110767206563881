<mat-dialog-header>
  <h1 [class]="{ complete: loadingVal === 100 }">{{ headline }}</h1>
</mat-dialog-header>
<mat-dialog-content class="dialog-container col-12">
  <div class="inner row col-12 mb-3" [innerHTML]="content"></div>
  <mat-progress-bar
    mode="determinate"
    [value]="loadingVal"
    [color]="progressColor()"
  ></mat-progress-bar>
</mat-dialog-content>
