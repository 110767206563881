import { Component, OnInit } from '@angular/core';
import { SessionStorageService } from '../services/session-storage.service';
import { VERSION } from '../../environments/version';

@Component({
  selector: 'app-footer',
  templateUrl: './app-footer.component.html',
  styleUrls: ['./app-footer.component.scss'],
})
export class AppFooterComponent implements OnInit {
  engine: string;
  ui: string;
  kb: string;
  year: string = new Date().getFullYear().toString();
  globals: any;
  disclaimer =
    'The RightBRIDGE&reg; system presents suggestions for educational purposes, not recommendations or solicitations.The RightBRIDGE&reg; Investment Wizard and CapitalRock do not offer investment or financial advice and using RightBRIDGE&reg; is not a substitute for advisor due diligence. Any advice or recommendation are the sole responsibility of the advisor and not that of CapitalRock. RightBRIDGE&reg; is intended to be an analytic tool and is not a replacement for a product prospectus. Refer to each product prospectus(when applicable) for specific product information.';
  date = new Date();
  uiVer: string = VERSION.version + '-' + this.date.toISOString();

  constructor(private ss: SessionStorageService) {}

  ngOnInit() {
    this.ss.storageChangeExternal.subscribe(chng => {
      if (chng.key === 'globals') {
        const localGlobals = this.ss.get('globals');
        if (localGlobals) {
          this.globals = localGlobals ? localGlobals : {};
          this.engine = this.globals ? this.globals.Engine : null;
          this.ui =
            this.globals && this.globals.uiconfig
              ? this.globals.uiconfig
              : null;
          this.kb = this.globals && this.globals.KB ? this.globals.KB : null;
          this.disclaimer =
            this.globals &&
            this.globals.disclaimers &&
            Array.isArray(this.globals.disclaimers)
              ? this.globals.disclaimers.find(x => x.name == 'standard').copy
              : this.disclaimer;

          if (this.disclaimer.includes('{year}')) {
            this.disclaimer = this.disclaimer.replace('{year}', this.year);
          }
        }
      }
    });
  }
}
