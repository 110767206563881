import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AdminService } from '../../../admin/admin.service';
import { Application, Unit } from '../../../models/admin-models';
import { distinctUntilChanged, map, switchMap, startWith } from 'rxjs/operators';
import { Platform } from '@angular/cdk/platform';
import { ReportingService } from '../../reporting.service';
import { saveAs } from '@progress/kendo-file-saver';
import { SessionStorageService }  from '../../../services/session-storage.service'

@Component({
  selector: 'app-active-users',
  templateUrl: './active-users.component.html',
  styleUrls: ['./active-users.component.scss']
})
export class ActiveUsersComponent implements OnInit {
  @Input() baseUrl;

  unitsControl = new FormControl();
  units: Unit[] = [] as Unit[];
  filteredUnits;
  selectedUnit;
  reportTypes = [
    { value: 'csv', label: 'CSV'},
    { value: 'json', label: 'JSON'},
  ];
  selectedType;
  loading = false;



  constructor(
    private admnSvc: AdminService,
    private pltfrm: Platform,
    private ss: SessionStorageService
  ) { }

  ngOnInit(): void {
    this.getUnits();
  }

  getUnits() {
    this.filteredUnits = this.unitsControl.valueChanges
      .pipe(
        startWith('%'),
        distinctUntilChanged(),
        switchMap(value => {
          if(value.length > 2) {
            return this._filterUnits(value);
          } return [];
        })
      );
  }

  getUnitActiveUsers() {
    this.loading = true;
    let iosSafari = this.pltfrm.IOS || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

    this.admnSvc.getUnitActiveUsersReport(this.selectedUnit, this.selectedType).subscribe(data => {
      let filename = `${this.selectedUnit}_${this.selectedType}_report.${this.selectedType}`;

      let fileData;
      const reader = new FileReader();
      reader.readAsDataURL(data);
      reader.onloadend = () => {
        fileData = reader.result;
        saveAs(fileData, filename, {
          proxyURL: `${this.baseUrl}/util/proxy`,
          proxyTarget: '_self',
          forceProxy: iosSafari
        });
      };
      this.loading = false;
    });
  }

  private _filterUnits(value: string) {
    if (!value) {
      value = '%25'
    }
    const list = this.admnSvc.getUnitList(value).pipe(map(data => data.names));
    return list;
  }
}
