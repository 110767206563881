import { Component, Inject, ViewEncapsulation, OnInit } from '@angular/core';
import { PostTradeService } from '../../post-trade.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormGroup, FormGroup, FormControl } from '@angular/forms';
import { UniversalSearchService } from '../../../universal-search/services/universal-search.service';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-account-grouping-dialog',
  templateUrl: './account-grouping-dialog.component.html',
  styleUrls: ['./account-grouping-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AccountGroupingDialogComponent implements OnInit {
  recommendedAccounts;
  selectedAccount = '';
  loading = false;
  tableView = true;
  accountGroupForm: FormGroup;
  tyepaheadOptions = [];
  typeaheadControl = new FormControl<any>('');
  filteredOptions: Observable<any>;

  constructor(
    public dialogRef: MatDialogRef<AccountGroupingDialogComponent>,
    private ptSvc: PostTradeService,
    private usServ: UniversalSearchService,
    private snacky: MatSnackBar,
    private route: Router,
    private location: Location,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit() {
    if (!this.data.createGroup) {
      if (!this.data.searchView) {
        this.getSuggestedAccounts();
      }
    } else {
      const formGroup = {};
      formGroup['description'] = new FormControl();
      formGroup['unit'] = new FormControl();
      this.accountGroupForm = new UntypedFormGroup(formGroup);
      this.getFilteredOptions();
    }

    if (this.data.searchView) {
      this.tableView = false;
      const formGroup = {};
      formGroup['HashedAccountID'] = new FormControl();
      formGroup['OwnerFirstName'] = new FormControl();
      formGroup['OwnerLastName'] = new FormControl();
      formGroup['HashedSSN'] = new FormControl();
      formGroup['ROANumber'] = new FormControl();
      formGroup['CumulativeDiscountNum'] = new FormControl();
      formGroup['RepCode'] = new FormControl();
      this.accountGroupForm = new UntypedFormGroup(formGroup);
      this.getFilteredOptions('repCodes');
    }
  }

  getSuggestedAccounts() {
    this.loading = true;
    const queryData = {};
    if (this.accountGroupForm) {
      queryData['search'] = {};
      const formKeys = Object.keys(this.accountGroupForm['value']);
      formKeys.map(keyName => {
        if (this.accountGroupForm['value'][keyName]) {
          queryData['search'][keyName] = {
            searchString: this.accountGroupForm['value'][keyName],
          };
        }
      });
    }
    this.ptSvc
      .searchAccount(encodeURIComponent(JSON.stringify(queryData)))
      .subscribe(
        resp => {
          this.recommendedAccounts = resp;
          this.loading = false;
        },
        err => {
          this.snacky.open(
            `Whoops something went wrong, please try again later!`,
            'Close',
            {
              duration: 3000,
            }
          );
          console.error(err);
        }
      );
  }

  linkAccount() {
    this.ptSvc.linkAccount(this.data.groupId, this.selectedAccount).subscribe(
      () => {
        this.snacky.open(`Account Linked!`, 'Close', {
          duration: 3000,
        });
        this.dialogRef.close();
      },
      err => {
        this.snacky.open(
          `Whoops something went wrong, please try again later!`,
          'Close',
          {
            duration: 3000,
          }
        );
        console.error(err);
      }
    );
  }

  selectAccount(accountId) {
    if (this.selectedAccount === accountId) {
      this.selectedAccount = '';
    } else {
      this.selectedAccount = accountId;
    }
  }

  viewAccountGroup(account) {
    window.open(
      `${this.location.prepareExternalUrl('')}account-grouping/${
        account.GroupID
      }`,
      '_blank'
    );
  }

  accountsNotSelected() {
    return !Object.values(this.selectedAccount).some(value => value);
  }

  toggleView() {
    if (!this.tableView) {
      this.getSuggestedAccounts();
    }
    this.tableView = !this.tableView;
  }

  getFilteredOptions(repCodes?) {
    this.typeaheadControl.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(value => {
        if (repCodes) {
          this.usServ.repCodeSearch(value).subscribe(resp => {
            const newUnitList = resp.names.map(rep => ({
              value: rep.repCode,
              display: rep.name,
            }));
            this.tyepaheadOptions = newUnitList;
          });
        } else {
          this.usServ.unitSearch(value).subscribe(resp => {
            const newUnitList = resp.names.map(rep => ({
              value: rep.id,
              display: rep.name,
            }));
            this.tyepaheadOptions = newUnitList;
          });
        }
      });
  }

  onBlur(ev) {
    if (this.typeaheadControl.value === '') {
      if (this.accountGroupForm.controls['unit']) {
        this.accountGroupForm.controls['unit'].setValue('');
      } else {
        this.accountGroupForm.controls['RepCode'].setValue('');
      }
    }

    if (!ev.target.value.includes(':')) {
      if (this.accountGroupForm.controls['unit']) {
        this.accountGroupForm.controls['unit'].setValue(ev.target.value);
      } else {
        this.accountGroupForm.controls['RepCode'].setValue(ev.target.value);
      }
    }
  }

  changeSelection(ev) {
    this.typeaheadControl.setValue(ev.option.getLabel());
    if (this.accountGroupForm.controls['unit']) {
      this.accountGroupForm.controls['unit'].setValue(ev.option.value);
    } else {
      this.accountGroupForm.controls['RepCode'].setValue(ev.option.value);
    }
  }

  createAccountGroup() {
    const { description, unit } = this.accountGroupForm.value;

    this.ptSvc
      .createAccountGroup({ description, unitid: unit })
      .subscribe(resp => {
        if (resp['result']) {
          this.snacky.open(`Account Group Created!`, 'Close', {
            duration: 3000,
          });
          this.dialogRef.close();
          this.route.navigate([`/account-grouping/${resp['result']}`]);
        }
      });
  }
}
