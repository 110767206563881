import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
  SortDescriptor,
  State,
  GroupDescriptor,
  CompositeFilterDescriptor,
  filterBy,
} from '@progress/kendo-data-query';
import { FilterService } from '@progress/kendo-angular-grid';
import { BookManagementService } from '../book-management.service';
import { CustomFiltersService } from '../../services/custom-filters.service';
import { SessionStorageService } from '../../services/session-storage.service';
import { UserPreferencesService } from '../../services/user-preferences.service';
import { Subject } from 'rxjs';
import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import { FormGroup, FormBuilder } from '@angular/forms';
import { GridComponent } from '@progress/kendo-angular-grid';
import { BmLaunchModalComponent } from '../bm-launch-modal/bm-launch-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { getFilterMenuText } from '../../lib/grid-sort-text';

@Component({
  selector: 'app-book-management-grid',
  templateUrl: './book-management-grid.component.html',
  styleUrls: ['./book-management-grid.component.scss'],
})
export class BookManagementGridComponent implements OnInit, OnDestroy {
  @ViewChild(GridComponent) bookManagementGrid;

  constructor(
    public fb: FormBuilder,
    private bmSrvc: BookManagementService,
    private cstmFltrSrvc: CustomFiltersService,
    private ss: SessionStorageService,
    private prefSrvc: UserPreferencesService,
    private route: Router,
    private dialog: MatDialog
  ) {}

  filterForm: FormGroup = this.fb.group({});
  filterOptions = {};
  public getFilterMenuText = getFilterMenuText;
  public gridData = {
    data: null,
    headers: [],
    total: 0,
  };
  public gridDataTotal;
  public ogData;
  public defaultHidden = ['IRSCode', 'NIPR', 'CarrierCode'];
  public gridColumns = [
    {
      DataField: 'EncryptedPolicyNum',
      Filter: true,
      Group: false,
      Hidden: false,
      Label: 'Policy #',
      RespVis: '',
      Sort: true,
      Type: 'string',
      FilterType: 'Search',
      Width: 98,
      externalFilter: {
        controlType: 'text',
        filterGroup: 'primary',
        filterType: 'contains',
        label: 'Policy Number',
        varName: 'PolicyNumber',
      },
    },
    {
      DataField: 'Carrier',
      Filter: true,
      Group: false,
      Hidden: false,
      Label: 'Carrier',
      RespVis: '',
      Sort: true,
      Type: 'string',
      FilterType: 'Dropdown',
      Width: 300,
      externalFilter: {
        controlType: 'dropdown',
        filterGroup: 'primary',
        filterType: 'contains',
        label: 'Carrier',
        options: [],
        varName: 'Carrier',
      },
    },
    {
      DataField: 'CarrierCode',
      Filter: false,
      Group: false,
      Hidden: false,
      Label: 'Carrier Code',
      RespVis: '',
      Sort: true,
      Type: 'string',
      Width: 105,
    },
    {
      DataField: 'Name',
      Filter: true,
      Group: false,
      Hidden: false,
      Label: 'Policy Name',
      RespVis: '',
      Sort: true,
      Type: 'string',
      FilterType: 'Search',
      Width: 150,
    },
    {
      DataField: 'PartyName',
      Filter: true,
      Group: false,
      Hidden: false,
      Label: 'Policy Owner',
      RespVis: '',
      Sort: true,
      Type: 'string',
      FilterType: 'Search',
      Width: 175,
      externalFilter: {
        controlType: 'text',
        filterGroup: 'primary',
        filterType: 'contains',
        label: 'Policy Owner',
        varName: 'PartyName',
      },
    },
    {
      DataField: 'PolicyStatus',
      Filter: true,
      Group: false,
      Hidden: false,
      Label: 'Policy Status',
      RespVis: '',
      Sort: true,
      Type: 'string',
      FilterType: 'Dropdown',
      Width: 100,
    },
    {
      DataField: 'ProductType',
      Filter: true,
      Group: false,
      Hidden: false,
      Label: 'Product Type',
      RespVis: '',
      Sort: true,
      Type: 'string',
      FilterType: 'Dropdown',
      Width: 100,
      externalFilter: {
        controlType: 'dropdown',
        filterGroup: 'primary',
        filterType: 'contains',
        label: 'Product Type',
        options: [],
        varName: 'ProductType',
      },
    },
    {
      DataField: 'ShareClass',
      Filter: true,
      Group: false,
      Hidden: false,
      Label: 'Share Class',
      RespVis: '',
      Sort: true,
      Width: 100,
      Type: 'string',
      FilterType: 'Dropdown',
    },
    {
      DataField: 'ContractValue',
      Filter: false,
      Group: false,
      Hidden: false,
      Label: 'Account Value',
      RespVis: '',
      Sort: true,
      Type: 'curr',
      Width: 115,
    },
    {
      DataField: 'IssueDate',
      Filter: true,
      Group: false,
      Hidden: false,
      Label: 'Issue Date',
      RespVis: '',
      Sort: true,
      Type: 'date',
      FilterType: 'DateRange',
      Width: 105,
    },
    {
      DataField: 'RefreshDate',
      Filter: false,
      Group: false,
      Hidden: false,
      Label: 'Last Updated',
      RespVis: '',
      Sort: true,
      Type: 'date',
      Width: 105,
    },
    {
      DataField: 'IRSCode',
      Filter: true,
      Group: false,
      Hidden: false,
      Label: 'IRS Code',
      RespVis: '',
      Sort: true,
      Type: 'string',
      FilterType: 'Dropdown',
      Width: 110,
      externalFilter: {
        controlType: 'dropdown',
        filterGroup: 'primary',
        filterType: 'contains',
        label: 'IRS Code',
        options: [],
        varName: 'IRSCode',
      },
    },
    {
      DataField: 'NIPR',
      Filter: false,
      Group: false,
      Hidden: false,
      Label: 'Producer Number',
      RespVis: '',
      Sort: true,
      Type: 'string',
      Width: 130,
    },
  ];
  public pageSize = 25;
  public pageSizeOptions = [25, 50, 75, 100];
  public skip = 0;
  public sort: SortDescriptor[] = [];
  public filter: CompositeFilterDescriptor = { logic: 'and', filters: [] };
  public group: GroupDescriptor[] = [];
  public hidden: string[] = [];
  state: State = {
    filter: this.filter,
    sort: this.sort,
    skip: this.skip,
    take: this.pageSize,
  };
  loading = false;
  ogFilterData = {};
  filterData = {};
  resetFilters = false;
  externalFilterOrder = [
    'Carrier',
    'EncryptedPolicyNum',
    'PartyName',
    'ProductType',
    'IRSCode',
  ];
  externalFilters = [];
  ogExternalFilters = [];
  dropdownFilters = [];
  searchFilters = [];
  dateFilters = [];
  hideGrid = false;
  globals = this.ss.get('globals');
  defaultUser = this.globals ? this.globals.user : null;
  bridgeIdOptions = [];
  bridgeIdSearch;
  unsubscribe: Subject<any> = new Subject();
  selectList = [];
  selectChecked = {};

  ngOnInit(): void {
    this.getGridSetup();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  getGridSetup() {
    this.hideGrid = true;
    this.loading = true;
    this.bmSrvc.gridSetup().subscribe(resp => {
      this.filterOptions = resp['dropdowns'];
      this.getGridData();
      this.getBridgeOptions();
    });
  }

  gridHeight() {
    return window.innerHeight - 100;
  }

  getGridData() {
    this.loading = true;
    this.hideGrid = true;

    const queryData = {
      search: {
        PolicyNumber: '',
        ProductType: '',
        PartyName: '',
        Carrier: '',
        IssueDate: {
          start: '',
          end: '',
        },
      },
      skip: this.state.skip,
      take: this.state.take,
      sort: {},
    };

    this.filter.filters.map(filter => {
      const subMaskedPolicy = policyField =>
        policyField === 'EncryptedPolicyNum' ? 'PolicyNumber' : policyField;

      if (filter['filters']) {
        filter['filters'].map(nextFilter => {
          if (
            queryData.search[nextFilter.field] &&
            this.dropdownFilters.includes(nextFilter.field)
          ) {
            queryData.search[nextFilter.field] = nextFilter.value;
          } else if (
            !queryData.search[nextFilter.field] &&
            this.dropdownFilters.includes(nextFilter.field)
          ) {
            queryData.search[nextFilter['field']] = nextFilter['value'];
          }

          if (
            queryData.search[nextFilter.field] &&
            this.searchFilters.includes(nextFilter.field)
          ) {
            queryData.search[subMaskedPolicy(nextFilter.field)] =
              nextFilter.value;
          } else if (
            !queryData.search[nextFilter.field] &&
            this.searchFilters.includes(nextFilter.field)
          ) {
            queryData.search[subMaskedPolicy(nextFilter['field'])] =
              nextFilter['value'];
          }

          if (
            queryData.search[nextFilter.field] &&
            this.dateFilters.includes(nextFilter.field)
          ) {
            queryData.search[nextFilter.field][nextFilter.operator] =
              DateTime.fromJSDate(new Date(nextFilter.value), {
                zone: 'utc',
              }).toISO();
          } else if (
            !queryData.search[nextFilter.field] &&
            this.dateFilters.includes(nextFilter.field)
          ) {
            queryData.search[nextFilter['field']] = {};
            queryData.search[nextFilter['field']][nextFilter.operator] =
              DateTime.fromJSDate(new Date(nextFilter.value), {
                zone: 'utc',
              }).toISO();
          }
        });
      } else {
        if (
          queryData.search[filter['field']] &&
          this.searchFilters.includes(filter['field'])
        ) {
          queryData.search[subMaskedPolicy(filter['field'])] = filter['value'];
        } else if (
          !queryData.search[filter['field']] &&
          this.searchFilters.includes(filter['field'])
        ) {
          queryData.search[subMaskedPolicy(filter['field'])] = filter['value'];
        }

        if (queryData.search[filter['field']]) {
          queryData.search[subMaskedPolicy(filter['field'])] = filter['value'];
        } else if (!queryData.search[filter['field']]) {
          queryData.search[subMaskedPolicy(filter['field'])] = filter['value'];
        }
      }
    });

    this.sort.map(sortItem => {
      if (sortItem.dir !== undefined) {
        queryData.sort[sortItem.field] = sortItem.dir.toUpperCase();
      } else {
        delete queryData.sort[sortItem.field];
      }
    });

    if (this.bridgeIdSearch) {
      queryData['bridgeid'] = [this.bridgeIdSearch];
    } else {
      queryData['bridgeid'] = [];
    }

    if (isEmpty(queryData.sort)) {
      queryData.sort = {
        Carrier: 'ASC',
        PartyName: 'ASC',
        PolicyNumber: 'ASC',
      };
    }
    this.bmSrvc.policyManagementData(queryData).subscribe(resp => {
      const gridData = resp['results'];
      this.ogData = gridData;
      this.gridData.data = gridData;
      this.gridData.headers = this.gridColumns;
      this.gridData.total = resp['total'];
      this.setColumnVisiblity();
      this.updateExternalFilterOptions(this.gridColumns);
      this.buildFilterOptions();
      this.hideGrid = false;
      this.loading = false;
    });
  }

  setColumnVisiblity() {
    const hiddenColumns = this.ss.get('hiddenPMColumns');
    if (!hiddenColumns) {
      this.hidden = this.defaultHidden;
    } else if (hiddenColumns !== null) {
      this.hidden = hiddenColumns;
    }

    this.hidden?.map(columnName => {
      this.gridColumns.find(column => column.DataField === columnName).Hidden =
        true;
    });

    this.hidden?.forEach(x => {
      this.hideColumn(x);
    });
  }

  hideColumn(columnName: string): void {
    this.gridColumns.find(column => column.DataField === columnName).Hidden =
      true;
    if (!this.isHidden(columnName)) {
      this.hidden.push(columnName);
      this.setStorage();
    }
  }

  setStorage() {
    this.ss.set('hiddenPMColumns', this.hidden);
  }

  getBridgeOptions() {
    this.bmSrvc.getPolicyBridgeOptions(this.defaultUser.id).subscribe(resp => {
      if (resp && resp['UnitPMBridges']) {
        this.bridgeIdOptions = resp['UnitPMBridges'];
      } else {
        this.bridgeIdOptions = ['noOptions'];
      }
    });
  }

  setBridgeIdSearch(e) {
    if (e === 'clear') {
      this.bridgeIdSearch = null;
    } else {
      this.bridgeIdSearch = e.value;
    }
  }

  buildFilterOptions() {
    this.filterData = this.cstmFltrSrvc.buildColumnFilterOptions(
      this.ogData,
      this.gridColumns
    );
    this.ogFilterData = this.cstmFltrSrvc.buildColumnFilterOptions(
      this.ogData,
      this.gridColumns
    );

    if (this.filterOptions) {
      for (const [key, value] of Object.entries(this.filterOptions)) {
        this.filterData[key] = value;
        this.ogFilterData[key] = value;
      }
    }
  }

  saveUserDefinedColumns() {
    const fullList = this.gridColumns.map(x => {
      return x.DataField;
    });
    const visible = fullList.filter(x => !this.hidden.includes(x));

    this.prefSrvc.saveColumns({ pmGrid: visible }).subscribe();
  }

  externalColumnsUpdated(ev) {
    const fullList = this.gridColumns.map(x => {
      return x.DataField;
    });
    const hidden = fullList.filter(y => !ev.includes(y));

    this.updateHidden({ value: hidden });
    this.saveUserDefinedColumns();
  }

  resetGrid() {
    this.sort = [];
    this.filter = { logic: 'and', filters: [] };
    this.skip = 0;

    this.state = {
      skip: this.skip,
      take: this.pageSize,
      group: this.group,
      sort: this.sort,
      filter: this.filter,
    };

    this.setBridgeIdSearch('clear');
    this.getGridData();
  }

  updateHidden(ev) {
    this.hidden = [...ev.value];
    this.setStorage();
  }

  isHidden(columnName: string): boolean {
    return this.hidden.indexOf(columnName) > -1;
  }

  updateFilters(ev?) {
    this.resetFilters = false;
    this.skip = 0;

    if (this.filter.filters && this.filter.filters.length > 0) {
      this.filter.filters = this.deDupObjectArray([
        ...this.filter.filters,
        ...ev.filters,
      ]);
    } else {
      this.filter = ev;
    }
  }

  searchResults() {
    this.state = {
      filter: this.filter,
      sort: this.sort,
      skip: this.skip,
      take: this.pageSize,
    };
    this.getGridData();
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    const passedFilterNames = [];
    const passedFilterDatas = {};

    // Extract passed filter data for trickle down column filter option narrowing
    filter.filters.map(filterObj => {
      if (filterObj['filters']) {
        const filterName = filterObj['filters'][0]['field'];
        passedFilterNames.push(filterName);
        passedFilterDatas[filterName] = this.filterData[filterName];
      }
    });

    this.gridData.data = filterBy(this.ogData, filter);
    this.filterData = this.cstmFltrSrvc.buildColumnFilterOptions(
      filterBy(this.ogData, filter),
      this.gridColumns
    );

    // Replace filter options with trickled down options
    passedFilterNames.map(filterName => {
      this.filterData[filterName] = passedFilterDatas[filterName];
    });
  }

  deDupObjectArray(data) {
    const uniqueArray = data.filter(
      (object, index) =>
        index ===
        data.findIndex(obj => JSON.stringify(obj) === JSON.stringify(object))
    );
    return uniqueArray;
  }

  updateExternalFilterOptions(headers) {
    headers
      .filter(x => x.externalFilter)
      .forEach(el => {
        const entry = el.externalFilter;
        entry.varName = el.DataField;
        entry.fullField = el;

        if (
          this.externalFilters.findIndex(x => x.varName === el.DataField) === -1
        ) {
          this.externalFilters.push(entry);
        }

        if (
          el.Type &&
          el.Type.toLowerCase() == 'curr' &&
          el.externalFilter &&
          el.externalFilter.controlType !== 'currencyRange'
        ) {
          entry.controlType = 'currency';
        }

        const entryIdx = this.externalFilters.findIndex(x => {
          return x.varName == el.DataField;
        });
        this.externalFilters[entryIdx].options =
          this.buildDropdownFilterOptions(el);
      });

    // Reorder external filters
    this.externalFilters.sort((a, b) => {
      return (
        this.externalFilterOrder.indexOf(a.varName) -
        this.externalFilterOrder.indexOf(b.varName)
      );
    });
  }

  buildDropdownFilterOptions(el) {
    let options;
    if (el.externalFilter && el.externalFilter.controlType === 'dropdown') {
      options = this.filterOptions[el.DataField].map(x => {
        return { value: x, display: x };
      });
      options = this.deDupObjectArray(options);
      options = this.clearEmptyFields(options);
      options = options.sort((a, b) => (a.display > b.display ? 1 : -1));
    }

    if (el.Validation) {
      options = options.map(x => {
        let label = el.Validation.find(z => x.value == z.value);

        label = label ? label.label : x.value;

        return { value: x.value, display: label };
      });
    }

    return options;
  }

  clearEmptyFields(data) {
    const updated = data.filter(item => {
      if (typeof item.value === 'string' && item.value.length < 1) {
        return false;
      }

      return item;
    });

    return updated;
  }

  public dataStateChange(state): void {
    this.loading = true;
    this.pageSize = state.take;
    this.state = state;
    this.filter = this.state.filter;
    this.sort = this.state.sort;
    this.getGridData();
  }

  sortChange(sort: SortDescriptor[]) {
    this.sort = sort;
    this.getGridData();
  }

  public filterValueChange(
    values: any[],
    field,
    filterService: FilterService
  ): void {
    if (!this.dropdownFilters.includes(field)) {
      this.dropdownFilters.push(field);
    }

    filterService.filter({
      filters: values.map(value => ({
        field: field,
        operator: 'eq',
        value,
      })),
      logic: 'or',
    });
  }

  public onFilterChange(searchTerm: string, dataSet): void {
    if (!searchTerm) {
      this.filterData[dataSet] = this.ogFilterData[dataSet];
    } else {
      this.filterData[dataSet] = this.filterData[dataSet].filter(dataPoint =>
        dataPoint.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
  }

  public searchFilterValueChange(
    values,
    field,
    filterService: FilterService
  ): void {
    if (!this.searchFilters.includes(field)) {
      this.searchFilters.push(field);
    }

    filterService.filter({
      filters: [
        {
          field: field,
          operator: 'contains',
          value: values,
        },
      ],
      logic: 'or',
    });
  }

  public dateFilterValueChange(field): void {
    if (!this.dateFilters.includes(field)) {
      this.dateFilters.push(field);
    }
  }

  viewPolicy(args, viewButton = false) {
    if (args?.column?.title !== 'Actions' || viewButton) {
      this.route.navigate([`/book-management/${args.dataItem.ID}`]);
    }
  }

  selectListUpdate(ev, item) {
    ev.preventDefault();
    const indexExisting = this.selectList.findIndex(x => x === item);

    if (indexExisting > -1) {
      this.selectList = this.selectList.filter(x => {
        return x != item;
      });
      this.selectChecked[item.ID] = false;
    } else {
      this.selectList.push(item);
      this.selectChecked[item.ID] = true;
    }
  }

  launchToAW() {
    const dialogRef = this.dialog.open(BmLaunchModalComponent, {
      panelClass: 'app-bm-launch-modal',
      width: '98vw',
      autoFocus: false,
      data: {
        selectedAnnuities: this.selectList,
      },
    });
  }
}
