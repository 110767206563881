export const caseHistoryKey = [
  { label: 'Case ID', varName: 'CRID' },
  { label: 'Open Date', varName: 'StatusDate', valueMask: 'date' },
  { label: 'Status', varName: 'StatusName' },
];

export const repInfoKey = [
  { label: 'Rep Name', varName: 'RepName' },
  { label: 'Rep Code', varName: 'RepCode' },
  { label: 'Unit', varName: 'UnitID' },
  { label: 'Rep Since', varName: 'RepStartDate' },
  { label: 'Processed', varName: 'ProcessedDate' },
  {
    label: 'Status',
    varName: 'ReviewStatus',
    key: {
      PEN: 'Pending',
      REV: 'Reviewed',
      INP: 'In Process',
      REO: 'Reopened',
      tst: 'Test',
    },
  },
  {
    label: 'Insurance License States',
    varSet: 'RepInfo',
    varName: 'StateList',
  },
  {
    label: 'Series',
    varSet: 'RepInfo',
    varName: 'SeriesList',
  },
];

export const scoreClassKey = {
  Positive: 'check_circle',
  Warning: 'error',
  Negative: 'remove_circle',
};

export const keyMetricsKey = [
  [
    {
      label: 'Sum of Account Balance',
      varName: 'SumAccountBalances',
      valueMask: 'currency',
    },
    {
      label: '# of Accounts',
      varName: 'AccountCount',
    },
    {
      label: '# of Transactions (12 months)',
      varName: 'TransactionCount',
    },
    {
      label: 'Favorite Fund',
      varName: 'FavoriteFund',
    },
    {
      label: 'Favorite VA',
      varName: 'FavoriteVA',
    },
    {
      label: 'Favorite FA',
      varName: 'FavoriteFA',
    },
  ],
  [
    {
      label: '% Switches vs MF Sales',
      varName: 'PercMFSwitches',
      valueMask: 'percent',
    },
    {
      label: '% Exchanges vs VA Sales',
      varName: 'PercVAExchange',
      valueMask: 'percent',
    },
    {
      label: '% Exchanges vs FA Sales',
      varName: 'PercFAExchange',
      valueMask: 'percent',
    },
    {
      label: '% of Transactions Yellow/Red',
      varName: 'PercTransactionsWarning',
      valueMask: 'percent',
    },
    {
      label: '% of Accounts Yellow/Red',
      varName: 'PercAccountsWarning',
      valueMask: 'percent',
    },
    {
      label: 'Most Common Transaction Alert',
      varName: 'CommonTransactionAlert',
    },
    {
      label: 'Most Common Account Alert',
      varName: 'CommonAccountAlert',
    },
  ],
];

export const relatedAccountsKey = [
  { label: 'Score', varName: 'ScoreClass' },
  { label: 'Account Number', varName: 'AccountIDDisplay' },
  { label: 'Client Name', varName: 'ClientFullName' },
  { label: 'Account Opened', varName: 'AccountOpenedDate', valueMask: 'date' },
  { label: 'Balance', varName: 'AccountBalance', valueMask: 'currency' },
  {
    label: 'Registration',
    varName: 'Registration',
    key: {
      CHSA: 'Checking/Savings',
      INDV: 'Individual Brokerage',
      JNTN: 'Joint Brokerage',
      FOUR01K: 'Traditional 401/IRA',
      ROTH: 'Roth 401K/IRA',
      TRST: 'Trust',
      CUST: 'Custodial',
      FIVE29: '529',
      CORP: 'Corporation',
      CLUB: 'Club',
      LLCP: 'LLC',
      OTHN: 'Other Non Qual',
      OTHQ: 'Other Qualified',
    },
  },
  {
    label: 'Discretionary',
    varName: 'Discretionary',
    key: {
      Y: 'Yes',
      N: 'No',
    },
  },
];

export const recentActivityKey = [
  { label: 'Score', varName: 'ScoreClass' },
  { label: 'Trade ID', varName: 'TradeID' },
  { label: 'Account #', varName: 'AccountNumber' },
  { label: 'Activity Date', varName: 'TradeDate', valueMask: 'date' },
  { label: 'Transaction Type', varName: 'TransactionType' },
  { label: 'Product Type', varName: 'ProductType' },
  { label: 'Symbol', varName: 'Symbol' },
  { label: 'Product Name', varName: 'ProductName' },
  { label: 'Amount', varName: 'Amount', valueMask: 'currency' },
  { label: 'Commission', varName: 'CommissionDollars', valueMask: 'currency' },
];

export const repReviewKey = [
  {
    label: 'Processed Date',
    varName: 'ProcessedDate',
  },
  {
    label: 'Score',
    varName: 'ScoreClass',
  },
  {
    label: 'Status',
    varName: 'ReviewStatus',
    key: {
      PEN: 'Pending',
      REV: 'Reviewed',
      INP: 'In Process',
      REO: 'Reopened',
      tst: 'Test',
    },
  },
];
