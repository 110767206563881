<div mat-dialog-header>
  <h1>{{ headline }}</h1>
</div>
<mat-dialog-content #scroll class="dialog-container password">
  <div class="row col-12" *ngIf="!reset && !submitted">
    <p class="w-100">{{ content }}</p>
  </div>
  <ng-container>
    <form #passwordForm="ngForm">
      <mat-form-field class="w-100" *ngIf="!reset && !submitted">
        <mat-label>Please enter your username</mat-label>
        <input
          matInput
          [(ngModel)]="username"
          name="uname"
          [placeholder]="'Please enter your username'"
          [required]="required"
        />
        <mat-error>This field is required</mat-error>
      </mat-form-field>

      <!-- <mat-form-field class="w-100" *ngIf="reset">
        <input matInput ngModel name="oPass" [placeholder]="'Old Password'" [required]="required" />
        <mat-error>This field is required</mat-error>
      </mat-form-field> -->
      <ng-container *ngIf="reset">
        <mat-form-field class="w-100">
          <mat-label>New Password</mat-label>
          <input
            matInput
            [(ngModel)]="nPass"
            name="newPass"
            [placeholder]="'New Password'"
            [required]="required"
            #newPass="ngModel"
            type="password"
            strongPassword="2"
          />
          <mat-error *ngIf="newPass.errors?.required"
            >This field is required</mat-error
          >
          <mat-error *ngIf="newPass.errors?.noMatch"
            >Passwords do not match.</mat-error
          >
        </mat-form-field>
        <strength-meter [password]="newPass.value"></strength-meter>
        <password-feedback
          *ngIf="newPass.errors?.strongPassword"
          [feedback]="newPass.errors.strongPassword.feedback"
        ></password-feedback>
        <mat-form-field class="w-100 verify">
          <mat-label>Verify New Password</mat-label>
          <input
            matInput
            [(ngModel)]="vPass"
            name="verifyPass"
            [placeholder]="'Verify New Password'"
            [required]="required"
            #verifyPass="ngModel"
            type="password"
          />
          <mat-error *ngIf="verifyPass.errors?.required"
            >This field is required</mat-error
          >
          <mat-error *ngIf="verifyPass.errors?.noMatch"
            >Passwords do not match.</mat-error
          >
        </mat-form-field>
      </ng-container>
    </form>
  </ng-container>
  <div class="submitted" [hidden]="!submitted">
    <p *ngIf="!reset" class="reset-message"
      >A temporary password has been sent to the email address on file.</p
    >
  </div>

  <pre>
   <!-- {{form}} -->
  </pre>
</mat-dialog-content>
<mat-dialog-actions class="row buttons">
  <button mat-raised-button color="accent" (click)="savePass()">{{
    confirm
  }}</button>
  <button mat-raised-button (click)="cancel()">Cancel</button>
</mat-dialog-actions>
