import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DashboardService } from '../../dashboard.service';
import { Router } from '@angular/router';
import { SessionStorageService } from '../../../services/session-storage.service';
import { DateTime } from 'luxon';
import { saveAs } from '@progress/kendo-file-saver';
import { Platform } from '@angular/cdk/platform';
import { EnvironmentService } from '../../../services/environment.service';

@Component({
  selector: 'app-high-activity-stats',
  templateUrl: './highActivityStats.component.html',
  styleUrls: ['./highActivityStats.component.scss'],
})
export class HighActivityStatsComponent implements OnInit {
  private environment;
  private baseUrl: string;

  loading = false;
  unitsManaged = this.ss.get('globals')?.user.managerUnits;
  unit =
    this.unitsManaged && this.unitsManaged.length > 0
      ? this.unitsManaged[0].id
      : null;
  app = this.ss.get('currentApp');
  startDate = new FormControl(
    DateTime.now().toUTC().minus({ days: 30 }).startOf('day').toISO()
  );
  endDate = new FormControl(DateTime.now().toUTC().endOf('day').toISO());
  data;
  viewingUser = false;
  limit30 = true;

  constructor(
    private dbs: DashboardService,
    private ss: SessionStorageService,
    private router: Router,
    private pltfrm: Platform,
    private envSvc: EnvironmentService
  ) {}

  ngOnInit() {
    this.environment = this.envSvc.get();
    this.baseUrl = this.environment.apiBase;

    const rights = this.ss.get('rights');
    this.limit30 = rights?.includes('limit30Days');

    this.getData();
  }

  getData(user?) {
    this.loading = true;
    this.viewingUser = user ? true : false;

    const back30 = new Date();
    back30.setDate(back30.getDate() - 30);

    const start = this.limit30
      ? back30.toISOString()
      : DateTime.fromJSDate(new Date(this.startDate.value))
          .startOf('day')
          .toISO();
    const end = this.limit30
      ? new Date().toISOString()
      : DateTime.fromJSDate(new Date(this.endDate.value)).endOf('day').toISO();

    this.dbs
      .getUnitProfileCaseStats(this.unit, start, end, user)
      .subscribe(x => {
        this.data = x;
        this.loading = false;
      });
  }

  goToProfile(ev, id) {
    this.router.navigate([`/li/questionnaire/${id}`]);
  }

  exportCSV(user?) {
    this.loading = true;
    const iosSafari =
      this.pltfrm.IOS ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

    const back30 = new Date();
    back30.setDate(back30.getDate() - 30);

    const start = this.limit30
      ? back30.toISOString()
      : DateTime.fromJSDate(new Date(this.startDate.value))
          .startOf('day')
          .toISO();
    const end = this.limit30
      ? new Date().toISOString()
      : DateTime.fromJSDate(new Date(this.endDate.value)).endOf('day').toISO();

    this.dbs
      .getProfileCaseStatsHistory(this.unit, start, end, user)
      .subscribe(x => {
        let fileData;
        const reader = new FileReader();
        reader.readAsDataURL(x);
        reader.onloadend = () => {
          fileData = reader.result;
          saveAs(fileData, 'Activity-Results.csv', {
            proxyURL: `${this.baseUrl}/util/proxy`,
            proxyTarget: '_self',
            forceProxy: iosSafari,
          });
          this.loading = false;
        };
        this.loading = false;
      });
  }
}
