<div mat-dialog-header>
  <h1>{{ headline }}</h1>
</div>
<div mat-dialog-content #scroll class="dialog-container">
  <p>{{ content }}</p>
  <form [formGroup]="form">
    <mat-form-field class="fam-filter w-100">
      <mat-select
        placeholder="Select a Fund Family"
        [formControlName]="'family'"
      >
        <mat-option *ngFor="let fam of fundFamilies" [value]="fam.ID">
          {{ fam.Name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</div>
<mat-dialog-actions class="row buttons">
  <button mat-raised-button color="accent" (click)="save()">{{
    confirm
  }}</button>
  <button mat-raised-button (click)="cancel()">Cancel</button>
</mat-dialog-actions>
