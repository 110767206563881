<mat-card appearance="outlined" class="col-12 stretch">
    <mat-card-header>
      All Annuities and Riders by Connector
    </mat-card-header>
    <mat-card-content>
      <span>
        <div mat-dialog-content class="dialog-container">
        <div class='row'>
            <mat-form-field class='col-6'>
                <input matInput [(ngModel)]="name" placeholder="Connector Name"/>
            </mat-form-field>
            <div class='col-3'></div>
            <div class='col-3'>
              <button mat-button (click)='download()' class="header-button download-link"><mat-icon>save</mat-icon></button>
            </div>
        </div>
      </div>
    </span>
    </mat-card-content>
</mat-card>