<div class="page-container unified container tools-launcher-container">
  <div class="apps w-100">
    <ng-container *ngFor="let unit of toolUnits">
      <div class="app-container">
        <span class="app-banner">
          <mat-icon>home_repair_service</mat-icon>
          <span>
            {{ unit.label }}
          </span>
        </span>
        <div class="app-message">
          {{ unit.description }}
        </div>
        <div class="line"></div>
        <div class="tool-links-container">
          <ng-container *ngFor="let tool of unit.unitTools">
            <a
              class="tool-link"
              *ngIf="!tool.ToolExternal"
              (click)="onLinkClick(tool.ToolLink, tool.ToolName)"
            >
              <div class="tool-link-label">
                <mat-icon>launch</mat-icon> {{ tool.ToolName }}
              </div>
              <div class="tool-link-description">
                {{ tool.ToolDescription }} 
              </div>
            </a>
            <a
              class="tool-link"
              *ngIf="tool.ToolExternal"
              [href]="tool.ToolLink"
              target="_blank"
            >
              <div class="tool-link-label">
                <mat-icon>launch</mat-icon> {{ tool.ToolName }}
              </div>
              <div class="tool-link-description">
                {{ tool.ToolDescription }} 
              </div>
            </a>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</div>
