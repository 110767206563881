import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'password-feedback',
  templateUrl: './password-feedback.component.html',
  styleUrls: ['./password-feedback.component.scss']
})
export class PasswordFeedbackComponent implements OnInit {

  @Input()
  feedback = {'warning': null, 'suggestions': null};


  constructor() { }

  ngOnInit() {

  }

}
