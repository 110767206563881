<span class="utility-apps-container">
  <button
    mat-stroked-button
    class="menu-button utility-app"
    (click)="nav('/post-trade/reports')"
  >
    <mat-icon>summarize</mat-icon> Downloadable Reports
  </button>
  @if (rights.includes('AccountGroup')) {
    <button
      mat-stroked-button
      class="menu-button utility-app"
      (click)="nav('/account-grouping')"
    >
      <mat-icon>dataset_linked</mat-icon> Account Grouping
    </button>
  }
</span>

<div class="page-container trade-review-container container">
  <div class="breadcrumb" id="breadcrumb">
    <button
      mat-button
      (click)="nav('unified')"
      *hasPermission="'!ToolsLandingPage'"
    >
      <mat-icon>home</mat-icon>
      <span class="text-container">Home</span>
    </button>
    <button
      mat-button
      (click)="nav('tools')"
      *hasPermission="'ToolsLandingPage'"
    >
      <mat-icon>home</mat-icon>
      <span class="text-container">Home</span>
    </button>
    /
    <button mat-button (click)="nav('post-trade/TR')">
      <span class="text-container">Post Transaction</span>
    </button>
    /
    <button
      mat-button
      (click)="nav('trade-review')"
      [disabled]="tradeId === ''"
    >
      <span class="text-container">Transaction Monitor</span>
    </button>
    @if (tradeId !== '') {
      <span>/</span>
      <button
        mat-button
        (click)="nav('trade-review')"
        [disabled]="tradeId !== ''"
      >
        <span class="text-container">{{ tradeId }}</span>
      </button>
    }
  </div>

  @if (tradeId === '') {
    <app-trade-review-grid></app-trade-review-grid>
  } @else {
    <app-trade-review-detail [tradeId]="tradeId"></app-trade-review-detail>
  }
</div>
