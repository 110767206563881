<mat-dialog-header>
  <h1><mat-icon>warning</mat-icon>{{headline}}</h1>
</mat-dialog-header>
<mat-dialog-content class="dialog-container col-12">
  <div class="inner row col-12" [innerHTML]="content"></div>
  <div class="inner row col-12 mt-3">
    <mat-form-field class="mr-2">
      <mat-label>Rep Code</mat-label>
      <input matInput placeholder="Rep Code" [(ngModel)]="Client.BackOfficeRRCode" />
    </mat-form-field>
    <mat-form-field class="mr-2">
      <mat-label>Office Range</mat-label>
      <input matInput placeholder="Office Range" [(ngModel)]="Client.BackOfficeOfficeCode" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Account Type</mat-label>
      <mat-select [(ngModel)]="Client.BackOfficeAccountTypeCode">
        <mat-option *ngFor="let opt of accountTypes" [value]="opt.RBAccountTypeCode">{{opt.AccountTypeName}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="buttons row">
    <button mat-raised-button color="accent" (click)="generateReport()" [disabled]="!Client.BackOfficeOfficeCode || !Client.BackOfficeRRCode">Submit</button>
    <button mat-raised-button (click)="cancel()">Cancel</button>
</mat-dialog-actions>

<ngx-loading [show]="loading"></ngx-loading>
