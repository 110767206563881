<mat-card appearance="outlined" class="col-12 stretch">
  <mat-card-content class="row px-3">
    <div class="col p-2" *hasPermission="'CP'">
      <button mat-raised-button color="primary" routerLink="/cp" class="py-4">
        <mat-icon>developer_board</mat-icon> Client Profiler
      </button>
    </div>
    <div class="col p-2" *hasPermission="'PP'">
      <button mat-raised-button color="primary" routerLink="/pp" class="py-4">
        <mat-icon>developer_board</mat-icon> Product Profiler
      </button>
    </div>
    <div class="col p-2" *hasPermission="'AW'">
      <button mat-raised-button color="primary" routerLink="/aw" class="py-4">
        <mat-icon>dvr</mat-icon> Annuity Wizard
      </button>
    </div>
    <div class="col p-2" *hasPermission="'IW'">
      <button mat-raised-button color="primary" routerLink="/iw" class="py-4">
        <mat-icon>style</mat-icon> Investment Wizard
      </button>
    </div>
    <div class="col p-2" *hasPermission="'LI'">
      <button mat-raised-button color="primary" routerLink="/li" class="py-4">
        <mat-icon>loyalty</mat-icon> Life Insurance Wizard
      </button>
    </div>
    <div class="col p-2" *hasPermission="'RW'">
      <button mat-raised-button color="primary" routerLink="/rw" class="py-4">
        <mat-icon>recent_actors</mat-icon> Rollover Wizard
      </button>
    </div>
    <div class="col p-2" *hasPermission="'ProductShelf'">
      <button mat-raised-button color="primary" routerLink="/aw/product-shelf" class="py-4">
        <mat-icon>assignment</mat-icon> Annuity Product Shelf
      </button>
    </div>
  </mat-card-content>
</mat-card>
