import { Component, Inject, ViewEncapsulation, OnInit } from '@angular/core';
import { PostTradeService } from '../../post-trade.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, UntypedFormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-external-grouping-dialog',
  templateUrl: './external-grouping-dialog.component.html',
  styleUrls: ['./external-grouping-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ExternalGroupingDialogComponent implements OnInit {
  recommendedGroups;
  selectedGroup = '';
  loading = false;
  tableView = true;
  groupForm: FormGroup;

  headers;

  constructor(
    public dialogRef: MatDialogRef<ExternalGroupingDialogComponent>,
    private ptSvc: PostTradeService,
    private snacky: MatSnackBar,
    private route: Router,
    private location: Location,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit() {
    this.getGridSetup();
  }

  getGridSetup() {
    this.loading = true;
    this.ptSvc.getAgGridSetup().subscribe(
      resp => {
        this.headers = resp['headers'];
        if (this.data.externalInitData) {
          const formGroup = {};
          const formKeys = Object.keys(this.data.externalInitData);
          formKeys.map(keyName => {
            if (keyName.includes('Hashed')) {
              const newFormKey = keyName.replace('Hashed', '');
              formGroup[newFormKey] = new FormControl();
            } else {
              formGroup[keyName] = new FormControl();
            }
          });
          this.groupForm = new UntypedFormGroup(formGroup);
        }

        this.getSuggestedGroups(true);
      },
      error => {
        console.error('GRID SETUP ERROR: ', error);
        this.loading = false;
      }
    );
  }

  getSuggestedGroups(initialGet?) {
    const queryData = { search: {} };
    if (initialGet) {
      const formKeys = Object.keys(this.data.externalInitData);
      formKeys.map(keyName => {
        if (this.data.externalInitData[keyName]) {
          queryData['search'][keyName] = {
            searchString: this.data.externalInitData[keyName],
          };
        }
      });
    } else {
      const formKeys = Object.keys(this.groupForm['value']);
      formKeys.map(keyName => {
        if (this.groupForm['value'][keyName]) {
          queryData['search'][keyName] = {
            searchString: this.groupForm['value'][keyName],
          };
        }
      });
    }
    this.ptSvc
      .getAccountGroupingGridData(JSON.stringify(queryData), initialGet)
      .subscribe(resp => {
        this.recommendedGroups = resp;
        this.loading = false;
      });
  }

  linkAccount() {
    this.ptSvc
      .linkAccount(
        this.selectedGroup,
        this.data.externalInitData.HashedAccountID
      )
      .subscribe(
        () => {
          this.snacky.open(`Account Linked!`, 'Close', {
            duration: 3000,
          });
          this.dialogRef.close();
        },
        err => {
          this.snacky.open(
            `Whoops something went wrong, please try again later!`,
            'Close',
            {
              duration: 3000,
            }
          );
          console.error(err);
        }
      );
  }

  selectGroup(accountId) {
    if (this.selectedGroup === accountId) {
      this.selectedGroup = '';
    } else {
      this.selectedGroup = accountId;
    }
  }

  viewAccountGroup(group) {
    window.open(
      `${this.location.prepareExternalUrl('')}account-grouping/${
        group.GroupID
      }`,
      '_blank'
    );
  }

  toggleView() {
    if (!this.tableView) {
      this.loading = true;
      this.getSuggestedGroups();
    }
    this.tableView = !this.tableView;
  }
}
