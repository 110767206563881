<div class="page-container asset-container container unified">
  <div class="needs-container">
    <div class="col-12 row section-header">
      <div class="col-11 col-xl-6 intro" *ngIf="data && data.vars">
        <h2
          ><mat-icon>account_balance</mat-icon>Additional Insurance Needed:
        </h2>
        <mat-form-field appearance="fill">
          <input
            matInput
            appearance="fill"
            type="currency"
            [(ngModel)]="
              data.vars['InsuranceNeedAmount^EditedInsuranceNeed'].raw[0]
            "
            [readonly]="editAmount"
            [maskito]="dollarMask"
            maxlength="20"
            class="currency-editor"
          />
          <button
            matSuffix
            mat-icon-button
            aria-label="Edit"
            (click)="editNeedAmount()"
            *ngIf="editAmount"
            class="currency-editor"
          >
            <mat-icon>mode_edit</mat-icon>
          </button>
          <button
            *ngIf="!editAmount"
            matSuffix
            mat-icon-button
            aria-label="Save"
            (click)="updateValue($event)"
            class="currency-editor"
          >
            <mat-icon>check</mat-icon>
          </button>
          <button
            *ngIf="!editAmount"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="clearEdit()"
            class="currency-editor"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div class="col-11 col-xl-6 intro" *ngIf="data && data.vars">
        <h2>
          <mat-icon>pie_chart</mat-icon>Calculated Insurance Mix:
          {{ data.vars['InsuranceMix^PermPercent'].display[0] }} Permanent,
          {{ data.vars['InsuranceMix^TermPercent'].display[0] }} Term
        </h2>
      </div>
    </div>
    <div class="row main-container" *ngFor="let need of needs">
      <div class="col-12 col-lg-6 content">
        <h2>
          {{ need.ShortName
          }}<span *hasPermission="'!RelevanceHideBridgeScore'">
            | {{ scoreFormat(need.score) }}</span
          >
        </h2>
        <p class="description" [innerHTML]="need.description"></p>
        <ul class="details">
          <ng-container *ngFor="let r of need.reasons; let i = index">
            <li class="col-12 reason no-gutter">
              <strong>
                <span [innerHTML]="r.headline"></span>
                <span *hasPermission="'!RelevanceHideBridgeScore'">
                  | {{ scoreFormat(r.score) }}
                </span>
              </strong>
              <p class="explanation" [innerHTML]="r.explanation"></p>
            </li>
          </ng-container>
        </ul>
      </div>
      <div class="col-12 col-lg-6 chart-container" *ngIf="need.formattedCharts">
        <ng-container *ngFor="let c of need.formattedCharts; let idx = index">
          <div [chart]="c"></div>
          <div *ngIf="need.charts[idx].Legend" class="chart-legend">
            <ng-container *ngIf="need.charts[idx].Legend.header; else noHeader">
              <table>
                <thead>
                  <tr>
                    <ng-container
                      *ngFor="let th of need.charts[idx].Legend.header"
                    >
                      <th *ngIf="th.toLowerCase() !== 'color'">
                        {{ th }}
                      </th>
                    </ng-container>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let tr of need.charts[idx].Legend.data">
                    <ng-container *ngFor="let td of tr; let i = index">
                      <td
                        *ngIf="
                          need.charts[idx].Legend.header[i].toLowerCase() !==
                          'color'
                        "
                      >
                        <span
                          *ngIf="
                            need.charts[idx].Legend.header[i].toLowerCase() !==
                              'color' &&
                            need.charts[idx].Legend.header[0].toLowerCase() ===
                              'color' &&
                            i === 1
                          "
                          [style.background]="tr[0]"
                          class="indicator"
                        ></span>
                        {{ td }}
                      </td>
                    </ng-container>
                  </tr>
                </tbody>
              </table>
            </ng-container>
            <ng-template #noHeader>
              <div *ngFor="let c of need.charts[idx].Legend">
                <strong>{{ c.label }}</strong> {{ c.value }}</div
              >
            </ng-template>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
