import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-form-date',
  styleUrls: ['form-date.component.scss'],
  template: `
    <mat-form-field class="w-100">
      <mat-label>{{ to.placeholder }}</mat-label>
      <input
        matInput
        [matDatepicker]="picker"
        [placeholder]="props.placeholder"
        [formControl]="formControl"
        [required]="props.required"
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
      <mat-error
        *ngIf="
          formControl.hasError('required') &&
          !formControl.hasError('regRequired')
        "
        >This field is required</mat-error
      >
      <mat-error *ngIf="formControl.hasError('regRequired')"
        >This field is needed for suitability review. You can opt out below if
        the applicant refuses to provide the information.</mat-error
      >
    </mat-form-field>
  `,
})
export class FormlyCustomDateComponent extends FieldType<FieldTypeConfig> {
  dateMask = {
    mask: Date,
  };
}
