import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NavHistoryService } from './services/nav-history.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

declare let gtag: Function;

@Component({
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'app';
  apps = ['pp', 'aw', 'iw', 'lw', 'cm'];

  constructor(
    public router: Router,
    private navigation: NavHistoryService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private gtmService: GoogleTagManagerService
  ) {
    this.navigation.startSaveHistory();
    this.apps.map(app => {
      this.matIconRegistry.addSvgIcon(
        `${app}_icon`,
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `..${
            window.location.host.includes('localhost') ? '' : '/app'
          }/assets/${app}-icon.svg`
        )
      );
    });

    this.router.events.forEach(item => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: item.url,
        };
        this.gtmService.pushTag(gtmTag);
      }
    });
  }
}
