import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AccountsService } from '../services/accounts-service.service';
import { TemplateModalComponent } from '../template-modal/template-modal.component';
import { SharedTemplateModalComponent } from '../shared-template-modal/shared-template-modal.component';
import { Fund, Template } from '../../models/account-models';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ClipboardService } from 'ngx-clipboard';
import { SessionStorageService } from '../../services/session-storage.service';
import { WarnDialogComponent } from '../../warn-dialog/warn-dialog.component';

@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss'],
})
export class TemplatesComponent implements OnInit {
  public templates: Template[] = [];
  public loading = false;
  public pagesize = 10;
  public rights = [];

  constructor(
    private as: AccountsService,
    private dialog: MatDialog,
    private snacky: MatSnackBar,
    private clippy: ClipboardService,
    private ss: SessionStorageService
  ) {}

  ngOnInit() {
    this.rights = this.ss.get('rights');
    this.getTemplates();
    this.clippy.copyResponse$.subscribe(res => {
      if (res.isSuccess) {
        this.snacky.open(`Template Share ID: ${res.content} Copied!`, 'Close', {
          duration: 4000,
        });
      }
    });
  }

  getTemplates() {
    this.loading = true;
    this.as.getTemplates().subscribe(x => {
      if (x && x.results) {
        this.templates = [
          ...x.results.FirmTemplates,
          ...x.results.UserTemplates,
        ];
      }
      this.loading = false;
    });
  }

  saveTemplates(templates) {
    this.loading = true;
    this.as.updateTemplates(templates).subscribe(x => {
      this.templates = [...x.results.FirmTemplates, ...x.results.UserTemplates];
      this.loading = false;
    });
  }

  deleteTemplate(id) {
    const dialogRef = this.dialog.open(WarnDialogComponent, {
      panelClass: 'warn-dialog',
      data: {
        headline: 'Warning',
        content:
          'Are you sure you would like to delete this template? Template deletion cannot be undone.',
        confirm: 'Delete',
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'continue') {
        this.as.deleteTemplate(id).subscribe(x => {
          this.templates = [
            ...x.results.FirmTemplates,
            ...x.results.UserTemplates,
          ];
          this.loading = false;
        });
      }
    });
  }

  editTemplate(data) {
    const dialogRef = this.dialog.open(TemplateModalComponent, {
      minWidth: '80vw',
      panelClass: 'template-dialog',
      data: data,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        result.data.edited = new Date();
        this.saveTemplates(result.data);
      }
    });
  }

  addSharedTemplate() {
    const dialogRef = this.dialog.open(SharedTemplateModalComponent, {
      panelClass: 'shared-template-dialog',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.templates = [...result.FirmTemplates, ...result.UserTemplates];
      }
    });
  }

  newTemplate() {
    const dialogRef = this.dialog.open(TemplateModalComponent, {
      minWidth: '80vw',
      panelClass: 'template-dialog',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result != 'cancel') {
        this.saveNewTemplate(
          result.data.name,
          result.data.funds,
          result.data.program,
          result.data.scope,
          result.data.firmTemplate
        );
      }
    });
  }

  saveNewTemplate(name, selectedFunds: Fund[], prog, shareScope, firm) {
    let length = this.templates.length;

    const template: Template = {
      id: `IWT-${length++}`,
      name: name,
      edited: new Date(),
      created: new Date(),
      funds: selectedFunds,
      program: prog,
      active: new Date(),
      scope: shareScope,
      firmTemplate: firm,
    };

    this.loading = true;
    this.as.saveNewTemplate(template).subscribe(x => {
      this.templates = [...x.results.FirmTemplates, ...x.results.UserTemplates];
      this.loading = false;
    });
  }

  clipboardCopy(ev, value) {
    this.clippy.copy(value);
  }

  sharingChange(ev, template) {
    template.scope = ev.checked ? 'private' : 'off';
    this.saveTemplates(template);
  }
}
