<button
  mat-button
  (click)="navSearch('name')"
  class="search"
  *hasPermission="'DisplayClientNameSearchLink'"
>
  <mat-icon>search</mat-icon> Search For Clients
</button>
<form [formGroup]="searchForm" *hasPermission="'DisplayClientNameSearch'">
  <mat-form-field class="w-100">
    <input
      matInput
      type="text"
      class="search-field"
      placeholder="Search for a client"
      formControlName="searchInput"
      [matAutocomplete]="auto"
    />
    <mat-icon matSuffix>search</mat-icon>
    <mat-autocomplete
      #auto="matAutocomplete"
      autoActiveFirstOption
      (optionSelected)="handleSelect($event)"
      [displayWith]="displayFn"
    >
      <ng-container *ngIf="isLoading">
        <mat-option></mat-option>
        <mat-option></mat-option>
        <ngx-loading
          [show]="isLoading"
          [config]="{ fullScreenBackdrop: false }"
        ></ngx-loading>
      </ng-container>
      <ng-container *ngIf="!isLoading">
        <mat-option *ngFor="let option of filteredSearch" [value]="option"
          >{{ option.first }} {{ option.last }}</mat-option
        >
      </ng-container>
    </mat-autocomplete>
    <button
      mat-button
      *ngIf="searchValue"
      matSuffix
      mat-icon-button
      aria-label="Clear"
      class="clearButton"
      (click)="clearSearch()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
</form>
<!-- <mat-form-field>
  <input matInput type="text" placeholder="Search For a Client" [(ngModel)]="searchValue" />
  <mat-icon matSuffix *ngIf="!searchValue.length">search</mat-icon>
  <mat-icon matSuffix *ngIf="searchValue.length">close</mat-icon>
</mat-form-field> -->
