import { of as observableOf } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RightBridgeApiService } from './right-bridge-api.service';
import { SessionStorageService } from './session-storage.service';
import { Observable } from 'rxjs/Rx';

@Injectable()
export class SsoVerifyService {
  constructor(
    private rbs: RightBridgeApiService,
    private router: Router,
    private ss: SessionStorageService
  ) {}

  verify(params): Observable<boolean> {
    const session: string = params.session || null,
      org: string = params.org || null;

    if (session && org) {
      this.rbs.querySession(session, org).pipe(
        map(data => {
          const auth = Number(data.results) > 0;
          return observableOf(auth);
        })
      );
    } else {
      return observableOf(false);
    }
  }
}
