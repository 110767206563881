import { AutofillMonitor } from '@angular/cdk/text-field';
import {
  Component,
  ElementRef,
  Output,
  EventEmitter,
  ViewChildren,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { FormService } from '../../form-service.service';

@Component({
  selector: 'app-form-hash',
  styleUrls: ['form-hash.component.scss'],
  template: `
    <label
      class="section col-12"
      *ngIf="props.label"
      [innerHTML]="props.label"
    ></label>
    <p *ngIf="props.prompt" class="explanation" [innerHTML]="props.prompt"></p>
    <mat-form-field floatPlaceholder="auto" class="w-100">
      <mat-label>{{ to.placeholder }}</mat-label>
      <input
        type="password"
        autocomplete="nope"
        name="hash"
        [id]="field.key"
        #hash
        matInput
        class="real-value"
        data-lpignore="true"
        [placeholder]="props.placeholder || props.label"
        [formControl]="formControl"
        [formlyAttributes]="field"
        [required]="props.required"
        (focus)="setValue($event)"
        (blur)="hasheroo($event)"
        [maxlength]="props.maxLength"
      />
      <mat-icon
        *ngIf="props.help"
        class="help-icon"
        matTooltip="{{ to.help }}"
        [matTooltipPosition]="'above'"
        [matTooltipClass]="'rb-tooltip'"
      >
        help
      </mat-icon>
      <mat-error
        *ngIf="
          formControl.hasError('required') &&
          !formControl.hasError('regRequired')
        "
      >
        This field is required
      </mat-error>
      <mat-error *ngIf="formControl.hasError('regRequired')">
        This field is needed for suitability review. You can opt out below if
        the applicant refuses to provide the information.
      </mat-error>
    </mat-form-field>
  `,
})
export class FormlyHashComponent
  extends FieldType
  implements AfterViewInit, OnDestroy
{
  @Output() hashComplete = new EventEmitter();
  @ViewChildren('hash', { read: ElementRef }) hashFields;
  returnedHash: string;
  bullets: string;
  hashee = '';
  autofilledField = false;

  constructor(
    private frmSvc: FormService,
    private autofill: AutofillMonitor
  ) {
    super();
  }

  ngAfterViewInit() {
    const hashField = this.hashFields.find(
      field => field.nativeElement.id === this.field.key
    );
    this.autofill.monitor(hashField).subscribe(e => {
      this.autofilledField = e.isAutofilled;
      if (e.isAutofilled) {
        this.formControl.setValue(null);
        this.formControl.reset();
      }
    });
  }

  ngOnDestroy() {
    if (this.hashFields) {
      const hashField = this.hashFields.find(
        field => field.nativeElement.id === this.field.key
      );
      this.autofill.stopMonitoring(hashField);
    }
  }

  setValue(ev) {
    if (ev.type == 'focus' && !this.autofilledField) {
      this.hashee = this.formControl.value;
    } else if (this.autofilledField) {
      this.formControl.setValue(null);
    }
  }

  hasheroo(ev) {
    if (
      ev.type == 'blur' &&
      this.formControl.value &&
      this.formControl.value.length <= 11 &&
      this.formControl.value.length > 0 &&
      !this.autofilledField
    ) {
      const sbmt = this.formControl.value.replace(/\D/g, '');
      this.frmSvc.hashInput(sbmt, this.props.hashType).subscribe(x => {
        this.returnedHash = x.result;
        this.formControl.setValue(this.returnedHash);
        this.hashComplete.emit(ev);
      });
    } else if (this.formControl.value && this.formControl.value.length < 11) {
      this.formControl.setValue(null);
    }
  }

  addToModel(field, value) {
    this.model[field] = value;
  }
}
