export const scoreClassKey = {
  Positive: 'check_circle',
  Warning: 'error',
  Negative: 'remove_circle',
};
export const accountGroupDetailsKey = [
  { varName: 'AccountNumDisplay', label: 'Account #' },
  { varName: 'OwnerNameFull', label: 'Owner Name' },
  { varName: 'MaskedSSN', label: 'SSN/TIN' },
  { varName: 'Balance', label: 'Balance', valueMask: 'currency' },
  { varName: 'OpenDate', label: 'Opened' },
  { varName: 'LastTradeDate', label: 'Last Activity' },
  { varName: 'Registration', label: 'Registration' },
  { varName: 'Managed', label: 'Managed' },
  { varName: 'RepID', label: 'Rep Code' },
];
