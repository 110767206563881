const gridSortText = {
  string: {
    asc: 'Sort A->Z',
    desc: 'Sort Z->A',
  },
  date: {
    asc: 'Sort Old->New',
    desc: 'Sort New->Old',
  },
  numeric: {
    asc: 'Sort Low->High',
    desc: 'Sort High->Low',
  },
  default: {
    asc: 'Sort Ascending',
    desc: 'Sort Descending',
  },
};

const numericTypes = [
  'perc',
  'perc2',
  'perc3',
  'double',
  'curr',
  'integer',
  'num',
];

export const getFilterMenuText = function (columnType, sortDirection) {
  if (gridSortText[columnType]) {
    return gridSortText[columnType][sortDirection];
  }
  if (numericTypes.includes(columnType)) {
    return gridSortText['numeric'][sortDirection];
  }
  return gridSortText['default'][sortDirection];
};
