import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogContent,
  MatDialogModule,
} from '@angular/material/dialog';
import { AccountsService } from '../../../account-management/services/accounts-service.service';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { SimpleLoadingComponent } from '../../../shared-components/simple-loading/simple-loading.component';

@Component({
  selector: 'app-iw-product-shelf-modal',
  standalone: true,
  imports: [
    MatDialogContent,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    FormsModule,
    MatDialogModule,
    SimpleLoadingComponent,
  ],
  templateUrl: './iw-product-shelf-modal.component.html',
  styleUrl: './iw-product-shelf-modal.component.scss',
})
export class IWProductShelfModalComponent implements OnInit {
  filterOptions = [];
  gridData: { data: any; headers: any; subHead: Map<any, any>; total: number };
  programName: string;
  selectedFilterOption: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private acctSrvc: AccountsService,
    public dialogRef: MatDialogRef<IWProductShelfModalComponent>
  ) {}

  ngOnInit(): void {
    this.acctSrvc.programSearch(true).subscribe(res => {
      if (res.results.length > 0) {
        res.results.map(prog => {
          this.filterOptions.push(prog.DisplayName);
        });
      }
    });
  }
  sendProgramOut() {
    this.dialogRef.close(this.programName);
  }
}
