<app-toolbar
  [hideReportType]="true"
  (unitAppSelected)="getUnitProfileHistory($event)"
  (unitAppReport)="getUnitProfileReport($event)"
  class="row w-100"
></app-toolbar>
<kendo-grid
  *ngIf="unitProfileHistoryData"
  class="col-12 mt-5 load-history-grid"
  [kendoGridBinding]="unitProfileHistoryData"
  [height]="600"
  [selectable]="true"
>
  <kendo-grid-column>
    <ng-template kendoGridCellTemplate let-dataItem>
      <button mat-button color="primary" class="mr-3" *hasPermission="'UnitProfileDownload'" [matTooltip]="'Download This Unit Profile as JSON'" (click)="download($event, dataItem.ID, 'json')">
        <mat-icon class="mr-2">file_download</mat-icon>JSON
      </button>
      <button mat-button color="primary" class="mr-3" *hasPermission="'UnitProfileDownload'" [matTooltip]="'Download This Unit Profile as XML'" (click)="download($event, dataItem.ID, 'xml')">
        <mat-icon class="mr-2">file_download</mat-icon>XML
      </button>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="ChangeDate" title="Date"> </kendo-grid-column>
  <kendo-grid-column field="ID" title="ID"> </kendo-grid-column>
  <kendo-grid-column field="KBVersion" title="KB Version"> </kendo-grid-column>
  <kendo-grid-column field="Solution" title="Solution"> </kendo-grid-column>
  <kendo-grid-column field="UnitID" title="Unit ID"> </kendo-grid-column>
  <kendo-grid-column field="UserId" title="User"> </kendo-grid-column>
</kendo-grid>
<ngx-loading [show]="loading"></ngx-loading>
