<div mat-dialog-header>
  <h1>Confirm Unit Profile</h1>
</div>
<div mat-dialog-content class="dialog-content">
  <mat-form-field class="col-6 p-0">
    <mat-label>Bug Tracker ID</mat-label>
    <input
      type="text"
      placeholder="Bug Tracker ID"
      matInput
      name="bugTrackerId"
      [(ngModel)]="bugTrackerId"
    />
  </mat-form-field>

  <div class="mb-3">
    Add a note to justify changes to Unit Profile.
  </div>
  <div class="mb-3">
    <strong>
      IMPORTANT:<br />
      <ol>
        <li>Notes for Unit Profiles will be seen by Home Office Users in the Unit History Report.</li>
        <li>Unit Profile notes should be a high level description. All details are logged in bug tracker (documents, pictures).</li>
        <li>Include the bug tracker id, or create one if it does not exist, for all Unit Profiles.</li>
      </ol>
    </strong>
  </div>
  
  <mat-form-field class="w-100">
    <mat-label>Enter Notes Here</mat-label>
    <textarea
      matInput
      [placeholder]="'Enter Notes Here'"
      [(ngModel)]="note"
      (keyup)="noteEntry.next($event)"
    ></textarea>
  </mat-form-field>
</div>
<div class="buttons">
  <button
    class="pull-right"
    mat-raised-button
    color="accent"
    (click)="saveUnit()"
    [disabled]="(!note || !fieldTouched) && noteRequired"
  >
    <mat-icon>save</mat-icon> Save
  </button>
  <button
    class="pull-right mr-3"
    mat-button
    color="warn"
    (click)="close()"
  >
    Cancel
  </button>
</div>