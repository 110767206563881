import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-page-not-found',
  standalone: true,
  imports: [MatIconModule, MatButtonModule],
  templateUrl: './page-not-found.component.html',
  styleUrl: './page-not-found.component.scss',
})
export class PageNotFoundComponent {
  constructor(
    private location: Location,
    private router: Router
  ) {}

  notFoundNavigation(path: string) {
    switch (path) {
      case 'back':
        this.location.back();
        break;
      case 'home':
        this.router.navigate(['']);
        break;
      default:
        break;
    }
  }
}
