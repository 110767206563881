import { AfterContentInit, Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-form-drop-exp',
  styleUrls: ['form-dropExp.component.scss'],
  template: `
    <label
      class="section w-100"
      [class.mat-error]="formControl.errors && formControl.touched"
      *ngIf="props.label"
      [innerHTML]="props.label"
      ><sup *ngIf="props.required">*</sup></label
    >
    <p *ngIf="props.prompt" class="explanation" [innerHTML]="props.prompt"></p>

    <mat-form-field class="w-100">
      <mat-label>{{ to.placeholder }}</mat-label>
      <mat-select
        [formControl]="formControl"
        [placeholder]="props.placeholder || props.label"
        [formlyAttributes]="field"
        class="w-100"
        [required]="props.required"
        (selectionChange)="props.change ? to.change(field, formControl) : ''"
        disableOptionCentering
      >
        <mat-option *ngFor="let opt of props.options" [value]="opt.value">{{
          opt.label
        }}</mat-option>
      </mat-select>
      <mat-icon
        *ngIf="props.help"
        class="help-icon"
        matTooltip="{{ to.help }}"
        [matTooltipPosition]="'above'"
        [matTooltipClass]="'rb-tooltip'"
        >help</mat-icon
      >
      <mat-error
        *ngIf="
          formControl.hasError('required') &&
          !formControl.hasError('regRequired')
        "
        >This field is required</mat-error
      >
      <mat-error *ngIf="formControl.hasError('regRequired')"
        >This field is needed for suitability review. You can opt out below if
        the applicant refuses to provide the information.</mat-error
      >
    </mat-form-field>
  `,
})
export class FormlyDropExpComponent extends FieldType implements AfterContentInit {
  ngAfterContentInit(): void {
 
    if(this.key === 'AnnuityWizard_TermToValidate') {
      this.props.options = this.field.parent.parent.model.multiTermOptions
    }
  }
}
