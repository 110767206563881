import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { SessionStorageService } from '../../../services/session-storage.service';
import { UnifiedFlowService } from '../../../unified-flow/unified.service';
import { ActivatedRoute } from '@angular/router';
import { Global } from '../../../models/global.model';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { RightBridgeApiService } from '../../../services/right-bridge-api.service';

@Component({
  selector: 'app-form-repcode',
  styleUrls: ['form-repCode.component.scss'],
  template: `
    <label
      class="section w-100"
      [class.mat-error]="formControl.errors && formControl.touched"
      *ngIf="props.label"
      [innerHTML]="props.label"
      ><sup *ngIf="props.required">*</sup></label
    >
    <p *ngIf="props.prompt" class="explanation" [innerHTML]="props.prompt"></p>
    <mat-form-field class="w-100">
      <mat-label>{{ loading ? 'Loading Rep Codes...' : to.label }}</mat-label>
      <input
        type="text"
        [placeholder]="props.placeholder || props.label"
        aria-label="Rep Code Search"
        matInput
        [formControl]="formControl"
        [matAutocomplete]="repAutocomplete"
        [required]="props.required"
        [formlyAttributes]="field"
        class="w-100"
      />
      <mat-autocomplete
        #repAutocomplete="matAutocomplete"
        (optionSelected)="autoCompleteRepChanged($event)"
      >
        @for (opt of repUnits; track opt) {
          <mat-option
            [value]="opt"
            matTooltip="{{ opt.name }} ({{ opt.repCode }})"
            matToolTipPosition="before"
            matTooltipShowDelay="750"
            >{{ opt.repName }} ({{ opt.repCode }})</mat-option
          >
        }
      </mat-autocomplete>
      @if (
        formControl.hasError('required') && !formControl.hasError('regRequired')
      ) {
        <mat-error>This field is required</mat-error>
      }
      @if (formControl.hasError('regRequired')) {
        <mat-error
          >This field is needed for suitability review. You can opt out below if
          the applicant refuses to provide the information.</mat-error
        >
      }
    </mat-form-field>
  `,
})
export class FormlyRepCodeComponent extends FieldType implements OnInit {
  globals: Global;
  disabledDrop = false;
  appStatus = false;
  profile = '';
  loading = false;
  repCodeTypeAhead = false;
  filteredReps: Observable<string[]>;
  repUnits: {
    repCode: string;
    repName: string;
    name?: string;
    unitId?: string;
    unitName?: string;
    unitType?: string;
  }[];
  rawRepCodes: any;
  requestRepsSearch: boolean;

  constructor(
    private ss: SessionStorageService,
    private unfFlowSvc: UnifiedFlowService,
    private route: ActivatedRoute,
    private rbs: RightBridgeApiService,
    private changeDetector: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.loading = true;
    this.globals = this.ss.get('globals');
    this.rawRepCodes = this.ss.get('repUnits');

    this.route.params.subscribe(params => {
      this.profile = params.id ? params.id : null;
    });
    if (
      this.globals &&
      this.globals.user.managerUnitCount &&
      this.globals.user.managerUnitCount > 0
    ) {
      this.requestRepsSearch = true;
    } else {
      this.requestRepsSearch = false;
    }

    if (this.profile) {
      if (this.requestRepsSearch) {
        this.repCodeTypeAhead = true;
        this.formControl.setValue(null);
        this.setUpTypeAhead();
      } else {
        this.repCodeTypeAhead = false;
        this.unfFlowSvc.getApps(this.profile).subscribe(x => {
          x.Modules.map(module => {
            if (module.vars.ModuleStatus.FlowStatus === 'C') {
              this.appStatus = true;
            }
          });
          this.setUpDropDown();
        });
      }
    }
  }

  setUpTypeAhead() {
    this.loading = false;
    if (this.formControl.enabled) {
      this.formControl.valueChanges
        .pipe(debounceTime(500), distinctUntilChanged())
        .subscribe(value => {
          if (value && typeof value === 'string' && value.length > 0) {
            return this._filterReps(value);
          } else {
            if (this.requestRepsSearch) {
              this.repUnits = [];
            } else {
              this.repUnits = this.rawRepCodes;
            }
            this.changeDetector.detectChanges();
          }
        });
    }
  }

  private _filterReps(value: string) {
    if (this.requestRepsSearch) {
      this.rbs.getRepUnits(null, value, value).subscribe(res => {
        if (res && res.length > 0) {
          this.repUnits = res;
          this.changeDetector.detectChanges();
        } else if (!res || res.length === 0) {
          this.repUnits = [];
          this.changeDetector.detectChanges();
        }
      });
    } else {
      this.repUnits = this.rawRepCodes.filter(
        rep =>
          rep.repName.toLowerCase().includes(value.toLowerCase()) ||
          rep.repCode.toLowerCase().includes(value.toLowerCase())
      );
      if (this.repUnits.length === 0) {
        this.repUnits = this.rawRepCodes;
      }
      this.changeDetector.detectChanges();
    }
  }

  autoCompleteRepChanged(ev) {
    this.formControl.setValue(ev.option.value.repCode);
  }

  displayRepCode(opt) {
    return opt ? opt.name : '';
  }

  setUpDropDown() {
    if (!this.rawRepCodes || this.rawRepCodes.length === 0) {
      this.rbs.getRepUnits().subscribe(res => {
        this.rawRepCodes = res.names;
        this.repCodeEval();
      });
    } else {
      this.repCodeEval();
    }
    this.setUpTypeAhead();
  }

  repCodeEval() {
    const dropOptions = this.rawRepCodes.map(x => x.repCode);
    this.repUnits = this.rawRepCodes.map(x => {
      return { repCode: x.repCode, repName: x.repName };
    });
    if (dropOptions.length < 1 && this.props.rawValues) {
      this.repUnits = [
        {
          repCode: this.props.rawValues['rawValue'],
          repName: this.props.rawValues['display'],
        },
      ];
      this.formControl.disable();
    } else if (dropOptions.length === 1 && this.formControl.value !== null) {
      this.formControl.disable();
    } else if (
      (dropOptions.length > 0 && this.formControl.value === null) ||
      (dropOptions.length > 0 &&
        this.formControl.value !== null &&
        dropOptions.includes(this.formControl.value)) ||
      !this.profile ||
      (this.profile && !this.appStatus)
    ) {
      this.repUnits = this.rawRepCodes.map(x => {
        return { repCode: x.repCode, repName: x.repName };
      });
      this.formControl.setValue(null);
    } else {
      this.formControl.disable();
    }
    this.loading = false;
    this.changeDetector.detectChanges();
  }
}
