@if (externalLink) {
  <a
    class="tool-link"
    [href]="externalLink"
    target="_blank"
    [matTooltip]="tooltip"
    [matTooltipShowDelay]="1000"
  >
    <div class="tool-link-label">
      <mat-icon>launch</mat-icon> {{ toolLabel }}
    </div>
  </a>
} @else {
  <a
    class="tool-link"
    (click)="defaultClick.emit($event)"
    (auxclick)="secondaryClick.emit()"
    (contextmenu)="secondaryClick.emit()"
    [matTooltip]="tooltip"
    [matTooltipShowDelay]="1000"
  >
    <div class="tool-link-label">
      <mat-icon>launch</mat-icon> {{ toolLabel }}
    </div>
  </a>
}
