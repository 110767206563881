import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { Fund, Template } from '../../models/account-models';
import {
  switchMap,
  distinctUntilChanged,
  map,
  startWith,
  debounceTime,
  tap,
  finalize,
} from 'rxjs/operators';
import { AccountsService } from '../services/accounts-service.service';
import { cloneDeep } from 'lodash';
import { Observable } from 'rxjs';

import { MaskService } from '../../services/mask.service';

@Component({
  selector: 'app-template-modal',
  templateUrl: './template-modal.component.html',
  styleUrls: ['./template-modal.component.scss'],
})
export class TemplateModalComponent implements OnInit {
  percentMask;
  template: Template = {} as Template;
  manualFunds = [];
  programList;
  filteredPrograms: Observable<any[]>;
  filteredFunds = null;
  templateName = new FormControl();
  programControl = new FormControl();
  fundControl = new FormControl();
  firmTemplate = new FormControl();
  showFundAdd = false;
  selected = { fund: null, fundName: null, percent: null };
  shared = false;
  loading = false;
  disableShare = false;
  dropdownNoResults: boolean;
  dropdownLoading: boolean;
  showManualEntryButton: boolean;

  constructor(
    private dialogRef: MatDialogRef<TemplateModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private as: AccountsService,
    private mask: MaskService,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.loading = true;
    this.as.programSearch(true).subscribe(x => {
      this.programList = x.results;
      this.searchPrograms();
      this.searchFund();

      if (this.data) {
        const nonManualFunds = [];
        this.template = cloneDeep(this.data);
        this.template.funds.forEach(fund => {
          fund.percent = fund.percent * 100;

          if (fund['manual']) {
            this.manualFunds.push(fund);
          } else {
            nonManualFunds.push(fund);
          }
        });

        this.template.funds = nonManualFunds;

        this.shared = this.data.scope == 'private';

        const selected = this.programList.find(
          x => x.ProgramID == this.template.program
        );
        this.programControl.setValue(selected);
        if (selected.ManualEntry === 'Y') {
          this.showManualEntryButton = true;
        }
        this.fundControl.enable();
      }

      this.loading = false;
    });
    this.fundControl.disable();
    this.percentMask = this.mask.percentMaskSpecs(2);
  }

  searchPrograms() {
    this.filteredPrograms = this.programControl.valueChanges.pipe(
      startWith(''),
      map(program => {
        if (typeof program === 'string') {
          return this._filterPrograms(program);
        }
      })
    );
  }

  searchFund() {
    this.fundControl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.dropdownNoResults = false;
          this.dropdownLoading = true;
          this.changeDetector.detectChanges();
        }),
        distinctUntilChanged(),
        switchMap((fund: string) => {
          if (fund) {
            return this._filterFunds(fund).pipe(
              finalize(() => {
                this.dropdownLoading = false;
                this.changeDetector.detectChanges();
              })
            );
          } else if (!fund) {
            this.dropdownLoading = false;
            this.filteredFunds = null;
            this.changeDetector.detectChanges();
          }
          return [];
        })
      )
      .subscribe(results => {
        if (!results) {
          this.dropdownNoResults = true;
        } else {
          this.filteredFunds = results;
          this.dropdownNoResults = false;
        }
        this.changeDetector.detectChanges();
      });
  }

  deleteFund(id, isManualFund = false) {
    if (isManualFund) {
      const idx = this.manualFunds.findIndex(x => x.ticker === id);
      this.manualFunds.splice(idx, 1);
    } else {
      const idx = this.template.funds.findIndex(x => x.ticker === id);
      this.template.funds.splice(idx, 1);
    }
  }

  saveFund() {
    const selectedFund: Fund = {
      ticker: this.selected.fund,
      name: this.selected.fundName,
      percent: this.selected.percent,
    };
    if (this.template.funds) {
      this.template.funds.push(selectedFund);
    } else {
      this.template.funds = [];
      this.template.funds.push(selectedFund);
    }
    this.selected = { fund: null, fundName: null, percent: null };
    this.fundControl.setValue(null);
    // this.programControl.setValue(null);
  }

  addManualFund() {
    const newFund = {
      ticker: '',
      name: '',
      percent: null,
      manual: true,
    };
    this.manualFunds.push(newFund);
  }

  validateManualFunds() {
    return (
      this.manualFunds.filter(
        manualFund =>
          manualFund.ticker === '' ||
          manualFund.name === '' ||
          manualFund.percent === null ||
          manualFund.percent === ''
      ).length > 0
    );
  }

  saveData() {
    if (!this.templateName.value) {
      this.templateName.markAsTouched();
      this.templateName.setErrors({ required: true });
    } else {
      if (this.template.funds.length > 0) {
        this.template.funds.forEach(fund => {
          fund.percent = this.mask.removePercentMask(fund.percent);
        });
      }

      // If there are manual funds format and add them to the template funds
      if (this.manualFunds.length > 0) {
        this.manualFunds.forEach(fund => {
          fund.percent = this.mask.removePercentMask(fund.percent);
          this.template.funds.push(fund);
        });
      }
      this.template.name = this.templateName.value;

      if (this.shared && this.template.scope !== 'firm') {
        this.template.scope = 'private';
      } else if (!this.shared && this.template.scope !== 'firm') {
        this.template.scope = 'off';
      }

      this.dialogRef.close({ data: this.template });
      this.dialogRef.afterClosed().subscribe(() => {
        this.dialogRef = null;
      });
    }
  }

  close() {
    this.dialogRef.close();
    this.dialogRef.afterClosed().subscribe(() => {
      this.dialogRef = null;
    });
  }

  displayProg(program): string | undefined {
    return program ? program.DisplayName : undefined;
  }

  displayFund(fund): string | undefined {
    return fund ? `${fund.FundID} - ${fund.FundName}` : undefined;
  }

  handleProgSelect(ev) {
    if (ev.option.value.ManualEntry === 'Y') {
      this.showManualEntryButton = true;
    } else {
      this.showManualEntryButton = false;
    }
    this.manualFunds = [];
    this.template.funds = [];
    this.template.program = ev.option.value.ProgramID;
    this.fundControl.enable();
  }

  clearProgram() {
    this.manualFunds = [];
    this.template.funds = [];
    this.template.program = null;
    this.programControl.setValue('');
    this.fundControl.disable();
    this.fundControl.setValue(null);
  }

  handleFundSelect(ev) {
    this.selected.fund = ev.option.value.FundID;
    this.selected.fundName = ev.option.value.FundName;
  }

  checkFundStatus(ev) {
    if (!ev.target.value || (ev.target.value && !ev.target.value.length)) {
      this.template.program = null;
      this.selected.fund = null;
      this.selected.fundName = null;
      this.fundControl.disable();
      this.fundControl.setValue(null);
    }
  }

  private _filterFunds(value: string) {
    const prog =
      this.programControl.value.NonFiltered &&
      this.programControl.value.NonFiltered.toLowerCase() == 'y'
        ? null
        : this.programControl.value.ProgramID;
    const list = this.as.fundSearch(value, prog).pipe(
      map(x => {
        if (x.results && x.results.length > 0) {
          return x.results;
        }
      })
    );
    return list;
  }

  private _filterPrograms(value): any[] {
    if (value.length < 1) {
      return this.programList;
    }
    return this.programList.filter(x => {
      return x.DisplayName.toLowerCase().includes(value.toLowerCase());
    });
  }

  setScope() {
    this.disableShare = !this.disableShare;
    if (this.disableShare) {
      this.shared = false;
      this.template.scope = 'firm';
    }
  }

  clearFundSearch(ev) {
    ev.stopPropagation();
    this.fundControl.setValue(null);
    this.selected.fund = null;
    this.selected.fundName = null;
    this.searchFund();
  }
}
