import { Component, OnInit } from '@angular/core';
// import { environment } from '../../../../environments/environment';
import { SessionStorageService }  from '../../../services/session-storage.service'
import { EnvironmentService } from '../../../services/environment.service';

@Component({
  selector: 'app-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss']
})
export class UploaderComponent implements OnInit {
  private environment;
  externalUrl;
  session: string = this.ss.get('session');

  constructor(
    private ss: SessionStorageService,
    private envSvc: EnvironmentService
  ) { }

  ngOnInit() {
    this.environment = this.envSvc.get();

    let parser = document.createElement('a');
    parser.href = this.environment.apiBase;
    this.externalUrl = `${parser.protocol}//${parser.hostname}/cetera/rbm.aspx#upload-page`;
  }
}
