<label *ngIf="props.label" [innerHTML]="props.label"></label>
<button
  mat-button
  *ngIf="scrollMode === 'virtual'"
  color="primary"
  class="grid-button header-button pull-right"
  (click)="exportToExcel(dataGrid)"
>
  <mat-icon>cloud_download</mat-icon>Download Excel File
</button>
<kendo-grid
  class="col-12"
  #dataGrid="kendoGrid"
  [kendoGridBinding]="gridData"
  [pageable]="true"
  [sortable]="{ allowUnsort: true, mode: 'multiple' }"
  [filterable]="'menu'"
  [columnMenu]="true"
  [height]="gridHeight"
  [selectable]="{
    enabled: isSelectable(),
    mode: 'multiple',
    checkboxOnly: true
  }"
  [rowSelected]="rowSelected"
  (selectionChange)="onSelect($event)"
  (detailExpand)="onExpandHandler($event)"
  [scrollable]="scrollMode"
>
  <kendo-grid-checkbox-column
    showSelectAll="true"
    *ngIf="selectColumn"
  ></kendo-grid-checkbox-column>
  <ng-template ngFor [ngForOf]="columns" let-column>
    <kendo-grid-column
      field="{{ column.DataField }}"
      title="{{ column.Label }}"
      [hidden]="isHidden(column.DataField)"
    >
      <ng-template
        kendoGridCellTemplate
        let-dataItem
        *ngIf="column.ControlType"
        let-columnIndex="columnIndex"
        let-rowIndex="rowIndex"
      >
        <mat-form-field *ngIf="column.ControlType === 'dropDown'">
          <mat-select
            placeholder="Set {{ column.Label }}"
            (selectionChange)="
              selectionEvent($event, rowIndex, column.DataField)
            "
            [value]="dataItem[column.DataField]"
          >
            <mat-option
              *ngFor="let opt of column.options"
              [value]="opt.value"
              >{{ opt.label }}</mat-option
            >
          </mat-select>
        </mat-form-field>

        <mat-radio-group
          aria-label="Select an option"
          *ngIf="column.ControlType === 'radioButton'"
          (change)="selectionEvent($event, rowIndex, column.DataField)"
          [value]="dataItem[column.DataField]"
        >
          <mat-radio-button
            value="{{ radio.value }}"
            *ngFor="let radio of column.options"
            >{{ radio.label }}</mat-radio-button
          >
        </mat-radio-group>

        <mat-form-field *ngIf="column.ControlType === 'text'">
          <!-- <mat-label>{{to.label}}</mat-label> -->
          <!-- [placeholder]="props.placeholder || props.label" -->
          <input
            matInput
            [value]="dataItem[column.DataField]"
            (keyup)="textChange($event, rowIndex, column.DataField, dataItem)"
          />
        </mat-form-field>
        <!-- (input)="textChange($event, rowIndex, column.DataField, dataItem)" -->
        <mat-form-field *ngIf="column.ControlType === 'number'">
          <!-- <mat-label>{{to.label}}</mat-label> -->
          <!-- [placeholder]="props.placeholder || props.label" -->
          <input
            matInput
            type="number"
            [value]="dataItem[column.DataField]"
            (keyup)="textChange($event, rowIndex, column.DataField, dataItem)"
          />
        </mat-form-field>
        <!-- (input)="textChange($event, rowIndex, column.DataField, dataItem)" -->
        <mat-form-field *ngIf="column.ControlType === 'currency'">
          <!-- <mat-label>{{to.label}}</mat-label> -->
          <!-- [placeholder]="props.placeholder || props.label" -->
          <input
            matInput
            type="number"
            [value]="dataItem[column.DataField]"
            [maskito]="dollarMask"
            maxlength="20"
            (keyup)="textChange($event, rowIndex, column.DataField, dataItem)"
          />
        </mat-form-field>
        <!-- (input)="textChange($event, rowIndex, column.DataField, dataItem)" -->
        <mat-form-field *ngIf="column.ControlType === 'datePicker'">
          <mat-label>Choose a date</mat-label>
          <input matInput [matDatepicker]="picker" />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </ng-template>
    </kendo-grid-column>
  </ng-template>
  <ng-template
    kendoGridDetailTemplate
    let-dataItem
    [kendoGridDetailTemplateShowIf]="showSubgrid"
  >
    <section>
      <table>
        <thead>
          <ng-container *ngFor="let col of dataItem['subgrid'].headers">
            <th *ngIf="!col.Hidden">
              {{ col.Label }}
            </th>
          </ng-container>
        </thead>
        <tbody>
          <tr *ngFor="let subItem of dataItem['subgrid'].data">
            <ng-container *ngFor="let col of dataItem['subgrid'].headers">
              <td *ngIf="!col.Hidden">
                <ng-container *ngIf="col.IsLink; else dataDisplay">
                  <mat-checkbox
                    (change)="
                      subRowSelected($event, subItem, dataItem['subgridDef'])
                    "
                    [(ngModel)]="subItem[col.DataField]"
                  >
                  </mat-checkbox>
                </ng-container>
                <ng-template #dataDisplay>
                  {{ subItem[col.DataField] }}
                </ng-template>
              </td>
            </ng-container>
          </tr>
        </tbody>
      </table>
    </section>
  </ng-template>
  <kendo-grid-excel [fetchData]="excelData"></kendo-grid-excel>
</kendo-grid>
<ngx-loading
  [show]="loading"
  [config]="{ fullScreenBackdrop: false }"
></ngx-loading>
