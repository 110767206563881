import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as env from '../../env.json';
import {throwError as observableThrowError, Observable } from 'rxjs';
import { catchError } from "rxjs/operators";
import { SessionStorageService } from './session-storage.service'

@Injectable({ providedIn: 'root' })
export class EnvironmentService {

  environment;

  constructor(
    private http: HttpClient,
    private ss: SessionStorageService
  ) {  }

  private envVars;

  load() {
    this.loadSettings();
  }

  get() {
    // let environment = this.ss.get('env');
    if (!this.environment) {
      this.loadSettings();
      // this.environment = this.ss.get('env');
      return this.environment;
    }

    return this.environment;
  }

  loadSettings() {
    let url = window.location.href;

    return new Promise((resolve, reject) => {
      if(!url.includes('localhost')) {
        this.http.get('env.json?v=1.1').toPromise().then(envResponse => {
            this.environment = envResponse;
            this.ss.set('env', this.environment);
            resolve(true);
        }),
        catchError(error => {
          resolve(error);
          return observableThrowError(error.error || 'Server error')
        });
      } else {
        this.environment = env['default'];
        this.ss.set('env', this.envVars);
        resolve(true);
      }

    });
  }
  }
