<div class="policy-details-container">
  <h1 class="header col-12 mb-2"> Annuity Policy Details </h1>
  <div class="action-buttons-container mb-2">
    <button
      class="nav-back-button"
      mat-button
      (click)="nav('book-management')"
      color="primary"
    >
      <mat-icon>arrow_back_ios</mat-icon> Back to Book Management
    </button>
    <button
      mat-flat-button
      color="accent"
      class="launch-aw-button"
      (click)="launchToAW()"
    >
      <mat-icon>rocket_launch</mat-icon>Launch to Annuity Wizard
    </button>
  </div>

  <mat-tab-group>
    <mat-tab>
      <ng-template matTabLabel>
        <mat-icon class="mr-2">assignment_ind</mat-icon>Policy Summary
      </ng-template>

      @for (policyRow of policyDetails; track policyRow.title) {
        <div class="info-card mt-4">
          <div class="card-header">
            <span class="card-title mb-0"> {{ policyRow.title }} </span>
          </div>

          <div
            class="policy-tables {{
              policyRow.tables.length > 1 ? 'multi-table' : ''
            }}"
          >
            @for (policyTable of policyRow.tables; track policyTable) {
              <div
                class="policy-table-container tables{{
                  policyRow.tables.length
                }} {{
                  policyTable.tableType === 'datapoints' ? 'no-scroll' : ''
                }}"
              >
                @switch (policyTable.tableType) {
                  @case ('datapoints') {
                    <div class="card-info row">
                      @for (
                        tableHeader of policyTable.headers;
                        track tableHeader.label
                      ) {
                        @if (policyTable.data[0][tableHeader.key]) {
                          <div
                            class="col-sm-{{ policyTable.cols || '4' }} col-12"
                          >
                            <div class="card-info-label">
                              {{ tableHeader.label }}
                            </div>
                            <div class="card-info-value mb-2">
                              {{ policyTable.data[0][tableHeader.key] }}
                            </div>
                          </div>
                        }
                      }
                    </div>
                  }
                  @case ('horizontal') {
                    <table class="horizontal">
                      <thead>
                        @for (
                          tableHeader of policyTable.headers;
                          track tableHeader.label
                        ) {
                          <th>
                            {{ tableHeader.label }}
                          </th>
                        }
                      </thead>
                      <tbody>
                        @for (tableRow of policyTable.data; track tableRow) {
                          <tr>
                            @for (
                              tableCell of policyTable.headers;
                              track tableCell.key
                            ) {
                              @if (tableRow[tableCell.key] !== '') {
                                <td>
                                  {{ tableRow[tableCell.key] }}
                                </td>
                              } @else {
                                <td class="empty-cell"></td>
                              }
                            }
                          </tr>
                        }
                      </tbody>
                    </table>
                  }
                  @case ('vertical') {
                    <table class="vertical">
                      <tbody>
                        @for (
                          tableRow of policyTable.headers;
                          track tableRow.label
                        ) {
                          <tr>
                            <th>{{ tableRow.label }}</th>
                            @if (
                              policyTable.data[0] &&
                              policyTable.data[0][tableRow.key] !== ''
                            ) {
                              <td>{{ policyTable.data[0][tableRow.key] }}</td>
                            } @else {
                              <td class="empty-cell"></td>
                            }
                          </tr>
                        }
                      </tbody>
                    </table>
                  }
                }
              </div>
            }
          </div>
        </div>
      }
    </mat-tab>

    <mat-tab>
      <ng-template matTabLabel>
        <mat-icon class="mr-2">account_balance</mat-icon>Policy Review
        Opportunities
      </ng-template>

      <ul class="policies">
        @for (policy of policies; track policy.name) {
          <li class="w-100 {{ policy.ScoreClass }} policy">
            <div class="policy-header">
              <span class="score-icon {{ policy.ScoreClass }}">
                @switch (policy.ScoreClass) {
                  @case ('positive') {
                    <mat-icon> check_circle </mat-icon>
                  }
                  @case ('neutral') {
                    <mat-icon> error </mat-icon>
                  }
                  @case ('negative') {
                    <mat-icon> remove_circle </mat-icon>
                  }
                }
              </span>

              <h4>
                <span>{{
                  policy.outline?.sections?.ShortName || policy.name
                }}</span>
                <span *hasPermission="'!RelevanceHideBridgeScore'">
                  | {{ policy.score }}</span
                >
              </h4>

              <div class="expand-button">
                <button mat-icon-button (click)="expand($event)">
                  <span class="collapsed-content">
                    <mat-icon>expand_circle_down</mat-icon>
                  </span>
                </button>
              </div>
            </div>

            <div class="policy-body">
              <div class="row">
                <app-reason-text
                  class="col-12"
                  [data]="policy.trueRules"
                ></app-reason-text>
              </div>
            </div>
          </li>
        }
      </ul>
    </mat-tab>
  </mat-tab-group>
</div>

<ngx-loading [show]="loading"></ngx-loading>
@if (loading) {
  <span class="policy-loading-text">
    Updating with latest policy information...
  </span>
}
