<div class="unified-questionnaire" #stepperContainer>
  <mat-stepper #stepper [selectedIndex]="stepIndex" (selectionChange)="stepChange($event)" hide-header [linear]="true">
    @for (step of field?.fieldGroup | visible; track index; let index = $index; let last = $last; let first = $first;)
    {
    @if (step.hide === undefined || !step.hide) {
    <mat-step>
      <!-- <ng-template matStepLabel>
        <span [ngClass]="{'error': checkStepValidity(index)}">{{ step.templateOptions.label }}</span>
        <span class="message">All Required Fields Have Not Been Filled Out</span>
      </ng-template> -->
      <ng-template matStepContent>
        <formly-field [field]="step"></formly-field>
      </ng-template>
    </mat-step>
    }
    }
  </mat-stepper>
</div>