import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'legacy-app-links',
  templateUrl: './legacy-app-links.component.html',
  styleUrls: ['./legacy-app-links.component.scss']
})
export class LegacyAppLinksComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
