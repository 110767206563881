import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SessionStorageService } from './session-storage.service';
import { throwError as observableThrowError, Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { EnvironmentService } from '../services/environment.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorReportingService {
  private environment;
  private baseUrl: string;
  private app: string;
  private org: string;

  constructor(
    private http: HttpClient,
    private ss: SessionStorageService,
    private envSvc: EnvironmentService
  ) {
    this.environment = this.envSvc.get();
    this.baseUrl = this.environment.apiBase;
    this.org = this.environment.org;
    this.app = this.ss.get('currentApp') || this.environment.defaultApp || 'pp';
    this.org = this.environment.org || 'basev2';
  }

  handleError(error, log = true) {
    let level = 'WARNING';
    const session = this.ss.get('session');
    if (log && this.environment.production) {
      if (error.status) {
        switch (error.status) {
          case 400:
            level = 'WARNING';
            break;
          case 403:
            level = 'WARNING';
            break;
          case 404:
            level = 'WARNING';
            break;
          case 500:
            level = 'ALERT';
            break;
          case 503:
            level = 'ALERT';
            break;
        }
      }
      this.loggingReport(level, error.message, error, session).subscribe();
    }
    return observableThrowError(error.error || 'Server error');
  }

  loggingReport(level, message, data, session): Observable<any> {
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', session)
      .set('X-RB-I', this.app)
      .set('X-RB-O', this.org)
      .set('Content-Type', 'application/json');
    const url = `${this.baseUrl}/Admin/WriteLogData`;
    const payload = {
      level: level,
      message: message,
      category: 'FRONT_END',
      data: data,
      status: data.status,
    };

    return this.http.post(url, payload, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return observableThrowError(error.error || 'Server error');
      })
    );
  }

  pagerDutyReport(): Observable<any> {
    const reqHeaders = new HttpHeaders().set(
      'Content-Type',
      'application/json'
    );
    const payload = {
      payload: {
        summary: 'Example alert on Frontend',
        timestamp: new Date().toDateString,
        source: 'Frontend',
        severity: 'info',
      },
      // routing_key: this.token,
      event_action: 'trigger',
    };

    return of('error Logging');
    // 
    // TODO: Implement Error Reporting Fallback
    // Removed api key for security reasons
    // 
    // return this.http.post(this.apiBase, payload, { headers: reqHeaders }).pipe(
    //   map(res => {
    //     return res;
    //   }),
    //   catchError((error: any) => {
    //     return observableThrowError(error.error || 'Server error');
    //   })
    // );
  }
}
