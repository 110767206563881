import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { SessionStorageService } from '../../services/session-storage.service';
import { pluck, map } from 'rxjs/operators';
import { UnifiedFlowService } from '../unified.service';

@Component({
  selector: 'app-unified-flow-search',
  templateUrl: './unified-flow-search.component.html',
  styleUrls: ['./unified-flow-search.component.scss'],
})
export class UnifiedFlowSearchComponent implements OnInit {
  rows = [];
  public search = '';
  searchControl = new FormControl();
  dataSource = [];
  rights = [];

  constructor(
    private router: Router,
    private unfFlowSvc: UnifiedFlowService,
    private ss: SessionStorageService
  ) {}

  ngOnInit(): void {
    this.rights = this.ss.get('rights');
  }

  handleSelect(result) {
    if (result.option.value) {
      this.search = result.option.value.Name;
      this.rows = this.rows.filter(x => {
        return (
          x.Name == result.option.value.Name ||
          x.caseNumber == result.option.value.caseNumber
        );
      });
    } else {
      this.rows = this.dataSource;
      this.searchControl.setValue('');
    }
  }

  onSelect(e) {
    let id;
    let app = e.app ? e.app : 'rb';

    if (e.id) {
      id = e.id;
      app = e.app;
      this.router.navigate([`/case/${app}/${id}/0`]);
    } else {
      id = e;

      this.unfFlowSvc
        .getApps(id)
        .pipe(
          pluck('Modules'),
          map(apps => {
            return apps.filter(x => {
              return (
                x.vars.ModuleStatus.FlowStatus == 'O' ||
                x.vars.ModuleStatus.FlowStatus == 'C'
              );
            });
          })
        )
        .subscribe(z => {
          app = z[0].name.toLowerCase();
          this.router.navigate([`/case/${app}/${id}/0`]);
        });
    }
  }
}
