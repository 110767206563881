import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppGlobalsService } from '../services/app-globals.service';

const routes: Routes = [
  {
    path: 'li',
    resolve: {
      globals: AppGlobalsService,
    },
    children: [
      // THE FOLLOWING ARE OLD ROUTES THAT HAVE BEEN
      // REDIRECTED TO THE LANDING PAGE
      {
        path: '',
        redirectTo: '/',
        pathMatch: 'full',
      },
      {
        path: 'search',
        redirectTo: '/',
        pathMatch: 'full',
      },
      {
        path: 'questionnaire',
        redirectTo: '/',
        pathMatch: 'full',
      },
      {
        path: 'questionnaire/:id',
        redirectTo: '/',
        pathMatch: 'full',
      },
      {
        path: 'results',
        redirectTo: '/',
        pathMatch: 'full',
      },
      {
        path: 'results/:id',
        redirectTo: '/',
        pathMatch: 'full',
      },
      {
        path: 'needs',
        redirectTo: '/',
        pathMatch: 'full',
      },
      {
        path: 'needs/:id',
        redirectTo: '/',
        pathMatch: 'full',
      },
      {
        path: 'compare',
        redirectTo: '/',
        pathMatch: 'full',
      },
      {
        path: 'compare/:id',
        redirectTo: '/',
        pathMatch: 'full',
      },
      {
        path: 'validate',
        redirectTo: '/',
        pathMatch: 'full',
      },
      {
        path: 'validate/:id',
        redirectTo: '/',
        pathMatch: 'full',
      },
      {
        path: 'products/:id',
        redirectTo: '/',
        pathMatch: 'full',
      },
      {
        path: 'help',
        redirectTo: '/',
        pathMatch: 'full',
      },
      {
        path: 'proxy',
        redirectTo: '/',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LiRouting {}
