import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { ConditionalFormModule } from '../conditional-form/conditional-form.module';

//Kendo Imports
import { GridModule } from '@progress/kendo-angular-grid';

//Highcharts
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import * as more from 'highcharts/highcharts-more.src';
import * as exporting from 'highcharts/modules/exporting.src';
import * as highcharts3d from 'highcharts/highcharts-3d.src';

export function highchartsModules() {
  // apply Highcharts Modules to this array
  return [more, exporting, highcharts3d];
}

//Services
import { AuthService } from '../services/auth-service.service';
import { RightBridgeApiService } from '../services/right-bridge-api.service';
import { LifeWizardService } from './life-wizard.service';

import { LiRouting } from './life-wizard-routing.module';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        LiRouting,
        FormsModule,
        ReactiveFormsModule,
        ConditionalFormModule,
        NgxDatatableModule,
        GridModule,
        // DynamicFormModule,
        NgxLoadingModule.forRoot({
            animationType: ngxLoadingAnimationTypes.circle,
            backdropBackgroundColour: 'rgba(0, 0, 0, 0.3)',
            fullScreenBackdrop: true,
            backdropBorderRadius: '0',
            primaryColour: 'rgb(38, 91, 151)',
            secondaryColour: 'rgba(38, 91, 151, 0.2)',
            tertiaryColour: '#265b97'
        }),
        FormlyModule.forRoot(),
        FormlyMaterialModule,
        SharedComponentsModule,
        ChartModule
    ],
    declarations: [
        // ResultComponent,
        // ScenariosComponent,
        // SliderChartsComponent,
        // ValidateComponent
    ],
    providers: [
        RightBridgeApiService,
        LifeWizardService,
        AuthService,
        { provide: HIGHCHARTS_MODULES, useFactory: highchartsModules }
    ],
    schemas: [
        NO_ERRORS_SCHEMA
    ]
})
export class LifeWizardModule { }
