import { Component, OnInit } from '@angular/core';
import { Decimal } from 'decimal.js-light';
import { FieldType } from '@ngx-formly/core';
import { MaskService } from '../../../services/mask.service';

@Component({
  selector: 'app-form-percentage',
  styleUrls: ['form-percentage.component.scss'],
  template: `
    <mat-form-field floatPlaceholder="auto" class="w-100">
      <mat-label>{{ to.label }}</mat-label>
      <input
        matInput
        [maskito]="percentMask"
        [placeholder]="props.placeholder || props.label"
        [formControl]="formControl"
        [formlyAttributes]="field"
        [required]="props.required"
        [defaultValue]="formControl.value || ''"
      />
      <mat-icon
        *ngIf="props.help"
        class="help-icon"
        matTooltip="{{ to.help }}"
        [matTooltipPosition]="'above'"
        [matTooltipClass]="'rb-tooltip'"
      >
        help
      </mat-icon>
      <mat-error
        *ngIf="
          formControl.hasError('required') &&
          !formControl.hasError('regRequired')
        "
      >
        This field is required
      </mat-error>
      <mat-error *ngIf="formControl.hasError('regRequired')">
        This field is needed for suitability review. You can opt out below if
        the applicant refuses to provide the information.
      </mat-error>
    </mat-form-field>
  `,
})
export class FormlyPercentageComponent extends FieldType implements OnInit {
  percentMask;

  constructor(public mask: MaskService) {
    super();
  }

  ngOnInit() {
    const precision = this.props.decimalPrecision
      ? this.props.decimalPrecision
      : 3;

    setTimeout(() => {
      if (this.formControl.value && this.formControl.value.replace) {
        this.formControl.setValue(
          new Decimal(+this.formControl.value.replace('%', ''))
            .todp(precision)
            .toNumber()
            .toString()
        );
      }
    });

    this.percentMask = this.mask.percentMaskSpecs(precision);
  }
}
