import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-consolidated-report-modal',
  templateUrl: './consolidated-report-modal.component.html',
  styleUrls: ['./consolidated-report-modal.component.scss'],
})
export class ConsolidatedReportModalComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ConsolidatedReportModalComponent>
  ) {}

  closeModal(ev) {
    this.dialogRef.close(ev);
  }
}
