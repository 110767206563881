import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SeRouting } from './suggestion-engine-routing.module';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { SuggestionEngineService } from './suggestion-engine.service';
// import { MatCardModule, MatIconModule, MatButtonModule, MatTooltipModule, MatDialogModule, MatRadioModule, MatFormFieldModule, MatCheckboxModule, MatInputModule } from '@angular/material';
// import { DashboardComponent } from './dashboard/dashboard.component';
// import { Top5Component } from './top5/top5.component';
// import { TopOppsComponent } from './topOpps/topOpps.component';
// import { TimeSensitiveComponent } from './timeSensitive/timeSensitive.component';
// import { FollowUpComponent } from './followUp/followUp.component';
// import { MessagesComponent } from './messages/messages.component';
// import { ScorecardComponent } from './scorecard/scorecard.component';
import { DetailDialogComponent } from './detail-dialog/detail-dialog.component';
import { ActionDialogComponent } from './action-dialog/action-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConditionalFormModule } from '../conditional-form/conditional-form.module';

@NgModule({
    imports: [
        CommonModule,
        SeRouting,
        SharedComponentsModule,
        FormsModule,
        ReactiveFormsModule,
        ConditionalFormModule
    ],
    declarations: [
        DetailDialogComponent,
        ActionDialogComponent,
    ],
    providers: [
        SuggestionEngineService
    ]
})
export class SuggestionEngineModule { }
