<div mat-dialog-title>
  <h1>I Like This</h1>
</div>
<div mat-dialog-content #scroll class="dialog-container">
  <form>
    <div class="row justify-content-center">
      <mat-radio-group [(ngModel)]="action.type" name="type" class="col-6" [value]="action.type">
        <mat-radio-button value="2335">Add to call list - and keep on top</mat-radio-button>
        <mat-radio-button value="2300">Keep it near the top of the suggestions</mat-radio-button>
        <mat-radio-button value="2100">Follow Up Later (set a call back date)</mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="row justify-content-center">
      <mat-form-field class="col-6">
        <textarea matInput placeholder="Notes" [(ngModel)]="action.notes" name="notes" matTextareaAutosize matAutosizeMinRows=3 matAutosizeMaxRows=6></textarea>
      </mat-form-field>
    </div>
    <div class="row justify-content-center">
      <mat-checkbox [(ngModel)]="action.futureLikes" name="futureLikes" class="col-6">Do this for all future "Likes"</mat-checkbox>
    </div>
  </form>
</div>
<div mat-dialog-actions class="row buttons">
  <button mat-raised-button color="accent" (click)="save()" [disabled]="!action.type">Save</button>
  <button mat-raised-button (click)="cancel()">Cancel</button>
</div>