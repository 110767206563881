<div class="page-container product-shelf-container container">
  <app-utility-toolbar></app-utility-toolbar>
  <h1 class="header col-12 mb-2">Product Shelf</h1>
  <div class="row mb-3 mx-1" *ngIf="!hideGrid">
    <app-grid-filters
      class="col-12"
      (filtersUpdated)="updateFilters($event)"
      [filters]="externalFilters"
      [reset]="resetFilters"
    ></app-grid-filters>

    <div class="col-12 mb-2 mx-1 filter-modal-result">
      <p
        ><strong>Showing Products from Program: </strong>{{ programToFilter }}
      </p>
      <button mat-button (click)="openProgramFilterModal()">
        <mat-icon>search</mat-icon>Change Program Name Filter
      </button>
    </div>

    <button
      mat-button
      color="primary"
      class="mr-0 ml-2"
      (click)="compare()"
      [disabled]="compareList.length < 2"
    >
      <mat-icon class="mr-2">compare_arrows</mat-icon>Compare Products
    </button>
    <app-grid-column-filter
      [columns]="gridColumns"
      (columnsUpdated)="externalColumnsUpdated($event)"
    ></app-grid-column-filter>
    @if (canExportGrid && !hideGrid) {
      <div
        ><button mat-button color="primary" (click)="exportGrid($event)"
          >Export Product Shelf</button
        ></div
      >
    }
    <button mat-button color="primary" class="ml-auto mr-0" (click)="reset()">
      <mat-icon class="mr-2">replay</mat-icon>Reset
    </button>
  </div>

  @if (!hideGrid) {
    <kendo-grid
      class="col-12"
      #productGrid="kendoGrid"
      [data]="gridData"
      [skip]="state.skip"
      [pageSize]="state.take"
      [pageable]="true"
      [sortable]="{ allowUnsort: true, mode: 'multiple' }"
      [sort]="state.sort"
      [filterable]="'menu'"
      [columnMenu]="{ columnChooser: false }"
      [filter]="state.filter"
      [state]="state"
      (dataStateChange)="dataStateChange($event)"
      [height]="gridHeight()"
    >
      <kendo-grid-column
        [width]="50"
        [sortable]="false"
        [filterable]="false"
        [groupable]="false"
        [columnMenu]="false"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <mat-checkbox
            matTooltip="Compare Fund"
            (click)="compareListUpdate($event, dataItem)"
            [(ngModel)]="compareChecked[dataItem.FundID]"
            [ngModelOptions]="{ standalone: true }"
          >
          </mat-checkbox>
        </ng-template>
      </kendo-grid-column>
      <ng-template ngFor [ngForOf]="gridData.headers" let-column>
        <kendo-grid-column
          [field]="column.DataField"
          [hidden]="isHidden(column.DataField)"
          [title]="column.Label"
          [sortable]="column.Sort"
          [filterable]="column.Filter"
          [groupable]="column.Group"
          [media]="column.RespVis"
          [filter]="column.DataType"
          [width]="column.Width"
        >
          @if (column.Sort || column.Filter) {
            <ng-template kendoGridColumnMenuTemplate let-service="service">
              @if (column.Sort) {
                <kendo-grid-columnmenu-sort
                  [column]="column"
                  [service]="service"
                >
                  <kendo-grid-messages
                    [sortAscending]="getFilterMenuText(column.Type, 'asc')"
                    [sortDescending]="getFilterMenuText(column.Type, 'desc')"
                  ></kendo-grid-messages>
                </kendo-grid-columnmenu-sort>
              }
              @if (column.Filter) {
                <kendo-grid-columnmenu-filter
                  [column]="column"
                  [service]="service"
                >
                  <ng-template
                    kendoGridFilterMenuTemplate
                    let-filter="filter"
                    let-filterService="filterService"
                  >
                    <kendo-multiselect
                      *ngIf="column.FilterType === 'Dropdown'"
                      style="width: 99%"
                      [data]="filterData[column.DataField]"
                      [filterable]="true"
                      (filterChange)="onFilterChange($event, column.DataField)"
                      textField="display"
                      valueField="value"
                      [valuePrimitive]="true"
                      [value]="filter | filterValues"
                      (valueChange)="
                        multiselectFilterValueChange(
                          $event,
                          column.DataField,
                          filterService
                        )
                      "
                      [fillMode]="'outline'"
                      placeholder="Filter By..."
                    ></kendo-multiselect>

                    <kendo-textbox
                      *ngIf="column.FilterType === 'Search'"
                      placeholder="John Smith"
                      style="width: 99%"
                      [value]="filter | filterValues"
                      (valueChange)="
                        searchFilterValueChange(
                          $event,
                          column.DataField,
                          filterService
                        )
                      "
                      [fillMode]="'outline'"
                    ></kendo-textbox>

                    <app-date-range-filter
                      *ngIf="column.FilterType === 'Range'"
                      [field]="column.DataField"
                      [filter]="filter"
                      [filterService]="filterService"
                      (updateFilter)="
                        dateFilterValueChange($event, column.DataField)
                      "
                    ></app-date-range-filter>
                  </ng-template>
                </kendo-grid-columnmenu-filter>
              }
            </ng-template>
          }
          <ng-template
            *ngIf="column.Type === 'perc'"
            kendoGridCellTemplate
            let-dataItem
          >
            {{ dataItem[column.DataField] }}%
          </ng-template>
          <ng-template
            kendoGridGroupHeaderTemplate
            let-group
            let-field="field"
            let-value="value"
            *ngIf="column.Type === 'perc'"
          >
            {{ value | percent }}
          </ng-template>

          <ng-template
            *ngIf="column.Type === 'curr'"
            kendoGridCellTemplate
            let-dataItem
          >
            {{ dataItem[column.DataField] | currency }}
          </ng-template>
          <ng-template
            kendoGridGroupHeaderTemplate
            let-group
            let-field="field"
            let-value="value"
            *ngIf="column.Type === 'curr'"
          >
            {{ value | currency }}
          </ng-template>
          <ng-template
            kendoGridCellTemplate
            let-dataItem
            *ngIf="column.DataField === 'notes'"
          >
            <span [innerHtml]="dataItem[column.DataField]"></span>
          </ng-template>
          <ng-template
            kendoGridCellTemplate
            let-dataItem
            *ngIf="column.Validation"
          >
            {{ findValue(dataItem[column.DataField], column.Validation) }}
          </ng-template>
        </kendo-grid-column>
      </ng-template>
      <ng-template
        kendoPagerTemplate
        let-totalPages="totalPages"
        let-currentPage="currentPage"
      >
        <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
        <kendo-pager-numeric-buttons
          [buttonCount]="buttonCount"
        ></kendo-pager-numeric-buttons>
        <kendo-pager-next-buttons></kendo-pager-next-buttons>
        <kendo-pager-input></kendo-pager-input>
        <kendo-pager-info></kendo-pager-info>
        <kendo-pager-page-sizes
          [pageSizes]="pageSizeOptions"
        ></kendo-pager-page-sizes>
      </ng-template>
    </kendo-grid>
  }
</div>
<ngx-loading [show]="loading"></ngx-loading>
