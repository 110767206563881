import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { InvestmentWizardService } from '../investment-wizard/services/investment-wizard-service.service';

@Component({
  selector: 'app-template-picker',
  templateUrl: './template-picker.component.html',
  styleUrls: ['./template-picker.component.scss'],
})
export class TemplatePickerComponent implements OnInit {
  @Output() templateSelected = new EventEmitter();
  @Input() model: string;

  templates;
  firmTemplates;
  placeholder = 'Select a template to apply';

  constructor(private iws: InvestmentWizardService) {}

  ngOnInit(): void {
    this.getTemplates();
  }

  getTemplates() {
    if (this.model && this.model['ValidateProduct']) {
      const program =
        this.model['ValidateProduct'].InvestmentValidation_Program;

      this.iws.getTemplates().subscribe(x => {
        const userTemplates = x.results.UserTemplates;
        const firmTemplates = x.results.FirmTemplates;

        this.templates =
          userTemplates && userTemplates.length > 0
            ? userTemplates.filter(z => z.program == program)
            : [];
        this.firmTemplates =
          firmTemplates && firmTemplates.length > 0
            ? firmTemplates.FirmTemplate.filter(z => z.program == program)
            : [];
      });

      this.placeholder =
        (!this.firmTemplates && !this.templates) ||
        (this.firmTemplates &&
          this.firmTemplates.length > 1 &&
          this.templates &&
          this.templates.length > 1)
          ? 'There are no templates available for your program'
          : this.placeholder;
    }
  }

  templateChosen(ev) {
    this.templateSelected.emit(ev);
  }
}
