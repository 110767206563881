<div mat-dialog-header>
  <h1>Tech Notes</h1>
</div>
<div mat-dialog-content class="dialog-content">
  <div class="build-info-section">
    <app-build-information [modalView]="true"></app-build-information>
  </div>
</div>
<div class="buttons">
  <button mat-raised-button (click)="close()">Close</button>
</div>