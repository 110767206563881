<mat-card appearance="outlined" class="col-12 stretch">
    <mat-card-header>
      Death Benefits
      <button mat-button (click)='load()' class="header-button download-link"><mat-icon>cloud_download</mat-icon></button>
    </mat-card-header>
    <mat-card-content>
      <ngx-loading [show]="state == 'loading'" [config]="{fullScreenBackdrop: false}"></ngx-loading>
      <button mat-button *ngIf="state == 'loaded' || state=='none'" (click)='download()' class="header-button download-link"><mat-icon>save</mat-icon></button>
      <div class="no-results" *ngIf="state == 'none'">
        <h4>No Annuities Available</h4>
      </div>
        <strong *ngIf="state == 'error'">Error Loading Rates</strong>
        <table *ngIf="updating">
          <thead>
            <th>Carrier</th>
            <th>Carrier Code</th>
            <th>Product Code</th>
            <th>Modifier</th>
            <th>VersionDate</th>
            <th>Name</th>
            <th>BasisCost</th>
            <th>RiderSubTypeCode</th>
            <th>AgeMin</th>
            <th>AgeMax</th>
            <th>SpousalProtection</th>
            <th>Enhancement Rate</th>
            <th>Calculation</th>
            <th>Effective Date</th>
            <th>Deprecate Date</th>
          </thead>
          <tbody>
            <tr *ngFor="let a of updating;" [className]="GetClass(a.RateEffectiveDate, a.RateDeprecateDate)">
              <td>{{a.Carrier}}</td>
              <td>{{a.CarrierCode}}</td>
              <td>{{a.ProductCode}}</td>
              <td>{{a.Modifier}}</td>
              <td>{{a.VersionDate}}</td>
              <td>{{a.Name}}</td>
              <td>{{a.BasisCost}}</td>
              <td>{{a.RiderSubTypeCode}}</td>
              <td>{{a.AgeMin}}</td>
              <td>{{a.AgeMax}}</td>
              <td>{{a.SpousalProtection}}</td>
              <td>{{a.EnhancementRate}}</td>
              <td>{{a.Calculation}}</td>
              <td>{{a.ActiveDate}}</td>
              <td>{{a.DeprecateDate}}</td>
            </tr>
          </tbody>
          </table>
    </mat-card-content>
  </mat-card>