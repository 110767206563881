import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormGroup, FormGroup, FormControl} from '@angular/forms';
import { SessionStorageService }  from '../services/session-storage.service'

@Component({
  selector: 'report-dialog',
  templateUrl: './report-dialog.component.html',
  styleUrls: ['./report-dialog.component.scss']
})
export class ReportDialogComponent implements OnInit {
  form: FormGroup;
  hideDetails: boolean = this.data && this.data.hideDetails ? this.data.hideDetails : false;
  hideVaDetails: boolean = this.data ? this.data.hideVaDetails : true;
  includeNotes: boolean = this.data ? this.data.showNotes : false;
  existingNotes: string = this.data ? this.data.existingNotes : false
  app;
  beforePrintPrompt;
  hintText = 'Enter a filename here if you would like to personalize the file. Otherwise the system will generate one for you.';
  notesPlaceholder = 'Additional Notes';

  constructor(public dialogRef: MatDialogRef<ReportDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private ss: SessionStorageService) {}

  ngOnInit() {
    this.app = this.ss.get('currentApp');

    const formGroup = new UntypedFormGroup({
      'filename': new FormControl('')
    });
    this.form = formGroup;

    this.beforePrintPrompt = this.data.beforePrintPrompt;
    this.hintText = this.data.subtext ? this.data.subtext : this.hintText;
    this.notesPlaceholder = this.data.placeholder || this.notesPlaceholder;

    if (!this.data.hideDetails) {
      this.form.addControl('details', new FormControl(''));
    }

    if (!this.data.hideVaDetails) {
      this.form.addControl('vaDetails', new FormControl(''));
    }

    if (this.includeNotes && (this.app == 'li' || this.app == 'rw' || this.app == 'pp')) {
      this.form.addControl('notes', new FormControl(''));
      this.form.controls.notes.setValue(this.data.printNote);
    }
  }

  save() {
    if (this.data.hideDetails && this.app == 'aw') {
      this.form.value.details = true;
    }

    this.dialogRef.close(this.form.value);
    this.dialogRef.afterClosed().subscribe(result => {
      this.dialogRef = null;
    });
  }

  cancel() {
    this.dialogRef.close('cancel');
    this.dialogRef.afterClosed().subscribe(result => {
      this.dialogRef = null;
    });
  }

}
