import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionStorageService } from '../../services/session-storage.service';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { UnifiedFlowService } from '../unified.service';
import { MatDialog } from '@angular/material/dialog';
import { WarnDialogComponent } from '../../warn-dialog/warn-dialog.component';
import { Subject } from 'rxjs';
import { ModuleStatusService } from '../../services/module-status.service';

@Component({
  selector: 'app-left-nav',
  templateUrl: './left-nav.component.html',
  styleUrls: ['./left-nav.component.scss'],
})
export class LeftNavComponent implements OnInit, OnChanges, OnDestroy {
  @Input() searchPage = false;
  @Input() stepInput;
  @Input() currentApp = 'rb';
  @Input() iwValidateTrack: boolean;
  @Input() iwProg;
  @Input() liValidateTrack: boolean;
  @Input() modules;
  @Input() loader = false;
  @Output() tabChanged = new EventEmitter();
  @Output() appChanged = new EventEmitter();
  @Output() appPage = new EventEmitter();
  @Output() checkStatuses = new EventEmitter();

  isNaN: Function = Number.isNaN;

  rights = [];
  currentTab: any = 0;
  clientInfo = false;
  state;
  visitedSteps = [];
  stepStatus = [];
  profile;
  stepsObs;
  stepsList = [];
  notesValid;
  disableButtons = false;
  apps = [];
  appStatuses = this.modStatusSvc.appStatuses();
  hasAwComparisonPage = this.modStatusSvc.hasAwComparisonPage();
  unsubscribe = new Subject();

  constructor(
    private router: Router,
    private ss: SessionStorageService,
    private unfFlowSvc: UnifiedFlowService,
    public modStatusSvc: ModuleStatusService,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    //
// Quick and Dirty refactor to get left nav working
// Sorry for butchering your code Shawn
//
    this.route.params.subscribe(params => {
      this.profile = params.id;
    });
    // this.modStatusSvc.getApps(this.profile);
    // this.apps = this.modStatusSvc.apps() ? this.modStatusSvc.apps() : [];
    this.rights = this.ss.get('rights');
    this.clientInfo = this.ss.get('currentApp') == 'rb';

    const storageApp = this.ss.get('currentApp');

    this.currentApp =
      storageApp && !this.currentApp ? storageApp : this.currentApp;
    this.currentTab = this.stepInput;

    const appStorage = this.ss.get('appStatus');
    // if (appStorage) {
    //   Object.keys(appStorage).forEach(app => {
    //     if (appStorage[app].ShowInSidebarNav) {
    //       this.apps.push(app);
    //     }
    //   });
    // }

    // if (!this.apps?.includes(this.currentApp)) {
    //   this.apps.push(this.currentApp);
    // }

    // this.ss.storageChangeExternal
    //   .pipe(takeUntil(this.unsubscribe))
    //   .subscribe(chng => {
    //     let dataKeys = [];
    //     const checkKeys = ['pp', 'aw', 'iw', 'li'];
    //     if (chng.value) {
    //       dataKeys = Object.keys(chng.value);
    //     }
    //     if (
    //       chng.key === 'appStatus' ||
    //       dataKeys.some(dataKey => checkKeys.includes(dataKey))
    //     ) {
    //       this.apps = [];
    //       const appStatus = chng.value;
    //       if (appStatus) {
    //         Object.keys(appStatus).forEach(app => {
    //           if (appStatus[app].ShowInSidebarNav || this.currentApp == app) {
    //             this.apps.push(app);
    //           }
    //         });
    //       }
    //     }
    //   });

    if (!this.stepsObs) {
      this.stepsObs = this.unfFlowSvc.stepsListExternal;
      this.stepsObs
        .pipe(debounceTime(250), distinctUntilChanged())
        .subscribe(x => {
          this.stepsList = [...x];
        });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.appInput) {
      if (changes.appInput.currentValue != changes.appInput.previousValue) {
        this.changeApp(changes.appInput.currentValue, true);
      }
    }
    if (changes.stepInput) {
      if (changes.stepInput.currentValue != changes.stepInput.previousValue) {
        this.changeTab(null, changes.stepInput.currentValue, true);
      }

      if (changes.stepInput.previousValue === 'results') {
        const app = changes.currentApp
          ? changes.currentApp.currentValue
          : this.currentApp;
        const validNoteTrigger = changes.currentApp ? false : true;
        this.changeApp(app, true, validNoteTrigger);
      }
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.profile = null;
  }

  changeTab(step, index, external = false) {
    this.currentTab = index;

    if (!external) {
      this.tabChanged.emit({ step: step, index: index });
    }
  }

  changeApp(app, external = false, changeApp?) {
    this.notesValid =
      changeApp || this.unfFlowSvc.getNoteValidity(this.currentApp);

      if(this.currentApp != 'summary' && !this.isNaN(+this.currentTab) && !external) {
        this.unfFlowSvc.setTriggerSave({
          msg: 'appChangeSave-' + this.modStatusSvc.nextApp(),
          id: this.profile,
        });
      }

    if (!this.notesValid && this.notesValid != undefined && this.rights.includes('BridgeNotes')) {
      let dialogRef = this.dialog.open(WarnDialogComponent, {
        minWidth: '35vw',
        panelClass: 'warn-dialog',
        data: {
          headline: 'Warning',
          content:
            '<div class="warn-text">Please enter rationale on all required items (as indicated by the "Notes Required for This Item" flag).<br/>Each required note needs to be filled out and <u>saved</u> in order to continue.</div>',
          confirm: 'OK',
          hideCancel: true,
        },
      });
      dialogRef.afterClosed().subscribe(() => {
        dialogRef = null;
      });
      return false;
    }

    if (this.currentApp != app) {
      // if (!this.apps?.includes(app)) {
      //   this.apps.push(app);
      // }
      this.clientInfo = this.currentApp == 'rb';
      if (!external) {
        this.appChanged.emit({ app: app });
      }
    } else if (!external) {
      if (this.currentApp !== app) {
        this.tabChanged.emit({ step: null, index: 0 });
      }
    }
  }

  changeToPage(app, tab) {
    this.appPage.emit({ app: app, tab: tab });
  }

  checkAppStep(i) {
    if (this.currentTab == i) {
      return 'current step-button';
    } else if (
      (this.stepsList[i].visited && !this.stepsList[i].valid) ||
      (+this.currentTab < i &&
        this.stepsList[i + 1]?.visited &&
        !this.stepsList[i].valid) ||
      (+this.currentTab > i && !this.stepsList[i].valid)
    ) {
      return 'error step-button';
    } else if (
      isNaN(+this.currentTab) ||
      this.stepsList[i].visited ||
      (this.currentTab > i && this.stepsList[i].valid) ||
      (this.stepsList[i].valid && this.stepsList[i].completed)
    ) {
      return 'visited step-button';
    }
    return 'step-button';
  }

  goToHome() {
    this.disableButtons = true;
    this.router.navigate(['']);
  }
}
