import { Component, OnInit } from '@angular/core';
import { SuggestionEngineService } from '../../../suggestion-engine/suggestion-engine.service';
import { MatDialog } from '@angular/material/dialog';
import { DetailDialogComponent } from '../../../suggestion-engine/detail-dialog/detail-dialog.component';
import { ActionDialogComponent } from '../../../shared-components/action-dialog/action-dialog.component';
import { Router } from '@angular/router';
import { HasPermissionDirective } from '../../../directives/permissions.directive';

@Component({
  selector: 'app-top5',
  templateUrl: './top5.component.html',
  styleUrls: ['./top5.component.scss']
})
export class Top5Component implements OnInit {
  clientOpts: any[];
  state: string = 'loaded';
  constructor(private ses: SuggestionEngineService, private dialog: MatDialog, private router: Router) { }

  ngOnInit() {
    this.ses.getTop5().subscribe(x => {
      this.clientOpts = x.opportunities.clients;
    });
  }
  
  dailyAction(type, id, bridge) {
    if(type === 'like') {
      let dialogRef = this.dialog.open(ActionDialogComponent, { panelClass: 'action-dialog', data: {action: 'like', id: id, bridge: bridge} });
      dialogRef.afterClosed().subscribe(result => {
        this.ses.takeDailyAction(id, result.data.type, bridge, result.data.date.toLocaleString('en')).subscribe(x => {
          this.clientOpts = x.opportunities.clients;
        });
      });
    } else {
      this.ses.takeDailyAction(id, 2290, bridge).subscribe(x => {
        this.clientOpts = x.opportunities.clients;
      });
    }
  }

  noBridgeAction(id) {
    const opp = this.clientOpts.find(z => z.id == id);

    if (opp.action.length > 0) {
      return false;
    }
    return true;
  }

  goToProfile(ev, id) {
    this.router.navigate([`/se/client/${id}`]);
  }
}
