import {
  AfterViewInit,
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { RightBridgeApiService } from '../../services/right-bridge-api.service';
import { UnifiedFlowService } from '../unified.service';
import { ConsolidatedReportModalComponent } from '../../shared-components/consolidated-report-modal/consolidated-report-modal.component';
import { CustomReportModalComponent } from '../../shared-components/custom-report-modal/custom-report-modal.component';
import { WarnDialogComponent } from '../../warn-dialog/warn-dialog.component';
import { LoadingDialogComponent } from '../../loading-dialog/loading-dialog.component';
import { AnnuityWizardService } from '../../annuity-wizard/services/annuity-wizard-service.service';
import { InvestmentWizardService } from '../../investment-wizard/services/investment-wizard-service.service';
import { LifeWizardService } from '../../services/life-wizard.service';
import { Platform } from '@angular/cdk/platform';
import { saveAs } from '@progress/kendo-file-saver';
import { EnvironmentService } from '../../services/environment.service';
import { CaseManagementService } from '../../case-management/case-management.service';
import { CaseManagementModalComponent } from '../../case-management/case-management-modal/case-management-modal.component';
import { SessionStorageService } from '../../services/session-storage.service';
import { NetworkLoadingModalComponent } from '../../network-loading-modal/network-loading-modal.component';
import { Global } from '../../models/global.model';
import { ConnectorMessage } from '../../models/network-models';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-unified-summary-page',
  templateUrl: './unified-summary-page.component.html',
  styleUrls: ['./unified-summary-page.component.scss'],
})
export class UnifiedSummaryPageComponent implements AfterViewInit, OnInit {
  @ViewChild('samlForm') samlForm: ElementRef;
  @Output() navigateTo = new EventEmitter();
  @Output() summaryClient = new EventEmitter();

  aiSummaryExpanded = true;
  aiSummaryText: string;
  aiSummaryTool = false;
  apiData;
  applications = [];
  baseUrl;
  buttonInstructions;
  buttons;
  currentApp;
  currentStep;
  disableCreateCm = false;
  environment;
  existingOrderId;
  globals: Global;
  networkModalData: ConnectorMessage;
  printDefaults;
  profile;
  profileCRID;
  rights: string[];
  submitting = false;
  showPrint = {
    pp: {
      show: false,
      disable: false,
      text: 'Submit',
    },
    aw1: {
      show: false,
      disable: false,
      text: 'Submit',
    },
    aw2: {
      show: false,
      disable: false,
      text: 'Submit',
    },
    aw3: {
      show: false,
      disable: false,
      text: 'Submit',
    },
    iw: {
      show: false,
      disable: false,
      text: 'Submit',
    },
    li: {
      show: false,
      disable: false,
      text: 'Submit',
    },
  };
  aiSummaryBtnText = 'Generate';
  aiBtnExpand = false;
  loading = false;

  numPositiveScores = 0;
  numNeutralScores = 0;
  numNegativeScores = 0;

  constructor(
    private unfFlowSvc: UnifiedFlowService,
    private route: ActivatedRoute,
    private rbs: RightBridgeApiService,
    private router: Router,
    private dialog: MatDialog,
    private aws: AnnuityWizardService,
    private iws: InvestmentWizardService,
    private lws: LifeWizardService,
    private pltfrm: Platform,
    private envSvc: EnvironmentService,
    private cmsSvc: CaseManagementService,
    private ss: SessionStorageService,
    private snacky: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.environment = this.envSvc.get();
    this.baseUrl = this.environment.apiBase;
    this.rights = this.ss.get('rights');

    this.aiSummaryTool = this.rights && this.rights.includes('AISummaryAccess');

    this.route.params.subscribe(params => {
      this.profile = params.id;
      this.currentApp = params.app ? params.app : 'rb';
      this.currentStep = params.step ? params.step : this.currentStep;
    });
    this.globals = this.ss.get('globals');
    if (this.globals) {
      this.networkModalData = this.globals.GlobalsGroup.UnitAPI.ConnectorMessage
        ? JSON.parse(this.globals.GlobalsGroup.UnitAPI.ConnectorMessage)[
            'ConnectorMessage'
          ]
        : { body: null, messageDelay: null, messages: null, title: null };
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.getSummaryData(this.profile);
    }, 500);
  }

  getSummaryData(profile) {
    const save = this.unfFlowSvc.getSaving();
    if (save) {
      setTimeout(() => {
        this.getSummaryData(this.profile);
      }, 250);
    } else {
      this.unfFlowSvc.setLoading(true);
      this.unfFlowSvc.summaryData(profile).subscribe(x => {
        this.setSummaryClient(x);
        this.apiData = x;
        this.applications = x.Modules;
        this.applications.forEach(app => {
          app['expanded'] = app.vars.GeneralSettings
            ? app.vars.GeneralSettings.Expanded
            : false;
          switch (app.name.toLowerCase()) {
            case 'pp':
              this.showPrint.pp.show =
                app.vars.ButtonSettings.PPPrintButtonShow;
              this.showPrint.pp.disable =
                !app.vars.ButtonSettings.PPPrintButtonActive;
              this.showPrint.pp.text =
                app.vars.ButtonSettings.PPPrintButtonText;
              break;
            case 'aw':
              this.showPrint.aw1.show =
                app.vars.ButtonSettings.AW1PrintButtonShow;
              this.showPrint.aw1.disable =
                !app.vars.ButtonSettings.AW1PrintButtonActive;
              this.showPrint.aw1.text =
                app.vars.ButtonSettings.AW1PrintButtonText;
              this.showPrint.aw2.show =
                app.vars.ButtonSettings.AW2PrintButtonShow;
              this.showPrint.aw2.disable =
                !app.vars.ButtonSettings.AW2PrintButtonActive;
              this.showPrint.aw2.text =
                app.vars.ButtonSettings.AW2PrintButtonText;
              this.showPrint.aw3.show =
                app.vars.ButtonSettings.AW3PrintButtonShow;
              this.showPrint.aw3.disable =
                !app.vars.ButtonSettings.AW3PrintButtonActive;
              this.showPrint.aw3.text =
                app.vars.ButtonSettings.AW3PrintButtonText;
              break;
            case 'iw':
              this.showPrint.iw.show =
                app.vars.ButtonSettings.IWPrintButtonShow;
              this.showPrint.iw.disable =
                !app.vars.ButtonSettings.IWPrintButtonActive;
              this.showPrint.iw.text =
                app.vars.ButtonSettings.IWPrintButtonText;
              break;
            case 'li':
              this.showPrint.li.show =
                app.vars.ButtonSettings.LWPrintButtonShow;
              this.showPrint.li.disable =
                !app.vars.ButtonSettings.LWPrintButtonActive;
              this.showPrint.li.text =
                app.vars.ButtonSettings.LWPrintButtonText;
              break;
          }
        });
        this.applications.forEach(app => {
          if (app.vars.ProductSelected?.length > 0) {
            app['numPositiveScores'] = 0;
            app['numNeutralScores'] = 0;
            app['numNegativeScores'] = 0;
            app.vars.ProductType.forEach(type => {
              app.vars[this.checkProductType(type.Type)]?.forEach(item => {
                if (
                  (this.checkProductType(type.Type) === 'ProductSelected' &&
                    item.Type.toLowerCase() === type.Type.toLowerCase()) ||
                  this.checkProductType(type.Type) !== 'ProductSelected'
                ) {
                  switch (item.ScoreClass) {
                    case 'positive':
                      app['numPositiveScores'] += 1;
                      break;
                    case 'neutral':
                      app['numNeutralScores'] += 1;
                      break;
                    case 'negative':
                      app['numNegativeScores'] += 1;
                      break;

                    default:
                      break;
                  }
                }
              });
            });
          }
        });
        this.buttons = x.Modules.find(
          z => z.name == 'Actions'
        ).vars.ButtonSettings;
        this.buttonInstructions = x.Modules.find(
          z => z.name == 'Actions'
        ).vars.GeneralSettings;
        this.printDefaults = x.UnitPrintDefaults;
        this.existingOrderId = x.AWProfilingResults.OrderEntryID;
        this.profileCRID = x.ClientProfilingOut.CRID;
        this.unfFlowSvc.setLoading(false);
        setTimeout(() => {
          this.aiBtnExpand = true;
        }, 100);
      });
    }
  }

  checkProductType(productType) {
    const productSelectedList = [
      'Accumulation',
      'DeathBenefit',
      'IWResult',
      'IWPortfolio',
      'Product',
      'Rollover',
      'Exchange',
      'Income',
      'LifeProduct',
    ];

    if (productSelectedList.includes(productType)) {
      return 'ProductSelected';
    }

    return productType;
  }

  toggleExpand(event, index, aiExpand?) {
    if (aiExpand) {
      this.aiSummaryExpanded = !this.aiSummaryExpanded;
    } else {
      this.applications[index]['expanded'] =
        !this.applications[index]['expanded'];
    }
  }

  completeCase(app) {
    this.navigateTo.emit({ app: app, profile: this.profile, step: 0 });
  }

  editInfo(app) {
    this.navigateTo.emit({ app: app, profile: this.profile, step: 0 });
  }

  viewResults(app) {
    if (app == 'aw' || app == 'pp') {
      this.router.navigate([`/case/${app}/${this.profile}/results`]);
    } else if (app == 'iw') {
      this.router.navigate([`/case/${app}/${this.profile}/validate`]);
    } else if (app == 'li') {
      if (this.apiData['LifeWizard'].AssessmentType == 1) {
        this.router.navigate([`/case/${app}/${this.profile}/products`]);
      } else {
        this.router.navigate([`/case/${app}/${this.profile}/validate`]);
      }
    }
  }

  consolidatedReport() {
    const status = { aw: null, pp: null, li: null, iw: null };

    this.applications.forEach(app => {
      status[app.name.toLowerCase()] = app.vars.ModuleStatus.FlowStatus == 'C';
    });

    let consolidatedDialog = this.dialog.open(
      ConsolidatedReportModalComponent,
      {
        panelClass: 'consolidated-report',
        data: { profile: this.profile, appStatus: status },
        minWidth: '80vw',
        minHeight: '95vh',
        maxHeight: '95vh',
      }
    );
    consolidatedDialog.afterClosed().subscribe(() => {
      consolidatedDialog = null;
    });
  }

  getReport(ev, app, type?, silent = false) {
    this.rbs.setApp(app);

    this.unfFlowSvc.setLoading(true);
    const iosSafari =
      this.pltfrm.IOS ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

    if (app == 'aw') {
      if (type == 'exchange') {
        const iosSafari =
          this.pltfrm.IOS ||
          (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

        this.rbs
          .getExchangeReport(this.profile, null, silent)
          .subscribe(data => {
            if (!silent) {
              const date = new Date();
              const filename =
                'AnnuityWizard-' +
                date.getMonth() +
                date.getDay() +
                date.getFullYear() +
                '.pdf';

              let fileData;
              const reader = new FileReader();
              reader.readAsDataURL(data);
              reader.onloadend = () => {
                fileData = reader.result;
                saveAs(fileData, filename, {
                  proxyURL: `${this.baseUrl}/util/proxy`,
                  proxyTarget: '_self',
                  forceProxy: iosSafari,
                });
              };
            }
            this.unfFlowSvc.setLoading(false);
          });
      } else {
        this.aws
          .getReport(
            this.profile,
            null,
            silent,
            this.printDefaults.AWIncludeVAInvestmentOptions,
            type && type == 'awClient',
            this.printDefaults.AWExcludeAlternatives
          )
          .subscribe(data => {
            if (!silent) {
              const date = new Date();
              const filename =
                'AnnuityWizard-' +
                date.getMonth() +
                date.getDay() +
                date.getFullYear() +
                '.pdf';

              let fileData;
              const reader = new FileReader();
              reader.readAsDataURL(data);
              reader.onloadend = () => {
                fileData = reader.result;
                saveAs(fileData, filename, {
                  proxyURL: `${this.baseUrl}/util/proxy`,
                  proxyTarget: '_self',
                  forceProxy: iosSafari,
                });
              };
            }
            this.unfFlowSvc.setLoading(false);
          });
      }
    } else if (app == 'pp') {
      // includeDetail	UnitPrintDefaults.PPIncludeDetail
      // includeIW	Set in Consolidated Print Dialog box
      // includeAW	Set in Consolidated Print Dialog box
      // includeLI	Set in Consolidated Print Dialog box

      this.rbs
        .getReport(
          this.profile,
          this.printDefaults.PPIncludeDetail,
          silent,
          false,
          false
        )
        .subscribe(data => {
          if (!silent) {
            const date = new Date();
            const filename =
              'ProductProfiler-' +
              date.getMonth() +
              date.getDay() +
              date.getFullYear() +
              '.pdf';

            const iosSafari =
              this.pltfrm.IOS ||
              (navigator.platform === 'MacIntel' &&
                navigator.maxTouchPoints > 1);

            let fileData;
            const reader = new FileReader();
            reader.readAsDataURL(data);
            reader.onloadend = () => {
              fileData = reader.result;
              saveAs(fileData, filename, {
                proxyURL: `${this.baseUrl}/util/proxy`,
                proxyTarget: '_self',
                forceProxy: iosSafari,
              });
            };
          }
          if (!this.submitting) {
            this.unfFlowSvc.setLoading(false);
          }
        });
    } else if (app == 'iw') {
      // includeDetail	UnitPrintDefaults.FundDetails
      // includeAlternatives	UnitPrintDefaults.ProgramDetails
      // includeFundAlternativesChart	UnitPrintDefaults.FundAlternatives
      this.iws
        .getReport(
          this.profile,
          this.printDefaults.IWFundDetails,
          this.printDefaults.IWProgramDetails,
          this.printDefaults.IWFundAlternatives,
          silent
        )
        .subscribe(data => {
          if (!silent) {
            const date = new Date();
            const filename =
              'InvestmentWizardReport-' +
              date.getMonth() +
              date.getDay() +
              date.getFullYear() +
              '.pdf';

            let fileData;
            const reader = new FileReader();
            reader.readAsDataURL(data);
            reader.onloadend = () => {
              fileData = reader.result;
              saveAs(fileData, filename, {
                proxyURL: `${this.baseUrl}/util/proxy`,
                proxyTarget: '_self',
                forceProxy: iosSafari,
              });
            };
          }
          this.unfFlowSvc.setLoading(false);
        });
    } else if (app == 'li' || app == 'lw') {
      // includeDetail	UnitPrintDefaults.FundDetails
      // includeAlternatives	UnitPrintDefaults.ProgramDetails
      // includeFundAlternativesChart	UnitPrintDefaults.FundAlternatives

      const liModule = this.applications.find(
        x => x.name.toLowerCase() == 'li'
      );
      const prodList = liModule.vars.ProductSelected.map(x => x.BridgeID);
      const prods = { bridgeIds: prodList };

      this.lws.getReport(this.profile, prods, silent).subscribe(data => {
        if (!silent) {
          const date = new Date();
          const filename =
            'LifeWizard-' +
            date.getMonth() +
            date.getDay() +
            date.getFullYear() +
            '.pdf';

          let fileData;
          const reader = new FileReader();
          reader.readAsDataURL(data);
          reader.onloadend = () => {
            fileData = reader.result;
            saveAs(fileData, filename, {
              proxyURL: `${this.baseUrl}/util/proxy`,
              proxyTarget: '_self',
              forceProxy: iosSafari,
            });
          };
        }
        this.unfFlowSvc.setLoading(false);
      });
    }
  }

  customReport(type?, silent?) {
    this.unfFlowSvc.setLoading(true);
    const customDefinition = { definition: null };
    customDefinition.definition = JSON.parse(this.buttons.CustomPrintModalName);
    const customDefinitionType = type ? type : customDefinition.definition.ReportName.toLowerCase();
    const uptickRevisionDef = customDefinition.definition.UptickRevision;
    if (
      customDefinitionType == 'switch' ||
      customDefinitionType == 'mmreport'
    ) {
      let dialogRef;
      if (customDefinitionType == 'mmreport') {
        dialogRef = this.dialog.open(LoadingDialogComponent, {
          panelClass: 'app-loading-dialog',
          data: {
            headline: 'Please wait',
            content: 'Your report is generating. This may take a moment...',
          },
          disableClose: true,
        });
      }
      const payload = {
        ReportType: customDefinitionType,
        ProfileID: this.profile,
        UptickRevision: uptickRevisionDef,
      };

      this.rbs.getCustomReport(payload, 'PP', silent).subscribe(data => {
        const date = new Date();
        const filename =
          'RightBridge-Report-' +
          date.getMonth() +
          date.getDay() +
          date.getFullYear() +
          '.pdf';

        const iosSafari =
          this.pltfrm.IOS ||
          (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

        let fileData;
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onloadend = () => {
          fileData = reader.result;
          saveAs(fileData, filename, {
            proxyURL: `${this.baseUrl}/util/proxy`,
            proxyTarget: '_self',
            forceProxy: iosSafari,
          });

          if (dialogRef) {
            dialogRef.componentInstance.headline = 'Complete!';
            dialogRef.componentInstance.content =
              'Your report has been generated! Thank you for your patience.';
            dialogRef.componentInstance.endModal();
          }
          this.unfFlowSvc.setLoading(false);
        };
      });
    } else {
      let dialogRef = this.dialog.open(CustomReportModalComponent, {
        panelClass: 'custom-report',
        data: {
          name: 'custom',
          profile: this.profile,
          reportDef: customDefinition,
        },
        minWidth: '80vw',
        minHeight: '95vh',
        maxHeight: '95vh',
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result && result != 'Cancel') {
          this.unfFlowSvc.setLoading(false);
        }
        dialogRef = null;
      });
    }
  }

  submitCase(ev, app, appParams?, buttonNum?) {
    this.submitting = true;
    const buttonSettings = appParams;
    const action = buttonNum
      ? appParams['ButtonAction' + buttonNum]
      : buttonSettings.ButtonAction;
    switch (action) {
      case 'R':
        this.redirect(buttonSettings, buttonNum);
        break;
      case 'P':
        this.postback(app, buttonSettings, buttonNum);
        break;
      case 'S':
        this.standardPostback(app, buttonSettings, buttonNum);
        break;
    }
  }

  submitCaseManagement(appSettings, buttonNum?) {
    // CMCreateAuditWithButtonAW1
    // CMCreateAuditWithButtonAW2
    // CMCreateAuditWithButtonAW3
    // CMCreateAuditWithButtonIW
    // CMCreateAuditWithButtonLW
    // CMCreateAuditWithButtonPP
    const num = buttonNum ? buttonNum : '',
    silentPrintAw = appSettings['CMCreateAuditWithButtonAW1'],
    silentClientRpt = appSettings['CMCreateAuditWithButtonAW2'],
    silentXchng = appSettings['CMCreateAuditWithButtonAW3'],
    silentPrintPp = appSettings['CMCreateAuditWithButtonPP'],
    silentPrintIw = appSettings['CMCreateAuditWithButtonIW'],
    silentPrintLw = appSettings['CMCreateAuditWithButtonLW'],
    silentPrintSwitch = appSettings['CMCreateAuditWithButtonSwitch'],
    customSolution = appSettings['CMCreatePostbackSolution'],
    newTab = appSettings[`RedirectSameTab${num}`] ? '_self' : '_blank';


    this.runSilentPrints({
      aw: silentPrintAw,
      awClient: silentClientRpt,
      exchange: silentXchng,
      pp: silentPrintPp,
      iw: silentPrintIw,
      lw: silentPrintLw,
      sw: silentPrintSwitch
    });

    this.cmsSvc.processCaseManagementRecord(this.profile).subscribe(
      resp => {
        if (resp['result'] === 'Success') {
          this.router.navigate([`/case-conclusion`]);
        } else {
          this.disableCreateCm = true;
          this.profileCRID = resp['result'];
          this.dialog.open(CaseManagementModalComponent, {
            panelClass: 'app-case-management-modal',
            height: '90vh',
            width: '90vw',
            maxHeight: '90vh',
            maxWidth: '90vw',
            data: {
              CRID: resp['result'],
              defaultUserId: this.globals.user.id,
              firstComplete: true,
            },
          });
        }
      },
      () => {
        this.snacky.open('There was an error with this request.', 'Close', {
          duration: 10000,
        });
      }
    );
  }

  launchCaseManagement() {
    this.router.navigate([`/case-management/${this.profileCRID}`]);
  }

  samlRedirect(data) {
    this.samlForm.nativeElement.action = data.url;
    this.samlForm.nativeElement['SAMLResponse'].value = data.SAMLResponse;
    this.samlForm.nativeElement.submit();
  }

  redirect(appSettings?, buttonNum?) {
    const num = buttonNum ? buttonNum : '',
      newTab = appSettings[`RedirectSameTab${num}`],
      link = appSettings[`RedirectURL${num}`] || null,
      outsideLink = link.includes('http');

    if (outsideLink) {
      window.open(link, newTab);
    } else {
      this.router.navigateByUrl(link);
    }
  }

  postback(app, appSettings, buttonNum?, ignoreWarning = false) {
    const num = buttonNum ? buttonNum : '',
      silentPrintAw = appSettings[`AuditWithButtonAW1${num}`],
      silentClientRpt = appSettings[`AuditWithButtonAW2${num}`],
      silentXchng = appSettings[`AuditWithButtonAW3${num}`],
      silentPrintPp = appSettings[`AuditWithButtonPP${num}`],
      silentPrintIw = appSettings[`AuditWithButtonIW${num}`],
      silentPrintLw = appSettings[`AuditWithButtonLW${num}`],
      customSolution = appSettings[`PostbackSolution${num}`],
      newTab = appSettings[`RedirectSameTab${num}`] ? '_self' : '_blank';

    let externalModal;

    try {
      externalModal = JSON.parse(appSettings.ModalName);
    } catch (error) {
      console.error('Not valid json for modal: ', error);
    }

    if (externalModal && externalModal.ModalName.toLowerCase() === 'pershing') {
      let runModal = false;
      this.rbs.getPershingGroupProfile(this.profile).subscribe(res => {
        runModal = res.PershingModal.AskForRRCode;

        if (runModal) {
          this.unfFlowSvc.setLoading(false);
          let dialogRef = this.dialog.open(CustomReportModalComponent, {
            panelClass: 'warn-dialog',
            minWidth: '50vw',
            data: {
              profile: this.profile,
              name: externalModal.ModalName,
              headline: externalModal.Title,
              content: externalModal.Body,
              accounts: res.PershingModalAccountType,
              confirm: 'Submit',
            },
          });

          dialogRef.afterClosed().subscribe(result => {
            this.submitting = false;
            dialogRef = null;
            if (result != 'cancel') {
              this.runSilentPrints({
                aw: silentPrintAw,
                awClient: silentClientRpt,
                exchange: silentXchng,
                pp: silentPrintPp,
                iw: silentPrintIw,
                lw: silentPrintLw,
              });

              let networkDialogRef = this.dialog.open(
                NetworkLoadingModalComponent,
                {
                  width: '50vw',
                  panelClass: 'network-loading-dialog',
                  data: {
                    headline: this.networkModalData.title,
                    body: this.networkModalData.body,
                    loadingMessages: this.networkModalData.messages,
                    messageDelay: this.networkModalData.messageDelay,
                  },
                  disableClose: true,
                }
              );
              this.rbs
                .extPostbackData(this.profile, null, customSolution)
                .subscribe(x => {
                  networkDialogRef.componentInstance.networkResponseReceived =
                    true;
                  networkDialogRef.close();
                  networkDialogRef.afterClosed().subscribe(() => {
                    networkDialogRef = null;
                  });
                  if (x && x.results != null && x.results.length > 0) {
                    const link = x.results;
                    window.open(link, newTab);
                  } else if (x && x.SAMLResponse) {
                    this.samlRedirect(x);
                  } else {
                    let dialogRef = this.dialog.open(WarnDialogComponent, {
                      panelClass: 'warn-dialog',
                      data: {
                        headline: 'Warning',
                        content:
                          'The service you are trying to connect to is not currently available. Please report this to your system administrator and try again later.',
                        confirm: 'OK',
                        hideCancel: true,
                      },
                    });
                    dialogRef.afterClosed().subscribe(() => {
                      this.submitting = false;
                      dialogRef = null;
                    });
                  }
                });
            } else {
              this.unfFlowSvc.setLoading(false);
              console.warn('User opted out of submission');
            }
          });
        } else {
          let networkDialogRef = this.dialog.open(
            NetworkLoadingModalComponent,
            {
              width: '50vw',
              panelClass: 'network-loading-dialog',
              data: {
                headline: this.networkModalData.title,
                body: this.networkModalData.body,
                loadingMessages: this.networkModalData.messages,
                messageDelay: this.networkModalData.messageDelay,
              },
              disableClose: true,
            }
          );
          this.rbs
            .extPostbackData(this.profile, null, customSolution)
            .subscribe(x => {
              networkDialogRef.componentInstance.networkResponseReceived = true;
              networkDialogRef.close();
              networkDialogRef.afterClosed().subscribe(() => {
                networkDialogRef = null;
              });
              if (x && x.results != null && x.results.length > 0) {
                const link = x.results;

                this.unfFlowSvc.setLoading(false);
                window.open(link, newTab);
              } else if (x && x.SAMLResponse) {
                this.samlRedirect(x);
              } else {
                setTimeout(() => {
                  let dialogRef = this.dialog.open(WarnDialogComponent, {
                    panelClass: 'warn-dialog',
                    data: {
                      headline: 'Warning',
                      content:
                        'The service you are trying to connect to is not currently available. Please report this to your system administrator and try again later.',
                      confirm: 'OK',
                      hideCancel: true,
                    },
                  });
                  dialogRef.afterOpened().subscribe(() => {
                    this.unfFlowSvc.setLoading(false);
                  });
                  dialogRef.afterClosed().subscribe(() => {
                    this.submitting = false;
                    dialogRef = null;
                  });
                });
              }
            });
        }
      });
    } else if (
      externalModal &&
      externalModal.ModalName.toLowerCase() != 'pershing'
    ) {
      let dialogRef = this.dialog.open(WarnDialogComponent, {
        panelClass: 'warn-dialog',
        data: {
          name: externalModal.ModalName,
          headline: externalModal.Title,
          content: externalModal.Body,
          confirm: 'Submit',
        },
      });

      dialogRef.afterClosed().subscribe(result => {
        this.submitting = false;
        dialogRef = null;
        if (result != 'cancel') {
          this.runSilentPrints({
            aw: silentPrintAw,
            awClient: silentClientRpt,
            exchange: silentXchng,
            pp: silentPrintPp,
            iw: silentPrintIw,
            lw: silentPrintLw,
          });

          let networkDialogRef = this.dialog.open(
            NetworkLoadingModalComponent,
            {
              width: '50vw',
              panelClass: 'network-loading-dialog',
              data: {
                headline: this.networkModalData.title,
                body: this.networkModalData.body,
                loadingMessages: this.networkModalData.messages,
                messageDelay: this.networkModalData.messageDelay,
              },
              disableClose: true,
            }
          );
          this.rbs
            .extPostbackData(this.profile, result, customSolution)
            .subscribe(x => {
              networkDialogRef.componentInstance.networkResponseReceived = true;
              networkDialogRef.close();
              networkDialogRef.afterClosed().subscribe(() => {
                networkDialogRef = null;
              });
              if (x && x.results != null && x.results.length > 0) {
                const link = x.results;

                this.unfFlowSvc.setLoading(false);
                window.open(link, newTab);
              } else {
                this.unfFlowSvc.setLoading(false);
                let dialogRef = this.dialog.open(WarnDialogComponent, {
                  panelClass: 'warn-dialog',
                  data: {
                    headline: 'Warning',
                    content:
                      'The service you are trying to connect to is not currently available. Please report this to your system administrator and try again later.',
                    confirm: 'OK',
                    hideCancel: true,
                  },
                });
                dialogRef.afterClosed().subscribe(() => {
                  this.submitting = false;
                  dialogRef = null;
                });
              }
            });
        } else {
          this.unfFlowSvc.setLoading(false);
          console.warn('User opted out of submission');
        }
      });
    } else if (this.existingOrderId && !ignoreWarning) {
      const dialogRef = this.dialog.open(WarnDialogComponent, {
        panelClass: 'warn-dialog',
        data: {
          headline: 'Warning',
          content:
            'This case has been submitted to order entry. If sent again, it will create a new record in the order entry system.',
          confirm: 'OK',
          hideCancel: false,
        },
      });
      dialogRef.afterClosed().subscribe(result => {
        this.submitting = false;
        if (result == 'continue') {
          this.postback(app, appSettings, buttonNum, true);
        } else {
          this.unfFlowSvc.setLoading(false);
        }
      });
    } else {
      if (
        this.buttons.postBackModalName &&
        this.buttons.postBackModalName.length > 0
      ) {
        let dialogRef = this.dialog.open(ConsolidatedReportModalComponent, {
          panelClass: 'consolidated-report',
          data: { profile: this.profile, silentPrint: true },
          minWidth: '80vw',
          minHeight: '95vh',
          maxHeight: '95vh',
        });

        dialogRef.afterClosed().subscribe(result => {
          this.runSilentPrints({
            aw: silentPrintAw,
            awClient: silentClientRpt,
            exchange: silentXchng,
            pp: silentPrintPp,
            iw: silentPrintIw,
            lw: silentPrintLw,
          });

          this.submitting = false;
          dialogRef = null;
          let networkDialogRef = this.dialog.open(
            NetworkLoadingModalComponent,
            {
              width: '50vw',
              panelClass: 'network-loading-dialog',
              data: {
                headline: this.networkModalData.title,
                body: this.networkModalData.body,
                loadingMessages: this.networkModalData.messages,
                messageDelay: this.networkModalData.messageDelay,
              },
              disableClose: true,
            }
          );
          this.rbs
            .extPostbackData(this.profile, result, customSolution)
            .subscribe(x => {
              networkDialogRef.componentInstance.networkResponseReceived = true;
              networkDialogRef.close();
              networkDialogRef.afterClosed().subscribe(() => {
                networkDialogRef = null;
              });
              if (x && x.results != null) {
                const link = x.results;

                this.unfFlowSvc.setLoading(false);
                if (!x.results && x.url) {
                  this.samlRedirect(x);
                } else {
                  window.open(link, newTab);
                }
              } else {
                const message = x.errorMessage
                  ? x.errorMessage
                  : 'The service you are trying to connect to is not currently available. Please report this to your system administrator and try again later.';
                let dialogRef = this.dialog.open(WarnDialogComponent, {
                  panelClass: 'warn-dialog',
                  data: {
                    headline: 'Warning',
                    content: message,
                    confirm: 'OK',
                    hideCancel: true,
                  },
                });
                dialogRef.afterClosed().subscribe(() => {
                  this.submitting = false;
                  dialogRef = null;

                  this.unfFlowSvc.setLoading(false);
                });
              }
            });
        });
      } else if (
        !this.existingOrderId ||
        (this.existingOrderId && ignoreWarning)
      ) {
        this.runSilentPrints({
          aw: silentPrintAw,
          awClient: silentClientRpt,
          exchange: silentXchng,
          pp: silentPrintPp,
          iw: silentPrintIw,
          lw: silentPrintLw,
        });

        let networkDialogRef = this.dialog.open(NetworkLoadingModalComponent, {
          width: '50vw',
          panelClass: 'network-loading-dialog',
          data: {
            headline: this.networkModalData.title,
            body: this.networkModalData.body,
            loadingMessages: this.networkModalData.messages,
            messageDelay: this.networkModalData.messageDelay,
          },
          disableClose: true,
        });
        this.rbs.extPostback(this.profile, customSolution).subscribe(x => {
          networkDialogRef.componentInstance.networkResponseReceived = true;
          networkDialogRef.close();
          networkDialogRef.afterClosed().subscribe(() => {
            networkDialogRef = null;
          });
          if (x && x.results != null) {
            const link = x.results;
            const outsideLink = link.includes('http');

            if (outsideLink) {
              window.open(link, newTab);
            } else {
              this.router.navigateByUrl(link);
            }
          } else if (x && !x.results && x.url) {
            this.samlRedirect(x);
          } else {
            const message =
              x && x.errorMessage
                ? x.errorMessage
                : 'The service you are trying to connect to is not currently available. Please report this to your system administrator and try again later.';

            this.unfFlowSvc.setLoading(false);
            let dialogRef = this.dialog.open(WarnDialogComponent, {
              panelClass: 'warn-dialog',
              data: {
                headline: 'Warning',
                content: message,
                confirm: 'OK',
                hideCancel: true,
              },
            });
            dialogRef.afterClosed().subscribe(() => {
              this.submitting = false;
              dialogRef = null;
            });
          }
        });
      }
    }
  }

  standardPostback(app, appSettings?, buttonNum?) {
    const num = buttonNum ? buttonNum : '',
      silentPrintAw =
        app == 'aw'
          ? appSettings[`AuditWithButtonAW1${num}`]
          : appSettings[`AuditWithButton${app.toUpperCase()}`],
      silentClientRpt = appSettings[`AuditWithButtonAW2${num}`],
      silentXchng = appSettings[`AuditWithButtonAW3${num}`],
      silentPrintPp = appSettings[`AuditWithButtonPP${num}`],
      silentPrintIw = appSettings[`AuditWithButtonIW${num}`],
      silentPrintLw = appSettings[`AuditWithButtonLW${num}`],
      newTab = appSettings[`RedirectSameTab${num}`] ? '_self' : 'new',
      includePID = appSettings[`IncludePID${num}`];

    this.unfFlowSvc.setLoading(true);

    try {
      JSON.parse(this.buttons.ModalName);
    } catch (error) {
      console.error('Not valid json for modal: ', error);
    }

    this.runSilentPrints({
      aw: silentPrintAw,
      awClient: silentClientRpt,
      exchange: silentXchng,
      pp: silentPrintPp,
      iw: silentPrintIw,
      lw: silentPrintLw,
    });

    let link = this.buttons.RedirectURL || null;
    const outsideLink = link.includes('http');

    if (includePID) {
      link += this.profile;
    }

    this.rbs.postback(this.profile).subscribe(() => {
      if (outsideLink) {
        window.open(link, newTab);
      } else {
        this.router.navigateByUrl(link);
      }
    });
  }

  runSilentPrints(reports) {
    Object.keys(reports).forEach(el => {
      const type = el;
      let app = el;

      if (reports[el]) {
        if (el == 'awClient' || el == 'exchange') {
          app = 'aw';
          this.getReport(null, app, type, true);
        } else if (el == 'sw') {
          app = 'pp';
          this.customReport('switch', true);
        } else {
          this.getReport(null, app, type, true);
        }
      }
    });
  }

  setSummaryClient(Client) {
    this.summaryClient.emit({ Client });
  }

  generateAISummary(): void {
    this.loading = true;
    const profileID = this.profile;
    const aiConfig = this.globals.GlobalsGroup.UnitAPI.AIConfig;
    const preprompt = this.globals.GlobalsGroup.UnitAPI.AISummaryPrePrompt;
    const aiSummaryUIConfig =
      this.globals.GlobalsGroup.UnitAPI.AISummaryUIConfig;
    const input = {
      name: aiConfig,
      UIConfig: aiSummaryUIConfig,
      profileID: profileID,
      preprompt: preprompt,
    };
    if (this.globals.GlobalsGroup.UnitAPI.AISummaryFormatText) {
      input['format'] = 'text';
    }
    this.rbs.getAISummary(input).subscribe(res => {
      this.aiSummaryText = res;
      this.loading = false;
    });
  }
}
