import { Injectable } from '@angular/core';
import { RightBridgeApiService } from './right-bridge-api.service';
import { SessionStorageService } from './session-storage.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable()
export class PermissionsService {
  private permList = this.ss.get('rights');
  private permSubject = new BehaviorSubject<any>(this.permList);
  public permissions = this.permSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  constructor(
    private rbs: RightBridgeApiService,
    private ss: SessionStorageService
  ) {}

  currentPerms(): Observable<any> {
    const perms = this.ss.get('rights') || [];
    return of(perms);
  }
}
