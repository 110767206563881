import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RightBridgeApiService } from '../services/right-bridge-api.service';
import { EnvironmentService } from '../services/environment.service';
import { SessionStorageService } from '../services/session-storage.service';
import { throwError as observableThrowError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ReportingService {
  private environment;
  private baseUrl: string;
  private org: string;

  constructor(
    private http: HttpClient,
    private rbs: RightBridgeApiService,
    private envSvc: EnvironmentService,
    private ss: SessionStorageService
  ) {
    this.environment = this.envSvc.get();
    this.baseUrl = this.environment.apiBase;
    this.org = this.environment.org;
  }

  getLoadHistory() {
    const url = `${this.baseUrl}/Reports/LoadHistory`;
    return this.rbs.postRequest(url);
  }

  getUnitProfileHistory(params) {
    const id = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', id)
      .set('X-RB-I', params.app)
      .set('X-RB-O', this.org);

    const url = `${this.baseUrl}/UnitProfile/History/${params.unit}`;
    return this.rbs.getRequest(url, reqHeaders);
  }

  getUnitProfileReport(change) {
    const id = this.ss.get('session');
    const url = `${this.baseUrl}/UnitProfile/HistoryProfile/${change}`;
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', id)
      .set('X-RB-I', 'pp')
      .set('X-RB-O', this.org)
      .set('accept', 'application/json');

    return this.http
      .get(url, {
        headers: reqHeaders,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        map(res => {
          return res.body;
        }),
        catchError(error => {
          return observableThrowError(error.error || 'Server error');
        })
      );
  }

  getUnitProfileChanges(app, unit, startDate, endDate) {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', app)
      .set('X-RB-O', this.org)
      .set('Accept', 'application/pdf');
    const url = `${this.rbs.baseUrl}/Reports/UnitProfileChanges/${unit}?startDate=${startDate}&endDate=${endDate}`;

    return this.http
      .post(url, null, {
        headers: reqHeaders,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        map(res => {
          return res.body;
        }),
        catchError(error => {
          return observableThrowError(error || 'Server error');
        })
      );
  }

  getBridgeDensity(unit, score) {
    const url = `${this.baseUrl}/Reports/BridgeDensity/${unit}/${score}`;
    return this.rbs.postRequest(url);
  }

  getBridgeAction(unit) {
    const url = `${this.baseUrl}/Reports/BridgeAction?unitID=${unit}&level=UNIT_CHILDREN&detailLevel=COMBINED`;
    return this.rbs.postRequest(url);
  }

  getBridgeActionDetail(unit, user) {
    const url = `${this.baseUrl}/Reports/BridgeActionDetail?unitID=${unit}&userID=${user}`;
    return this.rbs.postRequest(url);
  }

  getSalesTracking(salesReqData) {
    const url = `${this.baseUrl}/Reports/SalesTracking?unitID=${salesReqData.unit}&userID=${salesReqData.user}&activityType=${salesReqData.at}&saleType=${salesReqData.st}&repIntersect=${salesReqData.ri}&daysGap=${salesReqData.gap}&startDate=${salesReqData.startDate}&endDate=${salesReqData.endDate}`;
    return this.rbs.postRequest(url);
  }

  getReasonText(unit, app) {
    const id = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', id)
      .set('X-RB-I', app)
      .set('X-RB-O', this.org)
      .set('accept', 'application/xslx');
    const url = `${this.baseUrl}/Reports/ReasonText?unitID=${unit}`;

    return this.http
      .post(url, null, {
        headers: reqHeaders,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        map(res => {
          return res.body;
        }),
        catchError(error => {
          return observableThrowError(error.error || 'Server error');
        })
      );
  }

  getUsage(unit, start, end) {
    const url = `${this.baseUrl}/Reports/UnitUsage?unitID=${unit}&startDate=${start}&endDate=${end}`;
    return this.rbs.postRequest(url);
  }

  getMutualFund(unit, start, end) {
    const id = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', id)
      .set('X-RB-I', 'pp')
      .set('X-RB-O', this.org);
    const url = `${this.baseUrl}/Reports/MFUsage?unitID=${unit}&sd=${start}&ed=${end}`;

    return this.http
      .get(url, {
        headers: reqHeaders,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        map(res => {
          return res.body;
        }),
        catchError(error => {
          return observableThrowError(error.error || 'Server error');
        })
      );
  }

  getUnitSettings(unit, type) {
    const url = `${this.baseUrl}/Reports/UnitProfile?unitID=${unit}&reportType=${type}`;
    return this.rbs.postRequest(url);
  }

  getRepExceptions(start, end, id, app, unit) {
    const sess = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', app)
      .set('X-RB-O', this.org);
    const url = `${this.baseUrl}/Reports/RepExceptions?startDate=${start}&endDate=${end}&alertID=${id}&unitID=${unit}`;
    return this.rbs.postRequest(url, null, reqHeaders);
  }

  getRoleRightHistoryReport(startDate, endDate) {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'rb')
      .set('X-RB-O', this.org)
      .set('Accept', 'application/pdf');
    const url = `${this.rbs.baseUrl}/Reports/RoleRightChanges?startDate=${startDate}&endDate=${endDate}`;

    return this.http
      .post(url, null, {
        headers: reqHeaders,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        map(res => {
          return res.body;
        }),
        catchError(error => {
          return observableThrowError(error || 'Server error');
        })
      );
  }

  getUnitManagementChanges(unit, startDate, endDate) {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'rb')
      .set('X-RB-O', this.org)
      .set('Accept', 'application/pdf');
    const url = `${this.rbs.baseUrl}/Reports/UnitManagementChanges/${unit}?startDate=${startDate}&endDate=${endDate}`;

    return this.http
      .post(url, null, {
        headers: reqHeaders,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        map(res => {
          return res.body;
        }),
        catchError(error => {
          return observableThrowError(error || 'Server error');
        })
      );
  }

  getComparisonReport(reportData, solution) {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', solution)
      .set('X-RB-O', this.org)
      .set('Accept', 'application/pdf');

    const url = `${this.rbs.baseUrl}/Reports/Comparison`;

    return this.http
      .post(url, reportData, {
        headers: reqHeaders,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        map(res => {
          return res.body;
        }),
        catchError(error => {
          return observableThrowError(error || 'Server error');
        })
      );
  }
}
