import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { ConditionalFormModule } from '../conditional-form/conditional-form.module';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { RmdCalculationModule } from '../rmd-calculation/rmd-calculation.module';

//Kendo Imports
import { GridModule, ExcelModule } from '@progress/kendo-angular-grid';
// import { FloatingLabelModule } from "@progress/kendo-angular-label";
// import { InputsModule } from "@progress/kendo-angular-inputs";

// import { AnnuitiesResultComponent } from './annuities-result/annuities-result.component';
import { FiaComponent } from './fia/fia.component';
import { ViaComponent } from './via/via.component';
import { FaComponent } from './fa/fa.component';
import { ProductSearchComponent } from './product-search/product-search.component';

//Services
import { AuthService } from '../services/auth-service.service';
import { RightBridgeApiService } from '../services/right-bridge-api.service';
import { AnnuityWizardService } from './services/annuity-wizard-service.service';

import { AwRouting } from './annuity-wizard-routing.module';
import { BeaconService } from '../services/beacon.service';
import { ProductShelfComponent } from './product-shelf/product-shelf.component';
import { CreateProfileDialogComponent } from './create-profile-dialog/create-profile-dialog.component';
import { CalculateIncomeDialogComponent } from './calculate-income-dialog/calculate-income-dialog.component';
import { ProductShelfCompareDialogComponent } from './product-shelf-compare-dialog/product-shelf-compare-dialog.component';
import { UserPreferencesService } from '../services/user-preferences.service';
import { MaskitoDirective } from '@maskito/angular';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    AwRouting,
    FormsModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    GridModule,
    ExcelModule,
    ConditionalFormModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.circle,
      backdropBackgroundColour: 'rgba(0, 0, 0, 0.3)',
      fullScreenBackdrop: true,
      backdropBorderRadius: '0',
      primaryColour: 'rgb(38, 91, 151)',
      secondaryColour: 'rgba(38, 91, 151, 0.2)',
      tertiaryColour: '#265b97',
    }),
    SharedComponentsModule,
    RmdCalculationModule,
    MaskitoDirective,
    // FloatingLabelModule,
    // InputsModule
  ],
  declarations: [
    // AnnuitiesResultComponent,
    FiaComponent,
    ViaComponent,
    FaComponent,
    ProductSearchComponent,
    ProductShelfComponent,
    CreateProfileDialogComponent,
    CalculateIncomeDialogComponent,
    ProductShelfCompareDialogComponent,
  ],
  providers: [
    RightBridgeApiService,
    AuthService,
    BeaconService,
    AnnuityWizardService,
    UserPreferencesService,
  ],
  schemas: [NO_ERRORS_SCHEMA],
})
export class AnnuityWizardModule {}
