import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DownloadPageComponent } from './download-page/download-page.component';
import { DownloadsRouting } from './download-routing.module';
import { SharedComponentsModule } from '../shared-components/shared-components.module';



@NgModule({
  declarations: [DownloadPageComponent],
  imports: [
    DownloadsRouting,
    CommonModule,
    SharedComponentsModule
  ],
})
export class DownloadsModule { }
