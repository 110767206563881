import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import {
  highchartsModules,
  SharedComponentsModule,
} from '../shared-components/shared-components.module';
import { ConditionalFormModule } from '../conditional-form/conditional-form.module';

//Kendo Imports
import { GridModule } from '@progress/kendo-angular-grid';

import { InvestmentWizardComponent } from './investment-wizard/investment-wizard.component';
// import { ModelSelectionComponent } from './model-selection/model-selection.component';
import { RiskToleranceComponent } from '../risk-tolerance/risk-tolerance.component';
import { FundingOptionsComponent } from '../funding-options/funding-options.component';
import { FundsDialogComponent } from '../funds-dialog/funds-dialog.component';
// import { ProgramSelectionComponent } from './program-selection/program-selection.component';
// import { FundAllocationsComponent } from './fund-allocations/fund-allocations.component';
import { MultiDialogComponent } from '../multi-dialog/multi-dialog.component';
import { FamilyDialogComponent } from '../family-dialog/family-dialog.component';
import { CustomModelDialogComponent } from './custom-model-dialog/custom-model-dialog.component';
import { ValidationDialogComponent } from './validation-dialog/validation-dialog.component';
import { FundLookupDialogComponent } from './fund-lookup-dialog/fund-lookup-dialog.component';
import { ProductSearchComponent } from './product-search/product-search.component';
// import { ValidateResultComponent } from './validate-result/validate-result.component';

//Highcharts
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import * as exporting from 'highcharts/modules/exporting.src';

import { HighchartsChartModule } from 'highcharts-angular';

// export function highchartsModules() {
//   // apply Highcharts Modules to this array
//   return [more, exporting, highcharts3d];
// }

//Services
import { AuthService } from '../services/auth-service.service';
import { RightBridgeApiService } from '../services/right-bridge-api.service';
import { InvestmentWizardService } from './services/investment-wizard-service.service';

import { IwRouting } from './investment-wizard-routing.module';
import { ProductGridComponent } from './product-grid/product-grid.component';
import { MaskitoDirective } from '@maskito/angular';
// import { TemplateModalComponent } from '../account-management/template-modal/template-modal.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    IwRouting,
    FormsModule,
    ReactiveFormsModule,
    ConditionalFormModule,
    ChartModule,
    NgxDatatableModule,
    GridModule,
    // Ng2CompleterModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.circle,
      backdropBackgroundColour: 'rgba(0, 0, 0, 0.3)',
      fullScreenBackdrop: true,
      backdropBorderRadius: '0',
      primaryColour: 'rgb(38, 91, 151)',
      secondaryColour: 'rgba(38, 91, 151, 0.2)',
      tertiaryColour: '#265b97',
    }),
    SharedComponentsModule,
    HighchartsChartModule,
    MaskitoDirective,
  ],
  declarations: [
    // ModelSelectionComponent,
    RiskToleranceComponent,
    FundingOptionsComponent,
    FundsDialogComponent,
    // ProgramSelectionComponent,
    // FundAllocationsComponent,
    MultiDialogComponent,
    FamilyDialogComponent,
    InvestmentWizardComponent,
    CustomModelDialogComponent,
    ValidationDialogComponent,
    FundLookupDialogComponent,
    ProductSearchComponent,
    // ValidateResultComponent,
    ProductGridComponent,
  ],
  providers: [
    RightBridgeApiService,
    InvestmentWizardService,
    {
      provide: HIGHCHARTS_MODULES,
      useFactory: () => [highchartsModules, exporting],
    },
    // { provide: MAT_PLACEHOLDER_GLOBAL_OPTIONS, useValue: { float: 'auto' } },
    AuthService,
  ],
  schemas: [NO_ERRORS_SCHEMA],
})
export class InvestmentWizardModule {}
