import { Component, OnInit } from '@angular/core';
import { SuggestionEngineService } from '../../../suggestion-engine/suggestion-engine.service';
import { Router } from '@angular/router';
import { SessionStorageService }  from '../../../services/session-storage.service'

@Component({
  selector: 'app-timeSensitive',
  templateUrl: './timeSensitive.component.html',
  styleUrls: ['./timeSensitive.component.scss']
})
export class TimeSensitiveComponent implements OnInit {
  options: any[];
  state: string = 'loading';

  constructor(private ses: SuggestionEngineService, private router: Router, private ss: SessionStorageService) { }

  ngOnInit() {
    this.ses.getTimeSensitive().subscribe(x => {
      if (x.data && x.data.length) {
        this.options = x.data.map(z => {
          return { id: z.id, name: z.FullName, suggestion: z.bridgename, score: z.score };
        });
        this.setProfileList(this.options);
        this.state = 'loaded';
      } else if (!x.data) {
        this.state = 'none';
      }

    });
  }

  goToProfile(ev, id) {
    this.router.navigate([`/se/client/${id}`]);
  }

  setProfileList(list) {
    const filteredList = list.map(el => {
      return el.id;
    });
    this.ss.set('seProfileList', filteredList);
  }
}
