import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FundFeeDialogComponent } from '../fund-fee-dialog/fund-fee-dialog.component';
import { MatDialog } from '@angular/material/dialog'
import { FundFeeLookupService } from '../fund-fee-lookup.service';

@Component({
  selector: 'app-fund-fee-handler',
  templateUrl: './fund-fee-handler.component.html',
  styleUrls: ['./fund-fee-handler.component.scss']
})
export class FundFeeHandlerComponent implements OnInit {
  @Input() fieldData;
  @Input() varSet;
  @Input() group;
  dialogRef;

  constructor(private dialog: MatDialog, private feeSvc: FundFeeLookupService) { }

  ngOnInit() {
  }

  triggerLookUp(e) {
    e.preventDefault();
    this.openLookUpDialog();
  }

  openLookUpDialog() {
    if (!this.dialogRef) {
      this.dialogRef = this.dialog.open(FundFeeDialogComponent, { panelClass: 'fund-fee-dialog', minHeight: '50vh', width: '70vw' });
      // dialogRef.afterOpened().subscribe(x => { this.loading = false; });
      this.dialogRef.afterClosed().subscribe(result => {
        if (result && result != "cancel") {
          let data = {};
          data['groupIndex'] = this.fieldData.parent.form.controls.indexOf(this.fieldData.form);
          data['results'] = result;
          data['varset'] = this.varSet;
          data['group'] = this.group;

          this.feeSvc.calculationData(data);
        }
        this.dialogRef = null;
      });
    }
  }

}
