import { Component, ViewChild } from '@angular/core';
import { RmdCalc } from '../../models/rmd-models';
import { UntypedFormGroup, FormGroup, NgForm } from '@angular/forms';
import { RmdService } from '../rmd.service';
import { MaskService } from '../../services/mask.service';
import { Chart } from 'angular-highcharts';
import * as Highcharts from 'highcharts';
import { SessionStorageService } from '../../services/session-storage.service';

@Component({
  selector: 'app-rmd-calculation',
  templateUrl: './rmd-calculation.component.html',
  styleUrls: ['./rmd-calculation.component.scss'],
})
export class RmdCalculationComponent {
  @ViewChild('rmdForm', { static: false }) rmdForm: NgForm;

  form: FormGroup;
  formData: RmdCalc = {
    AgeAtIssue: 55,
    AgeAtFirstWithdrawal: 65,
    AgeAtFirstCashWithdrawal: 65,
    MortalityAge: 92,
    LifetimeIncome: null,
    AnnualIncomeNeed: 0,
    IncomeRisingRate: 0,
    EquityHoldings: null,
    FixedIncome: null,
    Annuity: null,
    IncomeAnnuity: null,
    EquityHoldingsRate: 6,
    FixedIncomeRate: 3.5,
    AnnuityRate: 2,
    IncomeAnnuityRate: 2,
    InflationRate: 2,
  };
  Highcharts: typeof Highcharts = Highcharts;
  dollarMask = this.mask.dollarMaskSpecs();
  percentMask = this.mask.percentMaskSpecs();
  calcComplete = false;
  calcData = {};
  chartData = [];
  tableData = [];
  loading = false;
  tableHeaderValue: number;
  tableColumns = [];

  constructor(
    private rmds: RmdService,
    private mask: MaskService,
    private ss: SessionStorageService
  ) {}

  calculate(data) {
    this.loading = true;
    const percRegex = new RegExp(/%/),
      moneyRegex = new RegExp(/^\$/);

    const submittedData = {};

    Object.keys(data).forEach(x => {
      if (
        percRegex.test(data[x]) ||
        x == 'InflationRate' ||
        x == 'AnnuityRate' ||
        x == 'FixedIncomeRate' ||
        x == 'EquityHoldingsRate' ||
        x == 'IncomeAnnuityRate' ||
        x == 'IncomeRisingRate'
      ) {
        submittedData[`RMDCalcInput.${x}`] = this.mask.removePercentMask(
          data[x]
        );
      } else if (moneyRegex.test(data[x])) {
        submittedData[`RMDCalcInput.${x}`] = this.mask.removeMoneyMask(data[x]);
      } else {
        submittedData[`RMDCalcInput.${x}`] = data[x];
      }
    });

    this.rmds.getRmdCalc(submittedData).subscribe(x => {
      this.calcComplete = true;
      this.calcData = x;
      this.chartData = [];
      x['charts'].forEach(chrt => {
        const data = new Chart(chrt.Data);
        this.chartData.push(data);
      });

      this.tableHeaderValue = x.RMDCalc.ValueAtDeath;

      this.tableData = x.RMDCalcArray;
      this.tableColumns = x.RMDHeader;
      this.loading = false;
    });
  }

  editCalc() {
    this.calcComplete = false;
  }

  resetCalc() {
    this.chartData = [];
    this.calcData = {};
    this.calcComplete = false;
    Object.keys(this.formData).forEach(x => (this.formData[x] = null));
  }
}
