import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { ErrorReportingService } from '../services/error-reporting.service';
import { RightBridgeApiService } from '../services/right-bridge-api.service';
import { EnvironmentService } from '../services/environment.service';
import { SessionStorageService } from '../services/session-storage.service';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class DtccService {
  environment;
  baseUrl;
  app;
  org;

  constructor(
    private envSvc: EnvironmentService,
    private errSvc: ErrorReportingService,
    private rbs: RightBridgeApiService,
    private ss: SessionStorageService
  ) {
    this.environment = this.envSvc.get();
    this.baseUrl = this.environment.apiBase;
    this.app = this.ss.get('currentApp') || this.environment.defaultApp || 'rb';
    this.org = this.environment.org || 'basev2';
  }

  searchPolicies(producer, policy?, name?) {
    const id = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', id)
      .set('X-RB-I', 'aw')
      .set('X-RB-O', this.org);

    let url = `${this.baseUrl}/Dtcc/SearchPolicy?producerNumber=${producer}`;

    if (policy) {
      url += `&PolicyNumber=${policy}`;
    }

    if (name) {
      url += `&ClientLastName=${name.toUpperCase()}`;
    }

    return this.rbs.getRequest(url, reqHeaders);
  }

  getPolicyDetails(policy, participantNumber) {
    const id = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
    .set('X-RB-S', id)
    .set('X-RB-I', 'aw')
    .set('X-RB-O', this.org);

    const url = `${this.baseUrl}/Dtcc/GetPolicy?insurancePolicyPolNumber=${policy}&insuranceParticipantCarrierParticipantNumber=${participantNumber}`;

    return this.rbs.getRequest(url, reqHeaders).pipe(
      res => {
        return res;
      },
      catchError(error => {
        return this.errSvc.handleError(error);
      })
    );
  }

  getPolicyDetailsComplete(policy, participantNumber) {
    const id = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
    .set('X-RB-S', id)
    .set('X-RB-I', 'aw')
    .set('X-RB-O', this.org);
    const url = `${this.baseUrl}/Dtcc/GetPolicyComplete?insurancePolicyPolNumber=${policy}&insuranceParticipantCarrierParticipantNumber=${participantNumber}&mappingID=DTCCExchange`;

    return this.rbs.getRequest(url, reqHeaders).pipe(
      res => {
        return res;
      },
      catchError(error => {
        return this.errSvc.handleError(error);
      })
    );
  }
  getNPNs(userID) {
    const sess = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', 'aw')
      .set('X-RB-O', this.org);
    const url = `${this.baseUrl}/User/${userID}/npn`;
    return this.rbs.getRequest(url, reqHeaders).pipe(
      res => {
        return res;
      },
      catchError(error => {
        return this.errSvc.handleError(error);
      })
    );
  }
}
