<mat-card appearance="outlined" class="col-12 stretch case-activity">
  <mat-card-header>
    Case Activity Statistics
    <button mat-button color="white" class="download" (click)="exportExcel(gridExportChooser())">
      <mat-icon>cloud_download</mat-icon>Download Excel File
    </button>
  </mat-card-header>
  <mat-card-content class="row">
    <div class="col-12 mb-3 toolbar">
      <mat-button-toggle-group [(ngModel)]="dataView" class="ml-2">
        <mat-button-toggle value="top5">Top 5</mat-button-toggle>
        <mat-button-toggle value="data">Case Data</mat-button-toggle>
      </mat-button-toggle-group>

      <mat-form-field class="ml-2">
        <mat-label>Solution</mat-label>
        <mat-select [(ngModel)]="solution" (selectionChange)="getCaseStatsSummary(true)">
          <mat-option *ngFor="let sltn of solutions" [value]="sltn.value">{{sltn.label}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="ml-2">
        <mat-label>View Mode</mat-label>
        <mat-select [(ngModel)]="flatOrGrouped"  (selectionChange)="updateFlatGrouped()">
          <mat-option value="FLAT">Bridge View (Flat)</mat-option>
          <mat-option value="GROUP">Case View (Grouped)</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-button-toggle-group [(ngModel)]="extendedData" class="ml-2">
        <mat-button-toggle [value]="false">Standard View</mat-button-toggle>
        <mat-button-toggle [value]="true">Extended View</mat-button-toggle>
      </mat-button-toggle-group>

      <mat-form-field class="pull-right">
        <input matInput [matDatepicker]="endPicker" placeholder="Choose an end date" [formControl]="endDate" (dateChange)="dateChange()">
        <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
        <mat-datepicker #endPicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="pull-right mr-2">
        <input matInput [matDatepicker]="startPicker" placeholder="Choose a start date" [formControl]="startDate" (dateChange)="dateChange()">
        <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
        <mat-datepicker #startPicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="pull-right mr-2" *ngIf="dataView == 'data'">
        <mat-label>Sort By</mat-label>
        <mat-select [(ngModel)]="sort" (selectionChange)="updateSorting()">
          <mat-option *ngFor="let sort of sortingPresets" [value]="sort.value">{{sort.label}}</mat-option>
        </mat-select>
      </mat-form-field>

    </div>
    <div class="button-row">
      <!-- <button mat-button (click)="goBack()" *ngIf="unitList && unitList.length >= 1">
        <mat-icon>arrow_back</mat-icon> Go back to

        <ng-container *ngIf="unitList.length > 2; else base_user">{{unitList[unitList.length - 1].UnitName}}</ng-container>
        <ng-template #base_user>{{unitList[0].User}}</ng-template>

      </button> -->
      <button mat-button (click)="goBack()" *ngIf="history.length">
        <mat-icon>arrow_back</mat-icon> Back
      </button>
    </div>
    <!--  -->
    <!-- Start Top 5 View -->
    <!--  -->
    <div class="col-12 top-5 row" [hidden]="dataView != 'top5'">
      <div class="col-6">
        <label>Users With Most Cases Ran</label>
        <table>
          <thead>
            <th>User</th>
            <th>Cases Ran</th>
          </thead>
          <tbody>
            <tr *ngFor="let ran of casesRan">
              <td (click)="dataRowClicked(ran, 'user')" class="clickable">{{ran.UserName}}</td>
              <td (click)="dataRowClicked(ran, 'user')" class="clickable">{{ran.CaseCount}}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="col-6">
        <label>Most Score Class Changes</label>
        <table>
          <thead>
            <th>User</th>
            <th>Case</th>
            <th>Classes Changed</th>
            <th>Run Count</th>
          </thead>
          <tbody>
            <tr *ngFor="let clss of classesChanged">
              <td >{{clss.UserName}}</td>
              <td (click)="dataRowClicked(clss, 'client')" class="clickable">
                {{clss.ClientName}}
                <button mat-icon-button (click)="viewCase($event, clss.ProfileID)" matTooltip="Open Case" class="pull-right mr-5" color="primary"><mat-icon>work</mat-icon></button>
              </td>
              <td (click)="dataRowClicked(clss, 'count')" class="clickable">
                {{clss.Changes}}
                <button mat-icon-button (click)="viewHistory($event, clss)" matTooltip="View Case History" class="pull-right mr-5" color="warn"><mat-icon>history</mat-icon></button>
              </td>
              <td>{{clss.RunCount}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!--  -->
    <!-- End Top 5 View -->
    <!--  -->

    <!--  -->
    <!-- Start Data View -->
    <!--  -->

    <div class="col-12 data-view" [hidden]="dataView != 'data'">
      <h2>{{gridTitle}}</h2>
      <kendo-grid class="col-12 case-stats-activity-grid" #resultsGrid="kendoGrid"
        [kendoGridBinding]="gridData.Data"
        [group]="grouping"
        [sortable]="{
          allowUnsort: true,
          mode: 'multiple'
        }"
        [sort]=sort
        [columnMenu]="true"
        [style.maxHeight.px]="500"
        [filterable]="'menu'"
        (cellClick)="handleRowClick($event)"
        [hidden]="profileHistory.History.length > 1"
        >
        <!-- && profileHistory.History.length < 1 -->
         <ng-template ngFor [ngForOf]="gridData.Headers" let-column >
          <kendo-grid-column
            [field]="column.DataField"
            [title]="column.Label"
            [sortable]="column.Sort"
            [hidden]="isHidden(column.DataField)"
            [filterable]="column.Filter"
            [groupable]="column.Group"
            [media]="column.RespVis"
            [width]="column.DataField == 'User' ? '275': null"
            [class]="{'text-center': column.DataField == 'ScoreClass'
              || column.DataField == 'ProductChanges'
              || column.DataField == 'ScoreClassChanges'
              || column.DataField == 'ProductType'
              || column.DataField == 'AssessmentType',
              'clickable': column.DataField == 'User'
              || column.DataField == 'ClientName'
            }"
          >
            <ng-template kendoGridCellTemplate let-dataItem *ngIf="column.DataField == 'User'">
              {{dataItem.User}}
              <!-- *ngIf="!gridData['subHead']" -->
              <button mat-icon-button (click)="viewHistory($event, dataItem)" matTooltip="View Case History" class="pull-right" color="warn"><mat-icon>history</mat-icon></button>
              <button mat-icon-button (click)="viewCase($event, dataItem.ProfileID)" matTooltip="Open Case" class="pull-right" color="primary"><mat-icon>work</mat-icon></button>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem *ngIf="column.DataField == 'ClientName'" let-columnIndex="columnIndex">
              {{dataItem.ClientName}}
              <button mat-icon-button *ngIf="columnIndex == 0" (click)="viewHistory($event, dataItem)" matTooltip="View Case History" class="pull-right" color="warn"><mat-icon>history</mat-icon></button>
              <button mat-icon-button *ngIf="columnIndex == 0" (click)="viewCase($event, dataItem.ProfileID)" matTooltip="Open Case" class="pull-right" color="primary"><mat-icon>work</mat-icon></button>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem *ngIf="column.DataField == 'ScoreClass'">
              <mat-icon *ngIf="dataItem.ScoreClass && dataItem.ScoreClass.toLowerCase() == 'positive'" class="positive">check_circle</mat-icon>
              <mat-icon *ngIf="dataItem.ScoreClass && dataItem.ScoreClass.toLowerCase() == 'neutral'" class="neutral">error</mat-icon>
              <mat-icon *ngIf="dataItem.ScoreClass && dataItem.ScoreClass.toLowerCase() == 'negative'" class="negative">remove_circle</mat-icon>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem *ngIf="column.DataField == 'DateTime'">
              {{dataItem['DateTime'] | date:'short'}}
            </ng-template>


            <ng-template kendoGridCellTemplate let-dataItem *ngIf="column.Validation">
              {{findValue(dataItem[column.DataField], column.Validation)}}
            </ng-template>
          </kendo-grid-column>
        </ng-template>
        <ng-template kendoGridDetailTemplate let-dataItem *ngIf="gridData['subHead']">
          <section>
            <button mat-button class="pull-left" (click)="getProfileHistory(dataItem.ProfileID)">
              <mat-icon>search</mat-icon>
              View Details
            </button>
            <table class="w-90 pull-right">
              <thead>
                <th *ngFor="let header of gridData['subHead']">
                  {{header.Label}}
                </th>
              </thead>
              <tbody>
                <tr *ngFor="let product of dataItem.products">
                  <td *ngFor="let header of gridData['subHead']">
                    <ng-container *ngIf="header.DataField == 'ScoreClass'; else normal">
                      <mat-icon *ngIf="product.ScoreClass && product.ScoreClass.toLowerCase() == 'positive'" class="positive">check_circle</mat-icon>
                      <mat-icon *ngIf="product.ScoreClass && product.ScoreClass.toLowerCase() == 'neutral'" class="neutral">error</mat-icon>
                      <mat-icon *ngIf="product.ScoreClass && product.ScoreClass.toLowerCase() == 'negative'" class="negative">remove_circle</mat-icon>
                    </ng-container>
                    <ng-template #normal>
                      {{product[header.DataField]}}
                    </ng-template>

                  </td>
                </tr>
              </tbody>
            </table>
          </section>
        </ng-template>
        <kendo-grid-excel ></kendo-grid-excel>
      </kendo-grid>

      <kendo-grid class="col-12 case-stats-activity-grid" #historyGrid="kendoGrid"
        [kendoGridBinding]="profileHistory.History"
        [group]="grouping"
        [style.maxHeight.px]="500"
        (cellClick)="historyRowClick($event, profileHistory.ProfileID)"
        [hidden]="profileHistory.History.length < 1 || ((profileHistoryDetail.additions && profileHistoryDetail.additions.length > 1) || (profileHistoryDetail.changes && profileHistoryDetail.changes.length > 1) || (profileHistoryDetail.deletions && profileHistoryDetail.deletions.length > 1))"
        >
        <kendo-grid-column field="date" title="Date">
        </kendo-grid-column>
        <kendo-grid-column field="userId" title="User">
        </kendo-grid-column>
        <kendo-grid-column field="solution" title="Solution">
        </kendo-grid-column>
        <kendo-grid-excel ></kendo-grid-excel>
      </kendo-grid>

      <span [hidden]="!profileHistoryDetail.additions || profileHistoryDetail.additions.length < 1">
        <h4>Additions</h4>
        <kendo-grid class="col-12 case-stats-activity-grid" #historyAdditionsGrid="kendoGrid"
        [kendoGridBinding]="profileHistoryDetail.additions"
        [group]="grouping"
        [style.maxHeight.px]="250"
        >
          <kendo-grid-column field="variable" title="Variable">
          </kendo-grid-column>
          <kendo-grid-column field="label" title="Label">
          </kendo-grid-column>
          <kendo-grid-column field="oldvalue" title="Old Value">
          </kendo-grid-column>
          <kendo-grid-column field="newvalue" title="New Value">
          </kendo-grid-column>
          <kendo-grid-excel ></kendo-grid-excel>
        </kendo-grid>
      </span>

      <span [hidden]="!profileHistoryDetail.changes || profileHistoryDetail.changes.length < 1">
        <h4>Changes</h4>
        <kendo-grid class="col-12 case-stats-activity-grid" #historyChangesGrid="kendoGrid"
        [kendoGridBinding]="profileHistoryDetail.changes"
        [group]="grouping"
        [style.maxHeight.px]="250"
        >
          <kendo-grid-column field="variable" title="Variable">
          </kendo-grid-column>
          <kendo-grid-column field="label" title="Label">
          </kendo-grid-column>
          <kendo-grid-column field="oldvalue" title="Old Value">
          </kendo-grid-column>
          <kendo-grid-column field="newvalue" title="New Value">
          </kendo-grid-column>
          <kendo-grid-excel ></kendo-grid-excel>
        </kendo-grid>
      </span>

      <span [hidden]="!profileHistoryDetail.deletions || profileHistoryDetail.deletions.length < 1">
        <h4>Deletions</h4>
        <kendo-grid class="col-12 case-stats-activity-grid" #historyDeletionsGrid="kendoGrid"
        [kendoGridBinding]="profileHistoryDetail.deletions"
        [group]="grouping"
        [style.maxHeight.px]="250"
        >
          <kendo-grid-column field="variable" title="Variable">
          </kendo-grid-column>
          <kendo-grid-column field="label" title="Label">
          </kendo-grid-column>
          <kendo-grid-column field="oldvalue" title="Old Value">
          </kendo-grid-column>
          <kendo-grid-column field="newvalue" title="New Value">
          </kendo-grid-column>
          <kendo-grid-excel ></kendo-grid-excel>
        </kendo-grid>
      </span>
  </div>
    <!--  -->
    <!-- End Data View -->
    <!--  -->






      <!-- <pre>{{data | json}}</pre> -->
      <!-- <kendo-grid class="col-12 case-activity-grid" #resultsGrid="kendoGrid"
        [kendoGridBinding]="data"
        scrollable="none"
        (cellClick)="handleRowClick($event)"

        >
        <kendo-grid-column field="UserName" title="User" *ngIf="level == 'UNIT_USERS'" [headerClass]="'header'">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.UserName}}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="UnitName" title="Unit" *ngIf="level != 'UNIT_USERS'" [headerClass]="'header'">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span [ngClass]="{'active': dataItem.Units >= 1}">
              {{dataItem.UnitName}}
              <mat-icon *ngIf="dataItem.Units >= 1">trending_flat</mat-icon>
          </span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="Users" title="# of Users" *ngIf="level != 'UNIT_USERS'" [headerClass]="'header'">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span [ngClass]="{'active': dataItem.Users >= 1}">
              {{dataItem.Users}}
              <mat-icon *ngIf="dataItem.Users >= 1">trending_flat</mat-icon>
          </span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="Cases" title="# of Cases"  [headerClass]="'header'"></kendo-grid-column>
        <kendo-grid-column field="Products" title="# of Products"  [headerClass]="'header'"></kendo-grid-column>
        <kendo-grid-column field="NegativePct" title="# of Reds"  [headerClass]="'header'">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.NegativePct}}%
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="NeutralPct" title="# of Yellows" [headerClass]="'header'">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.NeutralPct}}%
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="PositivePct" title="# of Greens" [headerClass]="'header'">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.PositivePct}}%
          </ng-template>
        </kendo-grid-column>

      </kendo-grid> -->

  </mat-card-content>
  <ngx-loading [show]="loading" [config]="{fullScreenBackdrop: false}"></ngx-loading>
</mat-card>
