import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
} from '@angular/core';

@Component({
  selector: 'app-grid-column-filter',
  templateUrl: './grid-column-filter.component.html',
  styleUrls: ['./grid-column-filter.component.scss'],
})
export class GridColumnFilterComponent implements OnInit, OnChanges {
  @Input() columns: [];
  @Output() columnsUpdated = new EventEmitter();

  updatedColumns = [];

  ngOnInit(): void {
    this.updateIncomingHeaders(this.columns);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('columns')) {
      this.updateIncomingHeaders(changes.columns.currentValue);
    }
  }

  selectionChange(ev, val) {
    ev.stopPropagation();
    ev.preventDefault();

    if (ev.target) {
      ev.target.classList.toggle('selected');
    }

    if (this.updatedColumns.includes(val)) {
      const idx = this.updatedColumns.indexOf(val);
      this.updatedColumns.splice(idx, 1);
    } else {
      this.updatedColumns.push(val);
    }

    this.columnsUpdated.emit(this.updatedColumns);
  }

  updateIncomingHeaders(cols) {
    if (cols) {
      this.updatedColumns = cols
        .map(x => {
          if (!x['Hidden']) {
            return x['DataField'];
          }
        })
        .filter(x => typeof x != 'undefined');
    }
  }
}
