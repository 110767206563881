import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { EnvironmentService } from '../../services/environment.service';

@Component({
  selector: 'app-save-unit-profile-dialog',
  templateUrl: './save-unit-profile-dialog.component.html',
  styleUrls: ['./save-unit-profile-dialog.component.scss'],
})
export class SaveUnitProfileDialogComponent implements OnInit {
  note = '';
  bugTrackerId = '';
  noteEntry = new Subject<string>();
  fieldTouched = false;
  noteRequired = false;

  constructor(
    private envSvc: EnvironmentService,
    private dialogRef: MatDialogRef<SaveUnitProfileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.noteRequired = this.envSvc.get().production;
    this.noteEntry
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe(x => {
        this.fieldTouched = true;
      });
  }

  saveUnit() {
    let sendNote;

    if (
      !this.noteRequired &&
      (!this.note || this.note.length < 1) &&
      (!this.bugTrackerId || this.bugTrackerId.length < 1)
    ) {
      sendNote = 'N/A';
    }

    if (
      !this.noteRequired &&
      (!this.note || this.note.length < 1) &&
      (this.bugTrackerId || this.bugTrackerId.length < 1)
    ) {
      sendNote = `${this.bugTrackerId}: N/A`;
    }

    if (
      !this.noteRequired &&
      (this.note || this.note.length >= 1) &&
      (this.bugTrackerId || this.bugTrackerId.length < 1)
    ) {
      sendNote = `bugid#${this.bugTrackerId}: ${this.note}`;
    }

    if (
      this.noteRequired &&
      (this.note || this.note.length >= 1) &&
      (this.bugTrackerId || this.bugTrackerId.length < 1)
    ) {
      sendNote = `bugid#${this.bugTrackerId}: ${this.note}`;
    }

    this.dialogRef.close({
      status: 'continue',
      note: encodeURIComponent(sendNote),
    });
  }

  close() {
    this.dialogRef.close({ status: 'cancel' });
  }
}
