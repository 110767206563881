import { Component, OnInit, Inject } from '@angular/core';
import {
  FormBuilder,
  UntypedFormGroup,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SuggestionEngineService } from '../suggestion-engine.service';

@Component({
  selector: 'app-action-dialog',
  templateUrl: './action-dialog.component.html',
  styleUrls: ['./action-dialog.component.scss'],
})
export class ActionDialogComponent implements OnInit {
  action = { type: '2335', notes: null, futureLikes: false };

  constructor(
    public dialogRef: MatDialogRef<ActionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ses: SuggestionEngineService
  ) {}

  ngOnInit() {}

  cancel() {
    this.dialogRef.close({ data: 'cancel' });
  }

  save() {
    this.ses
      .takeDailyAction(this.data.id, this.action.type, this.data.bridge)
      .subscribe(x => {
        return x;
      });
    // if(this.form.valid) {
    //   this.dialogRef.close({ data: this.form.get('reason').value });
    //   this.dialogRef.afterClosed().subscribe(result => {
    //     this.dialogRef = null;
    //   });
    // }
  }
}
