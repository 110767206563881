import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-loading-dialog',
  templateUrl: './loading-dialog.component.html',
  styleUrls: ['./loading-dialog.component.scss'],
})
export class LoadingDialogComponent implements OnInit {
  headline: string;
  content: string;
  confirm: string;
  loadingVal = 0;

  constructor(
    public dialogRef: MatDialogRef<LoadingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.headline = data.headline || 'Loading';
    this.content = data.content;
  }

  ngOnInit() {
    setTimeout(() => {
      this.loadingVal = 10;
    }, 1000);
    setTimeout(() => {
      this.loadingVal = 17;
    }, 2500);
    setTimeout(() => {
      this.loadingVal = 25;
    }, 3500);
    setTimeout(() => {
      this.loadingVal = 37;
    }, 4500);
    setTimeout(() => {
      this.loadingVal = 45;
    }, 5000);
    setTimeout(() => {
      this.loadingVal = 60;
    }, 6570);
    setTimeout(() => {
      this.loadingVal = 63;
    }, 7500);
    setTimeout(() => {
      this.loadingVal = 65;
    }, 8000);
    setTimeout(() => {
      this.loadingVal = 68;
    }, 10000);
    setTimeout(() => {
      this.loadingVal = 70;
    }, 11500);
    setTimeout(() => {
      this.loadingVal = 78;
    }, 12750);
    setTimeout(() => {
      this.loadingVal = 83;
    }, 14000);
    setTimeout(() => {
      this.loadingVal = 86;
    }, 15000);
    setTimeout(() => {
      this.loadingVal = 90;
    }, 17500);
    setTimeout(() => {
      this.loadingVal = 93;
    }, 19000);
    setTimeout(() => {
      this.loadingVal = 95;
    }, 20000);
    setTimeout(() => {
      this.loadingVal = 95;
    }, 25000);
    setTimeout(() => {
      this.loadingVal = 98;
    }, 28000);
  }

  progressColor() {
    if (this.loadingVal === 100) {
      return 'accent';
    }
    return 'primary';
  }

  endModal() {
    this.loadingVal = 100;

    setTimeout(() => {
      this.dialogRef.close();
    }, 1000);
  }
}
