<!-- <mat-dialog-header> -->
  <h1>Unit Profile History</h1>
<!-- </mat-dialog-header> -->
<!-- This stupid fucking compiler -->
<mat-dialog-content class="dialog-container row">
  <table class="w-100">
    <thead>
      <th>Action</th>
      <th>Date</th>
      <th>User</th>
      <th>KB Version</th>
      <th>Solution</th>
      <th>Unit</th>
      <th></th>
    </thead>
    <tbody>
      <tr *ngFor="let entry of historyData">
        <td>
          <mat-icon *ngIf="entry.Action == 'Upload'" [matTooltip]="entry.Action" color="primary">file_upload</mat-icon>
          <mat-icon *ngIf="entry.Action == 'Save'" [matTooltip]="entry.Action" color="accent">save</mat-icon>
          <mat-icon *ngIf="entry.Action == 'GridSave'" [matTooltip]="entry.Action" color="accent">view_quilt</mat-icon>
          <mat-icon *ngIf="entry.Action == 'Rollback'" [matTooltip]="entry.Action" color="warn">unarchive</mat-icon>
        </td>
        <td>{{entry.ChangeDate}}</td>
        <td>{{entry.UserId}}</td>
        <td>{{entry.KBVersion}}</td>
        <td>{{entry.Solution}}</td>
        <td>{{entry.UnitID}}</td>
        <td class="text-center">
          <button mat-button color="primary" class="mr-3" *hasPermission="'UnitProfileDownload'" [matTooltip]="'Download This Unit Profile as JSON'" (click)="download($event, entry.ID, 'json')">
            <mat-icon class="mr-2">file_download</mat-icon>JSON
          </button>
          <button mat-button color="primary" class="mr-3" *hasPermission="'UnitProfileDownload'" [matTooltip]="'Download This Unit Profile as XML'" (click)="download($event, entry.ID, 'xml')">
            <mat-icon class="mr-2">file_download</mat-icon>XML
          </button>
          <button mat-button color="primary" *hasPermission="'UnitProfileRollback'" [matTooltip]="'Rollback to This Unit Profile'" (click)="rollback($event, entry.ID)">
            <mat-icon class="mr-2">unarchive</mat-icon>Rollback
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</mat-dialog-content>
<mat-dialog-actions class="row buttons">
  <button mat-raised-button (click)="close()">Close</button>
</mat-dialog-actions>
