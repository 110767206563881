import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SessionStorageService } from '../../services/session-storage.service';
import { RightBridgeApiService } from '../../services/right-bridge-api.service';
import { ReportParams } from '../../models/unified-print-model';
import { Platform } from '@angular/cdk/platform';
import { EnvironmentService } from '../../services/environment.service';
import { saveAs } from '@progress/kendo-file-saver';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-unified-print',
  templateUrl: './unified-print.component.html',
  styleUrls: ['./unified-print.component.scss'],
})
export class UnifiedPrintComponent implements OnInit {
  @Input() profile;
  @Input() silentPrint;
  @Input() appStatus;
  @Output() closeDialog = new EventEmitter();

  customFilename;
  printParams;
  appAccess = [];
  enablePrint = {
    AWReport: false,
    AWClientReport: false,
    AnnuityWizardExchange: false,
    ProductProfiler: false,
    InvestmentWizard: false,
    LifeWizard: false,
    FeeCompare: false,
    Rollover: false,
  };
  loading = false;
  awProductsSelected = false;
  reportPayload: ReportParams = {} as ReportParams;
  rights: string[];
  firm;
  environment;
  baseUrl;
  generating = false;
  enableFeeCompare = false;
  showFeeCompare = false;

  constructor(
    private rbs: RightBridgeApiService,
    private params: ActivatedRoute,
    private ss: SessionStorageService,
    private pltfrm: Platform,
    private envSvc: EnvironmentService
  ) {}

  ngOnInit(): void {
    this.environment = this.envSvc.get();
    this.baseUrl = this.environment.apiBase;
    this.loading = true;
    this.appAccess = this.ss.get('globals')?.Modules;
    this.rights = this.ss.get('rights');
    this.firm = this.ss.get('firm');

    this.reportPayload = {
      profileID: null,
      FeeCompare: {
        includeIW: false,
        includeAW: false,
        includeLI: false,
      },
      ProductProfiler: {
        includeDetail: false,
        returnReport: false,
        IncludeFeeCompare: false,
        UseRolloverReport: false,
      },
      InvestmentWizard: {
        includeDetail: false,
        firmID: null,
        includeAlternatives: false,
        includeFundAlternativesChart: false,
      },
      AWReport: {
        bridgeIds: [],
        ivao: false,
        isValidation: false,
        excludeAlternatives: false,
      },
      AWClientReport: {
        bridgeIds: [],
        isValidation: true,
        excludeAlternatives: false,
        includeVAInvestmentOptions: false,
        returnReport: false,
      },
      AnnuityWizardExchange: {
        returnReport: false,
      },
      LifeWizard: {
        bridgeIds: [],
      },
    };

    if (this.appStatus) {
      this.enablePrint.ProductProfiler = this.appStatus.pp;
      this.enablePrint.InvestmentWizard = this.appStatus.iw;
      this.enablePrint.AWClientReport =
        this.appStatus.aw && this.rights.includes('AwCFVReport');
      this.enablePrint.AWReport =
        this.appStatus.aw && this.rights.includes('AwFullReport');
      this.enablePrint.LifeWizard = this.appStatus.li;
    }

    this.getPrintData();
  }

  getPrintData() {
    this.loading = true;
    this.rbs.getUnifiedPrintParameters(this.profile).subscribe(res => {
      this.printParams = res;

      if (
        (Object.keys(this.printParams.AWSelectedProductAccumulation).length >
          0 &&
          this.printParams.AWSelectedProductAccumulation.CarrierName.length >
            0) ||
        (Object.keys(this.printParams.AWSelectedProductDeathBenefit).length >
          0 &&
          this.printParams.AWSelectedProductDeathBenefit.CarrierName.length >
            0) ||
        (Object.keys(this.printParams.AWSelectedProductIncome).length > 0 &&
          this.printParams.AWSelectedProductIncome.CarrierName.length > 0)
      ) {
        this.awProductsSelected = true;
        this.reportPayload.FeeCompare.includeAW = true;
      }
      if (this.printParams.UnitPrintDefaults) {
        if (
          this.printParams.UnitPrintDefaults.ConsolidatedSignaturePageInclude
        ) {
          this.reportPayload['SignaturePage'] = {};
          this.reportPayload['SignaturePage']['include'] =
            this.printParams.UnitPrintDefaults.ConsolidatedSignaturePageFile;
        }

        if (
          this.printParams.PrintOutput.EnableFeeCompare &&
          (this.printParams.UnitPrintDefaults.PPIncludeFeeCompare == 1 ||
            this.printParams.UnitPrintDefaults.PPIncludeFeeCompare == 3) &&
          this.printParams.ClientProfilingOut.ValidPPRan &&
          this.printParams.ClientProfilingOut.ValidPPRan.toLowerCase() == 'y'
        ) {
          this.enablePrint.FeeCompare = true;
        }

        if (this.printParams.UnitPrintDefaults.AWAlternatives) {
          this.reportPayload.AWReport.excludeAlternatives = true;
          this.reportPayload.AWClientReport.excludeAlternatives = true;
        }

        if (
          this.printParams.UnitPrintDefaults.IWFundDetails == 1 ||
          this.printParams.UnitPrintDefaults.IWFundDetails == 3
        ) {
          this.reportPayload.InvestmentWizard.includeDetail = true;
        }

        if (
          this.printParams.UnitPrintDefaults.IWProgramDetails == 1 ||
          this.printParams.UnitPrintDefaults.IWProgramDetails == 3
        ) {
          this.reportPayload.InvestmentWizard.includeAlternatives = true;
        }

        if (
          this.printParams.UnitPrintDefaults.IWFundAlternatives == 1 ||
          this.printParams.UnitPrintDefaults.IWFundAlternatives == 3
        ) {
          this.reportPayload.InvestmentWizard.includeFundAlternativesChart =
            true;
        }
      } else {
        this.printParams.UnitPrintDefaults = {};
      }

      if (
        this.printParams.ClientProfilingOut.ValidPPRan &&
        this.printParams.ClientProfilingOut.ValidPPRan.toLowerCase() == 'y'
      ) {
        this.enableFeeCompare = true;
      }

      this.showFeeCompare = this.printParams.PrintOutput.EnableFeeCompare;

      if (this.printParams.InvestmentWizard.SelectedProgram) {
        this.reportPayload.FeeCompare.includeIW = true;
      }

      if (this.printParams.LISelectedProduct.LifeItemID) {
        this.reportPayload.FeeCompare.includeLI = true;
      }

      if (this.printParams.UnitPrintDefaults.HideConsolidatedPrintModal) {
        this.generateReport();
      }

      this.loading = false;
    });
  }

  updateApps(ev, app) {
    this.enablePrint[app] = !this.enablePrint[app];

    if (app == 'ProductProfiler' && this.enablePrint.FeeCompare) {
      this.reportPayload.ProductProfiler.IncludeFeeCompare = true;
    }
  }

  updateReportParams(ev, section, field) {
    this.reportPayload[section][field] = !this.reportPayload[section][field];
  }

  cancel() {
    this.closeDialog.emit();
  }

  generateReport() {
    this.loading = true;

    this.reportPayload.profileID = this.profile;

    const payload = cloneDeep(this.reportPayload);

    Object.keys(this.enablePrint).forEach(x => {
      if (!this.enablePrint[x]) {
        delete payload[x];
      }
    });

    if (payload.AWReport) {
      if (
        Object.keys(this.printParams.AWSelectedProductAccumulation).length > 0
      ) {
        payload.AWReport.bridgeIds =
          this.printParams.AWSelectedProductAccumulation.BridgeID;
      } else if (
        Object.keys(this.printParams.AWSelectedProductDeathBenefit).length > 0
      ) {
        payload.AWReport.bridgeIds =
          this.printParams.AWSelectedProductDeathBenefit.BridgeID;
      } else if (
        Object.keys(this.printParams.AWSelectedProductIncome).length > 0
      ) {
        payload.AWReport.bridgeIds =
          this.printParams.AWSelectedProductIncome.BridgeID;
      }
    }

    if (payload.AWClientReport) {
      if (
        Object.keys(this.printParams.AWSelectedProductAccumulation).length > 0
      ) {
        payload.AWClientReport.bridgeIds =
          this.printParams.AWSelectedProductAccumulation.BridgeID;
      } else if (
        Object.keys(this.printParams.AWSelectedProductDeathBenefit).length > 0
      ) {
        payload.AWClientReport.bridgeIds =
          this.printParams.AWSelectedProductDeathBenefit.BridgeID;
      } else if (
        Object.keys(this.printParams.AWSelectedProductIncome).length > 0
      ) {
        payload.AWClientReport.bridgeIds =
          this.printParams.AWSelectedProductIncome.BridgeID;
      }
    }

    if (payload.InvestmentWizard) {
      payload.InvestmentWizard.firmID = this.firm;
    }

    if (payload.LifeWizard) {
      payload.LifeWizard.bridgeIds =
        this.printParams.LISelectedProduct.LifeItemID;
    }

    if (this.silentPrint) {
      this.closeDialog.emit(payload);
    } else {
      this.generating = true;
      const date = new Date();

      this.rbs.getUnifiedReport(payload, 'rb').subscribe(data => {
        const filename = this.customFilename
          ? this.customFilename
          : 'RightBridge-UnifiedReport-' +
            (date.getMonth() + 1) +
            date.getDate() +
            date.getFullYear() +
            '.pdf';
        const iosSafari =
          this.pltfrm.IOS ||
          (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
        let fileData;
        const reader = new FileReader();

        reader.readAsDataURL(data);
        reader.onloadend = () => {
          fileData = reader.result;
          saveAs(fileData, filename, {
            proxyURL: `${this.baseUrl}/util/proxy`,
            proxyTarget: '_self',
            forceProxy: iosSafari,
          });
          this.closeDialog.emit('report generated');
          this.generating = false;
          this.loading = false;
        };
      });
    }
  }
}
