import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { UniversalSearchService } from '../../universal-search/services/universal-search.service';
import { switchMap, debounceTime, tap, finalize } from 'rxjs/operators';
import { HasPermissionDirective } from '../../directives/permissions.directive';

@Component({
  selector: 'app-toolbar-search',
  templateUrl: './toolbar-search.component.html',
  styleUrls: ['./toolbar-search.component.scss'],
})
export class ToolbarSearchComponent implements OnInit {
  @ViewChild(HasPermissionDirective) permDirect: HasPermissionDirective;

  searchValue = null;
  searchForm: FormGroup;
  filteredSearch = [];
  isLoading = false;

  constructor(
    private route: Router,
    private fb: FormBuilder,
    private uss: UniversalSearchService
  ) {}

  ngOnInit() {
    this.searchForm = this.fb.group({
      searchInput: null,
    });

    this.searchForm
      .get('searchInput')
      .valueChanges.pipe(
        debounceTime(300),
        tap(() => (this.isLoading = true)),
        switchMap(value =>
          this.uss
            .nameSearch(value)
            .pipe(finalize(() => (this.isLoading = false)))
        )
      )
      .subscribe(results => (this.filteredSearch = results.names));
  }

  navSearch(searchType) {
    this.route.navigate(['/se/search', { type: searchType }]);
  }

  searchClosed() {
    // if (!this.searchControl || !this.searchControl.value) {
    //   this.rows = this.ogData;
    // }
  }

  handleSelect(result) {
    this.route.navigate([`/se/client/${result.option.value.id}`]);
  }

  clearSearch() {
    this.searchValue = null;
    // this.searchControl.setValue('');
  }

  displayFn(option?) {
    if (option) {
      return `${option.first} ${option.last}`;
    }
  }
}
