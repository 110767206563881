import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SessionStorageService }  from '../../services/session-storage.service'
import { RightBridgeApiService } from '../../services/right-bridge-api.service';
import { Platform } from '@angular/cdk/platform';
import { EnvironmentService } from '../../services/environment.service';
import { saveAs } from '@progress/kendo-file-saver';
import { cloneDeep } from 'lodash';
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";

@Component({
  selector: 'app-ag-print',
  templateUrl: './ag-print.component.html',
  styleUrls: ['./ag-print.component.scss']
})
export class AgPrintComponent implements OnInit {
  @Input() profile;
  @Input() silentPrint;
  @Input() customDefinition;
  @Output() closeDialog = new EventEmitter();

  printForm: FormGroup;
  printParams;
  appAccess = [];
  enablePrint = {
    AnnuityWizard: true,
    ProductProfiler: true,
    InvestmentWizard: true,
    LifeWizard: true,
  };
  loading = false;
  awProductsSelected = false;
  rights: string[];
  firm;
  environment;
  baseUrl;
  generating = false;
  filename;

  constructor(
    private rbs: RightBridgeApiService,
    private params: ActivatedRoute,
    private ss: SessionStorageService,
    private pltfrm: Platform,
    private envSvc: EnvironmentService
  ) { }

  ngOnInit(): void {
    this.environment = this.envSvc.get();
    this.baseUrl = this.environment.apiBase;
    this.loading = true;
    this.appAccess = this.ss.get('globals')?.Modules;
    this.rights = this.ss.get('rights');
    this.firm = this.ss.get('firm');

    this.getPrintData();
  }

  getPrintData() {
    this.loading = true;
    this.rbs.getCustomPrintParameters(this.profile, this.customDefinition?.GroupName).subscribe(res => {
      this.printParams = res;
      if (
        Object.keys(this.printParams.AWSelectedProductAccumulation).length > 0 && this.printParams.AWSelectedProductAccumulation.CarrierName.length > 0 ||
        Object.keys(this.printParams.AWSelectedProductDeathBenefit).length > 0 && this.printParams.AWSelectedProductDeathBenefit.CarrierName.length > 0 ||
        Object.keys(this.printParams.AWSelectedProductIncome).length > 0 && this.printParams.AWSelectedProductIncome.CarrierName.length > 0
      ) {
        this.awProductsSelected = true;
      }

      if (!this.printParams.AWProfilingResults.AWRan || this.printParams.AWProfilingResults.AWRan != 'Y' || !this.awProductsSelected) {
        this.enablePrint.AnnuityWizard = false;
      }

      if (!this.printParams.IWProfilingResults.IWRan || this.printParams.IWProfilingResults.IWRan != 'Y') {
        this.enablePrint.InvestmentWizard = false;
      }

      if (!this.printParams.LIProfilingResults.LIRan || this.printParams.LIProfilingResults.LIRan != 'Y') {
        this.enablePrint.LifeWizard = false;
      }

      this.loading = false;
    })
  }

  updateApps(ev, app) {
    this.enablePrint[app] = !this.enablePrint[app];
  }

  cancel() {
    this.loading = false;
    this.closeDialog.emit({
      result: 'cancel'
    });
  }

  generateReport() {
    this.loading = true;

    let payload = {
      ReportType: this.customDefinition.ReportName,
      ProfileID: this.profile,
      ...cloneDeep(this.enablePrint)
    }

    if (this.silentPrint) {
      this.closeDialog.emit(payload);
    } else {
      this.generating = true;

      this.rbs.getCustomReport(payload, this.customDefinition.Solution).subscribe(data => {
        let date = new Date();
        // let filename = result.filename && result.filename != null ? result.filename + '.pdf' : "ProductProfiler-" + date.getMonth() + date.getDay() + date.getFullYear() + ".pdf";
        let filename = this.filename ? this.filename : "RightBridge-Report-" + date.getMonth() + date.getDay() + date.getFullYear() + ".pdf";

        let iosSafari = this.pltfrm.IOS || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

        let fileData;
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onloadend = () => {
          fileData = reader.result;
          saveAs(fileData, filename, {
            proxyURL: `${this.baseUrl}/util/proxy`,
            proxyTarget: '_self',
            forceProxy: iosSafari
          });
          this.closeDialog.emit('report generated');
          this.generating = false;
          this.loading = false;
        };
      },
      catchError((err) => {
        this.loading = false;
        return throwError(err.error || "Server error");
      }));
    }
  }
}
